import { Grid, InputAdornment, TextField } from '@mui/material';
import {
  addUserTry,
  callUserTry,
  editUserTry,
  postActivity,
} from '@thedavid/plitzredux/actions';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Alert, Card, Col, Row } from 'reactstrap';
import axios from 'axios';
import Switch from 'react-switch';
import PropTypes from 'prop-types';

// Parts
import Loader from '../../components/Loader';
import Dropzone from 'react-dropzone';

const AddUser = ({ id, toggle }) => {
  const currentClient = localStorage.getItem('tenant');
  const dispatch = useDispatch();
  const history = useNavigate();

  const [formData, setFormData] = useState({
    verified: true,
    isAdmin: false,
    fullname: '',
    companyId: currentClient,
    position: '',
    bio: '',
    email: '',
    password: '',
    photo: 'uploads/user-place-holder.jpg',
    linkedin: '',
    facebook: '',
    twitter: '',
    instagram: '',
  });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [filesError, setFilesError] = useState('');
  const [showError, setShowError] = useState(false);
  const [whatType, setWhatType] = useState('password');
  const [currenIcon, setCurrentIcon] = useState('mdi-eye');
  const [fullReq, setFullReq] = useState(false);

  const {
    verified,
    isAdmin,
    fullname,
    position,
    bio,
    email,
    password,
    linkedin,
    facebook,
    twitter,
    instagram,
  } = formData;

  useEffect(() => {
    if (id && id !== '') {
      dispatch(callUserTry(id));
    }
  }, [dispatch, id]);

  const Login = useSelector((state) => state.Login);
  const { user, otheruser, error, loading } = Login;

  const handleAcceptedFiles = (file) => {
    if (file.length > 1) {
      setFilesError('Only one file is allowed');
      setShowError(true);
    } else {
      file.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      );
      setShowError(false);
      setSelectedFiles(file);
    }
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  const changeType = (e) => {
    e.preventDefault();
    if (whatType === 'password') {
      setCurrentIcon('mdi-eye-off');
      setWhatType('text');
    } else {
      setCurrentIcon('mdi-eye');
      setWhatType('password');
    }
  };

  useEffect(() => {
    if (otheruser && otheruser !== null) {
      setFormData({
        _id: otheruser._id ? otheruser._id : null,
        verified: otheruser.verified ? otheruser.verified : false,
        isAdmin: otheruser.isAdmin ? otheruser.isAdmin : false,
        fullname: otheruser.fullname ? otheruser.fullname : '',
        companyId: otheruser.companyId ? otheruser.companyId : '',
        position: otheruser.position ? otheruser.position : '',
        bio: otheruser.bio ? otheruser.bio : '',
        email: otheruser.email ? otheruser.email : '',
        linkedin:
          otheruser.social && otheruser.social.linkedin
            ? otheruser.social.linkedin
            : '',
        facebook:
          otheruser.social && otheruser.social.facebook
            ? otheruser.social.facebook
            : '',
        twitter:
          otheruser.social && otheruser.social.twitter
            ? otheruser.social.twitter
            : '',
        instagram:
          otheruser.social && otheruser.social.instagram
            ? otheruser.social.instagram
            : '',
      });
    }
  }, [otheruser]);

  useEffect(() => {
    if (showError) {
      setTimeout(() => {
        setShowError(false);
      }, 12000);
    }
  }, [showError]);

  const onChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const switchButton = () => {
    if (isAdmin) {
      setFormData({
        ...formData,
        isAdmin: false,
      });
    } else {
      setFormData({
        ...formData,
        isAdmin: true,
      });
    }
  };

  const switchButton2 = () => {
    if (verified) {
      setFormData({
        ...formData,
        verified: false,
      });
    } else {
      setFormData({
        ...formData,
        verified: true,
      });
    }
  };

  useEffect(() => {
    if (selectedFiles.length > 0) {
      const fd = new FormData();
      fd.append('newimg', selectedFiles[0]);

      try {
        axios
          .post(`${process.env.REACT_APP_PUBLIC_URL}api/uploads`, fd, {
            headers: {
              'Content-Type': 'multipart/form-data',
              storedid: currentClient,
            },
          })
          .then((res) => {
            setFormData({
              ...formData,
              photo: res.data.filePath,
            });
          })
          .catch((error) => {
            setFilesError('Error after uploading file');
            setShowError(true);
          });
      } catch (err) {
        if (err.response.status === 500) {
          setFilesError('There was a problem with the server');
          setShowError(true);
        } else {
          setFilesError(err.response.data.msg);
        }
      }
    }

    // eslint-disable-next-line
  }, [selectedFiles]);

  useEffect(() => {
    if (error) {
      dispatch(
        postActivity({
          logtype: 'negative',
          logcontent: `An error occurred creating user: <strong>${error}</strong>`,
          email: '',
        })
      );
    }

    // eslint-disable-next-line
  }, [error]);

  const resetForm = (e) => {
    e.preventDefault();
    setFormData({
      verified: true,
      isAdmin: false,
      fullname: '',
      position: '',
      bio: '',
      email: '',
      password: '',
      photo: 'uploads/user-place-holder.jpg',
      linkedin: '',
      facebook: '',
      twitter: '',
      instagram: '',
    });
    setSelectedFiles([]);
    setFilesError('');
    setShowError(false);
  };

  const submitEditError = useCallback(() => {
    setShowError(false);
    if (error && error !== null) {
      setShowError(true);
    } else {
      dispatch(
        postActivity({
          logtype: 'positive',
          logcontent: `The user ${
            otheruser && otheruser.email
          } has been modified`,
          email: user ? user.email : '',
        })
      );
      setFormData({
        verified: true,
        isAdmin: false,
        fullname: '',
        position: '',
        bio: '',
        email: '',
        photo: 'uploads/user-place-holder.jpg',
        linkedin: '',
        facebook: '',
        twitter: '',
        instagram: '',
      });
      toggle(false);
    }

    // eslint-disable-next-line
  }, [error]);

  const submittingError = useCallback(() => {
    setShowError(false);
    if (error && error !== null) {
      setShowError(true);
    } else {
      dispatch(
        postActivity({
          logtype: 'positive',
          logcontent: `A new user has been registered susccesfully`,
          email: user ? user.email : '',
        })
      );
      setFormData({
        verified: true,
        isAdmin: false,
        fullname: '',
        position: '',
        bio: '',
        email: '',
        password: '',
        photo: 'uploads/user-place-holder.jpg',
        linkedin: '',
        facebook: '',
        twitter: '',
        instagram: '',
      });
      history('/all-users');
    }

    // eslint-disable-next-line
  }, [error]);

  const onSubmit = (e) => {
    e.preventDefault();
    setShowError(false);
    if (id && id !== null) {
      if (fullname === '' || email === '') {
        setFullReq(true);
      } else {
        setFullReq(false);
        dispatch(editUserTry(formData, id));
        submitEditError();
      }
    } else {
      if (fullname === '' || email === '' || password === '') {
        setFullReq(true);
      } else {
        setFullReq(false);
        dispatch(addUserTry(formData));
        submittingError();
      }
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <div className='container-fluid plitz-forms'>
      <Row className='align-items-center'>
        <Col sm={6}>
          <div className='page-title-box'>
            <h1 className=''>{id && id !== null ? 'Edit User' : 'New User'}</h1>
            {id === undefined ? (
              <ol className='breadcrumb mb-0'>
                <li className='breadcrumb-item'>
                  <Link to='/dashboard'>Dashboard</Link>
                </li>
                <li className='breadcrumb-item'>
                  <Link to='/all-users'>All Users</Link>
                </li>
                <li className='breadcrumb-item'>Add User</li>
              </ol>
            ) : null}
          </div>
        </Col>
      </Row>

      <Row className='justify-content-center mb-2'>
        <Col xs={12} sm={11} md={id ? 11 : 8} lg={id ? 11 : 6}>
          <form className='form-horizontal mt-5' onSubmit={(e) => onSubmit(e)}>
            {error && showError ? <Alert color='danger'>{error}</Alert> : null}
            {showError && filesError !== '' && !error && (
              <Alert color='danger'>{filesError}</Alert>
            )}

            <div className='form-group mb-4'>
              <Switch
                uncheckedIcon={<Offsymbol2 />}
                checkedIcon={<OnSymbol2 />}
                onColor='#0b093a'
                onChange={switchButton2}
                checked={verified}
              />
              <span className='ml-1 mr-4'>
                {verified ? 'Active' : 'Inactive'}
              </span>

              <Switch
                uncheckedIcon={<Offsymbol />}
                checkedIcon={<OnSymbol />}
                onColor='#0b093a'
                onChange={switchButton}
                checked={isAdmin}
              />
              <span className='ml-1'>{isAdmin ? 'Admin' : 'Editor'}</span>
            </div>

            <div className='form-group'>
              <TextField
                error={fullReq}
                helperText={
                  fullReq && fullname === '' ? 'This field is required' : null
                }
                fullWidth
                variant='outlined'
                name='fullname'
                label='Full Name *'
                value={fullname}
                onChange={(e) => onChange(e)}
                placeholder='Enter your Full Name'
              />
            </div>

            <div className='form-group'>
              <TextField
                error={fullReq}
                helperText={
                  fullReq && email === '' ? 'This field is required' : null
                }
                fullWidth
                variant='outlined'
                name='email'
                label='Email *'
                value={email}
                onChange={(e) => onChange(e)}
                placeholder='Enter email'
              />
            </div>

            <div className='form-group'>
              <TextField
                fullWidth
                variant='outlined'
                name='position'
                label='Title or Position'
                value={position}
                onChange={(e) => onChange(e)}
                placeholder='Enter your Title or Position'
              />
            </div>

            <div className='form-group'>
              <TextField
                fullWidth
                variant='outlined'
                name='bio'
                label='User Bio'
                multiline
                rows={5}
                value={bio}
                onChange={(e) => onChange(e)}
                placeholder='Enter Bio'
              />
            </div>

            {id === undefined || id === null ? (
              <div
                className='form-group'
                style={{
                  position: 'relative',
                }}>
                <TextField
                  error={fullReq}
                  helperText={
                    fullReq && password === '' ? 'This field is required' : null
                  }
                  fullWidth
                  variant='outlined'
                  name='password'
                  label='Password *'
                  type={whatType}
                  value={password ? password : ''}
                  onChange={(e) => onChange(e)}
                  placeholder='Enter Password'
                />
                <button className='button-reveal-password' onClick={changeType}>
                  <i className={`mdi ${currenIcon}`}></i>
                </button>
              </div>
            ) : null}

            <div className='form-group'>
              <h6 className='pb-2'>Social Networks</h6>
              <TextField
                fullWidth
                variant='outlined'
                name='linkedin'
                label='LinkedIn Profile'
                value={linkedin}
                onChange={(e) => onChange(e)}
                placeholder='https://linkedin.com'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <i className='fab fa-linkedin'></i>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                fullWidth
                variant='outlined'
                name='facebook'
                label='Facebook Profile'
                value={facebook}
                onChange={(e) => onChange(e)}
                placeholder='https://facebook.com'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <i className='fab fa-facebook'></i>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                fullWidth
                variant='outlined'
                name='twitter'
                label='Twitter Profile'
                value={twitter}
                onChange={(e) => onChange(e)}
                placeholder='https://twitter.com'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <i className='fab fa-twitter'></i>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                fullWidth
                variant='outlined'
                name='instagram'
                label='Instagram Profile'
                value={instagram}
                onChange={(e) => onChange(e)}
                placeholder='https://instagram.com'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <i className='fab fa-instagram'></i>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div className='form-group'>
              <div className='col-12'>
                <small>Profile Picture</small>
                <Dropzone
                  style={{ minHeight: 150 }}
                  onDrop={(acceptedFiles) =>
                    handleAcceptedFiles(acceptedFiles)
                  }>
                  {({ getRootProps, getInputProps }) => (
                    <div className='dropzone' style={{ maxHeight: 250 }}>
                      <div
                        className='dz-message needsclick'
                        style={{ margin: 0 }}
                        {...getRootProps()}>
                        <input
                          {...getInputProps()}
                          accept='image/png, image/gif, image/jpeg, image/jpg, image/webp'
                        />
                        <h3>
                          Drop photo here or
                          <br />
                          click to upload.
                        </h3>
                      </div>
                    </div>
                  )}
                </Dropzone>
                <div className='dropzone-previews mt-3' id='file-previews'>
                  {selectedFiles.map((f, i) => {
                    return (
                      <Card
                        className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
                        key={i + '-file'}>
                        <div className='p-2'>
                          <Grid container className='align-items-center'>
                            <Grid item xs={6} className='col-auto'>
                              <div className='avatar-sm rounded bg-light'>
                                <img
                                  data-dz-thumbnail=''
                                  height='80'
                                  className='actual-image bg-light'
                                  alt={f.name}
                                  src={f.preview}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={6}>
                              <Link
                                to='#'
                                className='text-muted font-weight-bold'>
                                {f.name}
                              </Link>
                              <p className='mb-0'>
                                <strong>{f.formattedSize}</strong>
                              </p>
                            </Grid>
                          </Grid>
                        </div>
                      </Card>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className='form-group'>
              <div className='col-12 text-right'>
                <button
                  className='btn btn-primary w-md waves-effect waves-light'
                  type='submit'>
                  {id && id !== null ? 'Save Changes' : 'Create User'}
                </button>
                <button
                  className='btn btn-danger w-md waves-effect ml-2 waves-light'
                  type='button'
                  onClick={(e) => resetForm(e)}>
                  Reset
                </button>
              </div>
            </div>
          </form>
        </Col>
      </Row>
    </div>
  );
};

const Offsymbol2 = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: 12,
        color: '#fff',
        paddingRight: 2,
        paddingTop: 3,
      }}>
      {' '}
      <small>OFF</small>
    </div>
  );
};

const OnSymbol2 = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: 12,
        color: '#fff',
        paddingRight: 2,
        paddingTop: 3,
      }}>
      {' '}
      <small>ON</small>
    </div>
  );
};

const Offsymbol = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: 12,
        color: '#fff',
        paddingRight: 2,
        paddingTop: 3,
      }}>
      {' '}
      E
    </div>
  );
};

const OnSymbol = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: 12,
        color: '#fff',
        paddingRight: 2,
        paddingTop: 3,
      }}>
      {' '}
      A
    </div>
  );
};

AddUser.propTypes = {
  user: PropTypes.object,
  otheruser: PropTypes.object,
  id: PropTypes.string,
  toggle: PropTypes.func,
};

export default AddUser;
