import {
	ADD_PROMO_FAILED,
	ADD_PROMO_GO,
	ADD_PROMO_TRY,
	CLEAR_CURRENT,
	EDIT_PROMO_FAILED,
	EDIT_PROMO_GO,
	EDIT_PROMO_TRY,
	GET_PROMOS_FAILED,
	GET_PROMOS_GO,
	GET_PROMOS_TRY,
	GET_APROMO_TRY,
	GET_APROMO_GO,
	GET_APROMO_FAILED,
	REMOVE_PROMO_FAILED,
	REMOVE_PROMO_GO,
	REMOVE_PROMO_TRY,
} from './actionTypes';

export const getPromos = () => {
	return {
		type: GET_PROMOS_TRY,
	};
};

export const gettingPromos = (data) => {
	return {
		type: GET_PROMOS_GO,
		payload: data,
	};
};

export const getPromosFailed = (error) => {
	return {
		type: GET_PROMOS_FAILED,
		payload: error,
	};
};

export const getPromo = (id) => {
	return {
		type: GET_APROMO_TRY,
		payload: id,
	};
};

export const gettingPromo = (data) => {
	return {
		type: GET_APROMO_GO,
		payload: data,
	};
};

export const getPromoFailed = (error) => {
	return {
		type: GET_APROMO_FAILED,
		payload: error,
	};
};

export const clearCurrentPr = () => {
	return {
		type: CLEAR_CURRENT,
	};
};

export const addPromo = (formData) => {
	return {
		type: ADD_PROMO_TRY,
		payload: { formData },
	};
};

export const promoAdded = (data) => {
	return {
		type: ADD_PROMO_GO,
		payload: data,
	};
};

export const addPromoFailed = (error) => {
	return {
		type: ADD_PROMO_FAILED,
		payload: error,
	};
};

export const editPromo = (formData) => {
	return {
		type: EDIT_PROMO_TRY,
		payload: { formData },
	};
};

export const promoEdited = (data) => {
	return {
		type: EDIT_PROMO_GO,
		payload: data,
	};
};

export const editPromoFailed = (error) => {
	return {
		type: EDIT_PROMO_FAILED,
		payload: error,
	};
};

export const removePromo = (id) => {
	return {
		type: REMOVE_PROMO_TRY,
		payload: id,
	};
};

export const removingPromo = (data) => {
	return {
		type: REMOVE_PROMO_GO,
		payload: data,
	};
};

export const removePromoFailed = (error) => {
	return {
		type: REMOVE_PROMO_FAILED,
		payload: error,
	};
};
