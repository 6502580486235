import React, { useEffect, useState } from 'react';
import { Card, CardBody, Alert } from 'reactstrap';
import { Grid, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

// Redux
import { Link } from 'react-router-dom';

// Parts
import AuthFooter from '../../components/AuthFooter';
import AuthHeader from '../../components/AuthHeader';
import Loader from '../../components/Loader';

// Actions
import { passwordChange, postActivity } from '@thedavid/plitzredux/actions';

const ForgetPasswordPage = () => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [vali, setVali] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const Login = useSelector((state) => state.Login);
  const { loading, error, msg } = Login;

  const onChange = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  useEffect(() => {
    if (error) {
      setShowError(true);
      dispatch(
        postActivity({
          logtype: 'negative',
          logcontent: `The following error happened when a forgot-password action was submitted: <strong>${error}</strong>`,
          email: '',
        })
      );
      setTimeout(() => {
        setShowError(false);
      }, 6000);
    }

    if (msg) {
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
      }, 8000);
    }

    // eslint-disable-next-line
  }, [error, msg]);

  // handleValidSubmit
  const handleValidSubmit = (e) => {
    e.preventDefault();
    let action = 'ForgotPass';

    window.grecaptcha.enterprise.ready(() => {
      window.grecaptcha.enterprise
        .execute(process.env.REACT_APP_SITE_KEY, { action: action })
        .then((token) => {
          let payload = {
            token: token,
            action: action,
          };
          fetch(`${process.env.REACT_APP_PUBLIC_URL}api/recaptcha`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          }).then((data) => {
            if (data.status === 200) {
              if (email === '') {
                setVali(true);
              } else {
                setVali(false);
                dispatch(passwordChange(email));
                dispatch(
                  postActivity({
                    logtype: 'warning',
                    logcontent: `The user requested a change of password`,
                    email: email,
                  })
                );
              }
            } else {
              setShowError(true);
            }
          });
        });
    });
  };

  return (
    <React.Fragment>
      <div className='account-pages pt-5'>
        <div className='container'>
          <Grid
            container
            spacing={5}
            justifyContent='center'
            alignItems='center'
            className='justify-content-center'
          >
            <Grid item xs={12} sm={10} md={5}>
              <div className='position-relative'>
                {loading ? <Loader /> : null}
                <Card className='overflow-hidden'>
                  <AuthHeader
                    title='Password Reset'
                    intro='Enter your email below and we will send you a link to reset your password.'
                  />

                  <CardBody className='p-4'>
                    <div className='p-3'>
                      {showError ? (
                        <Alert
                          color='danger'
                          style={{
                            marginTop: '13px',
                          }}
                        >
                          {error}
                        </Alert>
                      ) : null}
                      {showSuccess ? (
                        <Alert
                          color='success'
                          style={{
                            marginTop: '13px',
                          }}
                        >
                          {msg}
                        </Alert>
                      ) : null}

                      <form
                        className='form-horizontal mt-5'
                        onSubmit={(e) => handleValidSubmit(e)}
                      >
                        <div className='form-group'>
                          <TextField
                            error={vali}
                            helperText={vali ? 'This field is required' : null}
                            fullWidth
                            variant='outlined'
                            name='email'
                            value={email}
                            label='Email'
                            type='email'
                            onChange={(e) => onChange(e)}
                          />
                        </div>
                        <Grid container className='form-group'>
                          <Grid item xs={7} sm={6} className='text-left'>
                            Go back to <Link to='/login'>Login</Link>
                          </Grid>
                          <Grid item xs={5} sm={6} className='text-right'>
                            <button
                              className='btn btn-primary w-md waves-effect waves-light'
                              type='submit'
                            >
                              Reset
                            </button>
                          </Grid>
                        </Grid>
                      </form>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Grid>
          </Grid>
        </div>
        <AuthFooter
          text={`Do you have an account? `}
          button={`/login`}
          buttonlabel={`Login Here`}
        />
      </div>
    </React.Fragment>
  );
};

export default ForgetPasswordPage;
