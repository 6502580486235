import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers =
  (typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const loggedToken =
  typeof window !== 'undefined' && localStorage.getItem('token')
    ? localStorage?.getItem('token')
    : null;

const layoutType =
  typeof window !== 'undefined' && localStorage.getItem('layoutType')
    ? localStorage.getItem('layoutType')
    : 'vertical';
const layoutWidth =
  typeof window !== 'undefined' && localStorage.getItem('layoutWidth')
    ? localStorage.getItem('layoutWidth')
    : 'fluid';
const leftSideBarTheme =
  typeof window !== 'undefined' && localStorage.getItem('leftSideBarTheme')
    ? localStorage.getItem('leftSideBarTheme')
    : 'light';
const leftSideBarType =
  typeof window !== 'undefined' && localStorage.getItem('leftSideBarType')
    ? localStorage.getItem('leftSideBarType')
    : 'default';
const topbarTheme =
  typeof window !== 'undefined' && localStorage.getItem('topbarTheme')
    ? localStorage.getItem('topbarTheme')
    : 'light';
const toggleRightSidebar =
  typeof window !== 'undefined' && localStorage.getItem('toggleRightSidebar')
    ? JSON.parse(localStorage.getItem('toggleRightSidebar'))
    : false;
const isMobile =
  typeof window !== 'undefined' && localStorage.getItem('isMobile')
    ? JSON.parse(localStorage.getItem('isMobile'))
    : false;

const initialState = {
  Login: {
    isAuthenticated: loggedToken !== null ? true : false,
    token: loggedToken,
  },
  Layout: {
    layoutType: layoutType,
    layoutWidth: layoutWidth,
    leftSideBarTheme: leftSideBarTheme,
    leftSideBarType: leftSideBarType,
    topbarTheme: topbarTheme,
    toggleRightSidebar: toggleRightSidebar,
    isMobile: isMobile,
  },
};

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);
sagaMiddleware.run(rootSaga);

export default store;
