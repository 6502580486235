import React from 'react';

const FullScreen = ({ toggleFullscreen }) => {
	return (
		<div className='dropdown d-none d-lg-inline-block'>
			<button
				type='button'
				className='btn header-item waves-effect'
				onClick={toggleFullscreen}
				data-toggle='fullscreen'
			>
				<i className='fa-light fa-arrows-maximize' style={{ fontSize: 22 }}></i>
			</button>
		</div>
	);
};

export default FullScreen;
