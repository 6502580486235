import axios from 'axios';

const rooturl = `${
  process.env.REACT_APP_PUBLIC_URL || process.env.NEXT_PUBLIC_URL
}api/logs`;

export const getActivityApi = async () => {
  const storedId =
    typeof window !== 'undefined' && localStorage.getItem('tenant');

  const config = {
    headers: {
      'Content-Type': 'application/json',
      storedId,
    },
  };

  const response = await axios.get(rooturl, config);

  return response;
};

export const postActivityApi = async (formData) => {
  const storedId =
    typeof window !== 'undefined' && localStorage.getItem('tenant');

  const config = {
    headers: {
      'Content-Type': 'application/json',
      storedId,
    },
  };

  const response = await axios.post(rooturl, formData, config);

  return response;
};
