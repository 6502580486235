export const GET_MENUCATEGORIES_TRY = 'GET_MENUCATEGORIES_TRY';
export const GET_MENUCATEGORIES_GO = 'GET_MENUCATEGORIES_GO';
export const GET_MENUCATEGORIES_FAILED = 'GET_MENUCATEGORIES_FAILED';

export const GET_AMENUCATEGORY_TRY = 'GET_AMENUCATEGORY_TRY';
export const GET_AMENUCATEGORY_GO = 'GET_AMENUCATEGORY_GO';
export const GET_AMENUCATEGORY_FAILED = 'GET_AMENUCATEGORY_FAILED';

export const ADD_MENUCATEGORY_TRY = 'ADD_MENUCATEGORY_TRY';
export const ADD_MENUCATEGORY_GO = 'ADD_MENUCATEGORY_GO';
export const ADD_MENUCATEGORY_FAILED = 'ADD_MENUCATEGORY_FAILED';

export const EDIT_MENUCATEGORY_TRY = 'EDIT_MENUCATEGORY_TRY';
export const EDIT_MENUCATEGORY_GO = 'EDIT_MENUCATEGORY_GO';
export const EDIT_MENUCATEGORY_FAILED = 'EDIT_MENUCATEGORY_FAILED';

export const REMOVE_MENUCATEGORY_TRY = 'REMOVE_MENUCATEGORY_TRY';
export const REMOVE_MENUCATEGORY_GO = 'REMOVE_MENUCATEGORY_GO';
export const REMOVE_MENUCATEGORY_FAILED = 'REMOVE_MENUCATEGORY_FAILED';

export const CLEAR_CURRENT = 'CLEAR_CURRENT';
