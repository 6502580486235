import React from 'react';
import { Spinner } from 'reactstrap';

/**
 * Renders the preloader
 */
const Loader = () => {
	return (
		<div
			className='preloader'
			style={{
				width: '100%',
				height: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<div className='status'>
				<Spinner color={'primary'} />
			</div>
		</div>
	);
};

export default Loader;
