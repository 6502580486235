import React, { Fragment, useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  UncontrolledTooltip,
  Alert,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import Paginator from 'react-hooks-paginator';
import PropTypes from 'prop-types';

// Parts
import SlidersEditor from '../../components/Editors/SlidersEditor';
import Loader from '../../components/Loader';

// Actions
import {
  clearCurrentSl,
  getSliders,
  postActivity,
  removeSlider,
} from '@thedavid/plitzredux/actions';

const AllSliders = () => {
  const dispatch = useDispatch();

  const sliderLimit = 10;

  const [offset, setOffset] = useState(0);
  const [currentSlider, setCurrentSlider] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState('');

  const Sliders = useSelector((state) => state.Sliders);
  const { sliders, loading, error, msg } = Sliders;
  const Login = useSelector((state) => state.Login);
  const { user, users } = Login;

  useEffect(() => {
    setCurrentData(
      sliders &&
        sliders
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .slice(offset, offset + sliderLimit)
    );
  }, [offset, sliders]);

  const setToggle = (sl) => {
    if (isOpen) {
      setIsOpen(false);
      setCurrentItem('');
      dispatch(clearCurrentSl());
    } else {
      setIsOpen(true);
      setCurrentItem(sl._id);
    }
  };

  const deleteSlider = (sl) => {
    dispatch(
      postActivity({
        logtype: 'warning',
        logcontent: `The slider <strong>${sl.title}</strong> has been removed`,
        email: user ? user.email : '',
      })
    );
    dispatch(removeSlider(sl._id));
  };

  useEffect(() => {
    dispatch(getSliders());
  }, [dispatch]);

  return (
    <Fragment>
      {isOpen && (
        <SlidersEditor isOpen={isOpen} toggle={setToggle} id={currentItem} />
      )}
      <div className='container-fluid plitz-cards'>
        <Row className='align-items-center'>
          <Col sm={6}>
            <div className='page-title-box'>
              <h1 className=''>CTAs</h1>
              <ol className='breadcrumb mb-0'>
                <li className='breadcrumb-item'>
                  <Link to='/dashboard'>Dashboard</Link>
                </li>
                <li className='breadcrumb-item'>All CTAs</li>
              </ol>
            </div>
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            {error ? <Alert color='danger'>{error}</Alert> : null}
            {msg ? <Alert color='warning'>{msg}</Alert> : null}
          </Col>
        </Row>

        <Row>
          {loading ? (
            <Loader />
          ) : currentData && currentData.length > 0 ? (
            currentData.map((sl) => (
              <Col key={sl._id} md={12}>
                <Card>
                  <Row>
                    <Col sm={12} md={5}>
                      <div className='card-img-top' style={{ maxHeight: 250 }}>
                        <img
                          className='img-fluid actual-image'
                          src={
                            sl.featuredimg
                              ? `${process.env.REACT_APP_PUBLIC_URL}${sl.featuredimg}`
                              : `${process.env.REACT_APP_PUBLIC_URL}uploads/big-placeholder.jpg`
                          }
                          alt={sl.title}
                        />
                      </div>
                    </Col>
                    <Col sm={12} md={7}>
                      <CardBody>
                        <div className='card-container'>
                          <div className='card-status-area'>
                            {sl.isLive ? (
                              <span className='badge badge-success'>
                                Published
                              </span>
                            ) : (
                              <span className='badge badge-dark'>Draft</span>
                            )}
                          </div>
                          <div className='card-content-area'>
                            <h3 className='mt-0'>{sl.title}</h3>
                            {sl.subtitle ? (
                              <h5 className='card-title  mt-0'>
                                Subtitle: {sl.subtitle}
                              </h5>
                            ) : null}
                            <div className='author-section'>
                              <small>Created by</small>
                              <h6 className='mb-0'>
                                {' '}
                                {users &&
                                  users.length > 0 &&
                                  users.map(
                                    (usr) =>
                                      usr._id === sl.user && (
                                        <div
                                          className='flex-horizontal'
                                          key={usr._id}>
                                          <div className='avatar-sm rounded-circle mr-2'>
                                            <img
                                              src={`${process.env.REACT_APP_PUBLIC_URL}${usr.photo}`}
                                              alt={usr.fullname}
                                              className='actual-image'
                                            />{' '}
                                          </div>
                                          <span>{usr.fullname}</span>
                                        </div>
                                      )
                                  )}
                              </h6>
                            </div>
                          </div>
                          <div className='card-actions-area'>
                            <div className='buttons-only'>
                              <Fragment>
                                <UncontrolledTooltip
                                  placement='top'
                                  target={`EditTt-${sl._id}`}>
                                  Edit
                                </UncontrolledTooltip>
                                <button
                                  id={`EditTt-${sl._id}`}
                                  type='button'
                                  onClick={() => setToggle(sl)}
                                  className='btn waves-effect text-primary waves-light'
                                  data-toggle='modal'
                                  data-target='#myModal'
                                  style={{
                                    fontSize: 20,
                                    padding: '0.1rem',
                                    margin: '0.5rem',
                                  }}>
                                  <i className='fa-light fa-edit'></i>
                                </button>{' '}
                              </Fragment>
                              <Fragment>
                                <UncontrolledTooltip
                                  placement='top'
                                  target={`RemoveFt-${sl._id}`}>
                                  Remove
                                </UncontrolledTooltip>
                                <button
                                  id={`RemoveFt-${sl._id}`}
                                  type='button'
                                  onClick={() => deleteSlider(sl)}
                                  className='btn waves-effect ml-2 text-danger waves-light'
                                  style={{
                                    fontSize: 20,
                                    padding: '0.1rem',
                                    margin: '0.5rem',
                                  }}>
                                  <i className='fa-light fa-trash'></i>
                                </button>{' '}
                              </Fragment>
                            </div>
                            <span>
                              Last Modification:{' '}
                              <Moment format='MMMM DD, YYYY'>
                                {sl.updatedAt}
                              </Moment>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))
          ) : (
            <h4>There is no sliders yet</h4>
          )}
        </Row>

        <Row>
          <Col sm={12}>
            {sliders && (
              <Paginator
                totalRecords={sliders.length}
                pageLimit={sliderLimit}
                pageNeighbours={2}
                setOffset={setOffset}
                currentPage={currentSlider}
                setCurrentPage={setCurrentSlider}
                pageContainerClass='pagination'
                pageActiveClass='current-page'
                pageNextText={<i className='typcn typcn-chevron-right'></i>}
                pageNextClass='pagination-arrow'
                pagePrevText={<i className='typcn typcn-chevron-left'></i>}
                pagePrevClass='pagination-arrow'
              />
            )}
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

AllSliders.propTypes = {
  sliders: PropTypes.array,
  user: PropTypes.object,
};

export default AllSliders;
