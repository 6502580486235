export const GET_PROMOS_TRY = 'GET_PROMOS_TRY';
export const GET_PROMOS_GO = 'GET_PROMOS_GO';
export const GET_PROMOS_FAILED = 'GET_PROMOS_FAILED';

export const GET_APROMO_TRY = 'GET_APROMO_TRY';
export const GET_APROMO_GO = 'GET_APROMO_GO';
export const GET_APROMO_FAILED = 'GET_APROMO_FAILED';

export const ADD_PROMO_TRY = 'ADD_PROMO_TRY';
export const ADD_PROMO_GO = 'ADD_PROMO_GO';
export const ADD_PROMO_FAILED = 'ADD_PROMO_FAILED';

export const EDIT_PROMO_TRY = 'EDIT_PROMO_TRY';
export const EDIT_PROMO_GO = 'EDIT_PROMO_GO';
export const EDIT_PROMO_FAILED = 'EDIT_PROMO_FAILED';

export const REMOVE_PROMO_TRY = 'REMOVE_PROMO_TRY';
export const REMOVE_PROMO_GO = 'REMOVE_PROMO_GO';
export const REMOVE_PROMO_FAILED = 'REMOVE_PROMO_FAILED';

export const CLEAR_CURRENT = 'CLEAR_CURRENT';
