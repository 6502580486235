import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  ADD_SPECIAL_FAILED,
  ADD_SPECIAL_GO,
  ADD_SPECIAL_TRY,
  EDIT_SPECIAL_FAILED,
  EDIT_SPECIAL_GO,
  EDIT_SPECIAL_TRY,
  GET_ASPECIAL_FAILED,
  GET_ASPECIAL_GO,
  GET_ASPECIAL_TRY,
  GET_SPECIALS_FAILED,
  GET_SPECIALS_GO,
  GET_SPECIALS_TRY,
  REMOVE_SPECIAL_FAILED,
  REMOVE_SPECIAL_GO,
  REMOVE_SPECIAL_TRY,
} from './actionTypes';

import {
  addSpecial,
  editSpecial,
  getAllSpecialsApi,
  getSpecialApi,
  removeSpecial,
} from '../../helpers/restaurants/specialApi';

function* theSpecialsPull() {
  try {
    const response = yield call(getAllSpecialsApi);

    yield put({ type: GET_SPECIALS_GO, payload: response.data });
  } catch (error) {
    yield put({
      type: GET_SPECIALS_FAILED,
      payload: error.response.data.message,
    });
  }
}

function* oneSpecialPull({ payload }) {
  try {
    const response = yield call(getSpecialApi, payload);

    yield put({ type: GET_ASPECIAL_GO, payload: response.data });
  } catch (error) {
    yield put({
      type: GET_ASPECIAL_FAILED,
      payload: error.response.data.message,
    });
  }
}

function* addingSpecial({ payload: { formData } }) {
  try {
    const response = yield call(addSpecial, formData);

    yield put({ type: ADD_SPECIAL_GO, payload: response.data });
  } catch (error) {
    yield put({
      type: ADD_SPECIAL_FAILED,
      payload: error.response.data.message,
    });
  }
}

function* editingSpecial({ payload: { formData } }) {
  try {
    const response = yield call(editSpecial, formData);

    yield put({ type: EDIT_SPECIAL_GO, payload: response.data });
  } catch (error) {
    yield put({
      type: EDIT_SPECIAL_FAILED,
      payload: error.response.data.message,
    });
  }
}

function* removingSpecial({ payload: id }) {
  try {
    const response = yield call(removeSpecial, id);

    yield put({ type: REMOVE_SPECIAL_GO, payload: response.data });
  } catch (error) {
    yield put({
      type: REMOVE_SPECIAL_FAILED,
      payload: error.response.data.message,
    });
  }
}

function* pullingSpecials() {
  yield takeLatest(GET_SPECIALS_TRY, theSpecialsPull);
}

function* pullingSpecial() {
  yield takeLatest(GET_ASPECIAL_TRY, oneSpecialPull);
}

function* addSpecialTry() {
  yield takeLatest(ADD_SPECIAL_TRY, addingSpecial);
}

function* editingSpecialTry() {
  yield takeLatest(EDIT_SPECIAL_TRY, editingSpecial);
}

function* removingSpecialTry() {
  yield takeLatest(REMOVE_SPECIAL_TRY, removingSpecial);
}

function* SpecialsSaga() {
  yield all([
    pullingSpecials(),
    pullingSpecial(),
    addSpecialTry(),
    editingSpecialTry(),
    removingSpecialTry(),
  ]);
}

export default SpecialsSaga;
