import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  ADD_LOCATION_FAILED,
  ADD_LOCATION_GO,
  ADD_LOCATION_TRY,
  EDIT_LOCATION_FAILED,
  EDIT_LOCATION_GO,
  EDIT_LOCATION_TRY,
  GET_ALOCATION_FAILED,
  GET_ALOCATION_GO,
  GET_ALOCATION_TRY,
  GET_LOCATIONS_FAILED,
  GET_LOCATIONS_GO,
  GET_LOCATIONS_TRY,
  REMOVE_LOCATION_FAILED,
  REMOVE_LOCATION_GO,
  REMOVE_LOCATION_TRY,
} from './actionTypes';

import {
  addLocation,
  editLocation,
  getAllLocationsApi,
  getLocationApi,
  removeLocation,
} from '../../helpers/restaurants/locationApi';

function* theLocationsPull() {
  try {
    const response = yield call(getAllLocationsApi);

    yield put({ type: GET_LOCATIONS_GO, payload: response.data });
  } catch (error) {
    yield put({
      type: GET_LOCATIONS_FAILED,
      payload: error.response.data.message,
    });
  }
}

function* oneLocationPull({ payload }) {
  try {
    const response = yield call(getLocationApi, payload);

    yield put({ type: GET_ALOCATION_GO, payload: response.data });
  } catch (error) {
    yield put({
      type: GET_ALOCATION_FAILED,
      payload: error.response.data.message,
    });
  }
}

function* addingLocation({ payload: { formData } }) {
  try {
    const response = yield call(addLocation, formData);

    yield put({ type: ADD_LOCATION_GO, payload: response.data });
  } catch (error) {
    yield put({
      type: ADD_LOCATION_FAILED,
      payload: error.response.data.message,
    });
  }
}

function* editingLocation({ payload: { formData } }) {
  try {
    const response = yield call(editLocation, formData);

    yield put({ type: EDIT_LOCATION_GO, payload: response.data });
  } catch (error) {
    yield put({
      type: EDIT_LOCATION_FAILED,
      payload: error.response.data.message,
    });
  }
}

function* removingLocation({ payload: id }) {
  try {
    const response = yield call(removeLocation, id);

    yield put({ type: REMOVE_LOCATION_GO, payload: response.data });
  } catch (error) {
    yield put({
      type: REMOVE_LOCATION_FAILED,
      payload: error.response.data.message,
    });
  }
}

function* pullingLocations() {
  yield takeLatest(GET_LOCATIONS_TRY, theLocationsPull);
}

function* pullingLocation() {
  yield takeLatest(GET_ALOCATION_TRY, oneLocationPull);
}

function* addLocationTry() {
  yield takeLatest(ADD_LOCATION_TRY, addingLocation);
}

function* editingLocationTry() {
  yield takeLatest(EDIT_LOCATION_TRY, editingLocation);
}

function* removingLocationTry() {
  yield takeLatest(REMOVE_LOCATION_TRY, removingLocation);
}

function* LocationsSaga() {
  yield all([
    pullingLocations(),
    pullingLocation(),
    addLocationTry(),
    editingLocationTry(),
    removingLocationTry(),
  ]);
}

export default LocationsSaga;
