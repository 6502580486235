import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, Alert } from 'reactstrap';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, TextField } from '@mui/material';

// // action
import { postActivity, registerUser } from '@thedavid/plitzredux/actions';

// Redux
import { Link, useNavigate } from 'react-router-dom';

// Parts
import Loader from '../../components/Loader';
import Dropzone from 'react-dropzone';
import AuthFooter from '../../components/AuthFooter';
import AuthHeader from '../../components/AuthHeader';

const Register = (props) => {
  const currentClient = localStorage.getItem('tenant');
  const dispatch = useDispatch();
  const history = useNavigate();
  const [formData, setFormData] = useState({
    fullname: '',
    companyId: '',
    email: '',
    password: '',
    photo: 'uploads/user-place-holder.jpg',
  });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [filesError, setFilesError] = useState('');
  const [showError, setShowError] = useState(false);
  const [whatType, setWhatType] = useState('password');
  const [currenIcon, setCurrentIcon] = useState('mdi-eye');
  const [fullReq, setFullReq] = useState(false);
  const [companyReq, setCompoanyReq] = useState(false);
  const [emailReq, setEmailReq] = useState(false);
  const [passReq, setPassReq] = useState(false);

  const { fullname, companyId, email, password } = formData;

  const Login = useSelector((state) => state.Login);
  const { isAuthenticated, error, loading } = Login;

  const redirect = props.search ? props.search.split('=')[1] : '/dashboard';

  const handleAcceptedFiles = (file) => {
    if (file.length > 1) {
      setFilesError('Only one file is allowed');
      setShowError(true);
    } else {
      file.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      );
      setShowError(false);
      setSelectedFiles(file);
    }
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  const changeType = (e) => {
    e.preventDefault();
    if (whatType === 'password') {
      setCurrentIcon('mdi-eye-off');
      setWhatType('text');
    } else {
      setCurrentIcon('mdi-eye');
      setWhatType('password');
    }
  };

  useEffect(() => {
    if (showError) {
      setTimeout(() => {
        setShowError(false);
      }, 12000);
    }
  }, [showError]);

  const onChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (isAuthenticated) {
      history(redirect);
    }

    // eslint-disable-next-line
  }, [isAuthenticated, redirect]);

  useEffect(() => {
    if (selectedFiles.length > 0) {
      const fd = new FormData();
      fd.append('newimg', selectedFiles[0]);

      try {
        axios
          .post(`${process.env.REACT_APP_PUBLIC_URL}api/uploads`, fd, {
            headers: {
              'Content-Type': 'multipart/form-data',
              storedid: currentClient,
            },
          })
          .then((res) => {
            setFormData({
              ...formData,
              photo: res.data.filePath,
            });
          })
          .catch((error) => {
            setFilesError('Error after uploading file');
            setShowError(true);
          });
      } catch (err) {
        if (err.response.status === 500) {
          setFilesError('There was a problem with the server');
          setShowError(true);
        } else {
          setFilesError(err.response.data.msg);
        }
      }
    }

    // eslint-disable-next-line
  }, [selectedFiles]);

  useEffect(() => {
    if (error) {
      dispatch(
        postActivity({
          logtype: 'negative',
          logcontent: `An error occurred registering the user: <strong>${error}</strong>`,
          email: '',
        })
      );
    }

    // eslint-disable-next-line
  }, [error]);

  const onSubmit = (e) => {
    e.preventDefault();
    setShowError(false);
    let action = 'ChangePass';

    window.grecaptcha.enterprise.ready(() => {
      window.grecaptcha.enterprise
        .execute(process.env.REACT_APP_SITE_KEY, { action: action })
        .then((token) => {
          let payload = {
            token: token,
            action: action,
          };
          fetch(`${process.env.REACT_APP_PUBLIC_URL}api/recaptcha`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          }).then((data) => {
            if (data.status === 200) {
              if (fullname === '') {
                setFullReq(true);
              } else if (companyId === '') {
                setCompoanyReq(true);
              } else if (email === '') {
                setEmailReq(true);
              } else if (password === '') {
                setPassReq(true);
              } else {
                setFullReq(false);
                setCompoanyReq(false);
                setEmailReq(false);
                setPassReq(false);
                dispatch(registerUser(formData));
                dispatch(
                  postActivity({
                    logtype: 'positive',
                    logcontent: `A new user has been registered susccesfully`,
                    email: formData.email,
                  })
                );
              }
            } else {
              setShowError(true);
            }
          });
        });
    });
  };

  return (
    <Fragment>
      <div className='account-pages register-page pt-5'>
        <div className='container'>
          <Grid
            container
            spacing={3}
            justifyContent='center'
            alignItems='center'
            className='justify-content-center'>
            <Grid item xs={12} sm={10} md={5}>
              <h4>Hi there, are you ready?</h4>
              <h1>Get full access to our awesome CMS</h1>
            </Grid>
            <Grid item xs={12} sm={10} md={5}>
              <div className='position-relative'>
                {loading ? <Loader /> : null}

                <Card className='overflow-hidden'>
                  <AuthHeader
                    title='Register'
                    intro='For security reasons, your registration must be approved by an administrator.'
                  />
                  <CardBody className='p-4'>
                    <div className='p-3'>
                      <form
                        className='form-horizontal mt-5'
                        onSubmit={(e) => onSubmit(e)}>
                        {isAuthenticated ? (
                          <Alert color='success'>
                            Register User Successfully
                          </Alert>
                        ) : null}
                        {error && showError ? (
                          <Alert color='danger'>{error}</Alert>
                        ) : null}
                        {showError && filesError !== '' && !error && (
                          <Alert color='danger'>{filesError}</Alert>
                        )}
                        <div className='form-group'>
                          <TextField
                            error={fullReq}
                            helperText={
                              fullReq ? 'This field is required' : null
                            }
                            fullWidth
                            variant='outlined'
                            name='fullname'
                            label='Full Name *'
                            type='text'
                            value={fullname}
                            onChange={(e) => onChange(e)}
                            placeholder='Enter your Full Name'
                          />
                        </div>
                        <div className='form-group'>
                          <TextField
                            error={companyReq}
                            helperText={
                              companyReq ? 'This field is required' : null
                            }
                            fullWidth
                            variant='outlined'
                            name='companyId'
                            label='Company ID *'
                            value={companyId}
                            onChange={(e) => onChange(e)}
                            placeholder='Company ID'
                          />
                        </div>
                        <div className='form-group'>
                          <TextField
                            error={emailReq}
                            helperText={
                              emailReq ? 'This field is required' : null
                            }
                            fullWidth
                            variant='outlined'
                            name='email'
                            label='Email *'
                            type='email'
                            value={email}
                            onChange={(e) => onChange(e)}
                            placeholder='Enter email'
                          />
                        </div>
                        <div
                          className='form-group'
                          style={{
                            position: 'relative',
                          }}>
                          <TextField
                            error={passReq}
                            helperText={
                              passReq ? 'This field is required' : null
                            }
                            fullWidth
                            variant='outlined'
                            name='password'
                            label='Password *'
                            type={whatType}
                            value={password}
                            onChange={(e) => onChange(e)}
                            placeholder='Enter Password'
                          />
                          <button
                            className='button-reveal-password'
                            onClick={changeType}>
                            <i className={`mdi ${currenIcon}`}></i>
                          </button>
                        </div>
                        <div className='form-group'>
                          <div className='col-12'>
                            <small>Profile Picture</small>
                            <Dropzone
                              style={{ minHeight: 150 }}
                              onDrop={(acceptedFiles) =>
                                handleAcceptedFiles(acceptedFiles)
                              }>
                              {({ getRootProps, getInputProps }) => (
                                <div
                                  className='dropzone'
                                  style={{ maxHeight: 250 }}>
                                  <div
                                    className='dz-message needsclick'
                                    style={{ margin: 0 }}
                                    {...getRootProps()}>
                                    <input
                                      {...getInputProps()}
                                      accept='image/png, image/gif, image/jpeg, image/jpg, image/webp'
                                    />
                                    <h3>
                                      Drop photo here or
                                      <br />
                                      click to upload.
                                    </h3>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                            <div
                              className='dropzone-previews mt-3'
                              id='file-previews'>
                              {selectedFiles.map((f, i) => {
                                return (
                                  <Card
                                    className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
                                    key={i + '-file'}>
                                    <div className='p-2'>
                                      <Grid
                                        container
                                        className='align-items-center'>
                                        <Grid item xs={6} className='col-auto'>
                                          <img
                                            data-dz-thumbnail=''
                                            height='80'
                                            className='avatar-sm rounded bg-light'
                                            alt={f.name}
                                            src={f.preview}
                                          />
                                        </Grid>
                                        <Grid item xs={6}>
                                          <Link
                                            to='#!'
                                            className='text-muted font-weight-bold'>
                                            {f.name}
                                          </Link>
                                          <p className='mb-0'>
                                            <strong>{f.formattedSize}</strong>
                                          </p>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </Card>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div className='form-group'>
                          <div className='col-12 text-right'>
                            <button
                              className='btn btn-primary w-md waves-effect waves-light'
                              type='submit'>
                              Register
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Grid>
          </Grid>
        </div>
        <AuthFooter
          text={`Do you have an account? `}
          button={`/login`}
          buttonlabel={`Login Here`}
        />
      </div>
    </Fragment>
  );
};

export default Register;
