import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  Row,
  Col,
  Input,
  Card,
  CardBody,
  Alert,
  UncontrolledTooltip,
} from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Editor } from 'react-draft-wysiwyg';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import Switch from 'react-switch';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import {
  addTestimonial,
  clearCurrentTs,
  editTestimonial,
  getTestimonial,
  getTestimonials,
  postActivity,
} from '@thedavid/plitzredux/actions';

// Parts
import Loader from '../../components/Loader';

const AddTestimonial = ({ id, toggle }) => {
  const currentClient = localStorage.getItem('tenant');

  const dispatch = useDispatch();
  const history = useNavigate();
  const [editorState, setEditorState] = useState(null);
  const [formData, setFormData] = useState({
    isLive: false,
    order: 0,
    author: '',
    company: '',
    worktitle: '',
    source: '',
    rating: 0,
    details: '',
    picture: 'uploads/big-placeholder.jpg',
    othersizes: {
      tablet: '',
      mobile: '',
    },
  });

  const [selectedFeatured, setSelectedFeatured] = useState([]);
  const [showError, setShowError] = useState(false);
  const [filesErrorFi, setFilesErrorFi] = useState('');
  const [showErrorFi, setShowErrorFi] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [switchHtml, setSwitchHtml] = useState(false);
  const [currentRating, setCurrentRating] = useState([]);
  let ratingStars = [1, 2, 3, 4, 5];

  const {
    isLive,
    order,
    author,
    company,
    worktitle,
    source,
    rating,
    details,
    picture,
  } = formData;

  useEffect(() => {
    if (id && id !== undefined) {
      dispatch(getTestimonial(id));
    }

    dispatch(getTestimonials());

    // eslint-disable-next-line
  }, [id, dispatch]);

  const Testimonials = useSelector((state) => state.Testimonials);
  const { testimonial, loading, error } = Testimonials;
  const Login = useSelector((state) => state.Login);
  const { user } = Login;

  useEffect(() => {
    if (testimonial && testimonial !== null) {
      if (testimonial.details) {
        const contentBlock = htmlToDraft(testimonial.details);
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        setEditorState(EditorState.createWithContent(contentState));
      }

      setFormData({
        _id: testimonial._id ? testimonial._id : null,
        isLive: testimonial.isLive ? testimonial.isLive : false,
        order: testimonial.order ? testimonial.order : 0,
        author: testimonial.author ? testimonial.author : '',
        company: testimonial.company ? testimonial.company : '',
        worktitle: testimonial.worktitle ? testimonial.worktitle : '',
        source: testimonial.source ? testimonial.source : '',
        details: testimonial.details ? testimonial.details : '',
        rating: testimonial.rating ? testimonial.rating : 0,
        picture: testimonial.picture
          ? testimonial.picture
          : 'uploads/big-placeholder.jpg',
        othersizes: {
          tablet: testimonial.othersizes ? testimonial.othersizes.tablet : '',
          mobile: testimonial.othersizes ? testimonial.othersizes.mobile : '',
        },
      });

      if (testimonial.rating > 0) {
        let stars = document.querySelectorAll('.btn .fa-star');

        if (stars.length > 0) {
          for (let i = 0; i < testimonial.rating; i++) {
            stars[i].classList.add('fa-solid');
            stars[i].classList.remove('fa-light');
          }
        }
      }
    } else {
      setFormData({
        isLive: false,
        order: 0,
        author: '',
        company: '',
        worktitle: '',
        source: '',
        rating: 0,
        details: '',
        picture: 'uploads/big-placeholder.jpg',
        othersizes: {
          tablet: '',
          mobile: '',
        },
      });
    }

    // eslint-disable-next-line
  }, [testimonial]);

  useEffect(() => {
    setCurrentRating([]);
    let currentRating = [];

    for (let i = 1; i <= rating; i++) {
      currentRating.push(i);
    }

    if (rating > 0) {
      let stars = document.querySelectorAll('.btn .fa-star');

      if (stars.length > 0) {
        for (let i = rating; i < 5; i++) {
          stars[i].classList.add('fa-light');
          stars[i].classList.remove('fa-solid');
        }
      }
    }

    setCurrentRating(rating);
  }, [rating]);

  const switchButton = () => {
    if (isLive) {
      setFormData({
        ...formData,
        isLive: false,
      });
    } else {
      setFormData({
        ...formData,
        isLive: true,
      });
    }
  };

  const switchHtmlButton = () => {
    if (switchHtml) {
      setSwitchHtml(false);
    } else {
      setSwitchHtml(true);
    }
  };

  const onChange = (e) => {
    e.preventDefault();

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const contentChange = (editorState) => {
    setEditorState(editorState);
    setFormData({
      ...formData,
      details: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
  };

  const onRawChange = (e) => {
    e.preventDefault();
    setFormData({
      ...formData,
      details: e.target.value,
    });
  };

  const handleAcceptedFilesFi = (file) => {
    if (file.length > 1) {
      setFilesErrorFi('Only one file is allowed');
      setShowErrorFi(true);
    } else {
      file.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      );
      setShowErrorFi(false);
      setSelectedFeatured(file);
    }
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  useEffect(() => {
    if (details === '' && !switchHtml) {
      setEditorState(EditorState.createEmpty());
    } else {
      const contentBlock = htmlToDraft(details);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      setEditorState(EditorState.createWithContent(contentState));
    }

    // eslint-disable-next-line
  }, [switchHtml]);

  useEffect(() => {
    if (selectedFeatured.length > 0) {
      const fd = new FormData();
      fd.append('newimg', selectedFeatured[0]);

      try {
        axios
          .post(`${process.env.REACT_APP_PUBLIC_URL}api/uploads`, fd, {
            headers: {
              'Content-Type': 'multipart/form-data',
              storedid: currentClient,
            },
          })
          .then((res) => {
            setFormData({
              ...formData,
              picture: res.data.filePath,
              othersizes: {
                tablet: res.data.tablet,
                mobile: res.data.mobile,
              },
            });
          })
          .catch((error) => {
            setFilesErrorFi(
              `Error after uploading file - ${error.response.data}`
            );
            setShowErrorFi(true);
          });
      } catch (err) {
        if (err.response.status === 500) {
          setFilesErrorFi('There was a problem with the server');
          setShowErrorFi(true);
        } else {
          setFilesErrorFi(err.response.data.msg);
        }
      }
    }

    // eslint-disable-next-line
  }, [selectedFeatured]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (id && id !== '') {
      try {
        dispatch(editTestimonial(formData));
        dispatch(
          postActivity({
            logtype: 'positive',
            logcontent: `The testimonial by <strong>${formData.author}</strong> was edited successfully`,
            email: user ? user.email : '',
          })
        );
        setFormData({
          isLive: false,
          order: 0,
          author: '',
          company: '',
          worktitle: '',
          source: '',
          rating: 0,
          details: '',
          picture: 'uploads/big-placeholder.jpg',
          othersizes: {
            tablet: '',
            mobile: '',
          },
        });
        toggle(false);
        dispatch(clearCurrentTs());
        setShowError(false);
        setShowErrorFi(false);
        setSelectedFeatured([]);
      } catch (error) {
        dispatch(
          postActivity({
            logtype: 'negative',
            logcontent: `An error occurred <em>editing</em> testimonial: <strong>${error}</strong>`,
            email: user ? user.email : '',
          })
        );
        setErrorMsg(error);
      }
    } else if (author !== '' && details !== '') {
      try {
        dispatch(addTestimonial(formData));
        afterSubmit();
      } catch (error) {
        dispatch(
          postActivity({
            logtype: 'negative',
            logcontent: `An error occurred adding testimonial: <strong>${error}</strong>`,
            email: user ? user.email : '',
          })
        );
      }
    } else {
      setShowError(true);
      setErrorMsg(`Title and Content are required`);
      setTimeout(() => {
        setShowError(false);
        setErrorMsg('');
      }, 9000);
    }
  };

  const afterSubmit = useCallback(() => {
    if (error === null) {
      dispatch(
        postActivity({
          logtype: 'positive',
          logcontent: `The testimonial by <strong>${formData.author}</strong> was created`,
          email: user ? user.email : '',
        })
      );
      setFormData({
        isLive: false,
        order: 0,
        author: '',
        company: '',
        worktitle: '',
        source: '',
        rating: 0,
        details: '',
        picture: 'uploads/big-placeholder.jpg',
        othersizes: {
          tablet: '',
          mobile: '',
        },
      });
      setShowError(false);
      setShowErrorFi(false);
      setSelectedFeatured([]);
      history('/all-testimonials');
    }

    // eslint-disable-next-line
  }, [error]);

  const changeRating = (e, st) => {
    e.preventDefault();
    setFormData({ ...formData, rating: st });
  };

  const addClass = (e, st) => {
    e.preventDefault();
    let stars = document.querySelectorAll('.btn .fa-star');

    if (stars.length > 0) {
      for (let i = currentRating; i < st; i++) {
        if (!stars[i].classList.contains('fa-solid')) {
          stars[i].classList.remove('fa-light');
          stars[i].classList.add('fa-solid');
        }
      }
    }
  };

  const removeClass = (e, st) => {
    e.preventDefault();
    let stars = document.querySelectorAll('.btn .fa-star');

    if (stars.length > 0) {
      for (let i = currentRating; i < st; i++) {
        stars[i].classList.remove('fa-solid');
        stars[i].classList.add('fa-light');
      }
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <Fragment>
      <div className='container-fluid plitz-forms'>
        <Row className='align-items-center'>
          <Col sm={6}>
            <div className='page-title-box'>
              <h1 className=''>{id && id !== '' ? 'Edit Item' : 'New Item'}</h1>
              <ol className='breadcrumb mb-0'>
                <li className='breadcrumb-item'>
                  <Link to='/dashboard'>Dashboard</Link>
                </li>
                <li className='breadcrumb-item'>
                  <Link to='/all-testimonials'>All Testimonials</Link>
                </li>
                <li className='breadcrumb-item'>Add Testimonial</li>
              </ol>
            </div>
          </Col>
        </Row>

        <Row>
          {showError ? (
            <div className='container-fluid'>
              <Row className='align-items-center'>
                <Col sm={12}>
                  <Alert color='warning'>{errorMsg}</Alert>
                </Col>
              </Row>
            </div>
          ) : null}
          {error ? (
            <div className='container-fluid'>
              <Row className='align-items-center'>
                <Col sm={12}>
                  <h3 className='title-3'>{error}</h3>
                </Col>
              </Row>
            </div>
          ) : null}
        </Row>

        <form onSubmit={(e) => onSubmit(e)}>
          <Row>
            <Col sm={12} md={9}>
              <Row className='mb-2'>
                <Col sm={12} md={5} lg={6}>
                  <Card style={{ position: 'relative', zIndex: 0 }}>
                    <CardBody>
                      <div className='mb-4'>
                        <h4 className='card-title mb-1'>Logo or Photo</h4>
                        <small>
                          Upload the logo of the company or the photo of the
                          author of the review
                        </small>
                      </div>
                      {showErrorFi ? (
                        <Alert color='warning'>{filesErrorFi}</Alert>
                      ) : null}
                      <Dropzone
                        onDrop={(acceptedFiles) =>
                          handleAcceptedFilesFi(acceptedFiles)
                        }>
                        {({ getRootProps, getInputProps }) => (
                          <div className='dropzone'>
                            <div
                              className='dz-message needsclick'
                              {...getRootProps()}>
                              <input
                                {...getInputProps()}
                                accept='image/png, image/gif, image/jpeg, image/jpg, image/webp'
                              />
                              <h5>
                                Drop file here or
                                <br />
                                click to upload.
                              </h5>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm={12} md={7} lg={6}>
                  <div className='dropzone-previews mt-3' id='file-previews'>
                    {selectedFeatured.map((f, i) => {
                      return (
                        <Card
                          className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
                          key={i + '-file'}>
                          <div className='p-2'>
                            <Row className='align-items-center'>
                              <Col>
                                <div className='rounded-circle avatar-xl for-testimonials'>
                                  <img
                                    data-dz-thumbnail=''
                                    className='bg-light actual-image'
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                </div>
                              </Col>
                              <Col>
                                <Link
                                  to='#'
                                  className='text-muted font-weight-bold'>
                                  {f.name}
                                </Link>
                                <p className='mb-0'>
                                  <strong>{f.formattedSize}</strong>
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      );
                    })}
                    {selectedFeatured.length === 0 && picture && (
                      <Row className='align-items-center'>
                        <Col>
                          <div className='rounded-circle avatar-xl for-testimonials'>
                            <img
                              className='bg-light actual-image'
                              alt={author ? author : 'Placeholder'}
                              src={`${process.env.REACT_APP_PUBLIC_URL}${picture}`}
                            />
                          </div>
                        </Col>
                      </Row>
                    )}
                  </div>
                </Col>
              </Row>
              <Row className='form-group mb-4'>
                <Col sm={12} md={6}>
                  <input
                    className='form-control'
                    type='text'
                    placeholder='Full Name'
                    id='author'
                    name='author'
                    value={author}
                    onChange={(e) => onChange(e)}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <input
                    className='form-control'
                    type='text'
                    placeholder='Company or Business Name'
                    id='company'
                    name='company'
                    value={company}
                    onChange={(e) => onChange(e)}
                  />
                </Col>
              </Row>
              <Row className='form-group mb-4'>
                <Col sm={12} md={6}>
                  <input
                    className='form-control'
                    type='text'
                    placeholder='Title or Position'
                    id='worktitle'
                    name='worktitle'
                    value={worktitle}
                    onChange={(e) => onChange(e)}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <input
                    className='form-control'
                    type='text'
                    placeholder='Where was the review posted originally?'
                    id='source'
                    name='source'
                    value={source}
                    onChange={(e) => onChange(e)}
                  />
                </Col>
              </Row>
              <Row className='form-group'>
                <Col sm='12' className='mt-3 mb-3'>
                  <span className='mt-1 mr-2'>HTML Mode</span>
                  <Switch
                    uncheckedIcon={<Offsymbol />}
                    checkedIcon={<OnSymbol />}
                    onColor='#0b093a'
                    onChange={switchHtmlButton}
                    checked={switchHtml}
                  />{' '}
                </Col>
                <Col sm='12'>
                  {switchHtml ? (
                    <Input
                      placeholder='HTML Mode has been enabled'
                      type='textarea'
                      id='details'
                      name='details'
                      value={details}
                      onChange={(e) => onRawChange(e)}
                      style={{ height: '100%' }}
                    />
                  ) : (
                    <Editor
                      toolbar={{
                        options: [
                          'inline',
                          'list',
                          'link',
                          'emoji',
                          'remove',
                          'history',
                        ],
                        inline: {
                          options: [
                            'bold',
                            'italic',
                            'underline',
                            'strikethrough',
                          ],
                          bold: { className: 'bordered-option-classname' },
                          italic: { className: 'bordered-option-classname' },
                          underline: { className: 'bordered-option-classname' },
                          strikethrough: {
                            className: 'bordered-option-classname',
                          },
                          code: { className: 'bordered-option-classname' },
                        },

                        history: {
                          inDropdown: false,
                          options: ['undo', 'redo'],
                        },
                      }}
                      editorState={editorState}
                      toolbarClassName='toolbarClassName'
                      wrapperClassName='wrapperClassName'
                      onEditorStateChange={contentChange}
                    />
                  )}
                </Col>
              </Row>
            </Col>

            {/* Sidebar Settings */}
            <Col sm={12} md={3} className='pl-5'>
              <Card style={{ position: 'relative', zIndex: 1 }}>
                <CardBody>
                  <h4 className='card-title mb-4'>Testimonial Settings</h4>

                  <div className='mb-4 pb-4 setting-bottom-divider'>
                    <p className='card-title-desc'>Status</p>
                    <Switch
                      uncheckedIcon={<Offsymbol />}
                      checkedIcon={<OnSymbol />}
                      onColor='#0b093a'
                      onChange={switchButton}
                      checked={isLive}
                    />

                    <span className='ml-3'>
                      {isLive ? 'Published' : 'Draft'}
                    </span>
                  </div>
                  <div className='mb-4 pb-4 setting-bottom-divider'>
                    <p>Testimonial Order</p>
                    <small>
                      This will be used for the order on how the testimonials
                      will show in the front-end
                    </small>
                    <p></p>
                    <Input
                      placeholder={0}
                      type='number'
                      id='order'
                      name='order'
                      value={order}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                  <div className='mb-4 pb-4'>
                    <p>Rating</p>
                    <div className='stars-container'>
                      {ratingStars.map((st) => (
                        <div key={st}>
                          <UncontrolledTooltip
                            placement='top'
                            target={`star-${st}`}>
                            {st}
                          </UncontrolledTooltip>
                          <button
                            id={`star-${st}`}
                            type='button'
                            className='btn'
                            value={st}
                            onClick={(e) => changeRating(e, st)}
                            onMouseOver={(e) => addClass(e, st)}
                            onMouseLeave={(e) => removeClass(e, st)}>
                            <i className='fa-light fa-star'></i>
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <button
                    type='submit'
                    className='btn btn-primary waves-effect waves-light'>
                    Save
                  </button>
                  <button
                    type='button'
                    onClick={(e) => {
                      e.preventDefault();
                      setEditorState(EditorState.createEmpty());
                      setFormData({
                        isLive: false,
                        order: 0,
                        author: '',
                        company: '',
                        worktitle: '',
                        source: '',
                        rating: 0,
                        details: '',
                        picture: 'uploads/big-placeholder.jpg',
                        othersizes: {
                          tablet: '',
                          mobile: '',
                        },
                      });
                    }}
                    className='btn btn-danger ml-2 waves-effect waves-light'>
                    Reset
                  </button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
      </div>
    </Fragment>
  );
};

const Offsymbol = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: 12,
        color: '#fff',
        paddingRight: 2,
        paddingTop: 3,
      }}>
      {' '}
      <small>OFF</small>
    </div>
  );
};

const OnSymbol = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: 12,
        color: '#fff',
        paddingRight: 2,
        paddingTop: 3,
      }}>
      {' '}
      <small>ON</small>
    </div>
  );
};

export default AddTestimonial;
