import {
  ADDCLIENT_FAILED,
  ADDCLIENT_GO,
  ADDCLIENT_TRY,
  CALLCLIENT_FAILED,
  CALLCLIENT_GO,
  CALLCLIENT_TRY,
  CLIENT_LOADED,
  EDITCLIENT_FAILED,
  EDITCLIENT_GO,
  EDITCLIENT_TRY,
  GETTING_CLIENTS,
  GETTING_FAIL_CLIENT,
  GET_CLIENTS,
  REGISTERING_CLIENT,
  REGISTER_FAILED_CLIENT,
  REGISTER_TRY_CLIENT,
  REMOVE_CLIENT_FAILED,
  REMOVE_CLIENT_GO,
  REMOVE_CLIENT_TRY,
  SELF_REMOVE_FAILED_CLIENT,
  SELF_REMOVE_GO_CLIENT,
  SELF_REMOVE_TRY_CLIENT,
  UPDATE_FAILED_CLIENT,
  UPDATE_GO_CLIENT,
  UPDATE_TRY_CLIENT,
} from './actionTypes';

const initialState = {
  loading: false,
  clients: [],
  client: null,
  otherclient: null,
  error: null,
  msg: null,
};

const Clients = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLIENTS:
      return {
        ...state,
        loading: true,
      };

    case REGISTER_TRY_CLIENT:
    case UPDATE_TRY_CLIENT:
    case REMOVE_CLIENT_TRY:
    case SELF_REMOVE_TRY_CLIENT:
      return {
        ...state,
        ...action.payload,
        error: null,
        loading: true,
      };

    case ADDCLIENT_TRY:
    case CALLCLIENT_TRY:
    case EDITCLIENT_TRY:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case REGISTERING_CLIENT:
      return {
        ...state,
        client: action.payload.data,
        loading: false,
      };

    case GETTING_CLIENTS:
      return {
        ...state,
        clients: action.payload,
        loading: false,
      };

    case CLIENT_LOADED:
      return {
        ...state,
        loading: false,
        client: action.payload,
        alreadyin: true,
      };

    case UPDATE_GO_CLIENT:
      return {
        ...state,
        loading: false,
        error: null,
        msg: action.payload.msg,
        client: action.payload.client,
      };

    case ADDCLIENT_GO:
      return {
        ...state,
        clients:
          state.clients.length === 0
            ? [action.payload]
            : [action.payload, ...state.clients],
        otherclient: action.payload,
        loading: false,
      };

    case CALLCLIENT_GO:
      return {
        ...state,
        otherclient: action.payload,
        loading: false,
        error: null,
      };

    case EDITCLIENT_GO:
      return {
        ...state,
        clients: state.clients.map((usr) =>
          usr._id === action.payload._id ? action.payload : usr
        ),
        otherclient: null,
        loading: false,
        error: null,
      };

    case REMOVE_CLIENT_GO:
      return {
        ...state,
        clients: state.clients.filter((el) => el._id !== action.payload.id),
        msg: action.payload.msg,
        loading: false,
        error: null,
      };

    case UPDATE_FAILED_CLIENT:
    case ADDCLIENT_FAILED:
    case CALLCLIENT_FAILED:
    case EDITCLIENT_FAILED:
    case REMOVE_CLIENT_FAILED:
    case SELF_REMOVE_FAILED_CLIENT:
    case GETTING_FAIL_CLIENT:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case REGISTER_FAILED_CLIENT:
      return {
        ...state,
        loading: false,
        client: null,
        error: action.payload,
      };

    case SELF_REMOVE_GO_CLIENT:
      return {
        loading: false,
        client: null,
      };

    default:
      return state;
  }
};

export default Clients;
