import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardBody } from 'reactstrap';

// Parts
import AuthFooter from '../../components/AuthFooter';
import AuthHeader from '../../components/AuthHeader';

// Actions
import { logOut } from '@thedavid/plitzredux/actions';
import { Grid } from '@mui/material';

const NotApproved = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logOut());

    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <div className='account-pages pt-5'>
        <div className='container'>
          <Grid
            container
            spacing={5}
            justifyContent='center'
            alignItems='center'
            className='justify-content-center'
          >
            <Grid item xs={12} sm={10} md={5}>
              <div className='position-relative'>
                <Card className='overflow-hidden'>
                  <AuthHeader
                    title='Pending for Approval'
                    intro={`Your access hasn't been approved yet`}
                  />

                  <CardBody className='p-5'>
                    <div className='pt-5'>
                      For security reasons, new registrants must be approved by
                      a CMS administrator. You can contact{' '}
                      <a
                        href='https://plitz7.com/contact-plitz/'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        Plitz7
                      </a>{' '}
                      to find out about the status of your registration.
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Grid>
          </Grid>
        </div>

        <AuthFooter
          text={`Go back to `}
          button={`/login`}
          buttonlabel={`Login page`}
        />
      </div>
    </React.Fragment>
  );
};

export default NotApproved;
