import {
  ADD_MENUCATEGORY_FAILED,
  ADD_MENUCATEGORY_GO,
  ADD_MENUCATEGORY_TRY,
  CLEAR_CURRENT,
  EDIT_MENUCATEGORY_FAILED,
  EDIT_MENUCATEGORY_GO,
  EDIT_MENUCATEGORY_TRY,
  GET_AMENUCATEGORY_FAILED,
  GET_AMENUCATEGORY_GO,
  GET_AMENUCATEGORY_TRY,
  GET_MENUCATEGORIES_FAILED,
  GET_MENUCATEGORIES_GO,
  GET_MENUCATEGORIES_TRY,
  REMOVE_MENUCATEGORY_FAILED,
  REMOVE_MENUCATEGORY_GO,
  REMOVE_MENUCATEGORY_TRY,
} from './actionTypes';

const initialState = {
  menuCategories: [],
  menuCategory: null,
  loading: false,
  error: null,
  msg: null,
};

const MenuCategories = (state = initialState, action) => {
  switch (action.type) {
    case GET_MENUCATEGORIES_TRY:
    case GET_AMENUCATEGORY_TRY:
    case ADD_MENUCATEGORY_TRY:
    case EDIT_MENUCATEGORY_TRY:
    case REMOVE_MENUCATEGORY_TRY:
      return {
        ...state,
        loading: true,
      };

    case GET_MENUCATEGORIES_GO:
      return {
        ...state,
        menuCategories: action.payload,
        loading: false,
      };

    case ADD_MENUCATEGORY_GO:
      return {
        ...state,
        menuCategories: [action.payload, ...state.menuCategories],
        loading: false,
      };

    case EDIT_MENUCATEGORY_GO:
      return {
        ...state,
        menuCategories: state.menuCategories.map((menuCategory) =>
          menuCategory._id === action.payload._id
            ? action.payload
            : menuCategory
        ),
        loading: false,
      };

    case GET_AMENUCATEGORY_GO:
      return {
        ...state,
        menuCategory: action.payload,
        loading: false,
      };

    case REMOVE_MENUCATEGORY_GO:
      return {
        ...state,
        menuCategories: state.menuCategories.filter(
          (fld) => fld._id !== action.payload.id
        ),
        msg: action.payload.msg,
        loading: false,
      };

    case GET_MENUCATEGORIES_FAILED:
    case GET_AMENUCATEGORY_FAILED:
    case ADD_MENUCATEGORY_FAILED:
    case EDIT_MENUCATEGORY_FAILED:
    case REMOVE_MENUCATEGORY_FAILED:
      return {
        ...state,
        menuCategory: null,
        error: action.payload,
        loading: false,
      };

    case CLEAR_CURRENT:
      return {
        ...state,
        menuCategory: null,
        loading: false,
      };

    default:
      return state;
  }
};

export default MenuCategories;
