import {
	ADD_TESTIMONIAL_FAILED,
	ADD_TESTIMONIAL_GO,
	ADD_TESTIMONIAL_TRY,
	CLEAR_CURRENT,
	EDIT_TESTIMONIAL_FAILED,
	EDIT_TESTIMONIAL_GO,
	EDIT_TESTIMONIAL_TRY,
	GET_ATESTIMONIAL_FAILED,
	GET_ATESTIMONIAL_GO,
	GET_ATESTIMONIAL_TRY,
	GET_TESTIMONIALS_FAILED,
	GET_TESTIMONIALS_GO,
	GET_TESTIMONIALS_TRY,
	REMOVE_TESTIMONIAL_FAILED,
	REMOVE_TESTIMONIAL_GO,
	REMOVE_TESTIMONIAL_TRY,
} from './actionTypes';

const initialState = {
	testimonials: [],
	testimonial: null,
	loading: false,
	error: null,
	msg: null,
};

const Testimonials = (state = initialState, action) => {
	switch (action.type) {
		case GET_TESTIMONIALS_TRY:
		case GET_ATESTIMONIAL_TRY:
		case ADD_TESTIMONIAL_TRY:
		case EDIT_TESTIMONIAL_TRY:
		case REMOVE_TESTIMONIAL_TRY:
			return {
				...state,
				error: null,
				loading: true,
			};

		case GET_TESTIMONIALS_GO:
			return {
				...state,
				testimonials: action.payload,
				loading: false,
			};

		case ADD_TESTIMONIAL_GO:
			return {
				...state,
				error: null,
				testimonials:
					state.testimonials.length === 0
						? [action.payload]
						: [action.payload, ...state.testimonials],
				loading: false,
			};

		case EDIT_TESTIMONIAL_GO:
			return {
				...state,
				testimonials: state.testimonials.map((testimonial) =>
					testimonial._id === action.payload._id ? action.payload : testimonial
				),
				loading: false,
			};

		case GET_ATESTIMONIAL_GO:
			return {
				...state,
				testimonial: action.payload,
				loading: false,
			};

		case REMOVE_TESTIMONIAL_GO:
			return {
				...state,
				testimonials: state.testimonials.filter(
					(fld) => fld._id !== action.payload.id
				),
				error: null,
				msg: action.payload.msg,
				loading: false,
			};

		case GET_TESTIMONIALS_FAILED:
		case GET_ATESTIMONIAL_FAILED:
		case ADD_TESTIMONIAL_FAILED:
		case EDIT_TESTIMONIAL_FAILED:
		case REMOVE_TESTIMONIAL_FAILED:
			return {
				...state,
				testimonial: null,
				error: action.payload,
				loading: false,
			};

		case CLEAR_CURRENT:
			return {
				...state,
				testimonial: null,
				error: null,
				loading: false,
			};

		default:
			return state;
	}
};

export default Testimonials;
