import {
	ADD_SLIDER_FAILED,
	ADD_SLIDER_GO,
	ADD_SLIDER_TRY,
	CLEAR_CURRENT,
	EDIT_SLIDER_FAILED,
	EDIT_SLIDER_GO,
	EDIT_SLIDER_TRY,
	GET_SLIDERS_FAILED,
	GET_SLIDERS_GO,
	GET_SLIDERS_TRY,
	GET_ASLIDER_TRY,
	GET_ASLIDER_GO,
	GET_ASLIDER_FAILED,
	REMOVE_SLIDER_FAILED,
	REMOVE_SLIDER_GO,
	REMOVE_SLIDER_TRY,
} from './actionTypes';

export const getSliders = () => {
	return {
		type: GET_SLIDERS_TRY,
	};
};

export const gettingSliders = (data) => {
	return {
		type: GET_SLIDERS_GO,
		payload: data,
	};
};

export const getSlidersFailed = (error) => {
	return {
		type: GET_SLIDERS_FAILED,
		payload: error,
	};
};

export const getSlider = (id) => {
	return {
		type: GET_ASLIDER_TRY,
		payload: id,
	};
};

export const gettingSlider = (data) => {
	return {
		type: GET_ASLIDER_GO,
		payload: data,
	};
};

export const getSliderFailed = (error) => {
	return {
		type: GET_ASLIDER_FAILED,
		payload: error,
	};
};

export const clearCurrentSl = () => {
	return {
		type: CLEAR_CURRENT,
	};
};

export const addSlider = (formData) => {
	return {
		type: ADD_SLIDER_TRY,
		payload: { formData },
	};
};

export const sliderAdded = (data) => {
	return {
		type: ADD_SLIDER_GO,
		payload: data,
	};
};

export const addSliderFailed = (error) => {
	return {
		type: ADD_SLIDER_FAILED,
		payload: error,
	};
};

export const editSlider = (formData) => {
	return {
		type: EDIT_SLIDER_TRY,
		payload: { formData },
	};
};

export const sliderEdited = (data) => {
	return {
		type: EDIT_SLIDER_GO,
		payload: data,
	};
};

export const editSliderFailed = (error) => {
	return {
		type: EDIT_SLIDER_FAILED,
		payload: error,
	};
};

export const removeSlider = (id) => {
	return {
		type: REMOVE_SLIDER_TRY,
		payload: id,
	};
};

export const removingSlider = (data) => {
	return {
		type: REMOVE_SLIDER_GO,
		payload: data,
	};
};

export const removeSliderFailed = (error) => {
	return {
		type: REMOVE_SLIDER_FAILED,
		payload: error,
	};
};
