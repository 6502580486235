import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  ADD_MENUITEM_FAILED,
  ADD_MENUITEM_GO,
  ADD_MENUITEM_TRY,
  EDIT_MENUITEM_FAILED,
  EDIT_MENUITEM_GO,
  EDIT_MENUITEM_TRY,
  GET_AMENUITEM_FAILED,
  GET_AMENUITEM_GO,
  GET_AMENUITEM_TRY,
  GET_MENUITEMS_FAILED,
  GET_MENUITEMS_GO,
  GET_MENUITEMS_TRY,
  REMOVE_MENUITEM_FAILED,
  REMOVE_MENUITEM_GO,
  REMOVE_MENUITEM_TRY,
} from './actionTypes';

import {
  addMenuItem,
  editMenuItem,
  getAllMenuItemsApi,
  getMenuItemApi,
  removeMenuItem,
} from '../../helpers/restaurants/menuItemApi';

function* theMenuItemsPull() {
  try {
    const response = yield call(getAllMenuItemsApi);

    yield put({ type: GET_MENUITEMS_GO, payload: response.data });
  } catch (error) {
    yield put({
      type: GET_MENUITEMS_FAILED,
      payload: error.response.data.message,
    });
  }
}

function* oneMenuItemPull({ payload }) {
  try {
    const response = yield call(getMenuItemApi, payload);

    yield put({ type: GET_AMENUITEM_GO, payload: response.data });
  } catch (error) {
    yield put({
      type: GET_AMENUITEM_FAILED,
      payload: error.response.data.message,
    });
  }
}

function* addingMenuItem({ payload: { formData } }) {
  try {
    const response = yield call(addMenuItem, formData);

    yield put({ type: ADD_MENUITEM_GO, payload: response.data });
  } catch (error) {
    yield put({
      type: ADD_MENUITEM_FAILED,
      payload: error.response.data.message,
    });
  }
}

function* editingMenuItem({ payload: { formData } }) {
  try {
    const response = yield call(editMenuItem, formData);

    yield put({ type: EDIT_MENUITEM_GO, payload: response.data });
  } catch (error) {
    yield put({
      type: EDIT_MENUITEM_FAILED,
      payload: error.response.data.message,
    });
  }
}

function* removingMenuItem({ payload: id }) {
  try {
    const response = yield call(removeMenuItem, id);

    yield put({ type: REMOVE_MENUITEM_GO, payload: response.data });
  } catch (error) {
    yield put({
      type: REMOVE_MENUITEM_FAILED,
      payload: error.response.data.message,
    });
  }
}

function* pullingMenuItems() {
  yield takeLatest(GET_MENUITEMS_TRY, theMenuItemsPull);
}

function* pullingMenuItem() {
  yield takeLatest(GET_AMENUITEM_TRY, oneMenuItemPull);
}

function* addMenuItemTry() {
  yield takeLatest(ADD_MENUITEM_TRY, addingMenuItem);
}

function* editingMenuItemTry() {
  yield takeLatest(EDIT_MENUITEM_TRY, editingMenuItem);
}

function* removingMenuItemTry() {
  yield takeLatest(REMOVE_MENUITEM_TRY, removingMenuItem);
}

function* MenuItemsSaga() {
  yield all([
    pullingMenuItems(),
    pullingMenuItem(),
    addMenuItemTry(),
    editingMenuItemTry(),
    removingMenuItemTry(),
  ]);
}

export default MenuItemsSaga;
