import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Card, CardBody, Alert } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import Editable from 'react-bootstrap-editable';
import { Editor } from 'react-draft-wysiwyg';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import Switch from 'react-switch';
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

// Actions
import {
  addBlogpost,
  clearCurrentBp,
  editBlogpost,
  getBlogpost,
  getCategories,
  postActivity,
} from '@thedavid/plitzredux/actions';
import Loader from '../../components/Loader';

const AddBlogpost = ({ link, toggle }) => {
  const currentClient = localStorage.getItem('tenant');

  const dispatch = useDispatch();
  const history = useNavigate();
  const [editorState, setEditorState] = useState(null);
  const [editorStateIntro, setEditorStateIntro] = useState(null);
  const [formData, setFormData] = useState({
    isLive: false,
    title: '',
    link: '',
    postintro: '',
    content: '',
    seotitle: '',
    category: [],
    featuredimg: 'uploads/big-placeholder.jpg',
    othersizes: {
      tablet: '',
      mobile: '',
    },
  });

  const [selectedFeatured, setSelectedFeatured] = useState([]);
  const [showError, setShowError] = useState(false);
  const [filesErrorFi, setFilesErrorFi] = useState('');
  const [showErrorFi, setShowErrorFi] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [switchHtml, setSwitchHtml] = useState(false);
  const [slugVal, setSlugVal] = useState('');
  const [selectedCategories, setSelectedCategories] = useState(null);
  const [optionCategories, setOptionCategories] = useState({
    options: [],
  });
  const [filteredOptions, setFilteredOptions] = useState([]);

  const { title, isLive, content, postintro, seotitle, featuredimg } = formData;

  useEffect(() => {
    if (link && link !== undefined) {
      dispatch(getBlogpost(link));
    }
    dispatch(getCategories());

    // eslint-disable-next-line
  }, [link, dispatch]);

  const Blogposts = useSelector((state) => state.Blogposts);
  const { blogpost, loading, error } = Blogposts;
  const Categories = useSelector((state) => state.Categories);
  const { categories } = Categories;
  const Login = useSelector((state) => state.Login);
  const { user } = Login;

  useEffect(() => {
    if (blogpost && blogpost !== null) {
      if (blogpost.postintro) {
        const postintroBlock = convertFromHTML(blogpost.postintro);
        const postintroState = ContentState.createFromBlockArray(
          postintroBlock.contentBlocks
        );
        setEditorStateIntro(EditorState.createWithContent(postintroState));
      }
      if (blogpost.content) {
        const contentBlock = convertFromHTML(blogpost.content);
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        setEditorState(EditorState.createWithContent(contentState));
      }
      setFormData({
        _id: blogpost._id ? blogpost._id : null,
        isLive: blogpost.isLive ? blogpost.isLive : false,
        title: blogpost.title ? blogpost.title : '',
        link: blogpost.link ? blogpost.link : '',
        postintro: blogpost.postintro ? blogpost.postintro : '',
        content: blogpost.content ? blogpost.content : '',
        seotitle: blogpost.seotitle ? blogpost.seotitle : '',
        category: blogpost.category
          ? blogpost.category.map((cat) => ({
              label: cat.cattitle,
              value: cat._id,
            }))
          : [],
        featuredimg: blogpost.featuredimg
          ? blogpost.featuredimg
          : 'uploads/big-placeholder.jpg',
        othersizes: {
          tablet: blogpost.othersizes ? blogpost.othersizes.tablet : '',
          mobile: blogpost.othersizes ? blogpost.othersizes.mobile : '',
        },
      });

      const formatExistingCategories = blogpost.category.map((cat) => ({
        label: cat.cattitle,
        value: cat._id.toString(),
      }));
      setSelectedCategories(formatExistingCategories);

      let selectedCats = [];

      categories.length > 0 &&
        categories.forEach((element) => {
          const exists = blogpost.category.filter(
            (postcat) => postcat._id !== element._id
          );
          if (exists.length) {
            selectedCats.push({
              label: element.cattitle,
              value: element._id.toString(),
            });
          }
        });

      setFilteredOptions(selectedCats);
    } else {
      let categoryList = [];
      categories.length > 0 &&
        categories.map((cts) =>
          categoryList.push({ label: cts.cattitle, value: cts._id })
        );
      setFilteredOptions(categoryList);
    }
  }, [blogpost, categories]);

  const switchButton = () => {
    if (isLive) {
      setFormData({
        ...formData,
        isLive: false,
      });
    } else {
      setFormData({
        ...formData,
        isLive: true,
      });
    }
  };

  const switchHtmlButton = () => {
    if (switchHtml) {
      setSwitchHtml(false);
    } else {
      setSwitchHtml(true);
    }
  };

  const onChangeSlug = (value) => {
    setFormData({ ...formData, link: value });
  };

  const onValidate = (value) => {
    if (!value || value === '') {
      return "This field can't be empty";
    }
  };

  const onChange = (e) => {
    e.preventDefault();
    if (e.target.name === 'title') {
      setFormData({
        ...formData,
        title: e.target.value,
        link: e.target.value
          .replace(/[^a-zA-Z0-9]/g, '-')
          .replace(/--/g, '')
          .replace(/-$/, '')
          .toLowerCase(),
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  // Select Category for Submenu
  useEffect(() => {
    if (filteredOptions && filteredOptions.length > 0) {
      let options = [];
      filteredOptions.map((thp) =>
        options.push({ label: thp.label, value: thp.value })
      );

      if (options.length === filteredOptions.length) {
        setOptionCategories([{ options: options }]);
      }
    }
  }, [filteredOptions]);

  const onSelect = (e) => {
    setSelectedCategories(e);
    setFormData({
      ...formData,
      category: e,
    });
  };

  const contentChange = (editorState) => {
    setEditorState(editorState);
    setFormData({
      ...formData,
      content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
  };

  const contentChangeIntro = (editorStateIntro) => {
    setEditorStateIntro(editorStateIntro);
    setFormData({
      ...formData,
      postintro: draftToHtml(
        convertToRaw(editorStateIntro.getCurrentContent())
      ),
    });
  };

  const onRawChange = (e) => {
    e.preventDefault();
    setFormData({
      ...formData,
      content: e.target.value,
    });
  };

  const handleAcceptedFilesFi = (file) => {
    if (file.length > 1) {
      setFilesErrorFi('Only one file is allowed');
      setShowErrorFi(true);
    } else {
      file.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      );
      setShowErrorFi(false);
      setSelectedFeatured(file);
    }
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  useEffect(() => {
    if (content === '' && !switchHtml) {
      setEditorState(EditorState.createEmpty());
    } else {
      const contentBlock = htmlToDraft(content);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      setEditorState(EditorState.createWithContent(contentState));
    }

    // eslint-disable-next-line
  }, [switchHtml]);

  useEffect(() => {
    if (selectedFeatured.length > 0) {
      const fd = new FormData();
      fd.append('newimg', selectedFeatured[0]);

      try {
        axios
          .post(`${process.env.REACT_APP_PUBLIC_URL}api/uploads`, fd, {
            headers: {
              'Content-Type': 'multipart/form-data',
              storedid: currentClient,
            },
          })
          .then((res) => {
            setFormData({
              ...formData,
              featuredimg: res.data.filePath,
              othersizes: {
                tablet: res.data.tablet,
                mobile: res.data.mobile,
              },
            });
          })
          .catch((error) => {
            setFilesErrorFi('Error after uploading file');
            setShowErrorFi(true);
          });
      } catch (err) {
        if (err.response.status === 500) {
          setFilesErrorFi('There was a problem with the server');
          setShowErrorFi(true);
        } else {
          setFilesErrorFi(err.response.data.msg);
        }
      }
    }

    // eslint-disable-next-line
  }, [selectedFeatured]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (link && link !== '') {
      try {
        dispatch(editBlogpost(formData));
        dispatch(
          postActivity({
            logtype: 'positive',
            logcontent: `The blogpost item <strong>${formData.title}</strong> was edited successfully`,
            email: user ? user.email : '',
          })
        );
        setFormData({
          isLive: false,
          title: '',
          link: '',
          postintro: '',
          content: '',
          seotitle: '',
          category: [],
          featuredimg: 'uploads/big-placeholder.jpg',
          othersizes: {
            tablet: '',
            mobile: '',
          },
        });
        toggle(false);
        dispatch(clearCurrentBp());
        setSlugVal('');
        setShowError(false);
        setShowErrorFi(false);
        setSelectedFeatured([]);
      } catch (error) {
        dispatch(
          postActivity({
            logtype: 'negative',
            logcontent: `An error occurred <em>editing</em> blogpost: <strong>${error}</strong>`,
            email: user ? user.email : '',
          })
        );
        setErrorMsg(error);
      }
    } else if (
      title !== '' &&
      content !== '' &&
      postintro !== '' &&
      formData.link !== ''
    ) {
      try {
        dispatch(addBlogpost(formData));
        dispatch(
          postActivity({
            logtype: 'positive',
            logcontent: `The blogpost <strong>${formData.title}</strong> was created`,
            email: user ? user.email : '',
          })
        );
        setFormData({
          isLive: false,
          title: '',
          link: '',
          postintro: '',
          content: '',
          seotitle: '',
          category: [],
          featuredimg: 'uploads/big-placeholder.jpg',
          othersizes: {
            tablet: '',
            mobile: '',
          },
        });
        setSlugVal('');
        setShowError(false);
        setShowErrorFi(false);
        setSelectedFeatured([]);
        history('/all-blogposts');
      } catch (error) {
        dispatch(
          postActivity({
            logtype: 'negative',
            logcontent: `An error occurred adding blogpost: <strong>${error}</strong>`,
            email: user ? user.email : '',
          })
        );
      }
    } else {
      setShowError(true);
      setErrorMsg(`Title and Content are required`);
      setTimeout(() => {
        setShowError(false);
        setErrorMsg('');
      }, 9000);
    }
  };

  const uploadImageCallBack = async (file) => {
    const fd = new FormData();
    fd.append('newimg', file);

    const fileUploaded = await axios.post(
      `${process.env.REACT_APP_PUBLIC_URL}api/uploads`,
      fd,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          storedid: currentClient,
        },
      }
    );

    return Promise.resolve({
      data: {
        link: `${process.env.REACT_APP_PUBLIC_URL}${fileUploaded.data.filePath}`,
      },
    });
  };

  // Go to create Category
  const onCreateCategory = () => {
    if (title === '' || content === '' || postintro === '') {
      setShowError(true);
      setErrorMsg(
        'To save this post as Draft, Title, Content and Intro are required'
      );
    } else if (title === '' && content === '' && postintro === '') {
      history('/categories');
    } else {
      if (
        window.confirm(
          'To create a Category right now your post will be save as Draft and you will be redirected'
        ) === true
      ) {
        formData.isLive = false;
        try {
          if (link && link !== '') {
            dispatch(editBlogpost(formData));
          } else {
            dispatch(addBlogpost(formData));
          }
          setFormData({
            isLive: false,
            title: '',
            link: '',
            postintro: '',
            content: '',
            seotitle: '',
            category: [],
            featuredimg: 'uploads/big-placeholder.jpg',
            othersizes: {
              tablet: '',
              mobile: '',
            },
          });
          setSlugVal('');
          setShowError(false);
          setShowErrorFi(false);
          setSelectedFeatured([]);
          history('/categories');
        } catch (error) {
          dispatch(
            postActivity({
              logtype: 'negative',
              logcontent: `An error occurred adding blogpost: <strong>${error}</strong>`,
              email: user ? user.email : '',
            })
          );
        }
      }
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <React.Fragment>
      <div className='container-fluid plitz-forms'>
        <Row className='align-items-center'>
          <Col sm={6}>
            <div className='page-title-box'>
              <h1 className=''>
                {link && link !== '' ? 'Edit Item' : 'New Item'}
              </h1>
              <ol className='breadcrumb mb-0'>
                <li className='breadcrumb-item'>
                  <Link to='/dashboard'>Dashboard</Link>
                </li>
                <li className='breadcrumb-item'>
                  <Link to='/all-blogposts'>All Blogposts</Link>
                </li>
                <li className='breadcrumb-item'>Add Blogpost</li>
              </ol>
            </div>
          </Col>
        </Row>

        <Row>
          {showError ? (
            <div className='container-fluid'>
              <Row className='align-items-center'>
                <Col sm={12}>
                  <Alert color='warning'>{errorMsg}</Alert>
                </Col>
              </Row>
            </div>
          ) : null}
          {error ? (
            <div className='container-fluid'>
              <Row className='align-items-center'>
                <Col sm={12}>
                  <h3 className='title-3'>{error}</h3>
                </Col>
              </Row>
            </div>
          ) : null}
        </Row>

        <Row>
          <Col sm={12} md={9}>
            <Editable
              alwaysEditing={false}
              disabled={false}
              initialValue={
                link && link !== ''
                  ? link
                  : title
                      .replace(/[^a-zA-Z0-9]/g, '-')
                      .replace(/--/g, '')
                      .replace(/-$/, '')
                      .toLowerCase()
              }
              name='link'
              value={slugVal}
              id='link'
              isValueClickable={true}
              label={'Front-end Link (Click to edit)'}
              mode='inline'
              validate={(value) => onValidate(value)}
              onSubmit={(value) => onChangeSlug(value)}
              onValidated={(value) => {
                return value;
              }}
              placement='top'
              showText
              type='textfield'
            />
          </Col>
        </Row>

        <form onSubmit={(e) => onSubmit(e)}>
          <Row>
            <Col sm={12} md={9}>
              <Row className='form-group'>
                <Col sm={12}>
                  <input
                    className='form-control'
                    type='text'
                    placeholder='Title'
                    id='title'
                    name='title'
                    value={title}
                    onChange={(e) => onChange(e)}
                  />
                </Col>
              </Row>

              <Row className='form-group mt-3 mb-3'>
                <Col sm='12' className='mt-3'>
                  <h6>Post Intro</h6>
                  <small>
                    This will be the copy after the title and it will be used as
                    post preview
                  </small>
                </Col>
                <Col sm='12'>
                  <Editor
                    toolbar={{
                      options: [
                        'inline',
                        'list',
                        'link',
                        'emoji',
                        'remove',
                        'history',
                      ],
                      inline: {
                        options: ['bold', 'italic', 'underline'],
                        bold: { className: 'bordered-option-classname' },
                        italic: { className: 'bordered-option-classname' },
                        underline: { className: 'bordered-option-classname' },
                        strikethrough: {
                          className: 'bordered-option-classname',
                        },
                        code: { className: 'bordered-option-classname' },
                      },

                      history: {
                        inDropdown: false,
                        options: ['undo', 'redo'],
                      },
                    }}
                    editorState={editorStateIntro}
                    toolbarClassName='toolbarClassName'
                    wrapperClassName='wrapperClassName'
                    editorClassName='editorClassNameIntro'
                    onEditorStateChange={contentChangeIntro}
                  />
                </Col>
              </Row>

              <Row className='form-group'>
                <Col sm='12' className='mt-3 mb-3'>
                  <span className='mt-1 mr-2'>HTML Mode</span>
                  <Switch
                    uncheckedIcon={<Offsymbol />}
                    checkedIcon={<OnSymbol />}
                    onColor='#0b093a'
                    onChange={switchHtmlButton}
                    checked={switchHtml}
                  />{' '}
                </Col>
                <Col sm='12'>
                  {switchHtml ? (
                    <Input
                      placeholder='HTML Mode has been enabled'
                      type='textarea'
                      id='content'
                      name='content'
                      value={content}
                      className='editor-html'
                      onChange={(e) => onRawChange(e)}
                      style={{ height: '100%' }}
                    />
                  ) : (
                    <Editor
                      toolbar={{
                        options: [
                          'inline',
                          'blockType',
                          'list',
                          'textAlign',
                          'link',
                          'embedded',
                          'emoji',
                          'image',
                          'remove',
                          'history',
                        ],
                        inline: {
                          options: [
                            'bold',
                            'italic',
                            'underline',
                            'strikethrough',
                            'monospace',
                          ],
                          bold: { className: 'bordered-option-classname' },
                          italic: { className: 'bordered-option-classname' },
                          underline: { className: 'bordered-option-classname' },
                          strikethrough: {
                            className: 'bordered-option-classname',
                          },
                          code: { className: 'bordered-option-classname' },
                        },
                        image: {
                          urlEnabled: true,
                          uploadEnabled: true,
                          alignmentEnabled: true,
                          uploadCallback: uploadImageCallBack,
                          previewImage: true,
                          inputAccept:
                            'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                          alt: { present: true, mandatory: false },
                          defaultSize: {
                            width: '100%',
                            height: 'auto',
                          },
                        },

                        history: {
                          inDropdown: false,
                          options: ['undo', 'redo'],
                        },
                      }}
                      editorState={editorState}
                      toolbarClassName='toolbarClassName'
                      wrapperClassName='wrapperClassName'
                      editorClassName='editorClassName'
                      onEditorStateChange={contentChange}
                    />
                  )}
                </Col>
              </Row>

              <Row className='form-group mt-5'>
                <Col sm='12'>
                  <div className='extraboxes-secion'>
                    <h4>SEO Title Meta Tag</h4>
                    <small>Default is Post Title</small>
                    <br />
                    <Input
                      placeholder='HTML Title Tag'
                      type='text'
                      id='seotitle'
                      name='seotitle'
                      value={seotitle === '' ? title : seotitle}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col sm={12} md={3} className='pl-3 pl-lg-5'>
              <Card style={{ position: 'relative', zIndex: 1 }}>
                <CardBody>
                  <h4 className='card-title mb-4'>Blog Post Settings</h4>

                  <div className='mb-4 pb-4 setting-bottom-divider'>
                    <p className='card-title-desc'>Status</p>
                    <Switch
                      uncheckedIcon={<Offsymbol />}
                      checkedIcon={<OnSymbol />}
                      onColor='#0b093a'
                      onChange={switchButton}
                      checked={isLive}
                    />

                    <span className='ml-3'>
                      {isLive ? 'Published' : 'Draft'}
                    </span>
                  </div>

                  <div className='mb-4'>
                    <small>Post Category</small>
                    <div className='mt-2' style={{ zIndex: 999 }}>
                      {categories.length > 0 && (
                        <Select
                          value={selectedCategories}
                          isMulti
                          initialValue={selectedCategories}
                          onChange={(e) => onSelect(e)}
                          options={optionCategories}
                          className='plitz-selection'
                          classNamePrefix='plitzpost'
                        />
                      )}
                    </div>
                    <button
                      onClick={(e) => onCreateCategory(e)}
                      className='btn waves-effect text-primary waves-light'>
                      Add Category +
                    </button>
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <h4 className='card-title mb-4'>Featured Image</h4>

                  {showErrorFi ? (
                    <Alert color='warning'>{filesErrorFi}</Alert>
                  ) : null}
                  <Dropzone
                    onDrop={(acceptedFiles) =>
                      handleAcceptedFilesFi(acceptedFiles)
                    }>
                    {({ getRootProps, getInputProps }) => (
                      <div className='dropzone'>
                        <div
                          className='dz-message needsclick'
                          {...getRootProps()}>
                          <input
                            {...getInputProps()}
                            accept='image/png, image/gif, image/jpeg, image/jpg, image/webp'
                          />
                          <h5>
                            Drop file here or
                            <br />
                            click to upload.
                          </h5>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <div className='dropzone-previews mt-3' id='file-previews'>
                    {selectedFeatured.map((f, i) => {
                      return (
                        <Card
                          className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
                          key={i + '-file'}>
                          <div className='p-2'>
                            <Row className='align-items-center'>
                              <Col className='col-auto'>
                                <img
                                  data-dz-thumbnail=''
                                  height={80}
                                  width={'auto'}
                                  className='bg-light'
                                  alt={f.name}
                                  src={f.preview}
                                />
                              </Col>
                              <Col>
                                <Link
                                  to='#'
                                  className='text-muted font-weight-bold'>
                                  {f.name}
                                </Link>
                                <p className='mb-0'>
                                  <strong>{f.formattedSize}</strong>
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      );
                    })}
                    {selectedFeatured.length === 0 && featuredimg && (
                      <Row className='align-items-center'>
                        <Col className='col-auto'>
                          <img
                            height={80}
                            width={'auto'}
                            className='bg-light'
                            alt={title ? title : 'Placeholder'}
                            src={`${process.env.REACT_APP_PUBLIC_URL}${featuredimg}`}
                          />
                        </Col>
                      </Row>
                    )}
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <button
                    type='submit'
                    className='btn btn-primary waves-effect waves-light'>
                    Save
                  </button>
                  <button
                    type='button'
                    onClick={(e) => {
                      e.preventDefault();
                      setEditorState(EditorState.createEmpty());
                      setFormData({
                        isLive: false,
                        title: '',
                        link: '',
                        postintro: '',
                        content: '',
                        seotitle: '',
                        category: [],
                        featuredimg: 'uploads/big-placeholder.jpg',
                        othersizes: {
                          tablet: '',
                          mobile: '',
                        },
                      });
                    }}
                    className='btn btn-danger ml-2 waves-effect waves-light'>
                    Reset
                  </button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
      </div>
    </React.Fragment>
  );
};

const Offsymbol = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: 12,
        color: '#fff',
        paddingRight: 2,
        paddingTop: 3,
      }}>
      {' '}
      <small>OFF</small>
    </div>
  );
};

const OnSymbol = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: 12,
        color: '#fff',
        paddingRight: 2,
        paddingTop: 3,
      }}>
      {' '}
      <small>ON</small>
    </div>
  );
};

export default AddBlogpost;
