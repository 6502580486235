import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';

// //Import Scrollbar
import SimpleBar from 'simplebar-react';

// MetisMenu
import MetisMenu from '@metismenu/react';
import 'metismenu/dist/metisMenu.css';

// Components
import AdminMenu from './AdminMenu';
import RestaurantMenu from './RestaurantMenu';

const SidebarContent = (props) => {
  const ref = useRef();
  const currentLoc = useLocation();
  const [mainLinks, setMainlinks] = useState([]);
  const [items, setItems] = useState([]);

  useEffect(() => {
    const themenu = document.querySelector('.metismenu ul');
    const mainlinks = themenu.querySelectorAll('li a');
    const items = document.querySelectorAll('.with-dd ul li a');

    setMainlinks(mainlinks);
    setItems(items);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const pathName =
      process.env.NODE_ENV === 'production'
        ? `${currentLoc.pathname}`
        : `/td${currentLoc.pathname}`;

    for (var j = 0; j < mainLinks.length; ++j) {
      if (pathName === mainLinks[j].pathname) {
        mainLinks[j].classList.add('activated');
        break;
      }
    }

    for (var i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        activateParentDropdown(
          items[i].parentElement.parentElement.parentElement
        );

        break;
      }
    }

    // eslint-disable-next-line
  }, [mainLinks, items, currentLoc.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  const scrollElement = (item) => {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  };

  const activateParentDropdown = (item) => {
    const parent2El = item.childNodes[1];
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    item.classList.add('mm-active');
    const parent2 = item.parentElement;

    if (parent2) {
      parent2.classList.add('mm-show'); // ul tag

      const parent3 = parent2.parentElement; // li tag

      if (parent3) {
        parent3.classList.add('mm-active'); // li
        parent3.childNodes[0].classList.add('mm-active'); //a
        const parent4 = parent3.parentElement; // ul
        if (parent4) {
          parent4.classList.add('mm-show'); // ul
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add('mm-show'); // li
            parent5.childNodes[0].classList.add('mm-active'); // a tag
          }
        }
      }
    }

    scrollElement(item);
    return false;
  };

  const disableClick = (e) => {
    e.preventDefault();
  };

  return (
    <Fragment>
      <SimpleBar ref={ref}>
        <div id='sidebar-menu' className='list-unstyled'>
          <MetisMenu>
            <li className='menu-title'>Main</li>
            <li>
              <Link to='/dashboard' className='wsaves-effect'>
                <i className='fa-light fa-house-blank'></i>
                <span>Dashboard</span>
              </Link>
            </li>
            <li>
              <Link to='/email-inbox' className='waves-effect'>
                <i className='fa-light fa-envelope-dot'></i>
                <span>Inbox</span>
              </Link>
            </li>
            <li className='menu-title'>Content</li>
            <li className='with-dd'>
              <Link
                to='/#!'
                onClick={(e) => disableClick(e)}
                className='has-arrow waves-effect'>
                <i className='fa-light fa-file'></i>
                <span>Pages</span>
              </Link>
              <ul className='sub-menu'>
                <li>
                  <Link to='/add-page'>Add New</Link>
                </li>
                <li>
                  <Link to='/all-pages'>
                    <span className='badge badge-pill badge-primary float-right'>
                      {props.pages && props.pages.length}
                    </span>
                    All Pages
                  </Link>
                </li>
              </ul>
            </li>
            <li className='with-dd'>
              <Link
                to='/#!'
                onClick={(e) => disableClick(e)}
                className='has-arrow waves-effect'>
                <i className='fa-light fa-repeat'></i>
                <span>CTAs</span>
              </Link>
              <ul className='sub-menu'>
                <li>
                  <Link to='/add-cta'>Add New</Link>
                </li>
                <li>
                  <Link to='/all-cta'>
                    <span className='badge badge-pill badge-primary float-right'>
                      {props.sliders && props.sliders.length}
                    </span>
                    All CTAs
                  </Link>
                </li>
              </ul>
            </li>
            <li className='with-dd'>
              <Link
                to='/#!'
                onClick={(e) => disableClick(e)}
                className='has-arrow waves-effect'>
                <i className='fa-light fa-message-quote'></i>
                <span>Testimonials</span>
              </Link>
              <ul className='sub-menu'>
                <li>
                  <Link to='/add-testimonial'>Add New</Link>
                </li>
                <li>
                  <Link to='/all-testimonials'>
                    <span className='badge badge-pill badge-primary float-right'>
                      {props.testimonials && props.testimonials.length}
                    </span>
                    All Testimonials
                  </Link>
                </li>
              </ul>
            </li>
            <li className='with-dd'>
              <Link
                to='/#!'
                onClick={(e) => disableClick(e)}
                className='has-arrow waves-effect'>
                <i className='fa-light fa-typewriter'></i>
                <span>Blog</span>
              </Link>
              <ul className='sub-menu'>
                <li>
                  <Link to='/add-blogpost'>Add New Post</Link>
                </li>
                <li>
                  <Link to='/all-blogposts'>
                    <span className='badge badge-pill badge-primary float-right'>
                      {props.blogposts.length}
                    </span>
                    All Blog Posts
                  </Link>
                </li>
                <li>
                  <Link to='/categories'>
                    <span className='badge badge-pill badge-primary float-right'>
                      {props.categories.length}
                    </span>
                    Categories
                  </Link>
                </li>
              </ul>
            </li>
            {props.showRestaurantNav && (
              <RestaurantMenu
                disableClick={disableClick}
                locations={props.locations}
                categories={props.menuCategories}
                items={props.menuItems}
                specials={props.specials}
              />
            )}
            <li className='menu-title'>Settings</li>
            <li className='with-dd'>
              <Link
                to='/#!'
                onClick={(e) => disableClick(e)}
                className='has-arrow waves-effect'>
                <i className='fa-light fa-users-gear'></i>
                <span>Users</span>
              </Link>
              <ul className='sub-menu'>
                <li>
                  <Link to='/all-users'>
                    <span className='badge badge-pill badge-primary float-right'>
                      {props.users && props.users.length}
                    </span>
                    All Users
                  </Link>
                </li>
                <li>
                  <Link to='/add-user'>Create User</Link>
                </li>
                <li>
                  <Link to='/update-profile'>My User Settings</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to='/activity' className='waves-effect'>
                <i className='fa-light fa-wave-pulse'></i>
                <span>Activity</span>
              </Link>
            </li>
            {props.showAdminNav && (
              <AdminMenu
                clients={props.clients}
                promos={props.promos}
                disableClick={disableClick}
              />
            )}
          </MetisMenu>
        </div>
      </SimpleBar>
    </Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
};

export default SidebarContent;
