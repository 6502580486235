import React from 'react';

const ToggleLayout = ({ toggleRightbar }) => {
	return (
		<div className='dropdown d-inline-block'>
			<button
				type='button'
				onClick={toggleRightbar}
				className='btn header-item right-bar-toggle waves-effect'
			>
				<i className='fa-light fa-sidebar-flip' style={{ fontSize: 22 }}></i>
			</button>
		</div>
	);
};

export default ToggleLayout;
