import axios from 'axios';

const url = `${
  process.env.REACT_APP_PUBLIC_URL || process.env.NEXT_PUBLIC_URL
}api/clients`;

export const getClientsApi = async () => {
  const setToken =
    typeof window !== 'undefined' && localStorage.getItem('token');
  const storedId =
    typeof window !== 'undefined' && localStorage.getItem('tenant');

  const authConfig = {
    headers: {
      Authorization: `Bearer ${setToken}`,
      'Content-Type': 'application/json',
      storedId,
    },
  };
  const response = await axios.get(`${url}`, authConfig);

  return response;
};

export const registerClientApi = async (formData) => {
  const storedId =
    typeof window !== 'undefined' && localStorage.getItem('tenant');

  const config = {
    headers: {
      'Content-Type': 'application/json',
      storedId,
    },
  };

  const response = await axios.post(`${url}`, formData, config);

  return response;
};

export const getClientApi = async () => {
  const setToken =
    typeof window !== 'undefined' && localStorage.getItem('token');
  const storedId =
    typeof window !== 'undefined' && localStorage.getItem('tenant');

  const authConfig = {
    headers: {
      Authorization: `Bearer ${setToken}`,
      'Content-Type': 'application/json',
      storedId,
    },
  };

  const response = await axios.get(`${url}/profile`, authConfig);

  return response;
};

export const updateClientApi = async (formData) => {
  const setToken =
    typeof window !== 'undefined' && localStorage.getItem('token');
  const storedId =
    typeof window !== 'undefined' && localStorage.getItem('tenant');

  const authConfig = {
    headers: {
      Authorization: `Bearer ${setToken}`,
      'Content-Type': 'application/json',
      storedId,
    },
  };

  const response = await axios.put(`${url}/update`, formData, authConfig);

  return response;
};

export const createClientApi = async (formData) => {
  const setToken =
    typeof window !== 'undefined' && localStorage.getItem('token');
  const storedId =
    typeof window !== 'undefined' && localStorage.getItem('tenant');

  const authConfig = {
    headers: {
      Authorization: `Bearer ${setToken}`,
      'Content-Type': 'application/json',
      storedId,
    },
  };

  const response = await axios.post(`${url}/create`, formData, authConfig);

  return response;
};

export const callClientApi = async (id) => {
  const setToken =
    typeof window !== 'undefined' && localStorage.getItem('token');
  const storedId =
    typeof window !== 'undefined' && localStorage.getItem('tenant');

  const authConfig = {
    headers: {
      Authorization: `Bearer ${setToken}`,
      'Content-Type': 'application/json',
      storedId,
    },
  };

  const response = await axios.get(`${url}/${id}`, authConfig);

  return response;
};

export const editClientApi = async (payload) => {
  const setToken =
    typeof window !== 'undefined' && localStorage.getItem('token');
  const storedId =
    typeof window !== 'undefined' && localStorage.getItem('tenant');

  const authConfig = {
    headers: {
      Authorization: `Bearer ${setToken}`,
      'Content-Type': 'application/json',
      storedId,
    },
  };

  const response = await axios.put(
    `${url}/create/${payload.id}`,
    payload.formData,
    authConfig
  );

  return response;
};

export const deleteClientApi = async (id) => {
  const setToken =
    typeof window !== 'undefined' && localStorage.getItem('token');
  const storedId =
    typeof window !== 'undefined' && localStorage.getItem('tenant');

  const authConfig = {
    headers: {
      Authorization: `Bearer ${setToken}`,
      'Content-Type': 'application/json',
      storedId,
    },
  };

  const response = await axios.delete(`${url}/${id}`, authConfig);

  return response;
};
