import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBlogposts,
  getCategories,
  getLocations,
  getMenuCategories,
  getMenuItems,
  getPages,
  getPromos,
  getSliders,
  getSpecials,
  getTestimonials,
  tryGetclients,
  tryGetusers,
} from '@thedavid/plitzredux/actions';

import SidebarContent from './SidebarContent';

const Sidebar = () => {
  const dispatch = useDispatch();
  const [showAdminNav, setShowAdminNav] = useState(false);
  const [showRestaurantNav, setShowRestaurantNav] = useState(false);

  useEffect(() => {
    dispatch(getBlogposts());
    dispatch(getPages());
    dispatch(tryGetusers());
    dispatch(getSliders());
    dispatch(getTestimonials());
    dispatch(getPromos());
    dispatch(getCategories());
    dispatch(tryGetclients());
    dispatch(getLocations());
    dispatch(getMenuCategories());
    dispatch(getMenuItems());
    dispatch(getSpecials());
  }, [dispatch]);

  const Pages = useSelector((state) => state.Pages);
  const { pages } = Pages;
  const Sliders = useSelector((state) => state.Sliders);
  const { sliders } = Sliders;
  const Promos = useSelector((state) => state.Promos);
  const { promos } = Promos;
  const Testimonials = useSelector((state) => state.Testimonials);
  const { testimonials } = Testimonials;
  const Blogposts = useSelector((state) => state.Blogposts);
  const { blogposts } = Blogposts;
  const Categories = useSelector((state) => state.Categories);
  const { categories } = Categories;
  const Login = useSelector((state) => state.Login);
  const { users, user } = Login;
  const Clients = useSelector((state) => state.Clients);
  const { clients } = Clients;
  const Locations = useSelector((state) => state.Locations);
  const { locations } = Locations;
  const MenuCategories = useSelector((state) => state.MenuCategories);
  const { menuCategories } = MenuCategories;
  const MenuItems = useSelector((state) => state.MenuItems);
  const { menuItems } = MenuItems;
  const Specials = useSelector((state) => state.Specials);
  const { specials } = Specials;

  useEffect(() => {
    if (user && user.isSuperAdmin) {
      setShowAdminNav(true);
    } else {
      setShowAdminNav(false);
    }

    if (user?.companyId === 'Plitz7' || user?.companyId === 'MariaBonita') {
      setShowRestaurantNav(true);
    } else {
      setShowRestaurantNav(false);
    }
  }, [user]);

  return (
    <React.Fragment>
      <div data-simplebar className='h-100'>
        <SidebarContent
          pages={pages}
          blogposts={blogposts}
          categories={categories}
          users={users}
          sliders={sliders}
          testimonials={testimonials}
          promos={promos}
          clients={clients}
          locations={locations}
          menuCategories={menuCategories}
          menuItems={menuItems}
          specials={specials}
          showAdminNav={showAdminNav}
          showRestaurantNav={showRestaurantNav}
        />
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
  pages: PropTypes.array,
  blogposts: PropTypes.array,
  categories: PropTypes.array,
  users: PropTypes.array,
  clients: PropTypes.array,
  sliders: PropTypes.array,
  promos: PropTypes.array,
  locations: PropTypes.array,
  menuItems: PropTypes.array,
  menuCategories: PropTypes.array,
  specials: PropTypes.array,
};

const mapStatetoProps = (state) => {
  return {
    layout: state.Layout,
  };
};
export default connect(mapStatetoProps, {})(Sidebar);
