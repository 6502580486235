import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  DropdownItem,
  DropdownMenu,
  Dropdown,
  DropdownToggle,
} from 'reactstrap';

// import images
import logodarkImg from '../../assets/images/logo-dark.png';
import logosmImg from '../../assets/images/plitz-logo-sm.png';
import logosmLightImg from '../../assets/images/logo-sm-light.png';
import logolightImg from '../../assets/images/logo-light.png';

// Import other Dropdown
import NotificationDropdown from '../../components/NotificationDropdown';
import ProfileMenu from '../../components/ProfileMenu';
import FullScreen from '../Extras/FullScreen';
import ToggleLayout from '../Extras/ToggleLayout';

const TopBar = ({ toggleMenuCallback, toggleRightSidebar }) => {
  const [createMenu, setCreateMenu] = useState(false);

  /**
   * Toggle sidebar
   */
  const openActions = () => {
    if (createMenu) {
      setCreateMenu(false);
    } else {
      setCreateMenu(true);
    }
  };

  /**
   * Toggle sidebar
   */
  const toggleMenu = () => {
    toggleMenuCallback();
  };

  /**
   * Toggles the sidebar
   */
  const toggleRightbar = () => {
    toggleRightSidebar();
  };

  /**
   * Toggle full screen
   */
  const toggleFullscreen = () => {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  };

  return (
    <React.Fragment>
      <header id='page-topbar'>
        <div className='navbar-header'>
          <div className='d-flex'>
            <div className='navbar-brand-box'>
              <Link to='/dashboard' className='logo logo-dark'>
                <span className='logo-sm'>
                  <img src={logosmImg} alt='' height='50' />
                </span>
                <span className='logo-lg'>
                  <img src={logodarkImg} alt='' height='40' />
                </span>
              </Link>

              <Link to='/dashboard' className='logo logo-light'>
                <span className='logo-sm'>
                  <img src={logosmLightImg} alt='' height='50' />
                </span>
                <span className='logo-lg'>
                  <img src={logolightImg} alt='' height='40' />
                </span>
              </Link>
            </div>
            <button
              type='button'
              onClick={toggleMenu}
              className='btn btn-sm header-item waves-effect'
              id='vertical-menu-btn'>
              <i className='fa-light fa-sidebar' style={{ fontSize: 22 }}></i>
            </button>

            <div className='d-none d-sm-block vertical-align'>
              <Dropdown
                isOpen={createMenu}
                toggle={openActions}
                className='d-inline-block'>
                <DropdownToggle
                  className='btn btn-secondary create-shortcut'
                  tag='button'>
                  Create New <i className='mdi mdi-chevron-down'></i>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem tag='a' href={`add-page`}>
                    Page
                  </DropdownItem>
                  <DropdownItem tag='a' href={`add-cta`}>
                    CTA
                  </DropdownItem>
                  <DropdownItem tag='a' href={`add-testimonial`}>
                    Testimonial
                  </DropdownItem>
                  <DropdownItem tag='a' href={`add-blogpost`}>
                    Blog Post
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>

          <div className='d-flex'>
            <FullScreen toggleFullscreen={toggleFullscreen} />

            <NotificationDropdown />

            <ToggleLayout toggleRightbar={toggleRightbar} />

            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default TopBar;
