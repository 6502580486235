import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Input, FormGroup, Label } from 'reactstrap';

//SimpleBar
import SimpleBar from 'simplebar-react';

import {
	hideRightSidebar,
	changeLayout,
	changeLayoutWidth,
	changeTopbarTheme,
	changeSidebarType,
	changeSidebarTheme,
} from '@thedavid/plitzredux/actions';
import ClickOutside from './LayoutHelpers/ClickOutside';

const RightSidebar = () => {
	const dispatch = useDispatch();
	const [layoutTypeLs, setLayoutTypeLs] = useState(
		localStorage.getItem('layoutType') ? localStorage.getItem('layoutType') : ''
	);
	const [layoutWidthLs, setLayoutWidthLs] = useState(
		localStorage.getItem('layoutWidth')
			? localStorage.getItem('layoutWidth')
			: ''
	);
	const [topbarThemeLs, setTopbarThemeLs] = useState(
		localStorage.getItem('topbarTheme')
			? localStorage.getItem('topbarTheme')
			: ''
	);
	const [sidebarTypeLs, setSidebarTypeLs] = useState(
		localStorage.getItem('leftSideBarType')
			? localStorage.getItem('leftSideBarType')
			: ''
	);
	const [sidebarThemeLs, setSidebarThemeLs] = useState(
		localStorage.getItem(' sidebarTheme')
			? localStorage.getItem(' sidebarTheme')
			: ''
	);

	const Layout = useSelector((state) => state.Layout);
	const {
		layoutType,
		layoutWidth,
		topbarTheme,
		leftSideBarType,
		leftSideBarTheme,
	} = Layout;

	useEffect(() => {
		localStorage.setItem('layoutType', layoutType);
		localStorage.setItem('layoutWidth', layoutWidth);
		localStorage.setItem('topbarTheme', topbarTheme);
		localStorage.setItem('leftSideBarType', leftSideBarType);
		localStorage.setItem('leftSideBarTheme', leftSideBarTheme);
	}, [layoutType, layoutWidth, topbarTheme, leftSideBarType, leftSideBarTheme]);

	useEffect(() => {
		if (!localStorage.getItem('layoutType')) {
			setLayoutTypeLs(layoutType);
		}
		if (!localStorage.getItem('layoutWidth')) {
			setLayoutTypeLs(layoutWidth);
		}
		if (!localStorage.getItem('topbarTheme')) {
			setTopbarThemeLs(topbarTheme);
		}
		if (!localStorage.getItem('leftSideBarType')) {
			setSidebarTypeLs(leftSideBarType);
		}
		if (!localStorage.getItem('leftSideBarTheme')) {
			setSidebarThemeLs(leftSideBarTheme);
		}

		// eslint-disable-next-line
	}, []);

	/**
	 * Hides the right sidebar
	 */
	const hideRightbar = () => {
		dispatch(hideRightSidebar());
	};

	/**
	 * Change the layout
	 * @param {*} e
	 */
	const changeLayoutClick = (e) => {
		e.preventDefault();
		if (e.target.checked) {
			localStorage.setItem('layoutType', e.target.value);
			setLayoutTypeLs(e.target.value);
		}
	};

	/**
	 * Changes layout width
	 * @param {*} e
	 */
	const changeLayoutWidthClick = (e) => {
		if (e.target.checked) {
			localStorage.setItem('layoutWidth', e.target.value);
			setLayoutWidthLs(e.target.value);
		}
	};

	// change topbar theme
	const changeTopbarThemeClick = (e) => {
		if (e.target.checked) {
			localStorage.setItem('topbarTheme', e.target.value);
			setTopbarThemeLs(e.target.value);
		}
	};

	// change left sidebar design
	const changeLeftSidebarTypeClick = (e) => {
		if (e.target.checked) {
			localStorage.setItem('leftSideBarType', e.target.value);
			setSidebarTypeLs(e.target.value);
		}
	};

	// change left sidebar theme
	const changeLeftSidebarThemeClick = (e) => {
		if (e.target.checked) {
			localStorage.setItem('leftSideBarTheme', e.target.value);
			setSidebarThemeLs(e.target.value);
		}
	};

	useEffect(() => {
		dispatch(changeLayout(localStorage.getItem('layoutType')));
		dispatch(changeLayoutWidth(localStorage.getItem('layoutWidth')));
		dispatch(changeTopbarTheme(localStorage.getItem('topbarTheme')));
		dispatch(changeSidebarType(localStorage.getItem('leftSideBarType')));
		dispatch(changeSidebarTheme(localStorage.getItem('leftSideBarTheme')));

		// eslint-disable-next-line
	}, [
		layoutTypeLs,
		layoutWidthLs,
		topbarThemeLs,
		sidebarTypeLs,
		sidebarThemeLs,
	]);

	return (
		<Fragment>
			<div className='right-bar'>
				<SimpleBar style={{ height: '900px' }}>
					<ClickOutside onOutsideClick={() => hideRightbar()}>
						<div data-simplebar className='h-100'>
							<div className='rightbar-title px-3 py-4'>
								<Link
									to='#'
									onClick={hideRightbar}
									className='right-bar-toggle float-right'
								>
									<i className='mdi mdi-close noti-icon'></i>
								</Link>
								<h5 className='m-0'>Customize View</h5>
							</div>

							<hr className='my-0' />

							<div className='p-4'>
								<FormGroup>
									<Label className='option-label' for='layout'>
										Layouts
									</Label>

									<div>
										<div className='button-and-label'>
											<Input
												type='radio'
												id='verticalLayout'
												name='layout'
												value='vertical'
												checked={
													layoutTypeLs === 'vertical' ||
													layoutType === 'vertical'
														? true
														: false
												}
												onChange={(e) => changeLayoutClick(e)}
											/>{' '}
											<Label for='verticalLayout'>Vertical</Label>
										</div>
										<div className='button-and-label'>
											<Input
												type='radio'
												id='horizontalLayout'
												name='layout'
												value='horizontal'
												checked={
													layoutTypeLs === 'horizontal' ||
													layoutType === 'horizontal'
														? true
														: false
												}
												onChange={(e) => changeLayoutClick(e)}
											/>{' '}
											<Label for='horizontalLayout'>Horizontal</Label>
										</div>
									</div>
								</FormGroup>

								<hr />

								<FormGroup>
									<Label className='option-label' for='layout'>
										Layout Width
									</Label>
									<div>
										<div className='button-and-label'>
											<Input
												type='radio'
												id='fluid'
												name='layoutWidth'
												value='fluid'
												checked={
													layoutWidthLs === 'fluid' || layoutWidth === 'fluid'
														? true
														: false
												}
												onChange={(e) => changeLayoutWidthClick(e)}
											/>{' '}
											<Label for='fluid'>Fluid</Label>
										</div>
										<div className='button-and-label'>
											<Input
												type='radio'
												id='boxed'
												name='layoutWidth'
												value='boxed'
												checked={
													layoutWidthLs === 'boxed' || layoutWidth === 'boxed'
														? true
														: false
												}
												onChange={(e) => changeLayoutWidthClick(e)}
											/>{' '}
											<Label for='boxed'>Boxed</Label>
										</div>
									</div>
								</FormGroup>

								<hr />
								<FormGroup>
									<Label className='option-label'>Topbar Theme</Label>
									<div>
										<div className='button-and-label'>
											<Input
												type='radio'
												id='topbar-light'
												name='topbar-theme'
												value='light'
												checked={
													topbarThemeLs === 'light' || topbarTheme === 'light'
														? true
														: false
												}
												onChange={(e) => changeTopbarThemeClick(e)}
											/>{' '}
											<Label for='topbar-light'>Light</Label>
										</div>
										<div className='button-and-label'>
											<Input
												type='radio'
												id='topbar-dark'
												name='topbar-theme'
												value='dark'
												checked={
													topbarThemeLs === 'dark' || topbarTheme === 'dark'
														? true
														: false
												}
												onChange={(e) => changeTopbarThemeClick(e)}
											/>{' '}
											<Label for='topbar-dark'>Dark</Label>
										</div>
									</div>
								</FormGroup>

								{layoutType === 'vertical' ? (
									<React.Fragment>
										<hr />
										<FormGroup>
											<Label className='option-label'>Left Sidebar Type</Label>
											<div>
												<div className='button-and-label'>
													<Input
														type='radio'
														id='defaultSidebar'
														name='type'
														value='default'
														checked={
															sidebarTypeLs === 'default' ||
															leftSideBarType === 'default'
																? true
																: false
														}
														onChange={(e) => changeLeftSidebarTypeClick(e)}
													/>{' '}
													<Label for='defaultSidebar'>Default</Label>
												</div>
												<div className='button-and-label'>
													<Input
														type='radio'
														id='compactSidebar'
														name='type'
														value='compact'
														checked={
															sidebarTypeLs === 'compact' ||
															leftSideBarType === 'compact'
																? true
																: false
														}
														onChange={(e) => changeLeftSidebarTypeClick(e)}
													/>{' '}
													<Label for='compactSidebar'>Compact</Label>
												</div>
												<div className='button-and-label'>
													<Input
														type='radio'
														id='iconSidebar'
														name='type'
														value='icon'
														checked={
															sidebarTypeLs === 'icon' ||
															leftSideBarType === 'icon'
																? true
																: false
														}
														onChange={(e) => changeLeftSidebarTypeClick(e)}
													/>{' '}
													<Label for='iconSidebar'>Icon</Label>
												</div>
											</div>
										</FormGroup>

										<hr />

										<FormGroup>
											<Label className='option-label'>Left Sidebar Theme</Label>
											<div>
												<div className='button-and-label'>
													<Input
														type='radio'
														id='light'
														name='theme'
														value='light'
														checked={
															sidebarThemeLs === 'light' ||
															leftSideBarTheme === 'light'
																? true
																: false
														}
														onChange={(e) => changeLeftSidebarThemeClick(e)}
													/>{' '}
													<Label for='light'>Light</Label>
												</div>
												<div className='button-and-label'>
													<Input
														type='radio'
														id='colored'
														name='theme'
														value='colored'
														checked={
															sidebarThemeLs === 'colored' ||
															leftSideBarTheme === 'colored'
														}
														onChange={(e) => changeLeftSidebarThemeClick(e)}
													/>{' '}
													<Label for='colored'>Colored</Label>
												</div>
												<div className='button-and-label'>
													<Input
														type='radio'
														id='dark'
														name='theme'
														value='dark'
														checked={
															sidebarThemeLs === 'dark' ||
															leftSideBarTheme === 'dark'
														}
														onChange={(e) => changeLeftSidebarThemeClick(e)}
													/>{' '}
													<Label for='dark'>Dark</Label>
												</div>
											</div>
										</FormGroup>
									</React.Fragment>
								) : null}
							</div>
						</div>
					</ClickOutside>
				</SimpleBar>
			</div>
			{/* Right bar overlay */}
			<div className='rightbar-overlay'></div>
		</Fragment>
	);
};

export default RightSidebar;
