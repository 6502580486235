import {
  ADD_SPECIAL_FAILED,
  ADD_SPECIAL_GO,
  ADD_SPECIAL_TRY,
  CLEAR_CURRENT,
  EDIT_SPECIAL_FAILED,
  EDIT_SPECIAL_GO,
  EDIT_SPECIAL_TRY,
  GET_ASPECIAL_FAILED,
  GET_ASPECIAL_GO,
  GET_ASPECIAL_TRY,
  GET_SPECIALS_FAILED,
  GET_SPECIALS_GO,
  GET_SPECIALS_TRY,
  REMOVE_SPECIAL_FAILED,
  REMOVE_SPECIAL_GO,
  REMOVE_SPECIAL_TRY,
} from './actionTypes';

export const getSpecials = () => {
  return {
    type: GET_SPECIALS_TRY,
  };
};

export const gettingSpecials = (data) => {
  return {
    type: GET_SPECIALS_GO,
    payload: data,
  };
};

export const getSpecialsFailed = (error) => {
  return {
    type: GET_SPECIALS_FAILED,
    payload: error,
  };
};

export const getSpecial = (id) => {
  return {
    type: GET_ASPECIAL_TRY,
    payload: id,
  };
};

export const gettingSpecial = (data) => {
  return {
    type: GET_ASPECIAL_GO,
    payload: data,
  };
};

export const getSpecialFailed = (error) => {
  return {
    type: GET_ASPECIAL_FAILED,
    payload: error,
  };
};

export const clearCurrentSp = () => {
  return {
    type: CLEAR_CURRENT,
  };
};

export const addSpecial = (formData) => {
  return {
    type: ADD_SPECIAL_TRY,
    payload: { formData },
  };
};

export const specialAdded = (data) => {
  return {
    type: ADD_SPECIAL_GO,
    payload: data,
  };
};

export const addSpecialFailed = (error) => {
  return {
    type: ADD_SPECIAL_FAILED,
    payload: error,
  };
};

export const editSpecial = (formData) => {
  return {
    type: EDIT_SPECIAL_TRY,
    payload: { formData },
  };
};

export const specialEdited = (data) => {
  return {
    type: EDIT_SPECIAL_GO,
    payload: data,
  };
};

export const editSpecialFailed = (error) => {
  return {
    type: EDIT_SPECIAL_FAILED,
    payload: error,
  };
};

export const removeSpecial = (id) => {
  return {
    type: REMOVE_SPECIAL_TRY,
    payload: id,
  };
};

export const removingSpecial = (data) => {
  return {
    type: REMOVE_SPECIAL_GO,
    payload: data,
  };
};

export const removeSpecialFailed = (error) => {
  return {
    type: REMOVE_SPECIAL_FAILED,
    payload: error,
  };
};
