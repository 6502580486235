import {
  ADD_SPECIAL_FAILED,
  ADD_SPECIAL_GO,
  ADD_SPECIAL_TRY,
  CLEAR_CURRENT,
  EDIT_SPECIAL_FAILED,
  EDIT_SPECIAL_GO,
  EDIT_SPECIAL_TRY,
  GET_ASPECIAL_FAILED,
  GET_ASPECIAL_GO,
  GET_ASPECIAL_TRY,
  GET_SPECIALS_FAILED,
  GET_SPECIALS_GO,
  GET_SPECIALS_TRY,
  REMOVE_SPECIAL_FAILED,
  REMOVE_SPECIAL_GO,
  REMOVE_SPECIAL_TRY,
} from './actionTypes';

const initialState = {
  specials: [],
  special: null,
  loading: false,
  error: null,
  msg: null,
};

const Specials = (state = initialState, action) => {
  switch (action.type) {
    case GET_SPECIALS_TRY:
    case GET_ASPECIAL_TRY:
    case ADD_SPECIAL_TRY:
    case EDIT_SPECIAL_TRY:
    case REMOVE_SPECIAL_TRY:
      return {
        ...state,
        loading: true,
      };

    case GET_SPECIALS_GO:
      return {
        ...state,
        specials: action.payload,
        loading: false,
      };

    case ADD_SPECIAL_GO:
      return {
        ...state,
        specials: [action.payload, ...state.specials],
        loading: false,
      };

    case EDIT_SPECIAL_GO:
      return {
        ...state,
        specials: state.specials.map((special) =>
          special._id === action.payload._id ? action.payload : special
        ),
        loading: false,
      };

    case GET_ASPECIAL_GO:
      return {
        ...state,
        special: action.payload,
        loading: false,
      };

    case REMOVE_SPECIAL_GO:
      return {
        ...state,
        specials: state.specials.filter((fld) => fld._id !== action.payload.id),
        msg: action.payload.msg,
        loading: false,
      };

    case GET_SPECIALS_FAILED:
    case GET_ASPECIAL_FAILED:
    case ADD_SPECIAL_FAILED:
    case EDIT_SPECIAL_FAILED:
    case REMOVE_SPECIAL_FAILED:
      return {
        ...state,
        special: null,
        error: action.payload,
        loading: false,
      };

    case CLEAR_CURRENT:
      return {
        ...state,
        special: null,
        loading: false,
      };

    default:
      return state;
  }
};

export default Specials;
