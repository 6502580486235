import {
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  TRY_LOGIN,
  USER_LOGOUT,
  REGISTER_TRY,
  REGISTERING_USER,
  REGISTER_FAILED,
  CHANGEPASS_TRY,
  CHANGEPASS_GO,
  CHANGEPASS_FAILED,
  NEWPASS_TRY,
  NEWPASS_GO,
  NEWPASS_FAILED,
  UPDATE_TRY,
  UPDATE_GO,
  UPDATE_FAILED,
  GET_USERS,
  GETTING_USERS,
  GETTING_FAIL,
  ADDUSER_TRY,
  ADDUSER_GO,
  ADDUSER_FAILED,
  CALLUSER_TRY,
  CALLUSER_GO,
  CALLUSER_FAILED,
  EDITUSER_TRY,
  EDITUSER_GO,
  EDITUSER_FAILED,
  REMOVE_USER_TRY,
  REMOVE_USER_GO,
  REMOVE_USER_FAILED,
  SELF_REMOVE_TRY,
  SELF_REMOVE_GO,
  SELF_REMOVE_FAILED,
} from './actionTypes';

const initialState = {
  token: typeof window !== 'undefined' && localStorage.getItem('token'),
  isAuthenticated: null,
  loading: false,
  users: [],
  user: null,
  otheruser: null,
  alreadyin: false,
  error: null,
  msg: null,
};

const Login = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        loading: true,
      };

    case REGISTER_TRY:
    case CHANGEPASS_TRY:
    case NEWPASS_TRY:
    case UPDATE_TRY:
    case REMOVE_USER_TRY:
    case SELF_REMOVE_TRY:
    case TRY_LOGIN:
      return {
        ...state,
        ...action.payload,
        error: null,
        loading: true,
      };

    case ADDUSER_TRY:
    case CALLUSER_TRY:
    case EDITUSER_TRY:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case REGISTERING_USER:
    case NEWPASS_GO:
    case LOGIN_SUCCESS:
      window.localStorage.setItem('token', action.payload.data.token);
      window.localStorage.setItem('tenant', action.payload.data.currentDb);
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.data,
        loading: false,
      };

    case CHANGEPASS_GO:
      return {
        ...state,
        loading: false,
        msg: action.payload,
      };

    case GETTING_USERS:
      return {
        ...state,
        users: action.payload,
        loading: false,
      };

    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: action.payload,
        alreadyin: true,
      };

    case UPDATE_GO:
      return {
        ...state,
        loading: false,
        error: null,
        msg: action.payload.msg,
        user: action.payload.user,
      };

    case ADDUSER_GO:
      return {
        ...state,
        users:
          state.users.length === 0
            ? [action.payload]
            : [action.payload, ...state.users],
        otheruser: action.payload,
        loading: false,
      };

    case CALLUSER_GO:
      return {
        ...state,
        otheruser: action.payload,
        loading: false,
        error: null,
      };

    case EDITUSER_GO:
      return {
        ...state,
        users: state.users.map((usr) =>
          usr._id === action.payload._id ? action.payload : usr
        ),
        otheruser: null,
        loading: false,
        error: null,
      };

    case REMOVE_USER_GO:
      return {
        ...state,
        users: state.users.filter((el) => el._id !== action.payload.id),
        msg: action.payload.msg,
        loading: false,
        error: null,
      };

    case UPDATE_FAILED:
    case ADDUSER_FAILED:
    case CALLUSER_FAILED:
    case EDITUSER_FAILED:
    case REMOVE_USER_FAILED:
    case SELF_REMOVE_FAILED:
    case GETTING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case REGISTER_FAILED:
    case CHANGEPASS_FAILED:
    case LOGIN_FAIL:
    case NEWPASS_FAILED:
    case AUTH_ERROR:
      localStorage.removeItem('token');
      localStorage.removeItem('tenant');

      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
        error: action.payload,
      };

    case USER_LOGOUT:
    case SELF_REMOVE_GO:
      localStorage.removeItem('token');
      localStorage.removeItem('tenant');

      return {
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
      };

    default:
      return state;
  }
};

export default Login;
