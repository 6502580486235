import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Row, Col, Button, Input, Card, CardBody, Alert } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Editor } from 'react-draft-wysiwyg';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import Switch from 'react-switch';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

// Actions
import {
  addMenuItem,
  clearCurrentItem,
  editMenuItem,
  getMenuItem,
  postActivity,
} from '@thedavid/plitzredux/actions';

// Parts
import Loader from '../../../components/Loader';

const AddMenuItem = ({ currentItem, toggle }) => {
  const currentClient = localStorage.getItem('tenant');

  const dispatch = useDispatch();
  const history = useNavigate();
  const [editorState, setEditorState] = useState(null);
  const [formData, setFormData] = useState({
    isLive: false,
    title: '',
    subtitle: '',
    description: '',
    position: 0,
    locations: [],
    options: [],
    features: [],
    categories: [],
    featuredImg: {
      fullSize: 'uploads/big-placeholder.jpg',
      othersizes: {
        tablet: 'uploads/big-placeholder.jpg',
        mobile: 'uploads/big-placeholder.jpg',
      },
    },
  });
  const [extraBox, setExtraBox] = useState({
    optionTitle: '',
    optionSubtitle: '',
    price: 0,
    isAvailable: true,
  });
  const [itemFeatures, setItemFeatures] = useState({
    label: '',
    description: '',
  });
  const [selectedFeatured, setSelectedFeatured] = useState([]);
  const [showError, setShowError] = useState(false);
  const [filesErrorFi, setFilesErrorFi] = useState('');
  const [showErrorFi, setShowErrorFi] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [switchHtml, setSwitchHtml] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  // Categories
  const [selectedCategories, setSelectedCategories] = useState(null);
  const [optionCategories, setOptionCategories] = useState({
    options: [],
  });
  const [filteredOptions, setFilteredOptions] = useState([]);
  // Locations
  const [selectedLocations, setSelectedLocations] = useState(null);
  const [optionLocations, setOptionLocations] = useState({
    options: [],
  });
  const [filteredLocationsOptions, setFilteredLocationsOptions] = useState([]);

  const {
    isLive,
    title,
    subtitle,
    description,
    position,
    options,
    features,
    featuredImg,
  } = formData;
  const { optionTitle, optionSubtitle, price, isAvailable } = extraBox;

  useEffect(() => {
    if (currentItem && currentItem !== undefined) {
      dispatch(getMenuItem(currentItem));
    }

    // eslint-disable-next-line
  }, [currentItem, dispatch]);

  const MenuItems = useSelector((state) => state.MenuItems);
  const { menuItem, loading, error } = MenuItems;
  const MenuCategories = useSelector((state) => state.MenuCategories);
  const { menuCategories } = MenuCategories;
  const Locations = useSelector((state) => state.Locations);
  const { locations } = Locations;
  const Login = useSelector((state) => state.Login);
  const { user } = Login;

  useEffect(() => {
    if (menuItem && menuItem !== null) {
      if (menuItem.description) {
        const contentBlock = htmlToDraft(menuItem.description);
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        setEditorState(EditorState.createWithContent(contentState));
      }

      setFormData({
        _id: menuItem._id ? menuItem._id : null,
        isLive: menuItem.isLive ? menuItem.isLive : false,
        title: menuItem.title ? menuItem.title : '',
        subtitle: menuItem.subtitle ? menuItem.subtitle : '',
        description: menuItem.description ? menuItem.description : '',
        position: menuItem.position ? menuItem.position : 0,
        options: menuItem.options.length > 0 ? menuItem.options : [],
        features: menuItem.features.length > 0 ? menuItem.features : [],
        locations:
          menuItem.locations.length > 0
            ? menuItem.locations.map((cat) => ({
                label: cat.locationName,
                value: cat._id,
              }))
            : [],
        categories:
          menuItem.categories.length > 0
            ? menuItem.categories.map((cat) => ({
                label: cat.categoryTitle,
                value: cat._id,
              }))
            : [],
        featuredImg: menuItem.featuredImg
          ? menuItem.featuredImg
          : {
              fullSize: 'uploads/big-placeholder.jpg',
              othersizes: {
                tablet: 'uploads/big-placeholder.jpg',
                mobile: 'uploads/big-placeholder.jpg',
              },
            },
      });

      // Select menu for Categories
      const formatExistingCategories = menuItem.categories.map((cat) => ({
        label: cat.categoryTitle,
        value: cat._id.toString(),
      }));
      setSelectedCategories(formatExistingCategories);

      const formatExistingLocations = menuItem.locations.map((loc) => ({
        label: loc.locationName,
        value: loc._id.toString(),
      }));
      setSelectedLocations(formatExistingLocations);

      let selectedCats = [];
      menuCategories.length > 0 &&
        menuCategories.forEach((element) => {
          const exists = menuItem.categories.filter(
            (itemCat) => itemCat._id !== element._id
          );
          if (exists.length) {
            selectedCats.push({
              label: element.categoryTitle,
              value: element._id.toString(),
            });
          }
        });
      setFilteredOptions(selectedCats);

      // Select Menu for Locations
      let selectedLocations = [];
      locations.length > 0 &&
        locations.forEach((element) => {
          const exists = menuItem.locations.filter(
            (itemCat) => itemCat._id !== element._id
          );
          if (exists.length) {
            selectedLocations.push({
              label: element.locationName,
              value: element._id.toString(),
            });
          }
        });
      setFilteredLocationsOptions(selectedLocations);
    } else {
      // Categories
      let categoryList = [];
      menuCategories.length > 0 &&
        menuCategories.map((cts) =>
          categoryList.push({ label: cts.categoryTitle, value: cts._id })
        );
      setFilteredOptions(categoryList);

      // Locations
      let locationsList = [];
      locations.length > 0 &&
        locations.map((loc) =>
          locationsList.push({ label: loc.locationName, value: loc._id })
        );

      setFilteredLocationsOptions(locationsList);
    }
  }, [menuItem, menuCategories, locations]);

  const switchForLive = () => {
    setFormData({
      ...formData,
      isLive: !isLive,
    });
  };

  const switchHtmlButton = () => {
    if (switchHtml) {
      setSwitchHtml(false);
    } else {
      setSwitchHtml(true);
    }
  };

  const onChange = (e) => {
    e.preventDefault();

    setFormData({ ...formData, [e.target.name]: e.target.value });

    if (submitted) {
      setSubmitted(false);
    }
  };

  const contentChange = (editorState) => {
    setEditorState(editorState);
    setFormData({
      ...formData,
      description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
  };

  const onRawChange = (e) => {
    e.preventDefault();
    setFormData({
      ...formData,
      description: e.target.value,
    });
  };

  // Item options stuff
  const boxChange = (e) => {
    e.preventDefault();
    setExtraBox({ ...extraBox, [e.target.name]: e.target.value });
  };

  const switchAvailable = () => {
    if (isAvailable) {
      setExtraBox({ ...extraBox, isAvailable: false });
    } else {
      setExtraBox({ ...extraBox, isAvailable: true });
    }
  };

  const saveBox = (e) => {
    e.preventDefault();

    if (optionTitle === '') {
      setShowError(true);
      setErrorMsg(`Fields can't be empty`);
    } else if (price === 0) {
      setShowError(true);
      setErrorMsg(`You can't use this sort order number`);
    } else {
      setShowError(false);
      setErrorMsg('');
      setFormData({
        ...formData,
        options: [...formData.options, extraBox],
      });
      setExtraBox({
        optionTitle: '',
        optionSubtitle: '',
        price: 0,
        isAvailable: true,
      });
    }
  };

  const editBox = (e, box) => {
    e.preventDefault();
    setExtraBox(box);
    const filtered = formData.options.filter(
      (el) => el.optionTitle !== box.optionTitle
    );
    setFormData({ ...formData, options: filtered });
  };

  const removeBox = (e, box) => {
    e.preventDefault();
    const filtered = formData.options.filter(
      (el) => el.optionTitle !== box.optionTitle
    );
    setFormData({ ...formData, options: filtered });
  };

  const resetBox = (e) => {
    e.preventDefault();
    setExtraBox({
      optionTitle: '',
      optionSubtitle: '',
      price: 0,
      isAvailable: true,
    });
  };

  // Select Category
  useEffect(() => {
    if (filteredOptions && filteredOptions.length > 0) {
      let options = [];
      filteredOptions.map((thp) =>
        options.push({ label: thp.label, value: thp.value })
      );

      if (options.length === filteredOptions.length) {
        setOptionCategories([{ options: options }]);
      }
    }
  }, [filteredOptions]);

  const onSelect = (e) => {
    setSelectedCategories(e);
    setFormData({
      ...formData,
      categories: e,
    });
  };

  // Select Locations
  useEffect(() => {
    if (filteredLocationsOptions && filteredLocationsOptions.length > 0) {
      let options = [];
      filteredLocationsOptions.map((thp) =>
        options.push({ label: thp.label, value: thp.value })
      );

      if (options.length === filteredLocationsOptions.length) {
        setOptionLocations([{ options: options }]);
      }
    }
  }, [filteredLocationsOptions]);

  const onSelectLocation = (e) => {
    setSelectedLocations(e);
    setFormData({
      ...formData,
      locations: e,
    });
  };

  // For feature image
  const handleAcceptedFilesFi = (file) => {
    if (file.length > 1) {
      setFilesErrorFi('Only one file is allowed');
      setShowErrorFi(true);
    } else {
      file.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      );
      setShowErrorFi(false);
      setSelectedFeatured(file);
    }
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  const handleFeaturesChange = (e) => {
    e.preventDefault();
    setItemFeatures({ ...itemFeatures, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (description === '' && !switchHtml) {
      setEditorState(EditorState.createEmpty());
    } else {
      const contentBlock = htmlToDraft(description);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      setEditorState(EditorState.createWithContent(contentState));
    }

    // eslint-disable-next-line
  }, [switchHtml]);

  useEffect(() => {
    if (selectedFeatured.length > 0) {
      const fd = new FormData();
      fd.append('newimg', selectedFeatured[0]);

      try {
        axios
          .post(`${process.env.REACT_APP_PUBLIC_URL}api/uploads`, fd, {
            headers: {
              'Content-Type': 'multipart/form-data',
              storedid: currentClient,
            },
          })
          .then((res) => {
            setFormData({
              ...formData,
              featuredImg: {
                fullSize: res.data.filePath,
                othersizes: {
                  tablet: res.data.tablet,
                  mobile: res.data.mobile,
                },
              },
            });
          })
          .catch((error) => {
            console.log(error);
            setFilesErrorFi('Error after uploading file');
            setShowErrorFi(true);
          });
      } catch (err) {
        if (err.response.status === 500) {
          setFilesErrorFi('There was a problem with the server');
          setShowErrorFi(true);
        } else {
          setFilesErrorFi(err.response.data.msg);
        }
      }
    }

    // eslint-disable-next-line
  }, [selectedFeatured]);

  const saveFeatures = (e) => {
    e.preventDefault();
    const featureExists = formData.features.forEach((feature) => {
      if (feature.label === itemFeatures.label) {
        return true;
      }
    });

    if (itemFeatures.label === '') {
      setShowError(true);
      setErrorMsg(`Features label or name can't be empty`);
    } else if (featureExists) {
      setShowError(true);
      setErrorMsg(`A feature with the same name already exists`);
    } else {
      setShowError(false);
      setErrorMsg('');
      setFormData({
        ...formData,
        features: [...formData.features, itemFeatures],
      });
      setItemFeatures({
        description: '',
        label: '',
      });
    }
  };

  const editFeaturesNumber = (el) => {
    setItemFeatures(el);
    removeFromFormData(el);
  };

  const removeFromFormData = (el) => {
    const filtered = formData.features.filter(
      (thisFeatures) => thisFeatures.label !== el.label
    );
    setFormData({ ...formData, features: filtered });
  };

  const resetButton = (e) => {
    e.preventDefault();
    setItemFeatures({
      description: '',
      label: '',
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (itemFeatures.label !== '') {
      setShowError(true);
      setErrorMsg(
        `You can't submit with an active feature, save it first and then try again`
      );
      return;
    }

    setSubmitted(true);

    if (currentItem && currentItem !== '') {
      try {
        dispatch(editMenuItem(formData));
        setTimeout(() => {
          afterSubmit();
        }, 2000);
      } catch (error) {
        dispatch(
          postActivity({
            logtype: 'negative',
            logcontent: `An error occurred <em>editing</em> item: <strong>${error}</strong>`,
            email: user ? user.email : '',
          })
        );
        setErrorMsg(error);
      }
    } else {
      try {
        dispatch(addMenuItem(formData));
        setTimeout(() => {
          afterSubmit();
        }, 2000);
      } catch (error) {
        dispatch(
          postActivity({
            logtype: 'negative',
            logcontent: `An error occurred adding Item: <strong>${error}</strong>`,
            email: user ? user.email : '',
          })
        );
      }
    }
  };

  const afterSubmit = () => {
    dispatch(
      postActivity({
        logtype: 'positive',
        logcontent: currentItem
          ? `The Item <strong>${formData.title}</strong> was edited successfully`
          : `The Item <strong>${formData.title}</strong> was created`,
        email: user ? user.email : '',
      })
    );
    setFormData({
      isLive: false,
      title: '',
      subtitle: '',
      description: '',
      position: 0,
      locations: [],
      options: [],
      features: [],
      categories: [],
      featuredImg: {
        fullSize: 'uploads/big-placeholder.jpg',
        othersizes: {
          tablet: 'uploads/big-placeholder.jpg',
          mobile: 'uploads/big-placeholder.jpg',
        },
      },
    });

    setShowError(false);
    setShowErrorFi(false);
    setSelectedFeatured([]);
  };

  useEffect(() => {
    if (menuItem !== null && submitted) {
      if (currentItem) {
        toggle(false);
        dispatch(clearCurrentItem());
      } else {
        history('/all-menu-items');
      }

      setSubmitted(false);
    }
  }, [submitted, menuItem]);

  return loading ? (
    <Loader />
  ) : (
    <Fragment>
      <div className='container-fluid plitz-forms'>
        <Row className='align-items-center'>
          <Col sm={6}>
            <div className='page-title-box'>
              <h1 className=''>
                {currentItem && currentItem !== '' ? 'Edit Item' : 'New Item'}
              </h1>
              <ol className='breadcrumb mb-0'>
                <li className='breadcrumb-item'>
                  <Link to='/dashboard'>Dashboard</Link>
                </li>
                <li className='breadcrumb-item'>
                  <Link to='/all-menu-items'>All Menu Items</Link>
                </li>
                <li className='breadcrumb-item'>Add Menu Item</li>
              </ol>
            </div>
          </Col>
        </Row>

        <Row>
          {showError && (
            <div className='container-fluid'>
              <Row className='align-items-center'>
                <Col sm={12}>
                  <Alert color='warning'>{errorMsg}</Alert>
                </Col>
              </Row>
            </div>
          )}
          {error && (
            <div className='container-fluid'>
              <Row className='align-items-center'>
                <Col sm={12}>
                  <Alert color='danger'>
                    <p dangerouslySetInnerHTML={{ __html: error }}></p>
                  </Alert>
                </Col>
              </Row>
            </div>
          )}
        </Row>

        <form onSubmit={(e) => onSubmit(e)}>
          <Row>
            <Col sm={12} md={9}>
              <Row className='form-group'>
                <Col sm={12} md={6}>
                  <label htmlFor='title' className='col-form-label'>
                    Title
                  </label>
                  <input
                    className='form-control'
                    type='text'
                    placeholder='MenuItem Name'
                    id='title'
                    name='title'
                    value={title}
                    onChange={(e) => onChange(e)}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <label htmlFor='subtitle' className='col-form-label'>
                    Subtitle
                  </label>
                  <input
                    className='form-control'
                    type='text'
                    placeholder='Alternative Item Name'
                    id='subtitle'
                    name='subtitle'
                    value={subtitle}
                    onChange={(e) => onChange(e)}
                  />
                </Col>
              </Row>
              <Row className='form-group'>
                <Col sm='12' className='mt-3 mb-3'>
                  <span className='mt-1 mr-2'>HTML Mode</span>
                  <Switch
                    uncheckedIcon={<Offsymbol />}
                    checkedIcon={<OnSymbol />}
                    onColor='#0b093a'
                    onChange={switchHtmlButton}
                    checked={switchHtml}
                  />{' '}
                </Col>
                <Col sm='12'>
                  {switchHtml ? (
                    <Input
                      placeholder='HTML Mode has been enabled'
                      type='textarea'
                      id='description'
                      name='description'
                      value={description}
                      onChange={(e) => onRawChange(e)}
                      style={{ height: '100%' }}
                    />
                  ) : (
                    <Editor
                      toolbar={{
                        options: [
                          'inline',
                          'list',
                          'emoji',
                          'remove',
                          'history',
                        ],
                        inline: {
                          options: ['bold', 'italic'],
                          bold: { className: 'bordered-option-classname' },
                          italic: { className: 'bordered-option-classname' },
                        },

                        history: {
                          inDropdown: false,
                          options: ['undo', 'redo'],
                        },
                      }}
                      editorState={editorState}
                      toolbarClassName='toolbarClassName'
                      wrapperClassName='wrapperClassName'
                      onEditorStateChange={contentChange}
                    />
                  )}
                </Col>
              </Row>

              {/* Options for ordering */}
              <Row className='form-group mt-5'>
                <Col sm='12'>
                  <div className='options-secion'>
                    <h4>Menu Item Options</h4>
                  </div>
                </Col>
              </Row>
              <Row className='form-group'>
                <Col sm='12'>
                  <Card>
                    <CardBody>
                      {showError ? (
                        <Alert color='warning'>{errorMsg}</Alert>
                      ) : null}
                      <Row data-repeater-item>
                        <Col lg='5' className='form-group'>
                          <small>Option Title:</small>
                          <Input
                            placeholder='e.g. Steak'
                            type='text'
                            id='optionTitle'
                            name='optionTitle'
                            value={optionTitle}
                            onChange={(e) => boxChange(e)}
                          />
                          <br />
                          <small>Subtitle</small>
                          <Input
                            placeholder='e.g. Carne Asada'
                            type='text'
                            id='optionSubtitle'
                            name='optionSubtitle'
                            value={optionSubtitle}
                            onChange={(e) => boxChange(e)}
                          />
                        </Col>

                        <Col lg='4' className='form-group'>
                          <div className='mb-4 pb-2'>
                            <small>Is Item Available</small>
                            <div className='mt-3 w-100'>
                              <Switch
                                uncheckedIcon={<Offsymbol />}
                                checkedIcon={<OnSymbol />}
                                onColor='#0b093a'
                                onChange={switchAvailable}
                                checked={isAvailable}
                              />
                              <span className='ml-3'>
                                {isAvailable ? 'Is Available' : 'Not Available'}
                              </span>
                            </div>
                          </div>
                          <small>Price (e.g. 10.00, number only):</small>
                          <Input
                            placeholder='0.00'
                            type='number'
                            id='price'
                            name='price'
                            value={price}
                            onChange={(e) => boxChange(e)}
                          />
                        </Col>

                        <Col lg='3' className='form-group align-self-center'>
                          <Button
                            onClick={(e) => saveBox(e)}
                            color='success'
                            className='mt-3'
                            style={{
                              width: '100%',
                            }}>
                            {' '}
                            Save Item{' '}
                          </Button>
                          <Button
                            onClick={(e) => resetBox(e)}
                            color='primary'
                            className='mt-3'
                            style={{
                              width: '100%',
                            }}>
                            {' '}
                            Reset{' '}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <hr />
                  <Row>
                    <Col sm='4' lg='2' className='align-self-center'>
                      <h5>Option Name</h5>
                    </Col>
                    <Col sm='4' lg='2' className='align-self-center'>
                      <h6>Price</h6>
                    </Col>
                    <Col sm='4' lg='2' className='align-self-center'>
                      Is Item Available
                    </Col>

                    <Col lg='3' className='form-group align-self-center'></Col>
                    <Col lg='3' className='form-group align-self-center'></Col>
                    <hr className='mt-3' />
                  </Row>

                  {options.length > 0 &&
                    options.map((box, index) => (
                      <Row key={index}>
                        <Col sm='4' lg='2' className='align-self-center'>
                          <h5>{box.optionTitle}</h5>
                          <small>{box.optionSubtitle}</small>
                        </Col>
                        <Col sm='4' lg='2' className='align-self-center'>
                          <h6>${box.price}</h6>
                        </Col>
                        <Col sm='4' lg='2' className='align-self-center'>
                          {box.isAvailable ? 'Yes' : 'No'}
                        </Col>

                        <Col lg='3' className='form-group align-self-center'>
                          <Button
                            onClick={(e) => editBox(e, box)}
                            color='success'
                            style={{
                              width: '100%',
                            }}>
                            {' '}
                            Edit
                          </Button>
                        </Col>
                        <Col lg='3' className='form-group align-self-center'>
                          <Button
                            onClick={(e) => removeBox(e, box)}
                            color='primary'
                            className='mt-2'
                            style={{
                              width: '100%',
                            }}>
                            Remove This Box
                          </Button>
                        </Col>
                        <hr className='mt-3' />
                      </Row>
                    ))}
                </Col>
              </Row>

              {/* Features */}
              <Row className='form-group mt-5'>
                <Col sm='12'>
                  <div className='features-secion'>
                    <h5>Features</h5>
                    <p>You can add more than one</p>
                  </div>
                </Col>
              </Row>
              <Row className='form-group'>
                <Col sm='12'>
                  <Card>
                    <CardBody>
                      {showError ? (
                        <Alert color='warning'>{errorMsg}</Alert>
                      ) : null}
                      <Row data-repeater-item>
                        <Col lg='4' className='form-group align-self-center'>
                          <small>Feature label:</small>
                          <Input
                            placeholder='e.g. GF'
                            type='text'
                            id='label'
                            name='label'
                            value={itemFeatures.label}
                            onChange={(e) => handleFeaturesChange(e)}
                          />
                        </Col>

                        <Col lg='5' className='form-group align-self-center'>
                          <small>Describe the feature:</small>
                          <Input
                            placeholder='Gluten Free'
                            type='text'
                            id='description'
                            name='description'
                            value={itemFeatures.description}
                            onChange={(e) => handleFeaturesChange(e)}
                          />
                        </Col>

                        <Col lg='3' className='form-group align-self-center'>
                          <Button
                            onClick={(e) => saveFeatures(e)}
                            color='success'
                            className='mt-3'
                            style={{
                              width: '100%',
                            }}>
                            {' '}
                            Save Item{' '}
                          </Button>
                          <Button
                            onClick={(e) => resetButton(e)}
                            color='primary'
                            className='mt-3'
                            style={{
                              width: '100%',
                            }}>
                            {' '}
                            Reset{' '}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  {features.length > 0 &&
                    features.map((el, index) => (
                      <Card key={index}>
                        <CardBody>
                          <Row>
                            <Col sm={12} md={6}>
                              <div className='flex-vertical'>
                                <h5>{el.label}</h5>
                                <span>{el.description}</span>
                              </div>
                            </Col>

                            <Col
                              md={3}
                              className='form-group align-self-center'>
                              <Button
                                onClick={() => editFeaturesNumber(el)}
                                color='success'
                                className='mt-3'
                                style={{
                                  width: '100%',
                                }}>
                                {' '}
                                Edit
                              </Button>
                            </Col>
                            <Col
                              md={3}
                              className='form-group align-self-center'>
                              <Button
                                onClick={() => removeFromFormData(el)}
                                color='primary'
                                className='mt-3'
                                style={{
                                  width: '100%',
                                }}>
                                Remove Feature
                              </Button>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    ))}
                </Col>
              </Row>
            </Col>
            <Col sm={12} md={3} className='pl-5'>
              <Card style={{ position: 'relative', zIndex: 1 }}>
                <CardBody>
                  <h4 className='card-title mb-4'>Menu Item Settings</h4>

                  <div className='mb-4 pb-4 setting-bottom-divider'>
                    <p className='card-title-desc'>Status</p>
                    <Switch
                      uncheckedIcon={<Offsymbol />}
                      checkedIcon={<OnSymbol />}
                      onColor='#0b093a'
                      onChange={switchForLive}
                      checked={isLive}
                    />

                    <span className='ml-3'>
                      {isLive ? 'Published' : 'Draft'}
                    </span>
                  </div>

                  <div className='mb-4'>
                    <small>Item Category</small>
                    <div className='mt-2' style={{ zIndex: 999 }}>
                      {menuCategories.length > 0 && (
                        <Select
                          value={selectedCategories}
                          isMulti
                          initialValue={selectedCategories}
                          onChange={(e) => onSelect(e)}
                          options={optionCategories}
                          className='plitz-selection'
                          classNamePrefix='plitzcategory'
                        />
                      )}
                    </div>
                    <button
                      onClick={() => {
                        history('/all-menu-categories');
                      }}
                      className='btn waves-effect text-primary waves-light'>
                      Add Category +
                    </button>
                  </div>

                  <div className='mb-4'>
                    <small>Locations</small>
                    <div className='mt-2' style={{ zIndex: 999 }}>
                      {locations.length > 0 && (
                        <Select
                          value={selectedLocations}
                          isMulti
                          initialValue={selectedLocations}
                          onChange={(e) => onSelectLocation(e)}
                          options={optionLocations}
                          className='plitz-selection'
                          classNamePrefix='plitzlocaiton'
                        />
                      )}
                    </div>
                    <button
                      onClick={() => {
                        history('/all-locations');
                      }}
                      className='btn waves-effect text-primary waves-light'>
                      Add Location +
                    </button>
                  </div>

                  <div className='mb-4 pb-4'>
                    <p>MenuItem Order</p>
                    <small>
                      This will be used for the order on how the menu items will
                      show in the front-end
                    </small>
                    <p></p>
                    <Input
                      placeholder={0}
                      type='number'
                      id='position'
                      name='position'
                      value={position}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <h4 className='card-title mb-4'>Featured Image</h4>

                  {showErrorFi ? (
                    <Alert color='warning'>{filesErrorFi}</Alert>
                  ) : null}
                  <Dropzone
                    onDrop={(acceptedFiles) =>
                      handleAcceptedFilesFi(acceptedFiles)
                    }>
                    {({ getRootProps, getInputProps }) => (
                      <div className='dropzone'>
                        <div
                          className='dz-message needsclick'
                          {...getRootProps()}>
                          <input
                            {...getInputProps()}
                            accept='image/png, image/gif, image/jpeg, image/jpg, image/webp'
                          />
                          <h5>
                            Drop file here or
                            <br />
                            click to upload.
                          </h5>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <div className='dropzone-previews mt-3' id='file-previews'>
                    {selectedFeatured.map((f, i) => {
                      return (
                        <Card
                          className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
                          key={i + '-file'}>
                          <div className='p-2'>
                            <Row className='align-items-center'>
                              <Col className='col-auto'>
                                <img
                                  data-dz-thumbnail=''
                                  height={80}
                                  width={'auto'}
                                  className='bg-light'
                                  alt={f.name}
                                  src={f.preview}
                                />
                              </Col>
                              <Col>
                                <Link
                                  to='#'
                                  className='text-muted font-weight-bold'>
                                  {f.name}
                                </Link>
                                <p className='mb-0'>
                                  <strong>{f.formattedSize}</strong>
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      );
                    })}
                    {selectedFeatured.length === 0 && featuredImg && (
                      <Row className='align-items-center'>
                        <Col className='col-auto'>
                          <img
                            height={80}
                            width={'auto'}
                            className='bg-light'
                            alt={title ? title : 'Placeholder'}
                            src={`${process.env.REACT_APP_PUBLIC_URL}${featuredImg.fullSize}`}
                          />
                        </Col>
                      </Row>
                    )}
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <br />
                  <button
                    type='submit'
                    className='btn btn-primary waves-effect waves-light'>
                    Save
                  </button>
                  <button
                    type='button'
                    onClick={(e) => {
                      e.preventDefault();
                      setEditorState(EditorState.createEmpty());
                      dispatch(clearCurrentItem());
                      setFormData({
                        isLive: false,
                        title: '',
                        subtitle: '',
                        description: '',
                        position: 0,
                        locations: [],
                        options: [],
                        features: [],
                        categories: [],
                        featuredImg: {
                          fullSize: 'uploads/big-placeholder.jpg',
                          othersizes: {
                            tablet: 'uploads/big-placeholder.jpg',
                            mobile: 'uploads/big-placeholder.jpg',
                          },
                        },
                      });
                    }}
                    className='btn btn-danger ml-2 waves-effect waves-light'>
                    Reset
                  </button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
      </div>
    </Fragment>
  );
};

const Offsymbol = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: 12,
        color: '#fff',
        paddingRight: 2,
        paddingTop: 3,
      }}>
      {' '}
      <small>OFF</small>
    </div>
  );
};

const OnSymbol = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: 12,
        color: '#fff',
        paddingRight: 2,
        paddingTop: 3,
      }}>
      {' '}
      <small>ON</small>
    </div>
  );
};

export default AddMenuItem;
