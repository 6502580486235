export const GET_CLIENTS = 'GET_CLIENTS';
export const GETTING_CLIENTS = 'GETTING_CLIENTS';
export const GETTING_FAIL_CLIENT = 'GETTING_FAIL_CLIENT';

export const REQUEST_CLIENT = 'REQUEST_CLIENT';
export const CLIENT_LOADED = 'CLIENT_LOADED';
export const AUTH_ERROR_CLIENT = 'AUTH_ERROR_CLIENT';

export const REGISTER_TRY_CLIENT = 'REGISTER_TRY_CLIENT';
export const REGISTERING_CLIENT = 'REGISTERING_CLIENT';
export const REGISTER_FAILED_CLIENT = 'REGISTER_FAILED_CLIENT';

export const UPDATE_TRY_CLIENT = 'UPDATE_TRY_CLIENT';
export const UPDATE_GO_CLIENT = 'UPDATE_GO_CLIENT';
export const UPDATE_FAILED_CLIENT = 'UPDATE_FAILED_CLIENT';

export const ADDCLIENT_TRY = 'ADDCLIENT_TRY';
export const ADDCLIENT_GO = 'ADDCLIENT_GO';
export const ADDCLIENT_FAILED = 'ADDCLIENT_FAILED';

export const CALLCLIENT_TRY = 'CALLCLIENT_TRY';
export const CALLCLIENT_GO = 'CALLCLIENT_GO';
export const CALLCLIENT_FAILED = 'CALLCLIENT_FAILED';

export const EDITCLIENT_TRY = 'EDITCLIENT_TRY';
export const EDITCLIENT_GO = 'EDITCLIENT_GO';
export const EDITCLIENT_FAILED = 'EDITCLIENT_FAILED';

export const REMOVE_CLIENT_TRY = 'REMOVE_CLIENT_TRY';
export const REMOVE_CLIENT_GO = 'REMOVE_CLIENT_GO';
export const REMOVE_CLIENT_FAILED = 'REMOVE_CLIENT_FAILED';

export const SELF_REMOVE_TRY_CLIENT = 'SELF_REMOVE_TRY_CLIENT';
export const SELF_REMOVE_GO_CLIENT = 'SELF_REMOVE_GO_CLIENT';
export const SELF_REMOVE_FAILED_CLIENT = 'SELF_REMOVE_FAILED_CLIENT';
