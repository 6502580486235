export const GET_SPECIALS_TRY = 'GET_SPECIALS_TRY';
export const GET_SPECIALS_GO = 'GET_SPECIALS_GO';
export const GET_SPECIALS_FAILED = 'GET_SPECIALS_FAILED';

export const GET_ASPECIAL_TRY = 'GET_ASPECIAL_TRY';
export const GET_ASPECIAL_GO = 'GET_ASPECIAL_GO';
export const GET_ASPECIAL_FAILED = 'GET_ASPECIAL_FAILED';

export const ADD_SPECIAL_TRY = 'ADD_SPECIAL_TRY';
export const ADD_SPECIAL_GO = 'ADD_SPECIAL_GO';
export const ADD_SPECIAL_FAILED = 'ADD_SPECIAL_FAILED';

export const EDIT_SPECIAL_TRY = 'EDIT_SPECIAL_TRY';
export const EDIT_SPECIAL_GO = 'EDIT_SPECIAL_GO';
export const EDIT_SPECIAL_FAILED = 'EDIT_SPECIAL_FAILED';

export const REMOVE_SPECIAL_TRY = 'REMOVE_SPECIAL_TRY';
export const REMOVE_SPECIAL_GO = 'REMOVE_SPECIAL_GO';
export const REMOVE_SPECIAL_FAILED = 'REMOVE_SPECIAL_FAILED';

export const CLEAR_CURRENT = 'CLEAR_CURRENT';
