import React, { Fragment, useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  UncontrolledTooltip,
  Alert,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import Paginator from 'react-hooks-paginator';
import PropTypes from 'prop-types';

// Parts
import Loader from '../../components/Loader';

// Actions
import {
  getCategories,
  getCategory,
  postActivity,
  removeCategory,
} from '@thedavid/plitzredux/actions';
import AddCategory from './AddCategory';

const Categories = () => {
  const dispatch = useDispatch();

  const categoryLimit = 10;

  const [offset, setOffset] = useState(0);
  const [currentCategory, setCurrentCategory] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const [editThisOne, setEditThisOne] = useState(false);

  const Categories = useSelector((state) => state.Categories);
  const { categories, loading, error, msg } = Categories;
  const Login = useSelector((state) => state.Login);
  const { user } = Login;

  useEffect(() => {
    setCurrentData(
      categories &&
        categories.length > 0 &&
        categories
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .slice(offset, offset + categoryLimit)
    );
  }, [offset, categories]);

  const deleteCategory = (cat) => {
    dispatch(
      postActivity({
        logtype: 'warning',
        logcontent: `The category <strong>${cat.cattitle}</strong> has been removed`,
        email: user ? user.email : '',
      })
    );
    dispatch(removeCategory(cat._id));
  };

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  const viewButton = (cat) => {
    return (
      <Fragment>
        <UncontrolledTooltip placement='top' target={`visit-${cat.link}`}>
          View Item
        </UncontrolledTooltip>
        <a
          id={`visit-${cat.link}`}
          href={`${process.env.REACT_APP_FRONT_END}${cat.link}`}
          className='btn waves-effect text-primary waves-light'
          target='_blank'
          rel='noopener noreferrer'
          style={{ fontSize: 20, padding: '0.1rem', margin: '0.5rem' }}>
          <i className='fa-light fa-eye'></i>
        </a>
      </Fragment>
    );
  };

  const setCurrent = (link) => {
    dispatch(getCategory(link));
    setEditThisOne(true);
  };

  return (
    <Fragment>
      <div className='container-fluid plitz-cards'>
        <Row className='align-items-center'>
          <Col sm={6}>
            <div className='page-title-box'>
              <h1 className=''>Categories</h1>
              <ol className='breadcrumb mb-0'>
                <li className='breadcrumb-item'>
                  <Link to='/dashboard'>Dashboard</Link>
                </li>
                <li className='breadcrumb-item'>All Categories</li>
              </ol>
            </div>
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            {error ? <Alert color='danger'>{error}</Alert> : null}
            {msg ? <Alert color='warning'>{msg}</Alert> : null}
          </Col>
        </Row>

        <Row>
          <Col md={12} lg={4}>
            <Row>
              {loading ? (
                <Loader />
              ) : currentData && currentData.length > 0 ? (
                currentData.map((cat) => (
                  <Col key={cat._id} md={12}>
                    <Card>
                      <Row>
                        <Col sm={12}>
                          <CardBody>
                            <div className='card-container'>
                              <div className='card-status-area'>
                                {cat.isLive ? (
                                  <span className='badge badge-success'>
                                    Published
                                  </span>
                                ) : (
                                  <span className='badge badge-dark'>
                                    Draft
                                  </span>
                                )}
                              </div>
                              <div className='card-content-area mt-4'>
                                <h3 className='mt-0'>
                                  {cat.cattitle}{' '}
                                  <span
                                    className='badge badge-pill badge-primary float-right'
                                    style={{ fontSize: 12 }}>
                                    {cat.items.length}
                                  </span>
                                </h3>
                              </div>
                              <div className='card-actions-area'>
                                <div className='buttons-only'>
                                  {viewButton(cat)}
                                  <Fragment>
                                    <UncontrolledTooltip
                                      placement='top'
                                      target={`EditTt-${cat.link}`}>
                                      Edit
                                    </UncontrolledTooltip>
                                    <button
                                      id={`EditTt-${cat.link}`}
                                      type='button'
                                      onClick={() => setCurrent(cat.link)}
                                      className='btn waves-effect text-primary waves-light'
                                      data-toggle='modal'
                                      data-target='#myModal'
                                      style={{
                                        fontSize: 20,
                                        padding: '0.1rem',
                                        margin: '0.5rem',
                                      }}>
                                      <i className='fa-light fa-edit'></i>
                                    </button>{' '}
                                  </Fragment>
                                  <Fragment>
                                    <UncontrolledTooltip
                                      placement='top'
                                      target={`RemoveFt-${cat.link}`}>
                                      Remove
                                    </UncontrolledTooltip>
                                    <button
                                      id={`RemoveFt-${cat.link}`}
                                      type='button'
                                      onClick={() => deleteCategory(cat)}
                                      className='btn waves-effect ml-2 text-danger waves-light'
                                      style={{
                                        fontSize: 20,
                                        padding: '0.1rem',
                                        margin: '0.5rem',
                                      }}>
                                      <i className='fa-light fa-trash'></i>
                                    </button>{' '}
                                  </Fragment>
                                </div>
                                <span>
                                  Modified:{' '}
                                  <Moment format='MMMM DD, YYYY'>
                                    {cat.updatedAt}
                                  </Moment>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                ))
              ) : (
                <h4>There is no categories yet</h4>
              )}
            </Row>

            <Row>
              <Col sm={12}>
                {categories && (
                  <Paginator
                    totalRecords={categories.length}
                    pageLimit={categoryLimit}
                    pageNeighbours={2}
                    setOffset={setOffset}
                    currentPage={currentCategory}
                    setCurrentPage={setCurrentCategory}
                    pageContainerClass='pagination'
                    pageActiveClass='current-page'
                    pageNextText={<i className='typcn typcn-chevron-right'></i>}
                    pageNextClass='pagination-arrow'
                    pagePrevText={<i className='typcn typcn-chevron-left'></i>}
                    pagePrevClass='pagination-arrow'
                  />
                )}
              </Col>
            </Row>
          </Col>
          <Col md={12} lg={8}>
            <div className='special-bg'>
              <AddCategory editThisOne={editThisOne} />
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

Categories.propTypes = {
  categories: PropTypes.array,
  user: PropTypes.object,
};

export default Categories;
