export const GET_TESTIMONIALS_TRY = 'GET_TESTIMONIALS_TRY';
export const GET_TESTIMONIALS_GO = 'GET_TESTIMONIALS_GO';
export const GET_TESTIMONIALS_FAILED = 'GET_TESTIMONIALS_FAILED';

export const GET_ATESTIMONIAL_TRY = 'GET_ATESTIMONIAL_TRY';
export const GET_ATESTIMONIAL_GO = 'GET_ATESTIMONIAL_GO';
export const GET_ATESTIMONIAL_FAILED = 'GET_ATESTIMONIAL_FAILED';

export const ADD_TESTIMONIAL_TRY = 'ADD_TESTIMONIAL_TRY';
export const ADD_TESTIMONIAL_GO = 'ADD_TESTIMONIAL_GO';
export const ADD_TESTIMONIAL_FAILED = 'ADD_TESTIMONIAL_FAILED';

export const EDIT_TESTIMONIAL_TRY = 'EDIT_TESTIMONIAL_TRY';
export const EDIT_TESTIMONIAL_GO = 'EDIT_TESTIMONIAL_GO';
export const EDIT_TESTIMONIAL_FAILED = 'EDIT_TESTIMONIAL_FAILED';

export const REMOVE_TESTIMONIAL_TRY = 'REMOVE_TESTIMONIAL_TRY';
export const REMOVE_TESTIMONIAL_GO = 'REMOVE_TESTIMONIAL_GO';
export const REMOVE_TESTIMONIAL_FAILED = 'REMOVE_TESTIMONIAL_FAILED';

export const CLEAR_CURRENT = 'CLEAR_CURRENT';
