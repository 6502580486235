import React, { useEffect, useState, Fragment } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  UncontrolledTooltip,
  Alert,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import Paginator from 'react-hooks-paginator';

// Parts
import BlogpostsEditor from '../../components/Editors/BlogpostsEditor';
import Loader from '../../components/Loader';

// Actions
import {
  clearCurrentBp,
  getBlogposts,
  postActivity,
  removeBlogpost,
} from '@thedavid/plitzredux/actions';

const AllBlogposts = () => {
  const dispatch = useDispatch();

  const blogpostLimit = 10;

  const [offset, setOffset] = useState(0);
  const [currentBlogpost, setCurrentBlogpost] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState('');
  const [showMess, setShowMess] = useState(false);

  const Blogposts = useSelector((state) => state.Blogposts);
  const { blogposts, loading, error, msg } = Blogposts;
  const Login = useSelector((state) => state.Login);
  const { user, users } = Login;

  useEffect(() => {
    setCurrentData(
      blogposts &&
        blogposts
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .slice(offset, offset + blogpostLimit)
    );
  }, [offset, blogposts]);

  const setToggle = (link) => {
    if (isOpen) {
      setIsOpen(false);
      setCurrentItem('');
      dispatch(clearCurrentBp());
    } else {
      setIsOpen(true);
      setCurrentItem(link);
    }
  };

  const deleteBlogpost = (bp) => {
    setShowMess(true);
    dispatch(
      postActivity({
        logtype: 'warning',
        logcontent: `The blogpost <strong>${bp.title}</strong> has been removed`,
        email: user ? user.email : '',
      })
    );
    dispatch(removeBlogpost(bp._id));
    setTimeout(() => {
      setShowMess(false);
    }, 7200);
  };

  useEffect(() => {
    dispatch(getBlogposts());
  }, [dispatch]);

  const viewButton = (bp) => {
    return (
      <Fragment>
        <UncontrolledTooltip placement='top' target={`visit-${bp.id}`}>
          View Item
        </UncontrolledTooltip>
        <a
          id={`visit-${bp.id}`}
          href={`${process.env.REACT_APP_FRONT_END}${bp.link}`}
          className='btn waves-effect text-primary waves-light'
          style={{ fontSize: 20, padding: '0.1rem', margin: '0.5rem' }}
          target='_blank'
          rel='noopener noreferrer'>
          <i className='fa-light fa-eye'></i>
        </a>
      </Fragment>
    );
  };

  return (
    <Fragment>
      {isOpen && (
        <BlogpostsEditor
          isOpen={isOpen}
          toggle={setToggle}
          link={currentItem}
        />
      )}
      <div className='container-fluid plitz-cards'>
        <Row className='align-items-center'>
          <Col sm={6}>
            <div className='page-title-box'>
              <h1 className=''>Blog</h1>
              <ol className='breadcrumb mb-0'>
                <li className='breadcrumb-item'>
                  <Link to='/dashboard'>Dashboard</Link>
                </li>
                <li className='breadcrumb-item'>All Blog Posts</li>
              </ol>
            </div>
          </Col>
        </Row>

        {showMess && (
          <Row>
            <Col sm={12}>
              {error ? <Alert color='danger'>{error}</Alert> : null}
              {msg ? <Alert color='warning'>{msg}</Alert> : null}
            </Col>
          </Row>
        )}

        <Row>
          {loading ? (
            <Loader />
          ) : currentData && currentData.length > 0 ? (
            currentData
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .map((bp) => (
                <Col key={bp._id} md={12}>
                  <Card>
                    <Row>
                      <Col sm={12} md={3}>
                        <div
                          className='card-img-top'
                          style={{ maxHeight: 250 }}>
                          <img
                            className='img-fluid actual-image'
                            src={
                              bp.featuredimg
                                ? `${process.env.REACT_APP_PUBLIC_URL}${bp.featuredimg}`
                                : `${process.env.REACT_APP_PUBLIC_URL}uploads/big-placeholder.jpg`
                            }
                            alt={bp.title}
                          />
                        </div>
                      </Col>
                      <Col sm={12} md={9}>
                        <CardBody>
                          <div className='card-container'>
                            <div className='card-status-area'>
                              {bp.isLive ? (
                                <span className='badge badge-success'>
                                  Published
                                </span>
                              ) : (
                                <span className='badge badge-dark'>Draft</span>
                              )}
                            </div>
                            <div className='card-content-area'>
                              <h3 className='mt-0'>{bp.title}</h3>
                              <div className='card-extra-info'>
                                {bp.seotitle ? (
                                  <h5 className='card-title  mt-0'>
                                    SEO Title Tag: {bp.seotitle}
                                  </h5>
                                ) : null}
                                <div className='author-section'>
                                  <small>Created by</small>
                                  <h6 className='mb-0'>
                                    {users &&
                                      users.length > 0 &&
                                      users.map(
                                        (usr) =>
                                          usr._id === bp.user && (
                                            <div
                                              className='flex-horizontal'
                                              key={usr._id}>
                                              <div className='avatar-sm rounded-circle mr-2'>
                                                <img
                                                  src={`${process.env.REACT_APP_PUBLIC_URL}${usr.photo}`}
                                                  alt={usr.fullname}
                                                  className='actual-image'
                                                />{' '}
                                              </div>
                                              <span>{usr.fullname}</span>
                                            </div>
                                          )
                                      )}
                                  </h6>
                                </div>
                                <div className='categories'>
                                  {bp.category &&
                                    bp.category.map((cat, index) => (
                                      <span
                                        key={index}
                                        className='badge badge-cat badge-pill badge-info'>
                                        {cat.cattitle}
                                      </span>
                                    ))}
                                </div>
                              </div>
                            </div>
                            <div className='card-actions-area'>
                              <div className='buttons-only'>
                                {viewButton(bp)}
                                <UncontrolledTooltip
                                  placement='top'
                                  target={`EditTt-${bp._id}`}>
                                  Edit
                                </UncontrolledTooltip>
                                <button
                                  id={`EditTt-${bp._id}`}
                                  type='button'
                                  onClick={() => setToggle(bp.link)}
                                  className='btn waves-effect text-primary waves-light'
                                  data-toggle='modal'
                                  data-target='#myModal'
                                  style={{
                                    fontSize: 20,
                                    padding: '0.1rem',
                                    margin: '0.5rem',
                                  }}>
                                  <i className='fa-light fa-edit'></i>
                                </button>{' '}
                                <UncontrolledTooltip
                                  placement='top'
                                  target={`RemoveFt-${bp._id}`}>
                                  Remove
                                </UncontrolledTooltip>
                                <button
                                  id={`RemoveFt-${bp._id}`}
                                  type='button'
                                  onClick={() => deleteBlogpost(bp)}
                                  className='btn waves-effect ml-2 text-danger waves-light'
                                  style={{
                                    fontSize: 20,
                                    padding: '0.1rem',
                                    margin: '0.5rem',
                                  }}>
                                  <i className='fa-light fa-trash'></i>
                                </button>{' '}
                              </div>
                              <span>
                                Last Modification:{' '}
                                <Moment format='MMMM DD, YYYY'>
                                  {bp.updatedAt}
                                </Moment>
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))
          ) : (
            <h4>There is no blog posts yet</h4>
          )}
        </Row>

        <Row>
          <Col sm={12}>
            {blogposts && (
              <Paginator
                totalRecords={blogposts.length}
                pageLimit={blogpostLimit}
                pageNeighbours={2}
                setOffset={setOffset}
                currentPage={currentBlogpost}
                setCurrentPage={setCurrentBlogpost}
                pageContainerClass='pagination'
                pageActiveClass='current-page'
                pageNextText={<i className='typcn typcn-chevron-right'></i>}
                pageNextClass='pagination-arrow'
                pagePrevText={<i className='typcn typcn-chevron-left'></i>}
                pagePrevClass='pagination-arrow'
              />
            )}
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default AllBlogposts;
