import {
  ADD_SLIDER_FAILED,
  ADD_SLIDER_GO,
  ADD_SLIDER_TRY,
  CLEAR_CURRENT,
  EDIT_SLIDER_FAILED,
  EDIT_SLIDER_GO,
  EDIT_SLIDER_TRY,
  GET_ASLIDER_FAILED,
  GET_ASLIDER_GO,
  GET_ASLIDER_TRY,
  GET_SLIDERS_FAILED,
  GET_SLIDERS_GO,
  GET_SLIDERS_TRY,
  REMOVE_SLIDER_FAILED,
  REMOVE_SLIDER_GO,
  REMOVE_SLIDER_TRY,
} from './actionTypes';

const initialState = {
  sliders: [],
  slider: null,
  loading: true,
  error: null,
  msg: null,
};

const Sliders = (state = initialState, action) => {
  switch (action.type) {
    case GET_SLIDERS_TRY:
    case GET_ASLIDER_TRY:
    case ADD_SLIDER_TRY:
    case EDIT_SLIDER_TRY:
    case REMOVE_SLIDER_TRY:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case GET_SLIDERS_GO:
      return {
        ...state,
        sliders: action.payload,
        loading: false,
      };

    case ADD_SLIDER_GO:
      return {
        ...state,
        error: null,
        sliders:
          state.sliders.length === 0
            ? [action.payload]
            : [action.payload, ...state.sliders],
        loading: false,
      };

    case EDIT_SLIDER_GO:
      return {
        ...state,
        sliders: state.sliders.map((slider) =>
          slider._id === action.payload._id ? action.payload : slider
        ),
        loading: false,
      };

    case GET_ASLIDER_GO:
      return {
        ...state,
        slider: action.payload,
        loading: false,
      };

    case REMOVE_SLIDER_GO:
      return {
        ...state,
        sliders: state.sliders.filter((fld) => fld._id !== action.payload.id),
        error: null,
        msg: action.payload.msg,
        loading: false,
      };

    case GET_SLIDERS_FAILED:
    case GET_ASLIDER_FAILED:
    case ADD_SLIDER_FAILED:
    case EDIT_SLIDER_FAILED:
    case REMOVE_SLIDER_FAILED:
      return {
        ...state,
        slider: null,
        error: action.payload,
        loading: false,
      };

    case CLEAR_CURRENT:
      return {
        ...state,
        slider: null,
        error: null,
        loading: false,
      };

    default:
      return state;
  }
};

export default Sliders;
