import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const AppRoute = (props) => {
  const {
    component: Component,
    layout: Layout,
    isAuthProtected,
    isAuthenticated,
    verified,
  } = props;

  const location = useLocation();

  const propsWithLocation = { ...location, ...props };

  if (isAuthProtected && !isAuthenticated) {
    return <Navigate to={'/login'} state={{ from: location.pathname }} />;
  }

  if (verified && isAuthProtected && isAuthenticated && !verified) {
    return (
      <Navigate to={'/not-approved'} state={{ from: location.pathname }} />
    );
  }

  return (
    <Layout>
      <Component {...propsWithLocation} />
    </Layout>
  );
};

export default AppRoute;
