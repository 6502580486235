import React, { useCallback, useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Label,
  Input,
  Card,
  CardBody,
  Alert,
} from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import Editable from 'react-bootstrap-editable';
import { Editor } from 'react-draft-wysiwyg';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import Switch from 'react-switch';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

// Actions
import {
  addPage,
  clearCurrent,
  editPage,
  getPage,
  getPages,
  postActivity,
} from '@thedavid/plitzredux/actions';

// Parts
import Loader from '../../components/Loader';

const AddPage = ({ link, toggle }) => {
  const currentClient = localStorage.getItem('tenant');
  const dispatch = useDispatch();
  const history = useNavigate();
  const [editorState, setEditorState] = useState(null);
  const [formData, setFormData] = useState({
    isLive: false,
    title: '',
    subtitle: '',
    label: '',
    link: '',
    content: '',
    position: 0,
    seotitle: '',
    isMenu: false,
    isSubmenu: false,
    isUnder: '',
    featuredimg: 'uploads/big-placeholder.jpg',
    othersizes: {
      tablet: '',
      mobile: '',
    },
    extraboxes: [],
    gallery: [],
  });
  const [extraBox, setExtraBox] = useState({
    etitle: '',
    esubtitle: '',
    eposition: 0,
    econtent: '',
    eimg: 'uploads/big-placeholder.jpg',
  });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFilesGal, setSelectedFilesGal] = useState([]);
  const [selectedFeatured, setSelectedFeatured] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [optionGroup, setOptionGroup] = useState([{ label: '', options: [] }]);
  const [filesError, setFilesError] = useState('');
  const [showError, setShowError] = useState(false);
  const [filesErrorGal, setFilesErrorGal] = useState('');
  const [showErrorGal, setShowErrorGal] = useState(false);
  const [filesErrorFi, setFilesErrorFi] = useState('');
  const [showErrorFi, setShowErrorFi] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [switchHtml, setSwitchHtml] = useState(false);
  const [slugVal, setSlugVal] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);

  const {
    title,
    subtitle,
    isLive,
    isMenu,
    isSubmenu,
    label,
    position,
    content,
    seotitle,
    featuredimg,
    gallery,
  } = formData;
  const { etitle, esubtitle, eposition, econtent, eimg } = extraBox;

  useEffect(() => {
    if (link && link !== undefined) {
      dispatch(getPage(link));
    }

    dispatch(getPages());

    // eslint-disable-next-line
  }, [link, dispatch]);

  const Pages = useSelector((state) => state.Pages);
  const { page, pages, loading, error } = Pages;
  const Login = useSelector((state) => state.Login);
  const { user } = Login;

  useEffect(() => {
    if (page && page !== null) {
      if (page.content) {
        const contentBlock = htmlToDraft(page.content);
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        setEditorState(EditorState.createWithContent(contentState));
      }

      pages &&
        pages.length > 0 &&
        pages.map(
          (el) =>
            el._id.toString() === page.isUnder &&
            setSelectedGroup({
              label: el.title,
              value: el._id,
            })
        );

      const filtered =
        pages &&
        pages.length > 0 &&
        pages.filter((el) => el._id.toString() !== page._id.toString());

      setFilteredOptions(filtered);

      setFormData({
        _id: page._id ? page._id : null,
        isLive: page.isLive ? page.isLive : false,
        title: page.title ? page.title : '',
        subtitle: page.subtitle ? page.subtitle : '',
        label: page.label ? page.label : page.title,
        link: page.link ? page.link : '',
        content: page.content ? page.content : '',
        position: page.position ? page.position : 0,
        seotitle: page.seotitle ? page.seotitle : '',
        isMenu: page.isMenu ? page.isMenu : false,
        isSubmenu: page.isSubmenu ? page.isSubmenu : false,
        isUnder: page.isUnder ? page.isUnder : '',
        featuredimg: page.featuredimg
          ? page.featuredimg
          : 'uploads/big-placeholder.jpg',
        othersizes: {
          tablet: page.othersizes ? page.othersizes.tablet : '',
          mobile: page.othersizes ? page.othersizes.mobile : '',
        },
        extraboxes: page.extraboxes ? page.extraboxes : [],
        gallery: page.gallery ? page.gallery : [],
      });
    } else {
      setFormData({
        isLive: false,
        title: '',
        subtitle: '',
        label: '',
        link: '',
        content: '',
        position: 0,
        seotitle: '',
        isMenu: false,
        isSubmenu: false,
        isUnder: '',
        featuredimg: 'uploads/big-placeholder.jpg',
        othersizes: {
          tablet: '',
          mobile: '',
        },
        extraboxes: [],
        gallery: [],
      });

      pages.length > 0 && setFilteredOptions(pages);
    }
  }, [page, pages]);

  const switchButton = () => {
    if (isLive) {
      setFormData({
        ...formData,
        isLive: false,
      });
    } else {
      setFormData({
        ...formData,
        isLive: true,
      });
    }
  };

  const switchButton2 = () => {
    if (isMenu) {
      setFormData({
        ...formData,
        isMenu: false,
      });
    } else {
      setFormData({
        ...formData,
        label: label !== '' ? label : title,
        isMenu: true,
      });
    }
  };

  const switchButton3 = () => {
    if (isSubmenu) {
      setFormData({
        ...formData,
        isSubmenu: false,
      });
    } else {
      setFormData({
        ...formData,
        isSubmenu: true,
      });
    }
  };

  const switchHtmlButton = () => {
    if (switchHtml) {
      setSwitchHtml(false);
    } else {
      setSwitchHtml(true);
    }
  };

  const onChangeSlug = (value) => {
    setFormData({ ...formData, link: value });
  };

  const onValidate = (value) => {
    if (!value || value === '') {
      return "This field can't be empty";
    }
  };

  const onChange = (e) => {
    e.preventDefault();
    if (e.target.name === 'title') {
      setFormData({
        ...formData,
        title: e.target.value,
        link: e.target.value
          .replace(/[^a-zA-Z0-9]/g, '-')
          .replace(/--/g, '')
          .replace(/-$/, '')
          .toLowerCase(),
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const contentChange = (editorState) => {
    setEditorState(editorState);
    setFormData({
      ...formData,
      content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
  };

  const onRawChange = (e) => {
    e.preventDefault();
    setFormData({
      ...formData,
      content: e.target.value,
    });
  };

  const handleAcceptedFiles = (file) => {
    if (file.length > 1) {
      setFilesError('Only one file is allowed');
      setShowError(true);
    } else {
      file.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      );
      setShowError(false);
      setSelectedFiles(file);
    }
  };

  const handleAcceptedFilesGal = (file) => {
    file.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setShowErrorGal(false);
    setSelectedFilesGal(file);
  };

  const handleAcceptedFilesFi = (file) => {
    if (file.length > 1) {
      setFilesErrorFi('Only one file is allowed');
      setShowErrorFi(true);
    } else {
      file.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      );
      setShowErrorFi(false);
      setSelectedFeatured(file);
    }
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  const boxChange = (e) => {
    e.preventDefault();
    setExtraBox({ ...extraBox, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (content === '' && !switchHtml) {
      setEditorState(EditorState.createEmpty());
    } else {
      const contentBlock = htmlToDraft(content);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      setEditorState(EditorState.createWithContent(contentState));
    }

    // eslint-disable-next-line
  }, [switchHtml]);

  useEffect(() => {
    if (selectedFiles.length > 0) {
      const fd = new FormData();
      fd.append('newimg', selectedFiles[0]);

      try {
        axios
          .post(`${process.env.REACT_APP_PUBLIC_URL}api/uploads`, fd, {
            headers: {
              'Content-Type': 'multipart/form-data',
              storedid: currentClient,
            },
          })
          .then((res) => {
            setExtraBox({
              ...extraBox,
              eimg: res.data.filePath,
            });
          })
          .catch((error) => {
            setFilesError('Error after uploading file');
            setShowError(true);
          });
      } catch (err) {
        if (err.response.status === 500) {
          setFilesError('There was a problem with the server');
          setShowError(true);
        } else {
          setFilesError(err.response.data.msg);
        }
      }
    }

    // eslint-disable-next-line
  }, [selectedFiles]);

  useEffect(() => {
    if (selectedFeatured.length > 0) {
      const fd = new FormData();
      fd.append('newimg', selectedFeatured[0]);

      try {
        axios
          .post(`${process.env.REACT_APP_PUBLIC_URL}api/uploads`, fd, {
            headers: {
              'Content-Type': 'multipart/form-data',
              storedid: currentClient,
            },
          })
          .then((res) => {
            setFormData({
              ...formData,
              featuredimg: res.data.filePath,
              othersizes: {
                tablet: res.data.tablet,
                mobile: res.data.mobile,
              },
            });
          })
          .catch((error) => {
            setFilesErrorFi('Error after uploading file');
            setShowErrorFi(true);
          });
      } catch (err) {
        if (err.response.status === 500) {
          setFilesErrorFi('There was a problem with the server');
          setShowErrorFi(true);
        } else {
          setFilesErrorFi(err.response.data.msg);
        }
      }
    }

    // eslint-disable-next-line
  }, [selectedFeatured]);

  useEffect(() => {
    if (selectedFilesGal.length > 0) {
      const fd = new FormData();

      selectedFilesGal.forEach((el) => {
        fd.append('gallery', el);
      });

      try {
        axios
          .post(`${process.env.REACT_APP_PUBLIC_URL}api/uploads/multiple`, fd, {
            headers: {
              'Content-Type': 'multipart/form-data',
              storedid: currentClient,
            },
          })
          .then((res) => {
            setFormData({
              ...formData,
              gallery: [...formData.gallery, ...res.data],
            });
          })
          .catch((error) => {
            setFilesErrorGal(error.response.data.msg);
            setShowErrorGal(true);
          });
      } catch (err) {
        if (err.response.status === 500) {
          setFilesErrorGal('There was a problem with the server');
          setShowErrorGal(true);
        } else {
          setFilesErrorGal(err.response.data.msg);
        }
      }
    }

    // eslint-disable-next-line
  }, [selectedFilesGal]);

  const saveBox = (e) => {
    e.preventDefault();
    let boxExist;
    boxExist =
      formData.extraboxes.length > 0 &&
      formData.extraboxes.filter(
        (exb) => exb.eposition === extraBox.eposition && exb
      );

    if (etitle === '' || econtent === '') {
      setShowError(true);
      setErrorMsg(`Fields can't be empty`);
    } else if (eposition === 0 || boxExist.length > 0) {
      setShowError(true);
      setErrorMsg(`You can't use this sort order number`);
    } else {
      setShowError(false);
      setErrorMsg('');
      setFormData({
        ...formData,
        extraboxes: [...formData.extraboxes, extraBox],
      });
      setExtraBox({
        etitle: '',
        esubtitle: '',
        eposition: 0,
        econtent: '',
        eimg: 'uploads/big-placeholder.jpg',
      });
      setSelectedFiles([]);
    }
  };

  const editBox = (e, box) => {
    e.preventDefault();
    setExtraBox(box);
    const filtered = formData.extraboxes.filter(
      (el) => el.eposition !== box.eposition && el
    );
    setFormData({ ...formData, extraboxes: filtered });
  };

  const removeBox = (e, eposition) => {
    e.preventDefault();
    const filtered = formData.extraboxes.filter(
      (el) => el.eposition !== eposition && el
    );
    setFormData({ ...formData, extraboxes: filtered });
  };

  const resetBox = (e) => {
    e.preventDefault();
    setExtraBox({
      etitle: '',
      esubtitle: '',
      eposition: 0,
      econtent: '',
      eimg: 'uploads/big-placeholder.jpg',
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (etitle !== '' || econtent !== '' || eposition !== 0) {
      setShowError(true);
      setErrorMsg(`The extra box must be saved first before coninuing`);
    } else if (link && link !== '') {
      try {
        dispatch(editPage(formData));
        dispatch(
          postActivity({
            logtype: 'positive',
            logcontent: `The page item <strong>${formData.title}</strong> was edited successfully`,
            email: user ? user.email : '',
          })
        );
        setFormData({
          isLive: false,
          title: '',
          subtitle: '',
          label: '',
          link: '',
          content: '',
          position: 0,
          seotitle: '',
          isMenu: false,
          featuredimg: 'uploads/big-placeholder.jpg',
          othersizes: {
            tablet: '',
            mobile: '',
          },
          extraboxes: [],
          gallery: [],
        });
        toggle(false);
        dispatch(clearCurrent());
        setSlugVal('');
        setShowError(false);
        setShowErrorFi(false);
        setSelectedFiles([]);
        setSelectedFilesGal([]);
        setSelectedFeatured([]);
      } catch (error) {
        dispatch(
          postActivity({
            logtype: 'negative',
            logcontent: `An error occurred <em>editing</em> page: <strong>${error}</strong>`,
            email: user ? user.email : '',
          })
        );
        setErrorMsg(error);
      }
    } else if (title !== '' && content !== '' && formData.link !== '') {
      try {
        dispatch(addPage(formData));
        afterSubmit();
      } catch (error) {
        dispatch(
          postActivity({
            logtype: 'negative',
            logcontent: `An error occurred adding page: <strong>${error}</strong>`,
            email: user ? user.email : '',
          })
        );
      }
    } else {
      setShowError(true);
      setErrorMsg(`Title and Content are required`);
      setTimeout(() => {
        setShowError(false);
        setErrorMsg('');
      }, 9000);
    }
  };

  const afterSubmit = useCallback(() => {
    if (error === null) {
      dispatch(
        postActivity({
          logtype: 'positive',
          logcontent: `The page <strong>${formData.title}</strong> was created`,
          email: user ? user.email : '',
        })
      );
      setFormData({
        isLive: false,
        title: '',
        subtitle: '',
        label: '',
        link: '',
        content: '',
        position: 0,
        seotitle: '',
        isMenu: false,
        featuredimg: 'uploads/big-placeholder.jpg',
        othersizes: {
          tablet: '',
          mobile: '',
        },
        extraboxes: [],
        gallery: [],
      });
      setSlugVal('');
      setShowError(false);
      setShowErrorFi(false);
      setSelectedFiles([]);
      setSelectedFilesGal([]);
      setSelectedFeatured([]);
      history('/all-pages');
    }

    // eslint-disable-next-line
  }, [error]);

  const uploadImageCallBack = async (file) => {
    const fd = new FormData();
    fd.append('newimg', file);

    const fileUploaded = await axios.post(
      `${process.env.REACT_APP_PUBLIC_URL}api/uploads`,
      fd,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          storedid: currentClient,
        },
      }
    );

    return Promise.resolve({
      data: {
        link: `${process.env.REACT_APP_PUBLIC_URL}${fileUploaded.data.filePath}`,
      },
    });
  };

  const removeGalitem = (e, glritem) => {
    e.preventDefault();
    const filtered = gallery.filter(
      (gl) => gl.ref !== glritem.ref || (gl.fullSize !== glritem.fullSize && gl)
    );

    setFormData({
      ...formData,
      gallery: filtered,
    });
  };

  // Select Page for Submenu
  useEffect(() => {
    if (filteredOptions && filteredOptions.length > 0) {
      let options = [];
      filteredOptions.map((thp) =>
        options.push({ label: thp.title, value: thp._id })
      );

      if (options.length === filteredOptions.length) {
        setOptionGroup([{ label: 'Pages', options: options }]);
      }
    }
  }, [filteredOptions]);

  const onSelect = (e) => {
    setSelectedGroup(e);
    setFormData({ ...formData, isUnder: e.value });
  };

  return loading ? (
    <Loader />
  ) : (
    <React.Fragment>
      <div className='container-fluid plitz-forms'>
        <Row className='align-items-center'>
          <Col sm={6}>
            <div className='page-title-box'>
              <h1 className=''>
                {link && link !== '' ? 'Edit Item' : 'New Item'}
              </h1>
              <ol className='breadcrumb mb-0'>
                <li className='breadcrumb-item'>
                  <Link to='/dashboard'>Dashboard</Link>
                </li>
                <li className='breadcrumb-item'>
                  <Link to='/all-pages'>All Pages</Link>
                </li>
                <li className='breadcrumb-item'>Add Page</li>
              </ol>
            </div>
          </Col>
        </Row>

        <Row>
          {showError ? (
            <div className='container-fluid'>
              <Row className='align-items-center'>
                <Col sm={12}>
                  <Alert color='warning'>{errorMsg}</Alert>
                </Col>
              </Row>
            </div>
          ) : null}
          {error ? (
            <div className='container-fluid'>
              <Row className='align-items-center'>
                <Col sm={12}>
                  <h3 className='title-3'>{error}</h3>
                </Col>
              </Row>
            </div>
          ) : null}
        </Row>

        <Row>
          <Col sm={12} md={9}>
            <Editable
              alwaysEditing={false}
              disabled={false}
              initialValue={
                link && link !== ''
                  ? link
                  : title
                      .replace(/[^a-zA-Z0-9]/g, '-')
                      .replace(/--/g, '')
                      .replace(/-$/, '')
                      .toLowerCase()
              }
              name='link'
              value={slugVal}
              id='link'
              isValueClickable={true}
              label={'Front-end Link (Click to edit)'}
              mode='inline'
              validate={(value) => onValidate(value)}
              onSubmit={(value) => onChangeSlug(value)}
              onValidated={(value) => {
                return value;
              }}
              placement='top'
              showText
              type='textfield'
            />
          </Col>
        </Row>

        <form onSubmit={(e) => onSubmit(e)}>
          <Row>
            <Col sm={12} md={9}>
              <Row className='form-group'>
                <Col sm={12}>
                  <input
                    className='form-control'
                    type='text'
                    placeholder='Title'
                    id='title'
                    name='title'
                    value={title}
                    onChange={(e) => onChange(e)}
                  />
                </Col>
              </Row>
              <Row className='form-group'>
                <Col sm={12}></Col>
              </Row>
              <Row className='form-group'>
                <Col sm={12}>
                  <label htmlFor='fsubtitle' className='col-form-label'>
                    Recommended for h2 tags
                  </label>
                  <input
                    className='form-control'
                    type='text'
                    placeholder='Subtitle'
                    id='subtitle'
                    name='subtitle'
                    value={subtitle}
                    onChange={(e) => onChange(e)}
                  />
                </Col>
              </Row>
              <Row className='form-group'>
                <Col sm='12' className='mt-3 mb-3'>
                  <span className='mt-1 mr-2'>HTML Mode</span>
                  <Switch
                    uncheckedIcon={<Offsymbol />}
                    checkedIcon={<OnSymbol />}
                    onColor='#0b093a'
                    onChange={switchHtmlButton}
                    checked={switchHtml}
                  />{' '}
                </Col>
                <Col sm='12'>
                  {switchHtml ? (
                    <Input
                      placeholder='HTML Mode has been enabled'
                      type='textarea'
                      id='content'
                      name='content'
                      value={content}
                      className='editor-html'
                      onChange={(e) => onRawChange(e)}
                      style={{ height: '100%' }}
                    />
                  ) : (
                    <Editor
                      toolbar={{
                        options: [
                          'inline',
                          'blockType',
                          'list',
                          'link',
                          'embedded',
                          'emoji',
                          'image',
                          'remove',
                          'history',
                        ],
                        inline: {
                          options: [
                            'bold',
                            'italic',
                            'underline',
                            'strikethrough',
                            'monospace',
                          ],
                          bold: { className: 'bordered-option-classname' },
                          italic: { className: 'bordered-option-classname' },
                          underline: { className: 'bordered-option-classname' },
                          strikethrough: {
                            className: 'bordered-option-classname',
                          },
                          code: { className: 'bordered-option-classname' },
                        },
                        image: {
                          urlEnabled: true,
                          uploadEnabled: true,
                          alignmentEnabled: true,
                          uploadCallback: uploadImageCallBack,
                          previewImage: true,
                          inputAccept:
                            'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                          alt: { present: true, mandatory: false },
                          defaultSize: {
                            width: '100%',
                            height: 'auto',
                          },
                        },

                        history: {
                          inDropdown: false,
                          options: ['undo', 'redo'],
                        },
                      }}
                      editorState={editorState}
                      toolbarClassName='toolbarClassName'
                      wrapperClassName='wrapperClassName'
                      editorClassName='editorClassName'
                      onEditorStateChange={contentChange}
                    />
                  )}
                </Col>
              </Row>

              <Row className='form-group mt-5'>
                <Col sm='12'>
                  <div className='extraboxes-secion'>
                    <h4>SEO Title Meta Tag</h4>
                    <small>Default is Post Title</small>
                    <br />
                    <Input
                      placeholder='HTML Title Tag'
                      type='text'
                      id='seotitle'
                      name='seotitle'
                      value={seotitle === '' ? title : seotitle}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </Col>
              </Row>
              {/* Gallery Section */}
              <Row className='form-group mt-5'>
                <Col sm='12'>
                  <div className='extraboxes-secion'>
                    <h4>Image Gallery for this page</h4>
                  </div>
                </Col>
              </Row>
              <Row className='form-group'>
                <Col sm='12'>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col xs='12' sm='12' md='6' className='form-group'>
                          {filesErrorGal && showErrorGal !== '' ? (
                            <Alert color='warning'>{filesErrorGal}</Alert>
                          ) : null}
                          <Dropzone
                            onDrop={(acceptedFilesGal) =>
                              handleAcceptedFilesGal(acceptedFilesGal)
                            }>
                            {({ getRootProps, getInputProps }) => (
                              <div className='dropzone'>
                                <div
                                  className='dz-message needsclick'
                                  {...getRootProps()}>
                                  <input
                                    {...getInputProps()}
                                    accept='image/png, image/gif, image/jpeg, image/jpg, image/webp'
                                  />
                                  <h5>
                                    Drop file here or
                                    <br />
                                    click to upload.
                                  </h5>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        </Col>

                        <Col xs='12' sm='12' md='6' className='form-group'>
                          <div
                            className='dropzone-previews mt-3'
                            id='file-previews'>
                            <small>Uploading Box Results</small>
                            {selectedFilesGal.map((glr, i) => {
                              return (
                                <Card
                                  className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
                                  key={i + '-file'}>
                                  <div className='p-2'>
                                    <Row className='align-items-center'>
                                      <Col className='col-auto'>
                                        <img
                                          data-dz-thumbnail=''
                                          height='80'
                                          className='avatar-sm rounded bg-light'
                                          alt={glr.name}
                                          src={glr.preview}
                                        />
                                      </Col>
                                      <Col>
                                        <Link
                                          to='#'
                                          className='text-muted font-weight-bold'>
                                          {glr.name}
                                        </Link>
                                        <p className='mb-0'>
                                          <strong>{glr.formattedSize}</strong>
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              );
                            })}
                            <div className='setting-bottom-divider'></div>
                            <Row className='align-items-center'>
                              <Col xs='12' sm='6' md='4'>
                                <small>Uploaded Files</small>
                              </Col>
                            </Row>

                            <Row className='align-items-center'>
                              {gallery.length > 0 &&
                                gallery.map(
                                  (glritem, index) =>
                                    (glritem.ref || glritem.fullSize) && (
                                      <Col key={index} xs='6' sm='6' md='4'>
                                        <button
                                          id={`${glritem}`}
                                          onClick={(e) =>
                                            removeGalitem(e, glritem)
                                          }
                                          className='btn waves-effect text-primary waves-light remove-gal'>
                                          <div className='remove-image'>
                                            <i className='ti-trash'></i>
                                          </div>
                                          <div className='remove-overlay'>
                                            Click To Remove
                                          </div>
                                          <img
                                            width='100%'
                                            height='auto'
                                            className='bg-light'
                                            alt={`preview gal`}
                                            src={`${
                                              process.env.REACT_APP_PUBLIC_URL
                                            }${
                                              glritem.fullSize
                                                ? glritem.fullSize
                                                : glritem.ref
                                            }`}
                                          />
                                        </button>
                                      </Col>
                                    )
                                )}
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* Extra Boxes */}

              <Row className='form-group mt-5'>
                <Col sm='12'>
                  <div className='extraboxes-secion'>
                    <h4>Add extra boxes to this page</h4>
                  </div>
                </Col>
              </Row>
              <Row className='form-group'>
                <Col sm='12'>
                  <Card>
                    <CardBody>
                      {showError ? (
                        <Alert color='warning'>{errorMsg}</Alert>
                      ) : null}
                      <Row data-repeater-item>
                        <Col lg='3' className='form-group'>
                          <Input
                            placeholder='Box Title'
                            type='text'
                            id='etitle'
                            name='etitle'
                            value={etitle}
                            onChange={(e) => boxChange(e)}
                          />
                          <br />
                          <Input
                            placeholder='Box Subtitle'
                            type='text'
                            id='esubtitle'
                            name='esubtitle'
                            value={esubtitle}
                            onChange={(e) => boxChange(e)}
                          />
                          <br />
                          <small>Sort Order:</small>
                          <Input
                            placeholder='0'
                            type='number'
                            id='eposition'
                            name='eposition'
                            value={eposition}
                            onChange={(e) => boxChange(e)}
                          />
                        </Col>

                        <Col lg='4' className='form-group'>
                          <Input
                            placeholder='Box Content'
                            type='textarea'
                            id='econtent'
                            name='econtent'
                            value={econtent}
                            onChange={(e) => boxChange(e)}
                            style={{ height: '100%' }}
                          />
                        </Col>

                        <Col lg='3' className='form-group'>
                          {filesError !== '' ? (
                            <Alert color='warning'>{errorMsg}</Alert>
                          ) : null}
                          <Dropzone
                            onDrop={(acceptedFiles) =>
                              handleAcceptedFiles(acceptedFiles)
                            }>
                            {({ getRootProps, getInputProps }) => (
                              <div className='dropzone'>
                                <div
                                  className='dz-message needsclick'
                                  {...getRootProps()}>
                                  <input
                                    {...getInputProps()}
                                    accept='image/png, image/svg, image/gif, image/jpeg, image/jpg, image/webp'
                                  />
                                  <h5>
                                    Drop file here or
                                    <br />
                                    click to upload.
                                  </h5>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          <div
                            className='dropzone-previews mt-3'
                            id='file-previews'>
                            {selectedFiles.map((f, i) => {
                              return (
                                <Card
                                  className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
                                  key={i + '-file'}>
                                  <div className='p-2'>
                                    <Row className='align-items-center'>
                                      <Col className='col-auto'>
                                        <img
                                          data-dz-thumbnail=''
                                          height='80px'
                                          width='auto'
                                          className='bg-light'
                                          alt={f.name}
                                          src={f.preview}
                                        />
                                      </Col>
                                      <Col>
                                        <Link
                                          to='#'
                                          className='text-muted font-weight-bold'>
                                          {f.name}
                                        </Link>
                                        <p className='mb-0'>
                                          <strong>{f.formattedSize}</strong>
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              );
                            })}
                            {selectedFiles.length === 0 && eimg && (
                              <Row className='align-items-center'>
                                <Col className='col-auto'>
                                  <img
                                    height='80px'
                                    width='auto'
                                    className='bg-light'
                                    alt={etitle ? etitle : 'Placeholder'}
                                    src={`${process.env.REACT_APP_PUBLIC_URL}${eimg}`}
                                  />
                                </Col>
                              </Row>
                            )}
                          </div>
                        </Col>

                        <Col lg='2' className='form-group align-self-center'>
                          <Button
                            onClick={(e) => saveBox(e)}
                            color='success'
                            className='mt-3'
                            style={{
                              width: '100%',
                            }}>
                            {' '}
                            Save Item{' '}
                          </Button>
                          <Button
                            onClick={(e) => resetBox(e)}
                            color='primary'
                            className='mt-3'
                            style={{
                              width: '100%',
                            }}>
                            {' '}
                            Reset{' '}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  {formData.extraboxes.length > 0 &&
                    formData.extraboxes.map((box) => (
                      <Card key={box.eposition}>
                        <CardBody>
                          <Row>
                            <Col sm='12' md={6}>
                              <h5>{box.etitle}</h5>
                              <h6>{box.esubtitle}</h6>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: box.econtent,
                                }}></div>
                            </Col>
                            <Col sm='12' md={3}>
                              <img
                                className='img-thumbnail'
                                alt={box.etitle ? box.etitle : 'Extra Box'}
                                width='100%'
                                height='auto'
                                src={`${process.env.REACT_APP_PUBLIC_URL}${box.eimg}`}
                              />
                            </Col>
                            <Col
                              lg='2'
                              className='form-group align-self-center'>
                              <Button
                                onClick={(e) => editBox(e, box)}
                                color='success'
                                className='mt-3'
                                style={{
                                  width: '100%',
                                }}>
                                {' '}
                                Edit
                              </Button>
                              <Button
                                onClick={(e) => removeBox(e, box.eposition)}
                                color='primary'
                                className='mt-3'
                                style={{
                                  width: '100%',
                                }}>
                                Remove This Box
                              </Button>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    ))}
                </Col>
              </Row>
            </Col>
            <Col sm={12} md={3} className='pl-5'>
              <Card style={{ position: 'relative', zIndex: 1 }}>
                <CardBody>
                  <h4 className='card-title mb-4'>Page Settings</h4>

                  <div className='mb-4 pb-4 setting-bottom-divider'>
                    <p className='card-title-desc'>Status</p>
                    <Switch
                      uncheckedIcon={<Offsymbol />}
                      checkedIcon={<OnSymbol />}
                      onColor='#0b093a'
                      onChange={switchButton}
                      checked={isLive}
                    />

                    <span className='ml-3'>
                      {isLive ? 'Published' : 'Draft'}
                    </span>
                  </div>
                  <div className='mb-4 pb-4 setting-bottom-divider'>
                    <p>Page Order</p>
                    <small>
                      If number is not set, pages will be ordganized by date.
                    </small>
                    <Input
                      placeholder={0}
                      type='number'
                      id='position'
                      name='position'
                      value={position}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                  <div className='mb-4 pb-4 setting-bottom-divider'>
                    <p className='card-title-desc'>Menu Visibility</p>
                    <Switch
                      uncheckedIcon={<Offsymbol />}
                      checkedIcon={<OnSymbol />}
                      onColor='#0b093a'
                      onChange={switchButton2}
                      checked={isMenu}
                    />

                    <span className='ml-3'>
                      {isMenu ? 'Showing' : 'Hiding'}
                    </span>
                    {isMenu ? (
                      <div className='mt-2'>
                        <Label htmlFor='plitzlabel'>Name in Menu</Label>
                        <Input
                          placeholder='Menu Label'
                          type='text'
                          id='label'
                          name='label'
                          value={label === '' ? title : label}
                          required={isMenu ? true : false}
                          onChange={(e) => onChange(e)}
                          style={{ height: '100%' }}
                        />
                      </div>
                    ) : null}
                  </div>
                  <div className='mb-4'>
                    <div className='card-title-desc'>
                      <p style={{ marginBottom: 3 }}>Submenu Options</p>
                      <small>Menu Visibility has to be selected first</small>
                    </div>
                    <Switch
                      uncheckedIcon={<Offsymbol />}
                      checkedIcon={<OnSymbol />}
                      onColor='#0b093a'
                      onChange={switchButton3}
                      checked={isSubmenu}
                    />

                    <span className='ml-3'>{isSubmenu ? 'Yes' : 'No'}</span>
                    {isSubmenu && pages.length > 0 ? (
                      <div className='mt-2'>
                        <small>Select Parent Page</small>
                        <div className='mt-2' style={{ zIndex: 999 }}>
                          <Select
                            value={selectedGroup}
                            onChange={(e) => onSelect(e)}
                            options={optionGroup}
                            className='plitz-selection'
                            classNamePrefix='plitz'
                          />
                        </div>
                      </div>
                    ) : (
                      <small> (Create first the parent page)</small>
                    )}
                  </div>
                </CardBody>
              </Card>
              <Card style={{ position: 'relative', zIndex: 0 }}>
                <CardBody>
                  <h4 className='card-title mb-4'>Featured Image</h4>

                  {showErrorFi ? (
                    <Alert color='warning'>{filesErrorFi}</Alert>
                  ) : null}
                  <Dropzone
                    onDrop={(acceptedFiles) =>
                      handleAcceptedFilesFi(acceptedFiles)
                    }>
                    {({ getRootProps, getInputProps }) => (
                      <div className='dropzone'>
                        <div
                          className='dz-message needsclick'
                          {...getRootProps()}>
                          <input
                            {...getInputProps()}
                            accept='image/png, image/gif, image/jpeg, image/jpg, image/webp'
                          />
                          <h5>
                            Drop file here or
                            <br />
                            click to upload.
                          </h5>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <div className='dropzone-previews mt-3' id='file-previews'>
                    {selectedFeatured.map((f, i) => {
                      return (
                        <Card
                          className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
                          key={i + '-file'}>
                          <div className='p-2'>
                            <Row className='align-items-center'>
                              <Col className='col-auto'>
                                <img
                                  data-dz-thumbnail=''
                                  height='80px'
                                  width={'auto'}
                                  className='bg-light'
                                  alt={f.name}
                                  src={f.preview}
                                />
                              </Col>
                              <Col>
                                <Link
                                  to='#'
                                  className='text-muted font-weight-bold'>
                                  {f.name}
                                </Link>
                                <p className='mb-0'>
                                  <strong>{f.formattedSize}</strong>
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      );
                    })}
                    {selectedFeatured.length === 0 && featuredimg && (
                      <Row className='align-items-center'>
                        <Col className='col-auto'>
                          <img
                            height='80px'
                            width={'auto'}
                            className='bg-light'
                            alt={title ? title : 'Placeholder'}
                            src={`${process.env.REACT_APP_PUBLIC_URL}${featuredimg}`}
                          />
                        </Col>
                      </Row>
                    )}
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <button
                    type='submit'
                    className='btn btn-primary waves-effect waves-light'>
                    Save
                  </button>
                  <button
                    type='button'
                    onClick={(e) => {
                      e.preventDefault();
                      setEditorState(EditorState.createEmpty());
                      setFormData({
                        isLive: false,
                        title: '',
                        subtitle: '',
                        label: '',
                        link: '',
                        content: '',
                        position: 0,
                        seotitle: '',
                        isMenu: false,
                        featuredimg: 'uploads/big-placeholder.jpg',
                        othersizes: {
                          tablet: '',
                          mobile: '',
                        },
                        extraboxes: [],
                        gallery: [],
                      });
                    }}
                    className='btn btn-danger ml-2 waves-effect waves-light'>
                    Reset
                  </button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
      </div>
    </React.Fragment>
  );
};

const Offsymbol = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: 12,
        color: '#fff',
        paddingRight: 2,
        paddingTop: 3,
      }}>
      {' '}
      <small>OFF</small>
    </div>
  );
};

const OnSymbol = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: 12,
        color: '#fff',
        paddingRight: 2,
        paddingTop: 3,
      }}>
      {' '}
      <small>ON</small>
    </div>
  );
};

export default AddPage;
