import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Row, Col, Button, Input, Card, CardBody, Alert } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Editor } from 'react-draft-wysiwyg';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import Switch from 'react-switch';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import {
  addSlider,
  clearCurrentSl,
  editSlider,
  getSlider,
  getSliders,
  postActivity,
} from '@thedavid/plitzredux/actions';

// Parts
import Loader from '../../components/Loader';

const AddSlider = ({ id, toggle }) => {
  const currentClient = localStorage.getItem('tenant');

  const dispatch = useDispatch();
  const history = useNavigate();
  const [editorState, setEditorState] = useState(null);
  const [formData, setFormData] = useState({
    isLive: false,
    order: 0,
    title: '',
    subtitle: '',
    details: '',
    buttons: [],
    featuredimg: 'uploads/big-placeholder.jpg',
    othersizes: {
      tablet: '',
      mobile: '',
    },
  });
  const [sliderButton, setSliderButton] = useState({
    position: 0,
    primary: false,
    label: '',
    link: '',
  });
  const [selectedFeatured, setSelectedFeatured] = useState([]);
  const [showError, setShowError] = useState(false);
  const [filesErrorFi, setFilesErrorFi] = useState('');
  const [showErrorFi, setShowErrorFi] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [switchHtml, setSwitchHtml] = useState(false);

  const { isLive, order, title, subtitle, details, featuredimg } = formData;
  const { position, primary, label, link } = sliderButton;

  useEffect(() => {
    if (id && id !== undefined) {
      dispatch(getSlider(id));
    }

    dispatch(getSliders());

    // eslint-disable-next-line
  }, [id, dispatch]);

  const Sliders = useSelector((state) => state.Sliders);
  const { slider, loading, error } = Sliders;
  const Login = useSelector((state) => state.Login);
  const { user } = Login;

  useEffect(() => {
    if (slider && slider !== null) {
      if (slider.details) {
        const contentBlock = htmlToDraft(slider.details);
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        setEditorState(EditorState.createWithContent(contentState));
      }

      setFormData({
        _id: slider._id ? slider._id : null,
        isLive: slider.isLive ? slider.isLive : false,
        order: slider.order ? slider.order : 0,
        title: slider.title ? slider.title : '',
        subtitle: slider.subtitle ? slider.subtitle : '',
        details: slider.details ? slider.details : '',
        buttons: slider.buttons ? slider.buttons : [],
        featuredimg: slider.featuredimg
          ? slider.featuredimg
          : 'uploads/big-placeholder.jpg',
        othersizes: {
          tablet: slider.othersizes ? slider.othersizes.tablet : '',
          mobile: slider.othersizes ? slider.othersizes.mobile : '',
        },
      });
    } else {
      setFormData({
        isLive: false,
        order: 0,
        title: '',
        subtitle: '',
        details: '',
        buttons: [],
        featuredimg: 'uploads/big-placeholder.jpg',
        othersizes: {
          tablet: '',
          mobile: '',
        },
      });
    }
  }, [slider]);

  const switchButton = () => {
    if (isLive) {
      setFormData({
        ...formData,
        isLive: false,
      });
    } else {
      setFormData({
        ...formData,
        isLive: true,
      });
    }
  };

  const switchButton2 = () => {
    if (primary) {
      setSliderButton({ ...sliderButton, primary: false });
    } else {
      setSliderButton({ ...sliderButton, primary: true });
    }
  };

  const switchHtmlButton = () => {
    if (switchHtml) {
      setSwitchHtml(false);
    } else {
      setSwitchHtml(true);
    }
  };

  const onChange = (e) => {
    e.preventDefault();

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const contentChange = (editorState) => {
    setEditorState(editorState);
    setFormData({
      ...formData,
      details: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
  };

  const onRawChange = (e) => {
    e.preventDefault();
    setFormData({
      ...formData,
      details: e.target.value,
    });
  };

  const handleAcceptedFilesFi = (file) => {
    if (file.length > 1) {
      setFilesErrorFi('Only one file is allowed');
      setShowErrorFi(true);
    } else {
      file.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      );
      setShowErrorFi(false);
      setSelectedFeatured(file);
    }
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  const buttonChange = (e) => {
    e.preventDefault();
    setSliderButton({ ...sliderButton, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (details === '' && !switchHtml) {
      setEditorState(EditorState.createEmpty());
    } else {
      const contentBlock = htmlToDraft(details);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      setEditorState(EditorState.createWithContent(contentState));
    }

    // eslint-disable-next-line
  }, [switchHtml]);

  useEffect(() => {
    if (selectedFeatured.length > 0) {
      const fd = new FormData();
      fd.append('newimg', selectedFeatured[0]);

      try {
        axios
          .post(`${process.env.REACT_APP_PUBLIC_URL}api/uploads`, fd, {
            headers: {
              'Content-Type': 'multipart/form-data',
              storedid: currentClient,
            },
          })
          .then((res) => {
            setFormData({
              ...formData,
              featuredimg: res.data.filePath,
              othersizes: {
                tablet: res.data.tablet,
                mobile: res.data.mobile,
              },
            });
          })
          .catch((error) => {
            setFilesErrorFi('Error after uploading file');
            setShowErrorFi(true);
          });
      } catch (err) {
        if (err.response.status === 500) {
          setFilesErrorFi('There was a problem with the server');
          setShowErrorFi(true);
        } else {
          setFilesErrorFi(err.response.data.msg);
        }
      }
    }

    // eslint-disable-next-line
  }, [selectedFeatured]);

  const saveButton = (e) => {
    e.preventDefault();
    let buttonExist;
    buttonExist =
      formData.buttons.length > 0 &&
      formData.buttons.filter(
        (exb) => exb.position === sliderButton.position && exb
      );

    if (label === '' || link === '') {
      setShowError(true);
      setErrorMsg(`Fields can't be empty`);
    } else if (position === 0 || buttonExist.length > 0) {
      setShowError(true);
      setErrorMsg(`You can't use this sort order number`);
    } else {
      setShowError(false);
      setErrorMsg('');
      setFormData({
        ...formData,
        buttons: [...formData.buttons, sliderButton],
      });
      setSliderButton({
        position: 0,
        primary: false,
        label: '',
        link: '',
      });
    }
  };

  const editButton = (e, btn) => {
    e.preventDefault();
    setSliderButton(btn);
    const filtered = formData.buttons.filter(
      (el) => el.position !== btn.position && el
    );
    setFormData({ ...formData, buttons: filtered });
  };

  const removeButton = (e, position) => {
    e.preventDefault();
    const filtered = formData.buttons.filter(
      (el) => el.position !== position && el
    );
    setFormData({ ...formData, buttons: filtered });
  };

  const resetButton = (e) => {
    e.preventDefault();
    setSliderButton({
      primary: false,
      position: 0,
      label: '',
      link: '',
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (label !== '' || link !== '' || position !== 0) {
      setShowError(true);
      setErrorMsg(`The button must be saved first before coninuing`);
    } else if (id && id !== '') {
      try {
        dispatch(editSlider(formData));
        dispatch(
          postActivity({
            logtype: 'positive',
            logcontent: `The slider <strong>${formData.title}</strong> was edited successfully`,
            email: user ? user.email : '',
          })
        );
        setFormData({
          isLive: false,
          order: 0,
          title: '',
          subtitle: '',
          details: '',
          buttons: [],
          featuredimg: 'uploads/big-placeholder.jpg',
          othersizes: {
            tablet: '',
            mobile: '',
          },
        });
        toggle(false);
        dispatch(clearCurrentSl());
        setShowError(false);
        setShowErrorFi(false);
        setSelectedFeatured([]);
      } catch (error) {
        dispatch(
          postActivity({
            logtype: 'negative',
            logcontent: `An error occurred <em>editing</em> slider: <strong>${error}</strong>`,
            email: user ? user.email : '',
          })
        );
        setErrorMsg(error);
      }
    } else if (title !== '') {
      try {
        dispatch(addSlider(formData));
        afterSubmit();
      } catch (error) {
        dispatch(
          postActivity({
            logtype: 'negative',
            logcontent: `An error occurred adding slider: <strong>${error}</strong>`,
            email: user ? user.email : '',
          })
        );
      }
    } else {
      setShowError(true);
      setErrorMsg(`Title and Content are required`);
      setTimeout(() => {
        setShowError(false);
        setErrorMsg('');
      }, 9000);
    }
  };

  const afterSubmit = useCallback(() => {
    if (error === null) {
      dispatch(
        postActivity({
          logtype: 'positive',
          logcontent: `The slider <strong>${formData.title}</strong> was created`,
          email: user ? user.email : '',
        })
      );
      setFormData({
        isLive: false,
        order: 0,
        title: '',
        subtitle: '',
        details: '',
        buttons: [],
        featuredimg: 'uploads/big-placeholder.jpg',
        othersizes: {
          tablet: '',
          mobile: '',
        },
      });
      setShowError(false);
      setShowErrorFi(false);
      setSelectedFeatured([]);
      history('/all-cta');
    }

    // eslint-disable-next-line
  }, [error]);

  return loading ? (
    <Loader />
  ) : (
    <Fragment>
      <div className='container-fluid plitz-forms'>
        <Row className='align-items-center'>
          <Col sm={6}>
            <div className='page-title-box'>
              <h1 className=''>{id && id !== '' ? 'Edit Item' : 'New Item'}</h1>
              <ol className='breadcrumb mb-0'>
                <li className='breadcrumb-item'>
                  <Link to='/dashboard'>Dashboard</Link>
                </li>
                <li className='breadcrumb-item'>
                  <Link to='/all-cta'>All CTAs</Link>
                </li>
                <li className='breadcrumb-item'>Add CTA</li>
              </ol>
            </div>
          </Col>
        </Row>

        <Row>
          {showError ? (
            <div className='container-fluid'>
              <Row className='align-items-center'>
                <Col sm={12}>
                  <Alert color='warning'>{errorMsg}</Alert>
                </Col>
              </Row>
            </div>
          ) : null}
          {error ? (
            <div className='container-fluid'>
              <Row className='align-items-center'>
                <Col sm={12}>
                  <h3 className='title-3'>{error}</h3>
                </Col>
              </Row>
            </div>
          ) : null}
        </Row>

        <form onSubmit={(e) => onSubmit(e)}>
          <Row>
            <Col sm={12} md={9}>
              <Row className='mb-2'>
                <Col sm={12} md={5} lg={4}>
                  <Card style={{ position: 'relative', zIndex: 0 }}>
                    <CardBody>
                      <div className='mb-4'>
                        <h4 className='card-title mb-1'>Featured Image</h4>
                        <small>
                          For better layout results the image should be
                          horizontal
                        </small>
                      </div>
                      {showErrorFi ? (
                        <Alert color='warning'>{filesErrorFi}</Alert>
                      ) : null}
                      <Dropzone
                        onDrop={(acceptedFiles) =>
                          handleAcceptedFilesFi(acceptedFiles)
                        }>
                        {({ getRootProps, getInputProps }) => (
                          <div className='dropzone'>
                            <div
                              className='dz-message needsclick'
                              {...getRootProps()}>
                              <input
                                {...getInputProps()}
                                accept='image/png, image/gif, image/jpeg, image/jpg, image/webp'
                              />
                              <h5>
                                Drop file here or
                                <br />
                                click to upload.
                              </h5>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm={12} md={7} lg={8}>
                  <div className='dropzone-previews mt-3' id='file-previews'>
                    {selectedFeatured.map((f, i) => {
                      return (
                        <Card
                          className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
                          key={i + '-file'}>
                          <div className='p-2'>
                            <Row className='align-items-center'>
                              <Col className='col-auto'>
                                <div
                                  className='avatar-xl rounded'
                                  style={{ width: '100%', height: 'auto' }}>
                                  <img
                                    data-dz-thumbnail=''
                                    className='bg-light actual-image'
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                </div>
                              </Col>
                              <Col>
                                <Link
                                  to='#'
                                  className='text-muted font-weight-bold'>
                                  {f.name}
                                </Link>
                                <p className='mb-0'>
                                  <strong>{f.formattedSize}</strong>
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      );
                    })}
                    {selectedFeatured.length === 0 && featuredimg && (
                      <Row className='align-items-center'>
                        <Col className='col-auto'>
                          <div
                            className='avatar-xl rounded'
                            style={{ width: '100%', height: 'auto' }}>
                            <img
                              className='bg-light actual-image'
                              alt={title ? title : 'Placeholder'}
                              src={`${process.env.REACT_APP_PUBLIC_URL}${featuredimg}`}
                            />
                          </div>
                        </Col>
                      </Row>
                    )}
                  </div>
                </Col>
              </Row>
              <Row className='form-group'>
                <Col sm={12}>
                  <label htmlFor='title' className='col-form-label'>
                    Title
                  </label>
                  <input
                    className='form-control'
                    type='text'
                    placeholder='Main Text'
                    id='title'
                    name='title'
                    value={title}
                    onChange={(e) => onChange(e)}
                  />
                </Col>
              </Row>
              <Row className='form-group'>
                <Col sm={12}></Col>
              </Row>
              <Row className='form-group'>
                <Col sm={12}>
                  <label htmlFor='subtitle' className='col-form-label'>
                    Subtitle
                  </label>
                  <input
                    className='form-control'
                    type='text'
                    placeholder='Secondary Title'
                    id='subtitle'
                    name='subtitle'
                    value={subtitle}
                    onChange={(e) => onChange(e)}
                  />
                </Col>
              </Row>
              <Row className='form-group'>
                <Col sm='12' className='mt-3 mb-3'>
                  <span className='mt-1 mr-2'>HTML Mode</span>
                  <Switch
                    uncheckedIcon={<Offsymbol />}
                    checkedIcon={<OnSymbol />}
                    onColor='#0b093a'
                    onChange={switchHtmlButton}
                    checked={switchHtml}
                  />{' '}
                </Col>
                <Col sm='12'>
                  {switchHtml ? (
                    <Input
                      placeholder='HTML Mode has been enabled'
                      type='textarea'
                      id='details'
                      name='details'
                      value={details}
                      onChange={(e) => onRawChange(e)}
                      style={{ height: '100%' }}
                    />
                  ) : (
                    <Editor
                      toolbar={{
                        options: [
                          'inline',
                          'list',
                          'emoji',
                          'remove',
                          'history',
                        ],
                        inline: {
                          options: ['bold', 'italic'],
                          bold: { className: 'bordered-option-classname' },
                          italic: { className: 'bordered-option-classname' },
                        },

                        history: {
                          inDropdown: false,
                          options: ['undo', 'redo'],
                        },
                      }}
                      editorState={editorState}
                      toolbarClassName='toolbarClassName'
                      wrapperClassName='wrapperClassName'
                      onEditorStateChange={contentChange}
                    />
                  )}
                </Col>
              </Row>

              {/* Extra Buttones */}
              <Row className='form-group mt-5'>
                <Col sm='12'>
                  <div className='buttons-secion'>
                    <h4>Buttons</h4>
                    <p>
                      Add linkst to other pages or external URLs. We don't
                      recommend more than two.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className='form-group'>
                <Col sm='12'>
                  <Card>
                    <CardBody>
                      {showError ? (
                        <Alert color='warning'>{errorMsg}</Alert>
                      ) : null}
                      <Row data-repeater-item>
                        <Col lg='5' className='form-group'>
                          <small>Add button:</small>
                          <Input
                            placeholder='Label'
                            type='text'
                            id='label'
                            name='label'
                            value={label}
                            onChange={(e) => buttonChange(e)}
                          />
                          <br />

                          <Input
                            placeholder='Link or URL'
                            type='text'
                            id='link'
                            name='link'
                            value={link}
                            onChange={(e) => buttonChange(e)}
                          />
                        </Col>

                        <Col lg='4' className='form-group align-self-center'>
                          <small>Sort Order:</small>
                          <Input
                            placeholder='0'
                            type='number'
                            id='position'
                            name='position'
                            value={position}
                            onChange={(e) => buttonChange(e)}
                          />
                          <br />
                          <div className='mb-4 pb-4'>
                            <Switch
                              uncheckedIcon={<Offsymbol />}
                              checkedIcon={<OnSymbol />}
                              onColor='#0b093a'
                              onChange={switchButton2}
                              checked={primary}
                            />

                            <span className='ml-3'>
                              {primary ? 'Solid Style' : 'Outlined Styled'}
                            </span>
                          </div>
                        </Col>

                        <Col lg='3' className='form-group align-self-center'>
                          <Button
                            onClick={(e) => saveButton(e)}
                            color='success'
                            className='mt-3'
                            style={{
                              width: '100%',
                            }}>
                            {' '}
                            Save Item{' '}
                          </Button>
                          <Button
                            onClick={(e) => resetButton(e)}
                            color='primary'
                            className='mt-3'
                            style={{
                              width: '100%',
                            }}>
                            {' '}
                            Reset{' '}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  {formData.buttons.length > 0 &&
                    formData.buttons.map((btn) => (
                      <Card key={btn.position}>
                        <CardBody>
                          <Row>
                            <Col sm={12} md={6}>
                              <h4>Button {btn.position}</h4>
                              <div className='flex-horizontal'>
                                <h5>{btn.label}</h5>
                                <span>
                                  | Style:{' '}
                                  {btn.primary
                                    ? 'Primary or Solid'
                                    : 'Secondary or Outlined'}
                                </span>
                              </div>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: btn.link,
                                }}></div>
                            </Col>

                            <Col
                              md={3}
                              className='form-group align-self-center'>
                              <Button
                                onClick={(e) => editButton(e, btn)}
                                color='success'
                                className='mt-3'
                                style={{
                                  width: '100%',
                                }}>
                                {' '}
                                Edit
                              </Button>
                            </Col>
                            <Col
                              md={3}
                              className='form-group align-self-center'>
                              <Button
                                onClick={(e) => removeButton(e, btn.position)}
                                color='primary'
                                className='mt-3'
                                style={{
                                  width: '100%',
                                }}>
                                Remove Button
                              </Button>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    ))}
                </Col>
              </Row>
            </Col>
            <Col sm={12} md={3} className='pl-5'>
              <Card style={{ position: 'relative', zIndex: 1 }}>
                <CardBody>
                  <h4 className='card-title mb-4'>Component Settings</h4>

                  <div className='mb-4 pb-4 setting-bottom-divider'>
                    <p className='card-title-desc'>Status</p>
                    <Switch
                      uncheckedIcon={<Offsymbol />}
                      checkedIcon={<OnSymbol />}
                      onColor='#0b093a'
                      onChange={switchButton}
                      checked={isLive}
                    />

                    <span className='ml-3'>
                      {isLive ? 'Published' : 'Draft'}
                    </span>
                  </div>
                  <div className='mb-4 pb-4'>
                    <p>Component Order</p>
                    <small>
                      This will help sorting against other components
                    </small>
                    <p></p>
                    <Input
                      placeholder={0}
                      type='number'
                      id='order'
                      name='order'
                      value={order}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <button
                    type='submit'
                    className='btn btn-primary waves-effect waves-light'>
                    Save
                  </button>
                  <button
                    type='button'
                    onClick={(e) => {
                      e.preventDefault();
                      setEditorState(EditorState.createEmpty());
                      setFormData({
                        isLive: false,
                        order: 0,
                        title: '',
                        subtitle: '',
                        details: '',
                        buttons: [],
                        featuredimg: 'uploads/big-placeholder.jpg',
                        othersizes: {
                          tablet: '',
                          mobile: '',
                        },
                      });
                    }}
                    className='btn btn-danger ml-2 waves-effect waves-light'>
                    Reset
                  </button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
      </div>
    </Fragment>
  );
};

const Offsymbol = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: 12,
        color: '#fff',
        paddingRight: 2,
        paddingTop: 3,
      }}>
      {' '}
      <small>OFF</small>
    </div>
  );
};

const OnSymbol = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: 12,
        color: '#fff',
        paddingRight: 2,
        paddingTop: 3,
      }}>
      {' '}
      <small>ON</small>
    </div>
  );
};

export default AddSlider;
