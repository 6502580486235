import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  changeSidebarType,
  toggleRightSidebar,
  changeTopbarTheme,
  changeLayoutWidth,
} from '@thedavid/plitzredux/actions';

import RightSidebar from '../../components/RightSidebar';
import TopBar from './TopBar';
// Other Layout related Component
import Sidebar from './Sidebar';
import Footer from './Footer';

const Layout = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const Layout = useSelector((state) => state.Layout);

  const { layoutWidth, leftSideBarType, topbarTheme, showRightSidebar } =
    Layout;

  /**
   * Open/close right sidebar
   */
  const toggleRightSidebarClick = () => {
    dispatch(toggleRightSidebar());
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  useEffect(() => {
    // Scroll Top to 0
    window.scrollTo(0, 0);
    let currentage = capitalizeFirstLetter(location.pathname);

    document.title = currentage + ' | theDavid - CMS V2';

    if (layoutWidth) {
      changeLayoutWidth(layoutWidth);
    }

    if (leftSideBarType) {
      changeSidebarType(leftSideBarType);
    }
    if (topbarTheme) {
      changeTopbarTheme(topbarTheme);
    }
  }, [
    layoutWidth,
    leftSideBarType,
    topbarTheme,
    showRightSidebar,
    location.pathname,
  ]);

  const toggleMenuCallback = () => {
    if (leftSideBarType === 'default') {
      dispatch(changeSidebarType('condensed', isMobile));
    } else if (leftSideBarType === 'condensed') {
      dispatch(changeSidebarType('default', isMobile));
    }
  };

  return (
    <React.Fragment>
      <div id='layout-wrapper'>
        <TopBar
          toggleMenuCallback={toggleMenuCallback}
          toggleRightSidebar={toggleRightSidebarClick}
        />

        <div className='vertical-menu'>
          <div data-simplebar className='h-100 overflow-auto'>
            <Sidebar type={leftSideBarType} isMobile={isMobile} />
          </div>
        </div>
        <div className='main-content'>
          <div className='page-content'>{children}</div>
        </div>
        <Footer />

        <RightSidebar />
      </div>
    </React.Fragment>
  );
};

export default Layout;
