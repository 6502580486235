import {
  ADD_LOCATION_FAILED,
  ADD_LOCATION_GO,
  ADD_LOCATION_TRY,
  CLEAR_CURRENT,
  EDIT_LOCATION_FAILED,
  EDIT_LOCATION_GO,
  EDIT_LOCATION_TRY,
  GET_ALOCATION_FAILED,
  GET_ALOCATION_GO,
  GET_ALOCATION_TRY,
  GET_LOCATIONS_FAILED,
  GET_LOCATIONS_GO,
  GET_LOCATIONS_TRY,
  REMOVE_LOCATION_FAILED,
  REMOVE_LOCATION_GO,
  REMOVE_LOCATION_TRY,
} from './actionTypes';

const initialState = {
  locations: [],
  location: null,
  loading: false,
  error: null,
  msg: null,
};

const Locations = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOCATIONS_TRY:
    case GET_ALOCATION_TRY:
    case ADD_LOCATION_TRY:
    case EDIT_LOCATION_TRY:
    case REMOVE_LOCATION_TRY:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case GET_LOCATIONS_GO:
      return {
        ...state,
        locations: action.payload,
        loading: false,
      };

    case ADD_LOCATION_GO:
      return {
        ...state,
        locations: [action.payload, ...state.locations],
        location: action.payload,
        loading: false,
      };

    case EDIT_LOCATION_GO:
      return {
        ...state,
        locations: state.locations.map((location) =>
          location._id === action.payload._id ? action.payload : location
        ),
        loading: false,
      };

    case GET_ALOCATION_GO:
      return {
        ...state,
        location: action.payload,
        loading: false,
      };

    case REMOVE_LOCATION_GO:
      return {
        ...state,
        locations: state.locations.filter(
          (fld) => fld._id !== action.payload.id
        ),
        msg: action.payload.msg,
        loading: false,
      };

    case GET_LOCATIONS_FAILED:
    case GET_ALOCATION_FAILED:
    case ADD_LOCATION_FAILED:
    case EDIT_LOCATION_FAILED:
    case REMOVE_LOCATION_FAILED:
      return {
        ...state,
        location: null,
        error: action.payload,
        loading: false,
      };

    case CLEAR_CURRENT:
      return {
        ...state,
        location: null,
        loading: false,
      };

    default:
      return state;
  }
};

export default Locations;
