export const GET_LOCATIONS_TRY = 'GET_LOCATIONS_TRY';
export const GET_LOCATIONS_GO = 'GET_LOCATIONS_GO';
export const GET_LOCATIONS_FAILED = 'GET_LOCATIONS_FAILED';

export const GET_ALOCATION_TRY = 'GET_ALOCATION_TRY';
export const GET_ALOCATION_GO = 'GET_ALOCATION_GO';
export const GET_ALOCATION_FAILED = 'GET_ALOCATION_FAILED';

export const ADD_LOCATION_TRY = 'ADD_LOCATION_TRY';
export const ADD_LOCATION_GO = 'ADD_LOCATION_GO';
export const ADD_LOCATION_FAILED = 'ADD_LOCATION_FAILED';

export const EDIT_LOCATION_TRY = 'EDIT_LOCATION_TRY';
export const EDIT_LOCATION_GO = 'EDIT_LOCATION_GO';
export const EDIT_LOCATION_FAILED = 'EDIT_LOCATION_FAILED';

export const REMOVE_LOCATION_TRY = 'REMOVE_LOCATION_TRY';
export const REMOVE_LOCATION_GO = 'REMOVE_LOCATION_GO';
export const REMOVE_LOCATION_FAILED = 'REMOVE_LOCATION_FAILED';

export const CLEAR_CURRENT = 'CLEAR_CURRENT';
