import React, { Fragment, useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  UncontrolledTooltip,
  Alert,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import Paginator from 'react-hooks-paginator';
import PropTypes from 'prop-types';

// Parts
import PagesEditor from '../../components/Editors/PagesEditor';
import Loader from '../../components/Loader';

// Actions
import {
  clearCurrent,
  getPages,
  postActivity,
  removePage,
} from '@thedavid/plitzredux/actions';

const AllPages = () => {
  const dispatch = useDispatch();

  const pageLimit = 10;

  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState('');

  const Pages = useSelector((state) => state.Pages);
  const { pages, loading, error, msg } = Pages;
  const Login = useSelector((state) => state.Login);
  const { user, users } = Login;

  useEffect(() => {
    setCurrentData(
      pages &&
        pages
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .slice(offset, offset + pageLimit)
    );
  }, [offset, pages]);

  const setToggle = (link) => {
    if (isOpen) {
      setIsOpen(false);
      setCurrentItem('');
      dispatch(clearCurrent());
    } else {
      setIsOpen(true);
      setCurrentItem(link);
    }
  };

  const deletePage = (pg) => {
    dispatch(
      postActivity({
        logtype: 'warning',
        logcontent: `The page <strong>${pg.title}</strong> has been removed`,
        email: user ? user.email : '',
      })
    );
    dispatch(removePage(pg._id));
  };

  useEffect(() => {
    dispatch(getPages());
  }, [dispatch]);

  const viewButton = (pg) => {
    return (
      <Fragment>
        <UncontrolledTooltip placement='top' target={`visit-${pg.link}`}>
          View Item
        </UncontrolledTooltip>
        <a
          id={`visit-${pg.link}`}
          href={`${process.env.REACT_APP_FRONT_END}${pg.link}`}
          className='btn waves-effect text-primary waves-light'
          target='_blank'
          rel='noopener noreferrer'
          style={{ fontSize: 20, padding: '0.1rem', margin: '0.5rem' }}>
          <i className='fa-light fa-eye'></i>
        </a>
      </Fragment>
    );
  };

  return (
    <Fragment>
      {isOpen && (
        <PagesEditor isOpen={isOpen} toggle={setToggle} link={currentItem} />
      )}
      <div className='container-fluid plitz-cards'>
        <Row className='align-items-center'>
          <Col sm={6}>
            <div className='page-title-box'>
              <h1 className=''>Pages</h1>
              <ol className='breadcrumb mb-0'>
                <li className='breadcrumb-item'>
                  <Link to='/dashboard'>Dashboard</Link>
                </li>
                <li className='breadcrumb-item'>All Pages</li>
              </ol>
            </div>
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            {error ? <Alert color='danger'>{error}</Alert> : null}
            {msg ? <Alert color='warning'>{msg}</Alert> : null}
          </Col>
        </Row>

        <Row>
          {loading ? (
            <Loader />
          ) : currentData && currentData.length > 0 ? (
            currentData
              .sort((a, b) => b.position - a.position)
              .map((pg) => (
                <Col key={pg._id} md={12}>
                  <Card>
                    <Row>
                      <Col sm={12} md={3}>
                        <div
                          className='card-img-top'
                          style={{ maxHeight: 250 }}>
                          <img
                            className='img-fluid actual-image'
                            src={
                              pg.featuredimg
                                ? `${process.env.REACT_APP_PUBLIC_URL}${pg.featuredimg}`
                                : `${process.env.REACT_APP_PUBLIC_URL}uploads/big-placeholder.jpg`
                            }
                            alt={pg.title}
                          />
                        </div>
                      </Col>
                      <Col sm={12} md={9}>
                        <CardBody>
                          <div className='card-container'>
                            <div className='card-status-area'>
                              {pg.isLive ? (
                                <span className='badge badge-success'>
                                  Published
                                </span>
                              ) : (
                                <span className='badge badge-dark'>Draft</span>
                              )}
                            </div>
                            <div className='card-content-area'>
                              <h3 className='mt-0'>{pg.title}</h3>
                              {pg.subtitle ? (
                                <h5 className='card-title  mt-0'>
                                  Subtitle: {pg.subtitle}
                                </h5>
                              ) : null}
                              <div className='author-section'>
                                <small>Created by</small>
                                <h6 className='mb-0'>
                                  {' '}
                                  {users &&
                                    users.length > 0 &&
                                    users.map(
                                      (usr) =>
                                        usr._id === pg.user && (
                                          <div
                                            className='flex-horizontal'
                                            key={usr._id}>
                                            <div className='avatar-sm rounded-circle mr-2'>
                                              <img
                                                src={`${process.env.REACT_APP_PUBLIC_URL}${usr.photo}`}
                                                alt={usr.fullname}
                                                className='actual-image'
                                              />{' '}
                                            </div>
                                            <span>{usr.fullname}</span>
                                          </div>
                                        )
                                    )}
                                </h6>
                              </div>
                            </div>
                            <div className='card-actions-area'>
                              <div className='buttons-only'>
                                {viewButton(pg)}
                                <Fragment>
                                  <UncontrolledTooltip
                                    placement='top'
                                    target={`EditTt-${pg.link}`}>
                                    Edit
                                  </UncontrolledTooltip>
                                  <button
                                    id={`EditTt-${pg.link}`}
                                    type='button'
                                    onClick={() => setToggle(pg.link)}
                                    className='btn waves-effect text-primary waves-light'
                                    data-toggle='modal'
                                    data-target='#myModal'
                                    style={{
                                      fontSize: 20,
                                      padding: '0.1rem',
                                      margin: '0.5rem',
                                    }}>
                                    <i className='fa-light fa-edit'></i>
                                  </button>{' '}
                                </Fragment>
                                <Fragment>
                                  <UncontrolledTooltip
                                    placement='top'
                                    target={`RemoveFt-${pg.link}`}>
                                    Remove
                                  </UncontrolledTooltip>
                                  <button
                                    id={`RemoveFt-${pg.link}`}
                                    type='button'
                                    onClick={() => deletePage(pg)}
                                    className='btn waves-effect ml-2 text-danger waves-light'
                                    style={{
                                      fontSize: 20,
                                      padding: '0.1rem',
                                      margin: '0.5rem',
                                    }}>
                                    <i className='fa-light fa-trash'></i>
                                  </button>{' '}
                                </Fragment>
                              </div>
                              <span>
                                Last Modification:{' '}
                                <Moment format='MMMM DD, YYYY'>
                                  {pg.updatedAt}
                                </Moment>
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))
          ) : (
            <h4>There is no pages yet</h4>
          )}
        </Row>

        <Row>
          <Col sm={12}>
            {pages && (
              <Paginator
                totalRecords={pages.length}
                pageLimit={pageLimit}
                pageNeighbours={2}
                setOffset={setOffset}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageContainerClass='pagination'
                pageActiveClass='current-page'
                pageNextText={<i className='typcn typcn-chevron-right'></i>}
                pageNextClass='pagination-arrow'
                pagePrevText={<i className='typcn typcn-chevron-left'></i>}
                pagePrevClass='pagination-arrow'
              />
            )}
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

AllPages.propTypes = {
  pages: PropTypes.array,
  user: PropTypes.object,
};

export default AllPages;
