import React from 'react';
import MetisMenu from '@metismenu/react';
import { Link } from 'react-router-dom';

const AdminMenu = (props) => {
  const { disableClick, clients, promos } = props;

  return (
    <MetisMenu>
      <li className='menu-title'>Administration</li>

      <li className='with-dd'>
        <Link
          to='/#!'
          onClick={(e) => disableClick(e)}
          className='has-arrow waves-effect'>
          <i className='fa-light fa-address-card'></i>
          <span>Clients</span>
        </Link>

        <ul className='sub-menu'>
          <li>
            <Link to='/add-client'>Add Client</Link>
          </li>
          <li>
            <Link to='/all-clients'>
              <span className='badge badge-pill badge-primary float-right'>
                {clients && clients.length}
              </span>
              All Clients
            </Link>
          </li>
        </ul>
      </li>
      <li className='with-dd'>
        <Link
          to='/#!'
          onClick={(e) => disableClick(e)}
          className='has-arrow waves-effect'>
          <i className='fa-light fa-bullhorn'></i>
          <span>Announcements</span>
        </Link>

        <ul className='sub-menu'>
          <li>
            <Link to='/add-announcement'>Add New</Link>
          </li>
          <li>
            <Link to='/all-announcements'>
              <span className='badge badge-pill badge-primary float-right'>
                {promos && promos.length}
              </span>
              All
            </Link>
          </li>
        </ul>
      </li>
    </MetisMenu>
  );
};

export default AdminMenu;
