import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Label, Input, Card, CardBody, Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import Editable from 'react-bootstrap-editable';
import { Editor } from 'react-draft-wysiwyg';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import Switch from 'react-switch';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

// Actions
import {
  addCategory,
  editCategory,
  getCategories,
  getBlogposts,
  postActivity,
  clearCurrentCat,
} from '@thedavid/plitzredux/actions';

// Parts
import Loader from '../../components/Loader';

const AddCategory = ({ editThisOne }) => {
  const currentClient = localStorage.getItem('tenant');

  const dispatch = useDispatch();
  const [editorState, setEditorState] = useState(null);
  const [formData, setFormData] = useState({
    isLive: false,
    cattitle: '',
    link: '',
    catdescription: '',
    position: 0,
    items: [],
    seotitle: '',
    isMenu: false,
    isSubmenu: false,
    isUnder: '',
    featuredimg: 'uploads/big-placeholder.jpg',
    othersizes: {
      tablet: '',
      mobile: '',
    },
  });
  const [selectedFeatured, setSelectedFeatured] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [optionGroup, setOptionGroup] = useState([{ label: '', options: [] }]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [optionPost, setOptionPost] = useState([{ label: '', options: [] }]);
  const [showError, setShowError] = useState(false);
  const [filesErrorFi, setFilesErrorFi] = useState('');
  const [showErrorFi, setShowErrorFi] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [switchHtml, setSwitchHtml] = useState(false);
  const [slugVal, setSlugVal] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);

  const {
    isLive,
    cattitle,
    link,
    catdescription,
    position,
    seotitle,
    isMenu,
    label,
    isSubmenu,
    featuredimg,
  } = formData;

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getBlogposts());
  }, [dispatch]);

  const Categories = useSelector((state) => state.Categories);
  const { category, categories, loading, error } = Categories;
  const Blogposts = useSelector((state) => state.Blogposts);
  const { blogposts } = Blogposts;
  const Login = useSelector((state) => state.Login);
  const { user } = Login;

  useEffect(() => {
    if (category && category !== null) {
      if (category.catdescription) {
        const contentBlock = htmlToDraft(category.catdescription);
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        setEditorState(EditorState.createWithContent(contentState));
      }
      setFormData({
        _id: category._id ? category._id : null,
        isLive: category.isLive ? category.isLive : false,
        cattitle: category.cattitle ? category.cattitle : '',
        link: category.link ? category.link : '',
        catdescription: category.catdescription ? category.catdescription : '',
        position: category.position ? category.position : 0,
        items: category.items
          ? category.items.map((post) => ({
              label: post.title,
              value: post._id,
            }))
          : [],
        seotitle: category.seotitle ? category.seotitle : '',
        isMenu: category.isMenu ? category.isMenu : false,
        label: category.label ? category.label : '',
        isSubmenu: category.isSubmenu ? category.isSubmenu : false,
        isUnder: category.isUnder ? category.isUnder : '',
        featuredimg: category.featuredimg
          ? category.featuredimg
          : 'uploads/big-placeholder.jpg',
        othersizes: {
          tablet: category.othersizes ? category.othersizes.tablet : '',
          mobile: category.othersizes ? category.othersizes.mobile : '',
        },
      });

      // This is to create sub categories.
      categories &&
        categories.length > 0 &&
        categories.map(
          (el) =>
            el._id.toString() === category.isUnder &&
            setSelectedGroup({
              label: el.cattitle,
              value: el._id,
            })
        );

      const selectedPosts = category.items.map((post) => ({
        label: post.title,
        value: post._id.toString(),
      }));

      setSelectedPost(selectedPosts);

      const filtered =
        categories &&
        categories.length > 0 &&
        categories.filter(
          (el) => el._id.toString() !== category._id.toString()
        );

      setFilteredOptions(filtered);
    } else {
      setFormData({
        isLive: false,
        cattitle: '',
        link: '',
        catdescription: '',
        position: 0,
        items: [],
        seotitle: '',
        isMenu: false,
        isSubmenu: false,
        isUnder: '',
        featuredimg: 'uploads/big-placeholder.jpg',
        othersizes: {
          tablet: '',
          mobile: '',
        },
      });

      categories.length > 0 && setFilteredOptions(categories);
      blogposts.length > 0 && setFilteredPosts(blogposts);
    }
  }, [category, categories, blogposts]);

  const switchButton = () => {
    if (isLive) {
      setFormData({
        ...formData,
        isLive: false,
      });
    } else {
      setFormData({
        ...formData,
        isLive: true,
      });
    }
  };

  const switchButton2 = () => {
    if (isMenu) {
      setFormData({
        ...formData,
        isMenu: false,
      });
    } else {
      setFormData({
        ...formData,
        label: label !== '' ? label : cattitle,
        isMenu: true,
      });
    }
  };

  const switchButton3 = () => {
    if (isSubmenu) {
      setFormData({
        ...formData,
        isSubmenu: false,
        isUnder: '',
      });
      setOptionGroup(null);
    } else {
      setFormData({
        ...formData,
        isSubmenu: true,
      });
    }
  };

  const switchHtmlButton = () => {
    if (switchHtml) {
      setSwitchHtml(false);
    } else {
      setSwitchHtml(true);
    }
  };

  const onChangeSlug = (value) => {
    setFormData({ ...formData, link: value });
  };

  const onValidate = (value) => {
    if (!value || value === '') {
      return "This field can't be empty";
    }
  };

  const onChange = (e) => {
    e.preventDefault();
    if (e.target.name === 'cattitle') {
      setFormData({
        ...formData,
        cattitle: e.target.value,
        link: e.target.value
          .replace(/[^a-zA-Z0-9]/g, '-')
          .replace(/--/g, '')
          .replace(/-$/, '')
          .toLowerCase(),
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const contentChange = (editorState) => {
    setEditorState(editorState);
    setFormData({
      ...formData,
      catdescription: draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      ),
    });
  };

  const onRawChange = (e) => {
    e.preventDefault();
    setFormData({
      ...formData,
      catdescription: e.target.value,
    });
  };

  const handleAcceptedFilesFi = (file) => {
    if (file.length > 1) {
      setFilesErrorFi('Only one file is allowed');
      setShowErrorFi(true);
    } else {
      file.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      );
      setShowErrorFi(false);
      setSelectedFeatured(file);
    }
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  useEffect(() => {
    if (catdescription === '' && !switchHtml) {
      setEditorState(EditorState.createEmpty());
    } else {
      const contentBlock = htmlToDraft(catdescription);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      setEditorState(EditorState.createWithContent(contentState));
    }

    // eslint-disable-next-line
  }, [switchHtml]);

  useEffect(() => {
    if (selectedFeatured.length > 0) {
      const fd = new FormData();
      fd.append('newimg', selectedFeatured[0]);

      try {
        axios
          .post(`${process.env.REACT_APP_PUBLIC_URL}api/uploads`, fd, {
            headers: {
              'Content-Type': 'multipart/form-data',
              storedid: currentClient,
            },
          })
          .then((res) => {
            setFormData({
              ...formData,
              featuredimg: res.data.filePath,
              othersizes: {
                tablet: res.data.tablet,
                mobile: res.data.mobile,
              },
            });
          })
          .catch((error) => {
            setFilesErrorFi('Error after uploading file');
            setShowErrorFi(true);
          });
      } catch (err) {
        if (err.response.status === 500) {
          setFilesErrorFi('There was a problem with the server');
          setShowErrorFi(true);
        } else {
          setFilesErrorFi(err.response.data.msg);
        }
      }
    }
  }, [selectedFeatured]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (editThisOne) {
      try {
        dispatch(editCategory(formData));
        dispatch(
          postActivity({
            logtype: 'positive',
            logcontent: `The category item <strong>${formData.cattitle}</strong> was edited successfully`,
            email: user ? user.email : '',
          })
        );
        setFormData({
          isLive: false,
          cattitle: '',
          link: '',
          catdescription: '',
          position: 0,
          items: [],
          seotitle: '',
          isMenu: false,
          isSubmenu: false,
          isUnder: '',
          featuredimg: 'uploads/big-placeholder.jpg',
          othersizes: {
            tablet: '',
            mobile: '',
          },
        });
        dispatch(clearCurrentCat());
        setSlugVal('');
        setShowError(false);
        setShowErrorFi(false);
        setSelectedFeatured([]);
        setEditorState(null);
        setOptionPost([{ label: '', options: [] }]);
        setSelectedPost(null);
        setOptionGroup(null);
      } catch (error) {
        dispatch(
          postActivity({
            logtype: 'negative',
            logcontent: `An error occurred <em>editing</em> category: <strong>${error}</strong>`,
            email: user ? user.email : '',
          })
        );
        setErrorMsg(error);
      }
    } else if (cattitle !== '' && formData.link !== '') {
      try {
        dispatch(addCategory(formData));
        afterSubmit();
        setFormData({
          isLive: false,
          cattitle: '',
          link: '',
          catdescription: '',
          position: 0,
          items: [],
          seotitle: '',
          isMenu: false,
          isSubmenu: false,
          isUnder: '',
          featuredimg: 'uploads/big-placeholder.jpg',
          othersizes: {
            tablet: '',
            mobile: '',
          },
        });
        dispatch(clearCurrentCat());
        setSlugVal('');
        setShowError(false);
        setShowErrorFi(false);
        setSelectedFeatured([]);
        setEditorState(null);
        setOptionPost([{ label: '', options: [] }]);
        setSelectedPost(null);
        setOptionGroup(null);
      } catch (error) {
        dispatch(
          postActivity({
            logtype: 'negative',
            logcontent: `An error occurred adding category: <strong>${error}</strong>`,
            email: user ? user.email : '',
          })
        );
      }
    } else {
      setShowError(true);
      setErrorMsg(`Title and Content are required`);
      setTimeout(() => {
        setShowError(false);
        setErrorMsg('');
      }, 9000);
    }
  };

  const afterSubmit = useCallback(() => {
    if (error === null) {
      dispatch(
        postActivity({
          logtype: 'positive',
          logcontent: `The category <strong>${formData.cattitle}</strong> was created`,
          email: user ? user.email : '',
        })
      );
      setFormData({
        isLive: false,
        cattitle: '',
        link: '',
        catdescription: '',
        position: 0,
        items: [],
        seotitle: '',
        isMenu: false,
        isSubmenu: false,
        isUnder: '',
        featuredimg: 'uploads/big-placeholder.jpg',
        othersizes: {
          tablet: '',
          mobile: '',
        },
      });
      setSlugVal('');
      setShowError(false);
      setShowErrorFi(false);
      setSelectedFeatured([]);
    }
  }, [error]);

  const uploadImageCallBack = async (file) => {
    const fd = new FormData();
    fd.append('newimg', file);

    const fileUploaded = await axios.post(
      `${process.env.REACT_APP_PUBLIC_URL}api/uploads`,
      fd,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          storedid: currentClient,
        },
      }
    );

    return Promise.resolve({
      data: {
        link: `${process.env.REACT_APP_PUBLIC_URL}${fileUploaded.data.filePath}`,
      },
    });
  };

  // Select Category for Submenu
  useEffect(() => {
    if (filteredOptions && filteredOptions.length > 0) {
      let options = [];
      filteredOptions.map((thp) =>
        options.push({ label: thp.cattitle, value: thp._id })
      );

      if (options.length === filteredOptions.length) {
        setOptionGroup([{ label: 'Current Categories', options: options }]);
      }
    }
  }, [filteredOptions]);

  // Select Post for Items
  useEffect(() => {
    if (filteredPosts && filteredPosts.length > 0) {
      let options = [];
      filteredPosts.map((thp) =>
        options.push({ label: thp.title, value: thp._id })
      );

      if (options.length === filteredPosts.length) {
        setOptionPost([{ label: 'Blog Posts', options: options }]);
      }
    }
  }, [filteredPosts]);

  const onSelect = (e) => {
    setSelectedGroup(e);
    setFormData({ ...formData, isUnder: e.value });
  };

  const onSelectPost = (e) => {
    setSelectedPost(e);
    setFormData({ ...formData, items: e });
  };

  return loading ? (
    <Loader />
  ) : (
    <React.Fragment>
      <div className='container-fluid plitz-forms'>
        <Row className='align-items-center'>
          <Col sm={6}>
            <div className='category-title-box'>
              <h3 className=''>
                {link && link !== '' ? 'Edit Item' : 'New Item'}
              </h3>
            </div>
          </Col>
        </Row>

        <Row>
          {showError ? (
            <div className='container-fluid'>
              <Row className='align-items-center'>
                <Col sm={12}>
                  <Alert color='warning'>{errorMsg}</Alert>
                </Col>
              </Row>
            </div>
          ) : null}
          {error ? (
            <div className='container-fluid'>
              <Row className='align-items-center'>
                <Col sm={12}>
                  <h3 className='title-3'>{error}</h3>
                </Col>
              </Row>
            </div>
          ) : null}
        </Row>

        <Row>
          <Col md={12} lg={6}>
            <Editable
              alwaysEditing={false}
              disabled={false}
              initialValue={
                link && link !== ''
                  ? link
                  : cattitle
                      .replace(/[^a-zA-Z0-9]/g, '-')
                      .replace(/--/g, '')
                      .replace(/-$/, '')
                      .toLowerCase()
              }
              name='link'
              value={slugVal}
              id='link'
              isValueClickable={true}
              label={'Front-end Link (Click to edit)'}
              mode='inline'
              validate={(value) => onValidate(value)}
              onSubmit={(value) => onChangeSlug(value)}
              onValidated={(value) => {
                return value;
              }}
              placement='top'
              showText
              type='textfield'
            />
          </Col>
        </Row>

        <form onSubmit={(e) => onSubmit(e)}>
          <Row>
            <Col md={12}>
              <Row className='form-group'>
                <Col sm={12}>
                  <input
                    className='form-control'
                    type='text'
                    placeholder='Category Name'
                    id='cattitle'
                    name='cattitle'
                    value={cattitle}
                    onChange={(e) => onChange(e)}
                  />
                </Col>
              </Row>

              <Row className='form-group'>
                <Col sm='12' className='mt-3 mb-3'>
                  <span className='mt-1 mr-2'>HTML Mode</span>
                  <Switch
                    uncheckedIcon={<Offsymbol />}
                    checkedIcon={<OnSymbol />}
                    onColor='#0b093a'
                    onChange={switchHtmlButton}
                    checked={switchHtml}
                  />{' '}
                </Col>
                <Col sm='12'>
                  {switchHtml ? (
                    <Input
                      placeholder='HTML Mode has been enabled'
                      type='textarea'
                      id='catdescription'
                      name='catdescription'
                      value={catdescription}
                      className='cat-editor-html'
                      onChange={(e) => onRawChange(e)}
                      style={{ height: '100%' }}
                    />
                  ) : (
                    <Editor
                      toolbar={{
                        options: [
                          'inline',
                          'blockType',
                          'list',
                          'textAlign',
                          'link',
                          'embedded',
                          'emoji',
                          'image',
                          'remove',
                          'history',
                        ],
                        inline: {
                          options: ['bold', 'italic', 'underline'],
                          bold: { className: 'bordered-option-classname' },
                          italic: { className: 'bordered-option-classname' },
                          underline: { className: 'bordered-option-classname' },
                        },
                        image: {
                          urlEnabled: true,
                          uploadEnabled: true,
                          alignmentEnabled: true,
                          uploadCallback: uploadImageCallBack,
                          previewImage: true,
                          inputAccept:
                            'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                          alt: { present: true, mandatory: false },
                          defaultSize: {
                            width: '100%',
                            height: 'auto',
                          },
                        },

                        history: {
                          inDropdown: false,
                          options: ['undo', 'redo'],
                        },
                      }}
                      editorState={editorState}
                      toolbarClassName='toolbarClassName'
                      wrapperClassName='wrapperClassName'
                      onEditorStateChange={contentChange}
                    />
                  )}
                </Col>
              </Row>

              <Row className='form-group mt-5 mb-5'>
                <Col sm='12'>
                  <div className='extraboxes-secion'>
                    <h4>SEO Title Meta Tag</h4>
                    <small>Default is Post Title</small>
                    <br />
                    <Input
                      placeholder='HTML Title Tag'
                      type='text'
                      id='seotitle'
                      name='seotitle'
                      value={seotitle === '' ? cattitle : seotitle}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={12} lg={6} className='pl-0 pl-lg-2 pt-5 pt-lg-0'>
              <Card style={{ position: 'relative', zIndex: 1 }}>
                <CardBody>
                  <h4 className='card-title mb-4'>Category Settings</h4>

                  <div className='mb-4 pb-4 setting-bottom-divider'>
                    <p className='card-title-desc'>Status</p>
                    <Switch
                      uncheckedIcon={<Offsymbol />}
                      checkedIcon={<OnSymbol />}
                      onColor='#0b093a'
                      onChange={switchButton}
                      checked={isLive}
                    />

                    <span className='ml-3'>
                      {isLive ? 'Published' : 'Draft'}
                    </span>
                  </div>
                  <div className='mb-4 pb-4 setting-bottom-divider'>
                    <p>Category Order</p>
                    <small>
                      If number is not set, categories will be ordganized by
                      date.
                    </small>
                    <Input
                      placeholder={0}
                      type='number'
                      id='position'
                      name='position'
                      value={position}
                      onChange={(e) => onChange(e)}
                    />

                    <div className='mt-4 mb-4'>
                      <p>Add Posts</p>
                      <small>Add posts to this category</small>
                      <div className='mt-2' style={{ zIndex: 999 }}>
                        <Select
                          value={selectedPost}
                          isMulti
                          initialValue={selectedPost}
                          onChange={(e) => onSelectPost(e)}
                          options={optionPost}
                          className='plitz-selection'
                          classNamePrefix='plitzpost'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='mb-4 pb-4 setting-bottom-divider'>
                    <p className='card-title-desc'>Menu Visibility</p>
                    <Switch
                      uncheckedIcon={<Offsymbol />}
                      checkedIcon={<OnSymbol />}
                      onColor='#0b093a'
                      onChange={switchButton2}
                      checked={isMenu}
                    />

                    <span className='ml-3'>
                      {isMenu ? 'Showing' : 'Hiding'}
                    </span>
                    {isMenu ? (
                      <div className='mt-2'>
                        <Label htmlFor='plitzlabel'>Name in Menu</Label>
                        <Input
                          placeholder='Menu Label'
                          type='text'
                          id='label'
                          name='label'
                          value={label === '' ? cattitle : label}
                          required={isMenu ? true : false}
                          onChange={(e) => onChange(e)}
                          style={{ height: '100%' }}
                        />
                      </div>
                    ) : null}
                  </div>
                  <div className='mb-4'>
                    <div className='card-title-desc'>
                      <p style={{ marginBottom: 3 }}>Submenu Options</p>
                      <small>Menu Visibility has to be selected first</small>
                    </div>
                    <Switch
                      uncheckedIcon={<Offsymbol />}
                      checkedIcon={<OnSymbol />}
                      onColor='#0b093a'
                      onChange={switchButton3}
                      checked={isSubmenu}
                    />

                    <span className='ml-3'>{isSubmenu ? 'Yes' : 'No'}</span>
                    {isSubmenu && categories.length > 0 ? (
                      <div className='mt-2'>
                        <small>Select Parent Category</small>
                        <div className='mt-2' style={{ zIndex: 999 }}>
                          <Select
                            value={selectedGroup}
                            onChange={(e) => onSelect(e)}
                            options={optionGroup}
                            className='plitz-selection'
                            classNamePrefix='plitz'
                          />
                        </div>
                      </div>
                    ) : (
                      <div className='mt-2'>
                        <small>Create first the parent category</small>
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={12} lg={6} className='pl-0 pl-lg-2 pt-5 pt-lg-0'>
              <Card style={{ position: 'relative', zIndex: 0 }}>
                <CardBody>
                  <h4 className='card-title mb-4'>Featured Image</h4>

                  {showErrorFi ? (
                    <Alert color='warning'>{filesErrorFi}</Alert>
                  ) : null}
                  <Dropzone
                    onDrop={(acceptedFiles) =>
                      handleAcceptedFilesFi(acceptedFiles)
                    }>
                    {({ getRootProps, getInputProps }) => (
                      <div className='dropzone'>
                        <div
                          className='dz-message needsclick'
                          {...getRootProps()}>
                          <input
                            {...getInputProps()}
                            accept='image/png, image/gif, image/jpeg, image/jpg, image/webp'
                          />
                          <h5>
                            Drop file here or
                            <br />
                            click to upload.
                          </h5>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <div className='dropzone-previews mt-3' id='file-previews'>
                    {selectedFeatured.map((f, i) => {
                      return (
                        <Card
                          className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
                          key={i + '-file'}>
                          <div className='p-2'>
                            <Row className='align-items-center'>
                              <Col className='col-auto'>
                                <img
                                  data-dz-thumbnail=''
                                  height='80px'
                                  width={'auto'}
                                  className='bg-light'
                                  alt={f.name}
                                  src={f.preview}
                                />
                              </Col>
                              <Col>
                                <Link
                                  to='#'
                                  className='text-muted font-weight-bold'>
                                  {f.name}
                                </Link>
                                <p className='mb-0'>
                                  <strong>{f.formattedSize}</strong>
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      );
                    })}
                    {selectedFeatured.length === 0 && featuredimg && (
                      <Row className='align-items-center'>
                        <Col className='col-auto'>
                          <img
                            height='80px'
                            width={'auto'}
                            className='bg-light'
                            alt={cattitle ? cattitle : 'Placeholder'}
                            src={`${process.env.REACT_APP_PUBLIC_URL}${featuredimg}`}
                          />
                        </Col>
                      </Row>
                    )}
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <button
                    type='submit'
                    className='btn btn-primary waves-effect waves-light'>
                    Save
                  </button>
                  <button
                    type='button'
                    onClick={(e) => {
                      e.preventDefault();
                      setEditorState(EditorState.createEmpty());
                      setFormData({
                        isLive: false,
                        cattitle: '',
                        link: '',
                        catdescription: '',
                        position: 0,
                        items: [],
                        seotitle: '',
                        isMenu: false,
                        isSubmenu: false,
                        isUnder: '',
                        featuredimg: 'uploads/big-placeholder.jpg',
                        othersizes: {
                          tablet: '',
                          mobile: '',
                        },
                      });
                    }}
                    className='btn btn-danger ml-2 waves-effect waves-light'>
                    Reset
                  </button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
      </div>
    </React.Fragment>
  );
};

const Offsymbol = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: 12,
        color: '#fff',
        paddingRight: 2,
        paddingTop: 3,
      }}>
      {' '}
      <small>OFF</small>
    </div>
  );
};

const OnSymbol = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: 12,
        color: '#fff',
        paddingRight: 2,
        paddingTop: 3,
      }}>
      {' '}
      <small>ON</small>
    </div>
  );
};

export default AddCategory;
