import React from 'react';
import MetisMenu from '@metismenu/react';
import { Link } from 'react-router-dom';

const RestaurantMenu = (props) => {
  const { disableClick, locations, categories, items, specials } = props;

  return (
    <MetisMenu>
      <li className='menu-title'>Restaurant</li>
      <li className='with-dd'>
        <Link
          to='/#!'
          onClick={(e) => disableClick(e)}
          className='has-arrow waves-effect'>
          <i className='fa-light fa-location'></i>
          <span>Locations</span>
        </Link>
        <ul className='sub-menu'>
          <li>
            <Link to='/add-location'>Add Location</Link>
          </li>
          <li>
            <Link to='/all-locations'>
              <span className='badge badge-pill badge-primary float-right'>
                {locations.length}
              </span>
              All Locations
            </Link>
          </li>
        </ul>
      </li>
      <li className='with-dd'>
        <Link
          to='/#!'
          onClick={(e) => disableClick(e)}
          className='has-arrow waves-effect'>
          <i className='fa-light fa-store'></i>
          <span>Menu</span>
        </Link>
        <ul className='sub-menu'>
          <li>
            <Link to='/add-menu-item'>Add Menu Item</Link>
          </li>
          <li>
            <Link to='/all-menu-items'>
              <span className='badge badge-pill badge-primary float-right'>
                {items.length}
              </span>
              All Menu Items
            </Link>
          </li>
          <li>
            <Link to='/all-menu-categories'>
              <span className='badge badge-pill badge-primary float-right'>
                {categories.length}
              </span>
              Categories
            </Link>
          </li>
        </ul>
      </li>
      <li className='with-dd'>
        <Link
          to='/#!'
          onClick={(e) => disableClick(e)}
          className='has-arrow waves-effect'>
          <i className='fa-light fa-tag'></i>
          <span>Specials</span>
        </Link>
        <ul className='sub-menu'>
          <li>
            <Link to='/add-special'>Add Special</Link>
          </li>
          <li>
            <Link to='/all-specials'>
              <span className='badge badge-pill badge-primary float-right'>
                {specials.length}
              </span>
              All Specials
            </Link>
          </li>
        </ul>
      </li>
    </MetisMenu>
  );
};

export default RestaurantMenu;
