export const GET_USERS = 'GET_USERS';
export const GETTING_USERS = 'GETTING_USERS';
export const GETTING_FAIL = 'GETTING_FAIL';

export const TRY_LOGIN = 'TRY_LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const REQUEST_USER = 'REQUEST_USER';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';

export const USER_LOGOUT = 'USER_LOGOUT';

export const REGISTER_TRY = 'REGISTER_TRY';
export const REGISTERING_USER = 'REGISTERING_USER';
export const REGISTER_FAILED = 'REGISTER_FAILED ';

export const CHANGEPASS_TRY = 'CHANGEPASS_TRY';
export const CHANGEPASS_GO = 'CHANGEPASS_GO';
export const CHANGEPASS_FAILED = 'CHANGEPASS_FAILED';

export const NEWPASS_TRY = 'NEWPASS_TRY';
export const NEWPASS_GO = 'NEWPASS_GO';
export const NEWPASS_FAILED = 'NEWPASS_FAILED';

export const UPDATE_TRY = 'UPDATE_TRY';
export const UPDATE_GO = 'UPDATE_GO';
export const UPDATE_FAILED = 'UPDATE_FAILED';

export const ADDUSER_TRY = 'ADDUSER_TRY';
export const ADDUSER_GO = 'ADDUSER_GO';
export const ADDUSER_FAILED = 'ADDUSER_FAILED';

export const CALLUSER_TRY = 'CALLUSER_TRY';
export const CALLUSER_GO = 'CALLUSER_GO';
export const CALLUSER_FAILED = 'CALLUSER_FAILED';

export const EDITUSER_TRY = 'EDITUSER_TRY';
export const EDITUSER_GO = 'EDITUSER_GO';
export const EDITUSER_FAILED = 'EDITUSER_FAILED';

export const REMOVE_USER_TRY = 'REMOVE_USER_TRY';
export const REMOVE_USER_GO = 'REMOVE_USER_GO';
export const REMOVE_USER_FAILED = 'REMOVE_USER_FAILED';

export const SELF_REMOVE_TRY = 'SELF_REMOVE_TRY';
export const SELF_REMOVE_GO = 'SELF_REMOVE_GO';
export const SELF_REMOVE_FAILED = 'SELF_REMOVE_FAILED';
