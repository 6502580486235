import { takeLatest, put, call, all, takeEvery } from 'redux-saga/effects';

import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  TRY_LOGIN,
  USER_LOADED,
  AUTH_ERROR,
  REQUEST_USER,
  REGISTERING_USER,
  REGISTER_FAILED,
  REGISTER_TRY,
  CHANGEPASS_GO,
  CHANGEPASS_FAILED,
  CHANGEPASS_TRY,
  NEWPASS_GO,
  NEWPASS_FAILED,
  NEWPASS_TRY,
  GETTING_USERS,
  GETTING_FAIL,
  GET_USERS,
  UPDATE_GO,
  UPDATE_FAILED,
  UPDATE_TRY,
  ADDUSER_TRY,
  ADDUSER_GO,
  ADDUSER_FAILED,
  CALLUSER_TRY,
  CALLUSER_GO,
  CALLUSER_FAILED,
  EDITUSER_TRY,
  EDITUSER_GO,
  EDITUSER_FAILED,
  REMOVE_USER_TRY,
  REMOVE_USER_GO,
  REMOVE_USER_FAILED,
  SELF_REMOVE_GO,
  SELF_REMOVE_FAILED,
  SELF_REMOVE_TRY,
} from './actionTypes';

import {
  changePassApi,
  getUserApi,
  getUsersApi,
  loginUserApi,
  newPassApi,
  registerUserApi,
  updateUserApi,
  createUserApi,
  callUserApi,
  editUserApi,
  deleteUserApi,
} from '../helpers/loginUserApi';

function* theGettingUsers() {
  try {
    const response = yield call(getUsersApi);

    yield put({ type: GETTING_USERS, payload: response.data });
  } catch (error) {
    yield put({ type: GETTING_FAIL, payload: error.response.data.message });
  }
}

function* theLoginUser({ payload: { formData } }) {
  try {
    const response = yield call(loginUserApi, formData);

    yield put({ type: LOGIN_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: LOGIN_FAIL, payload: error.response.data.message });
  }
}

function* theLoadingUser() {
  try {
    const response = yield call(getUserApi);
    yield put({ type: USER_LOADED, payload: response.data });
  } catch (error) {
    yield put({ type: AUTH_ERROR, payload: error.response.data.message });
  }
}

function* theRegisteringUser({ payload: { formData } }) {
  try {
    const response = yield call(registerUserApi, formData);

    yield put({ type: REGISTERING_USER, payload: response });
  } catch (error) {
    yield put({ type: REGISTER_FAILED, payload: error.response.data.message });
  }
}

function* theChangingPass({ payload }) {
  try {
    const response = yield call(changePassApi, payload);

    yield put({ type: CHANGEPASS_GO, payload: response.data.msg });
  } catch (error) {
    yield put({
      type: CHANGEPASS_FAILED,
      payload: error.response.data.message,
    });
  }
}

function* theNewPassword({ payload: { formData } }) {
  try {
    const response = yield call(newPassApi, formData);

    yield put({ type: NEWPASS_GO, payload: response });
  } catch (error) {
    yield put({ type: NEWPASS_FAILED, payload: error.response.data.message });
  }
}

function* theUpdatingUser({ payload: { formData } }) {
  try {
    const response = yield call(updateUserApi, formData);

    yield put({ type: UPDATE_GO, payload: response.data });
  } catch (error) {
    yield put({ type: UPDATE_FAILED, payload: error.response.data.message });
  }
}

function* theAddingUser({ payload: { formData } }) {
  try {
    const response = yield call(createUserApi, formData);

    yield put({ type: ADDUSER_GO, payload: response.data });
  } catch (error) {
    yield put({ type: ADDUSER_FAILED, payload: error.response.data.message });
  }
}

function* theCallingUser({ payload: id }) {
  try {
    const response = yield call(callUserApi, id);

    yield put({ type: CALLUSER_GO, payload: response.data });
  } catch (error) {
    yield put({ type: CALLUSER_FAILED, payload: error.response.data.message });
  }
}

function* theEditingUser({ payload }) {
  try {
    const response = yield call(editUserApi, payload);

    yield put({ type: EDITUSER_GO, payload: response.data });
  } catch (error) {
    yield put({ type: EDITUSER_FAILED, payload: error.response.data.message });
  }
}

function* theDeletingUser({ payload: id }) {
  try {
    const response = yield call(deleteUserApi, id);

    yield put({ type: REMOVE_USER_GO, payload: response.data });
  } catch (error) {
    yield put({
      type: REMOVE_USER_FAILED,
      payload: error.response.data.message,
    });
  }
}

function* theSelfDelete({ payload: id }) {
  try {
    if (
      window.confirm(
        'Are you sure you want to delete your account? This cannot be undone.'
      )
    ) {
      const response = yield call(deleteUserApi, id);

      yield put({ type: SELF_REMOVE_GO, payload: response.data });
    } else {
      yield put({
        type: SELF_REMOVE_FAILED,
        payload: 'Action cancelled by user',
      });
    }
  } catch (error) {
    yield put({
      type: SELF_REMOVE_FAILED,
      payload: error.response.data.message,
    });
  }
}

function* theUsersGet() {
  yield takeLatest(GET_USERS, theGettingUsers);
}

function* theLoggedUser() {
  yield takeLatest(TRY_LOGIN, theLoginUser);
}

function* theLoadedUser() {
  yield takeEvery(REQUEST_USER, theLoadingUser);
}

function* theRegisteredUser() {
  yield takeLatest(REGISTER_TRY, theRegisteringUser);
}

function* theChangedPass() {
  yield takeLatest(CHANGEPASS_TRY, theChangingPass);
}

function* theNewPass() {
  yield takeLatest(NEWPASS_TRY, theNewPassword);
}

function* theUpdate() {
  yield takeLatest(UPDATE_TRY, theUpdatingUser);
}

function* theAddingTry() {
  yield takeLatest(ADDUSER_TRY, theAddingUser);
}

function* theCallingTry() {
  yield takeLatest(CALLUSER_TRY, theCallingUser);
}

function* theEditingTry() {
  yield takeLatest(EDITUSER_TRY, theEditingUser);
}

function* theDeletingTry() {
  yield takeLatest(REMOVE_USER_TRY, theDeletingUser);
}

function* theSelfDeleting() {
  yield takeLatest(SELF_REMOVE_TRY, theSelfDelete);
}

function* LoginSaga() {
  yield all([
    theUsersGet(),
    theLoggedUser(),
    theLoadedUser(),
    theRegisteredUser(),
    theChangedPass(),
    theNewPass(),
    theUpdate(),
    theAddingTry(),
    theCallingTry(),
    theEditingTry(),
    theDeletingTry(),
    theSelfDeleting(),
  ]);
}

export default LoginSaga;
