import React, { Fragment, useEffect, useState } from 'react';
import {
	Row,
	Col,
	Card,
	CardBody,
	UncontrolledTooltip,
	Alert,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import Paginator from 'react-hooks-paginator';
import PropTypes from 'prop-types';

// Parts
import TestimonialsEditor from '../../components/Editors/TestimonialsEditor';
import Loader from '../../components/Loader';

// Actions
import {
	clearCurrentTs,
	getTestimonials,
	postActivity,
	removeTestimonial,
} from '@thedavid/plitzredux/actions';

const AllTestimonials = () => {
	const dispatch = useDispatch();

	const testimonialLimit = 10;

	const [offset, setOffset] = useState(0);
	const [currentTestimonial, setCurrentTestimonial] = useState(1);
	const [currentData, setCurrentData] = useState([]);
	const [isOpen, setIsOpen] = useState(false);
	const [currentItem, setCurrentItem] = useState('');

	const Testimonials = useSelector((state) => state.Testimonials);
	const { testimonials, loading, error, msg } = Testimonials;
	const Login = useSelector((state) => state.Login);
	const { user, users } = Login;

	useEffect(() => {
		setCurrentData(
			testimonials &&
				testimonials
					.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
					.slice(offset, offset + testimonialLimit)
		);
	}, [offset, testimonials]);

	const setToggle = (sl) => {
		if (isOpen) {
			setIsOpen(false);
			setCurrentItem('');
			dispatch(clearCurrentTs());
		} else {
			setIsOpen(true);
			setCurrentItem(sl._id);
		}
	};

	const deleteTestimonial = (sl) => {
		dispatch(
			postActivity({
				logtype: 'warning',
				logcontent: `The testimonial by <strong>${sl.author}</strong> has been removed`,
				email: user ? user.email : '',
			})
		);
		dispatch(removeTestimonial(sl._id));
	};

	useEffect(() => {
		dispatch(getTestimonials());
	}, [dispatch]);

	return (
		<Fragment>
			{isOpen && (
				<TestimonialsEditor
					isOpen={isOpen}
					toggle={setToggle}
					id={currentItem}
				/>
			)}
			<div className='container-fluid plitz-cards'>
				<Row className='align-items-center'>
					<Col sm={6}>
						<div className='page-title-box'>
							<h1 className=''>Testimonials</h1>
							<ol className='breadcrumb mb-0'>
								<li className='breadcrumb-item'>
									<Link to='/dashboard'>Dashboard</Link>
								</li>
								<li className='breadcrumb-item'>All Testimonials</li>
							</ol>
						</div>
					</Col>
				</Row>

				<Row>
					<Col sm={12}>
						{error ? <Alert color='danger'>{error}</Alert> : null}
						{msg ? <Alert color='warning'>{msg}</Alert> : null}
					</Col>
				</Row>

				<Row>
					{loading ? (
						<Loader />
					) : currentData && currentData.length > 0 ? (
						currentData.map((sl) => (
							<Col key={sl._id} md={12}>
								<Card>
									<Row>
										<Col
											sm={12}
											md={3}
											style={{ position: 'relative', minHeight: '10em' }}
										>
											<div className='avatar-xl rounded-circle for-testimonials-list'>
												<img
													className='img-fluid bg-light actual-image'
													src={
														sl.picture
															? `${process.env.REACT_APP_PUBLIC_URL}${sl.picture}`
															: `${process.env.REACT_APP_PUBLIC_URL}uploads/big-placeholder.jpg`
													}
													alt={sl.title}
												/>
											</div>
										</Col>
										<Col sm={12} md={9}>
											<CardBody>
												<div className='card-container'>
													<div className='card-status-area'>
														{sl.isLive ? (
															<span className='badge badge-success'>
																Published
															</span>
														) : (
															<span className='badge badge-dark'>Draft</span>
														)}
													</div>
													<div className='card-content-area'>
														<div className='flex-horizontal'>
															<h3 className='mt-0 mb-0'>{sl.author} </h3>
															{sl.rating > 0 && (
																<div
																	className='ml-3'
																	style={{
																		display: 'flex',
																		alignItems: 'center',
																	}}
																>
																	Rating:{'  '}
																	{sl.rating}{' '}
																	<i
																		className='fa-solid fa-star'
																		style={{ fontSize: 8 }}
																	></i>
																</div>
															)}
														</div>
														{sl.company ? (
															<h5 className='card-title  mt-0'>
																Company: {sl.company}{' '}
																{sl.worktitle && `| ${sl.worktitle}`}
															</h5>
														) : null}

														<div className='author-section'>
															<small>Created by</small>
															<h6 className='mb-0'>
																{' '}
																{users &&
																	users.length > 0 &&
																	users.map(
																		(usr) =>
																			usr._id === sl.user && (
																				<div
																					className='flex-horizontal'
																					key={usr._id}
																				>
																					<div className='avatar-sm rounded-circle mr-2'>
																						<img
																							src={`${process.env.REACT_APP_PUBLIC_URL}${usr.photo}`}
																							alt={usr.fullname}
																							className='actual-image'
																						/>{' '}
																					</div>
																					<span>{usr.fullname}</span>
																				</div>
																			)
																	)}
															</h6>
														</div>
													</div>
													<div className='card-actions-area'>
														<div className='buttons-only'>
															<Fragment>
																<UncontrolledTooltip
																	placement='top'
																	target={`EditTt-${sl._id}`}
																>
																	Edit
																</UncontrolledTooltip>
																<button
																	id={`EditTt-${sl._id}`}
																	type='button'
																	onClick={() => setToggle(sl)}
																	className='btn waves-effect text-primary waves-light'
																	data-toggle='modal'
																	data-target='#myModal'
																	style={{
																		fontSize: 20,
																		padding: '0.1rem',
																		margin: '0.5rem',
																	}}
																>
																	<i className='fa-light fa-edit'></i>
																</button>{' '}
															</Fragment>
															<Fragment>
																<UncontrolledTooltip
																	placement='top'
																	target={`RemoveFt-${sl._id}`}
																>
																	Remove
																</UncontrolledTooltip>
																<button
																	id={`RemoveFt-${sl._id}`}
																	type='button'
																	onClick={() => deleteTestimonial(sl)}
																	className='btn waves-effect ml-2 text-danger waves-light'
																	style={{
																		fontSize: 20,
																		padding: '0.1rem',
																		margin: '0.5rem',
																	}}
																>
																	<i className='fa-light fa-trash'></i>
																</button>{' '}
															</Fragment>
														</div>
														<span>
															Last Modification:{' '}
															<Moment format='MMMM DD, YYYY'>
																{sl.updatedAt}
															</Moment>
														</span>
													</div>
												</div>
											</CardBody>
										</Col>
									</Row>
								</Card>
							</Col>
						))
					) : (
						<h4>There is no testimonials yet</h4>
					)}
				</Row>

				<Row>
					<Col sm={12}>
						{testimonials && (
							<Paginator
								totalRecords={testimonials.length}
								pageLimit={testimonialLimit}
								pageNeighbours={2}
								setOffset={setOffset}
								currentPage={currentTestimonial}
								setCurrentPage={setCurrentTestimonial}
								pageContainerClass='pagination'
								pageActiveClass='current-page'
								pageNextText={<i className='typcn typcn-chevron-right'></i>}
								pageNextClass='pagination-arrow'
								pagePrevText={<i className='typcn typcn-chevron-left'></i>}
								pagePrevClass='pagination-arrow'
							/>
						)}
					</Col>
				</Row>
			</div>
		</Fragment>
	);
};

AllTestimonials.propTypes = {
	testimonials: PropTypes.array,
	user: PropTypes.object,
};

export default AllTestimonials;
