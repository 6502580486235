import React, { Fragment, useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  UncontrolledTooltip,
  Alert,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import Paginator from 'react-hooks-paginator';
import PropTypes from 'prop-types';

// Parts
import EditorModal from '../../../components/Editors/EditorModal';
import Loader from '../../../components/Loader';
import AddLocation from './AddLocation';

// Actions
import {
  clearCurrentLoc,
  getLocations,
  postActivity,
  removeLocation,
} from '@thedavid/plitzredux/actions';

const AllLocations = () => {
  const dispatch = useDispatch();

  const locationLimit = 10;

  const [offset, setOffset] = useState(0);
  const [currentLocation, setCurrentLocation] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState('');

  const Locations = useSelector((state) => state.Locations);
  const { locations, loading, error, msg } = Locations;
  const Login = useSelector((state) => state.Login);
  const { user } = Login;

  useEffect(() => {
    setCurrentData(
      locations &&
        locations
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .slice(offset, offset + locationLimit)
    );
  }, [offset, locations]);

  const setToggle = (link) => {
    if (isOpen) {
      setIsOpen(false);
      setCurrentItem('');
      dispatch(clearCurrentLoc());
    } else {
      setIsOpen(true);
      setCurrentItem(link);
    }
  };

  const deleteLocation = (location) => {
    dispatch(
      postActivity({
        logtype: 'warning',
        logcontent: `The location <strong>${location.title}</strong> has been removed`,
        email: user ? user.email : '',
      })
    );
    dispatch(removeLocation(location._id));
  };

  useEffect(() => {
    dispatch(getLocations());
  }, [dispatch]);

  return (
    <Fragment>
      {isOpen && (
        <EditorModal isOpen={isOpen} toggle={setToggle}>
          <AddLocation toggle={setToggle} currentItem={currentItem} />
        </EditorModal>
      )}
      <div className='container-fluid plitz-cards'>
        <Row className='align-items-center'>
          <Col sm={6}>
            <div className='page-title-box'>
              <h1 className=''>Locations</h1>
              <ol className='breadcrumb mb-0'>
                <li className='breadcrumb-item'>
                  <Link to='/dashboard'>Dashboard</Link>
                </li>
                <li className='breadcrumb-item'>All Locations</li>
              </ol>
            </div>
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            {error ? <Alert color='danger'>{error}</Alert> : null}
            {msg ? <Alert color='warning'>{msg}</Alert> : null}
          </Col>
        </Row>

        <Row>
          {loading ? (
            <Loader />
          ) : currentData && currentData.length > 0 ? (
            currentData.map((location) => (
              <Col key={location._id} md={12}>
                <Card>
                  <Row>
                    <Col sm={12} md={5}>
                      <div className='card-img-top' style={{ maxHeight: 250 }}>
                        <img
                          className='img-fluid actual-image'
                          src={
                            location.featuredImg
                              ? `${process.env.REACT_APP_PUBLIC_URL}${location.featuredImg.fullSize}`
                              : `${process.env.REACT_APP_PUBLIC_URL}uploads/big-placeholder.jpg`
                          }
                          alt={location.locationName}
                        />
                      </div>
                    </Col>
                    <Col sm={12} md={7}>
                      <CardBody>
                        <div className='card-container'>
                          <div className='card-status-area'>
                            {location.isLive ? (
                              <span className='badge badge-success'>
                                Published
                              </span>
                            ) : (
                              <span className='badge badge-dark'>Draft</span>
                            )}
                          </div>
                          <div className='card-content-area'>
                            <h3 className='mt-0'>{location.locationName}</h3>
                            <h5 className='card-title  mt-0'>
                              Address: {location.address}
                            </h5>

                            <div className='author-section'>
                              <small>Last modified by</small>
                              <h6 className='mb-0'>
                                <div
                                  className='flex-horizontal'
                                  key={location.user._id}>
                                  <div className='avatar-sm rounded-circle mr-2'>
                                    <img
                                      src={`${process.env.REACT_APP_PUBLIC_URL}${location.user.photo}`}
                                      alt={location.user.fullname}
                                      className='actual-image'
                                    />{' '}
                                  </div>
                                  <span>{location.user.fullname}</span>
                                </div>
                              </h6>
                            </div>
                          </div>
                          <div className='card-actions-area'>
                            <div className='buttons-only'>
                              <Fragment>
                                <UncontrolledTooltip
                                  placement='top'
                                  target={`EditTt-${location._id}`}>
                                  Edit
                                </UncontrolledTooltip>
                                <button
                                  id={`EditTt-${location._id}`}
                                  type='button'
                                  onClick={() => setToggle(location.link)}
                                  className='btn waves-effect text-primary waves-light'
                                  data-toggle='modal'
                                  data-target='#myModal'
                                  style={{
                                    fontSize: 20,
                                    padding: '0.1rem',
                                    margin: '0.5rem',
                                  }}>
                                  <i className='fa-light fa-edit'></i>
                                </button>{' '}
                              </Fragment>
                              <Fragment>
                                <UncontrolledTooltip
                                  placement='top'
                                  target={`RemoveFt-${location._id}`}>
                                  Remove
                                </UncontrolledTooltip>
                                <button
                                  id={`RemoveFt-${location._id}`}
                                  type='button'
                                  onClick={() => deleteLocation(location)}
                                  className='btn waves-effect ml-2 text-danger waves-light'
                                  style={{
                                    fontSize: 20,
                                    padding: '0.1rem',
                                    margin: '0.5rem',
                                  }}>
                                  <i className='fa-light fa-trash'></i>
                                </button>{' '}
                              </Fragment>
                            </div>
                            <span>
                              Last Modification:{' '}
                              <Moment format='MMMM DD, YYYY'>
                                {location.updatedAt}
                              </Moment>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))
          ) : (
            <h4>There is no locations yet</h4>
          )}
        </Row>

        <Row>
          <Col sm={12}>
            {locations && (
              <Paginator
                totalRecords={locations.length}
                pageLimit={locationLimit}
                pageNeighbours={2}
                setOffset={setOffset}
                currentPage={currentLocation}
                setCurrentPage={setCurrentLocation}
                pageContainerClass='pagination'
                pageActiveClass='current-page'
                pageNextText={<i className='typcn typcn-chevron-right'></i>}
                pageNextClass='pagination-arrow'
                pagePrevText={<i className='typcn typcn-chevron-left'></i>}
                pagePrevClass='pagination-arrow'
              />
            )}
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

AllLocations.propTypes = {
  locations: PropTypes.array,
  user: PropTypes.object,
};

export default AllLocations;
