import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Card,
  CardBody,
  Col,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// Actions
import {
  deleteClientTry,
  postActivity,
  tryGetclients,
} from '@thedavid/plitzredux/actions';

// Parts
import ClientsEditor from '../../components/Editors/ClientsEditor';
import Loader from '../../components/Loader';

const AllClients = () => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [chosenItem, setChosenitem] = useState(null);

  const Clients = useSelector((state) => state.Clients);
  const { clients, loading, error } = Clients;

  useEffect(() => {
    dispatch(tryGetclients());
  }, [dispatch]);

  const setToggle = (id) => {
    if (isOpen) {
      setChosenitem(null);
      setIsOpen(false);
    } else {
      setChosenitem(id);
      setIsOpen(true);
    }
  };

  const deleteClient = (client) => {
    dispatch(
      postActivity({
        logtype: 'warning',
        logcontent: `The client <strong>${client.fullname}</strong> has been removed`,
        email: client ? client.email : '',
      })
    );
    dispatch(deleteClientTry(client._id));
  };

  return (
    <Fragment>
      {isOpen && (
        <ClientsEditor
          isOpen={isOpen}
          toggle={setToggle}
          chosenItem={chosenItem}
        />
      )}

      <div className='container-fluid plitz-cards'>
        <Row className='align-items-center'>
          <Col sm={6}>
            <div className='page-title-box'>
              <h1 className=''>Client Profiles</h1>
              <ol className='breadcrumb mb-0'>
                <li className='breadcrumb-item'>
                  <Link to='/dashboard'>Dashboard</Link>
                </li>
                <li className='breadcrumb-item'>All Clients</li>
              </ol>
            </div>
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            {error ? <Alert color='danger'>{error}</Alert> : null}
          </Col>
        </Row>

        <Row className='justify-content-center'>
          <div
            style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
            <i className='mdi mdi-clipboard-outline text-danger ml-2'></i>{' '}
            <span className='font-size-10 ml-2'>
              * <strong>Tenants & Clients:</strong> Tenants are the clients with
              a website in our system
            </span>
          </div>
          {loading ? (
            <Loader />
          ) : (
            clients.length > 0 &&
            clients
              .sort((a, b) =>
                new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1
              )
              .map((client) => (
                <Col key={client._id} sm={12} md={8} lg={8} xl={5}>
                  <Card className='directory-card'>
                    <CardBody>
                      <div className='d-flex'>
                        <div className='rounded-circle avatar-lg'>
                          <img
                            src={`${process.env.REACT_APP_PUBLIC_URL}${client.photo}`}
                            alt={client.fullname}
                            className='img-fluid actual-image'
                          />
                        </div>
                        <div className='flex-1 ms-3'>
                          <h5 className='text-primary font-size-18 mt-0 mb-1'>
                            {client.fullname}{' '}
                            {client && client._id === client._id && (
                              <i className='mdi mdi-account-check text-success'></i>
                            )}
                          </h5>
                          {client.companyRole && (
                            <p className='font-size-12 mb-2'>
                              {client.companyRole}
                            </p>
                          )}
                          {client.company && (
                            <p className='font-size-12 mb-2'>
                              <b>{client.company}</b>
                            </p>
                          )}
                          <p className='mb-0 small'>{client.email}</p>
                        </div>
                      </div>
                      <hr />
                      <div
                        className='mb-1 mt-0'
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}>
                        <div>
                          <UncontrolledTooltip
                            placement='top'
                            target={`EditTt-${client._id}`}>
                            Edit
                          </UncontrolledTooltip>
                          <button
                            id={`EditTt-${client._id}`}
                            type='button'
                            onClick={() => setToggle(client._id)}
                            className='btn waves-effect text-primary waves-light'
                            data-toggle='modal'
                            data-target='#myModal'
                            style={{ fontSize: 20, padding: 0 }}>
                            <i className='fa-light fa-edit'></i>
                          </button>

                          <UncontrolledTooltip
                            placement='top'
                            target={`RemoveFt-${client._id}`}>
                            Remove
                          </UncontrolledTooltip>
                          <button
                            id={`RemoveFt-${client._id}`}
                            type='button'
                            onClick={() => deleteClient(client)}
                            className='btn waves-effect ml-2 text-danger waves-light'
                            style={{ fontSize: 20, padding: 0 }}>
                            <i className='fa-light fa-trash'></i>
                          </button>
                        </div>
                        <div>
                          {client.servicePlan} | {client.companyId}
                        </div>
                        <div>
                          {client.verified ? (
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}>
                              <i className='mdi mdi-check text-success ml-2'></i>{' '}
                              <span className='font-size-10'>Verified</span>
                            </div>
                          ) : (
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}>
                              <i className='mdi mdi-close text-danger ml-2'></i>{' '}
                              <span className='font-size-10'>Not Verified</span>
                            </div>
                          )}
                          {client.isTenant ? (
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}>
                              <i className='mdi mdi-clipboard-multiple-outline text-success ml-2'></i>{' '}
                              <span className='font-size-10'>Tenant</span>
                            </div>
                          ) : (
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}>
                              <i className='mdi mdi-clipboard-outline text-danger ml-2'></i>{' '}
                              <span className='font-size-10'>Client</span>
                            </div>
                          )}
                        </div>
                      </div>
                      <hr />
                      <h6>Tenant's Users</h6>
                      <div
                        className='mb-1 mt-0'
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}>
                        {client.tenantUsers &&
                          client.tenantUsers.length > 0 &&
                          client.tenantUsers.map((usr) => (
                            <div
                              className='flex-horizontal w-100 mb-2'
                              key={usr._id}>
                              <div className='avatar-sm rounded-circle mr-2'>
                                <img
                                  src={`${process.env.REACT_APP_PUBLIC_URL}${usr.photo}`}
                                  alt={usr.fullname}
                                  className='actual-image'
                                />{' '}
                              </div>
                              <div>
                                <span>
                                  CMS User: <b>{usr.fullname}</b>
                                </span>
                                <br />
                                <span>
                                  Role in Tenant: <b>{usr.position}</b>
                                </span>
                              </div>
                            </div>
                          ))}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))
          )}
        </Row>
      </div>
    </Fragment>
  );
};

AllClients.propTypes = {
  clients: PropTypes.array,
};

export default AllClients;
