import React, { useEffect, useState } from 'react';
import { Card, CardBody, Alert } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, TextField } from '@mui/material';

// Redux
import { Link, useNavigate } from 'react-router-dom';

// actions
import { postActivity, tryLogin } from '@thedavid/plitzredux/actions';

// Parts
import AuthFooter from '../../components/AuthFooter';
import Loader from '../../components/Loader';
import AuthHeader from '../../components/AuthHeader';

const Login = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [vali, setVali] = useState(false);
  const [showError, setShowError] = useState(false);

  const { email, password } = formData;

  const userLogin = useSelector((state) => state.Login);
  const { loading, error, isAuthenticated } = userLogin;

  const redirect = props.search ? props.search.split('=')[1] : '/dashboard';

  const onChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // handleValidSubmit
  const handleValidSubmit = (e) => {
    e.preventDefault();

    let action = 'Login'; // this is any name to identify this particular action

    window.grecaptcha.enterprise.ready(() => {
      window.grecaptcha.enterprise
        .execute(process.env.REACT_APP_SITE_KEY, { action: action })
        .then((token) => {
          let payload = {
            token: token,
            action: action,
          };
          fetch(`${process.env.REACT_APP_PUBLIC_URL}api/recaptcha`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          }).then((data) => {
            if (data.status === 200) {
              if (email === '' || password === '') {
                setVali(true);
              } else {
                setVali(false);
                dispatch(tryLogin(formData));
                dispatch(
                  postActivity({
                    logtype: 'positive',
                    logcontent: `User logged-in successfully`,
                    email: formData.email ? formData.email : '',
                  })
                );
              }
            }
          });
        });
    });
  };

  useEffect(() => {
    if (error) {
      setShowError(true);
    }

    // eslint-disable-next-line
  }, [error]);

  useEffect(() => {
    if (showError) {
      setTimeout(() => {
        setShowError(false);
      }, 7000);
    }
    // eslint-disable-next-line
  }, [showError]);

  useEffect(() => {
    if (isAuthenticated) {
      history(redirect);
    }

    // eslint-disable-next-line
  }, [isAuthenticated, redirect]);

  return (
    <React.Fragment>
      <div className='account-pages pt-5'>
        <div className='container'>
          <Grid
            container
            spacing={3}
            justifyContent='center'
            alignItems='center'
            className='justify-content-center'
          >
            <Grid item xs={12} sm={10} md={5}>
              <h4>Welcome Back!</h4>
              <h1>It's time to update your website</h1>
            </Grid>
            <Grid item xs={12} sm={10} md={5}>
              <div className='position-relative'>
                {loading ? <Loader /> : null}

                <Card className='overflow-hidden'>
                  <AuthHeader
                    title='Sign In'
                    intro='Enter your details below.'
                  />

                  <CardBody className='p-4'>
                    <div className='p-3'>
                      <form
                        className='form-horizontal mt-5'
                        onSubmit={(e) => handleValidSubmit(e)}
                      >
                        {error && showError ? (
                          <Alert color='danger'>{error}</Alert>
                        ) : null}

                        <div className='form-group'>
                          <TextField
                            error={vali}
                            helperText={vali ? 'This field is required' : null}
                            fullWidth
                            variant='outlined'
                            name='email'
                            label='Email'
                            value={email}
                            placeholder='Enter email'
                            type='email'
                            autoComplete='email'
                            onChange={(e) => onChange(e)}
                          />
                        </div>
                        <div className='form-group'>
                          <TextField
                            error={vali}
                            helperText={vali ? 'This field is required' : null}
                            fullWidth
                            variant='outlined'
                            name='password'
                            label='Password'
                            type='password'
                            value={password}
                            placeholder='Enter Password'
                            autoComplete='current-password'
                            onChange={(e) => onChange(e)}
                          />
                        </div>

                        <Grid container className='form-group'>
                          <Grid
                            item
                            xs={8}
                            sm={6}
                            className='form-group mt-2 mb-0'
                          >
                            <Link to='/forgot-password'>
                              <i className='mdi mdi-lock'></i> Forgot your
                              password?
                            </Link>
                          </Grid>
                          <Grid item xs={4} sm={6} className='text-right'>
                            <button
                              className='btn btn-primary w-md waves-effect waves-light'
                              type='submit'
                            >
                              Log In
                            </button>
                          </Grid>
                        </Grid>
                      </form>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Grid>
          </Grid>
        </div>
        <AuthFooter
          text={`Don't have an account? `}
          button={`/pages-register`}
          buttonlabel={`Request Access`}
        />
      </div>
    </React.Fragment>
  );
};

export default Login;
