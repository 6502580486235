import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  ADD_PROMO_FAILED,
  ADD_PROMO_GO,
  ADD_PROMO_TRY,
  EDIT_PROMO_FAILED,
  EDIT_PROMO_GO,
  EDIT_PROMO_TRY,
  GET_APROMO_FAILED,
  GET_APROMO_GO,
  GET_APROMO_TRY,
  GET_PROMOS_FAILED,
  GET_PROMOS_GO,
  GET_PROMOS_TRY,
  REMOVE_PROMO_FAILED,
  REMOVE_PROMO_GO,
  REMOVE_PROMO_TRY,
} from './actionTypes';

import {
  addPromo,
  editPromo,
  getAllPromosApi,
  getPromoApi,
  removePromo,
} from '../helpers/promoApi';

function* thePromosPull() {
  try {
    const response = yield call(getAllPromosApi);

    yield put({ type: GET_PROMOS_GO, payload: response.data });
  } catch (error) {
    yield put({
      type: GET_PROMOS_FAILED,
      payload: error.response.data.message,
    });
  }
}

function* onePromoPull({ payload }) {
  try {
    const response = yield call(getPromoApi, payload);

    yield put({ type: GET_APROMO_GO, payload: response.data });
  } catch (error) {
    yield put({
      type: GET_APROMO_FAILED,
      payload: error.response.data.message,
    });
  }
}

function* addingPromo({ payload: { formData } }) {
  try {
    const response = yield call(addPromo, formData);

    yield put({ type: ADD_PROMO_GO, payload: response.data.promo });
  } catch (error) {
    yield put({
      type: ADD_PROMO_FAILED,
      payload: error.response.data.message,
    });
  }
}

function* editingPromo({ payload: { formData } }) {
  try {
    const response = yield call(editPromo, formData);

    yield put({ type: EDIT_PROMO_GO, payload: response.data });
  } catch (error) {
    yield put({
      type: EDIT_PROMO_FAILED,
      payload: error.response.data.message,
    });
  }
}

function* removingPromo({ payload: id }) {
  try {
    const response = yield call(removePromo, id);

    yield put({ type: REMOVE_PROMO_GO, payload: response.data });
  } catch (error) {
    yield put({
      type: REMOVE_PROMO_FAILED,
      payload: error.response.data.message,
    });
  }
}

function* pullingPromos() {
  yield takeLatest(GET_PROMOS_TRY, thePromosPull);
}

function* pullingPromo() {
  yield takeLatest(GET_APROMO_TRY, onePromoPull);
}

function* addPromoTry() {
  yield takeLatest(ADD_PROMO_TRY, addingPromo);
}

function* editingPromoTry() {
  yield takeLatest(EDIT_PROMO_TRY, editingPromo);
}

function* removingPromoTry() {
  yield takeLatest(REMOVE_PROMO_TRY, removingPromo);
}

function* PromosSaga() {
  yield all([
    pullingPromos(),
    addPromoTry(),
    editingPromoTry(),
    removingPromoTry(),
    pullingPromo(),
  ]);
}

export default PromosSaga;
