import React, { Fragment, useEffect, useState } from 'react';
import { Alert } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { Grid, TextField } from '@mui/material';
import PropTypes from 'prop-types';

// actions
import { newPassword, postActivity } from '@thedavid/plitzredux/actions';

const MyPassword = ({ user, error, token }) => {
	const dispatch = useDispatch();

	const [formData, setFormData] = useState({
		token: null,
		password: '',
	});
	const [password2, setPassword2] = useState('');
	const [vali, setVali] = useState(false);
	const [warning, setWarning] = useState('');
	const [whatType, setWhatType] = useState('password');
	const [currenIcon, setCurrentIcon] = useState('mdi-eye');
	const [showError, setShowError] = useState(false);

	const { password } = formData;

	const onChange = (e) => {
		e.preventDefault();
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const changeType = (e) => {
		e.preventDefault();
		if (whatType === 'password') {
			setCurrentIcon('mdi-eye-off');
			setWhatType('text');
		} else {
			setCurrentIcon('mdi-eye');
			setWhatType('password');
		}
	};

	const secPassword = (e) => {
		e.preventDefault();
		setPassword2(e.target.value);
	};

	useEffect(() => {
		if (token && token !== null) {
			setFormData({ ...formData, token: token });
		}

		// eslint-disable-next-line
	}, [token]);

	useEffect(() => {
		if (error) {
			setShowError(true);
			dispatch(
				postActivity({
					logtype: 'negative',
					logcontent: `The following error traying to change the password: <strong>${error}</strong>`,
					email: '',
				})
			);
			setTimeout(() => {
				setShowError(false);
			}, 6000);
		}

		// eslint-disable-next-line
	}, [error]);

	// handleValidSubmit
	const handleValidSubmit = (e) => {
		e.preventDefault();
		if (password === '' || password2 === '') {
			setVali(true);
		} else if (password !== password2) {
			setWarning(`Passwords don't match`);
		} else {
			setVali(false);
			dispatch(newPassword(formData));
			dispatch(
				postActivity({
					logtype: 'positive',
					logcontent: `The user changed the password successfully`,
					email: user ? user.email : '',
				})
			);
		}
	};

	return (
		<Fragment>
			<div className='change-password'>
				<div className='container'>
					<div className='p-3'>
						<form
							className='form-horizontal'
							onSubmit={(e) => handleValidSubmit(e)}
						>
							{showError ? (
								<Alert
									color='danger'
									style={{
										marginTop: '13px',
									}}
								>
									{error}
								</Alert>
							) : null}
							{warning !== '' ? <Alert color='warning'>{warning}</Alert> : null}

							<div
								className='form-group'
								style={{
									position: 'relative',
								}}
							>
								<TextField
									error={vali}
									helperText={vali ? 'This field is required' : null}
									fullWidth
									variant='outlined'
									name='password'
									label='Password'
									type={whatType}
									value={password}
									placeholder='Enter Password'
									autoComplete='new-password'
									onChange={(e) => onChange(e)}
									tabIndex='1'
								/>
								<button
									className='button-reveal-password'
									onClick={changeType}
									tabIndex='3'
								>
									<i className={`mdi ${currenIcon}`}></i>
								</button>
							</div>

							<div
								className='form-group'
								style={{
									position: 'relative',
								}}
							>
								<TextField
									error={vali}
									helperText={vali ? 'This field is required' : null}
									fullWidth
									variant='outlined'
									name='password2'
									label='Verify Password'
									type={whatType}
									value={password2}
									placeholder='Verify Password'
									autoComplete='new-password'
									onChange={(e) => secPassword(e)}
									tabIndex='2'
								/>
								<button
									className='button-reveal-password'
									onClick={changeType}
									tabIndex='4'
								>
									<i className={`mdi ${currenIcon}`}></i>
								</button>
							</div>

							<Grid container className='form-group'>
								<Grid item xs={7} sm={6} className='form-group'></Grid>
								<Grid item xs={5} sm={6} className='text-right'>
									<button
										className='btn btn-primary w-md waves-effect waves-light'
										type='submit'
									>
										Change Password
									</button>
								</Grid>
							</Grid>
						</form>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

MyPassword.propTypes = {
	user: PropTypes.object,
	token: PropTypes.string,
};

export default MyPassword;
