import {
	USER_LOADED,
	AUTH_ERROR,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	TRY_LOGIN,
	REQUEST_USER,
	USER_LOGOUT,
	REGISTER_TRY,
	REGISTERING_USER,
	REGISTER_FAILED,
	CHANGEPASS_TRY,
	CHANGEPASS_GO,
	CHANGEPASS_FAILED,
	NEWPASS_TRY,
	NEWPASS_GO,
	NEWPASS_FAILED,
	UPDATE_TRY,
	UPDATE_GO,
	UPDATE_FAILED,
	GET_USERS,
	GETTING_USERS,
	GETTING_FAIL,
	ADDUSER_TRY,
	ADDUSER_GO,
	ADDUSER_FAILED,
	CALLUSER_TRY,
	CALLUSER_GO,
	CALLUSER_FAILED,
	EDITUSER_TRY,
	EDITUSER_GO,
	EDITUSER_FAILED,
	REMOVE_USER_TRY,
	REMOVE_USER_GO,
	REMOVE_USER_FAILED,
	SELF_REMOVE_TRY,
	SELF_REMOVE_GO,
	SELF_REMOVE_FAILED,
} from './actionTypes';

export const tryGetusers = () => {
	return {
		type: GET_USERS,
	};
};

export const goGetuser = (data) => {
	return {
		type: GETTING_USERS,
		payload: data,
	};
};

export const gettingFailed = (error) => {
	return {
		type: GETTING_FAIL,
		payload: error,
	};
};

export const tryLogin = (formData) => {
	return {
		type: TRY_LOGIN,
		payload: { formData },
	};
};

export const loginUser = (data) => {
	return {
		type: LOGIN_SUCCESS,
		payload: data,
	};
};

export const registerUser = (formData) => {
	return {
		type: REGISTER_TRY,
		payload: { formData },
	};
};

export const registeringUser = (data) => {
	return {
		type: REGISTERING_USER,
		payload: data,
	};
};

export const requestUser = () => {
	return {
		type: REQUEST_USER,
	};
};

export const loadUser = (user) => {
	return {
		type: USER_LOADED,
		payload: user,
	};
};

export const loginFail = (error) => {
	return {
		type: LOGIN_FAIL,
		payload: error,
	};
};

export const registeringFail = (error) => {
	return {
		type: REGISTER_FAILED,
		payload: error,
	};
};

export const passwordChange = (email) => {
	return {
		type: CHANGEPASS_TRY,
		payload: { email },
	};
};

export const passwordChangedOk = (msg) => {
	return {
		type: CHANGEPASS_GO,
		payload: msg,
	};
};

export const newPassword = (formData) => {
	return {
		type: NEWPASS_TRY,
		payload: { formData },
	};
};

export const newPassOk = (data) => {
	return {
		type: NEWPASS_GO,
		payload: data,
	};
};

export const passwordChangeFailed = (error) => {
	return {
		type: CHANGEPASS_FAILED,
		payload: error,
	};
};

export const newPassFailed = (error) => {
	return {
		type: NEWPASS_FAILED,
		payload: error,
	};
};

export const authError = (error) => {
	return {
		type: LOGIN_FAIL,
		payload: error,
	};
};

export const userFail = (error) => {
	return {
		type: AUTH_ERROR,
		payload: error,
	};
};

export const logOut = () => {
	return {
		type: USER_LOGOUT,
	};
};

export const tryUpdate = (formData) => {
	return {
		type: UPDATE_TRY,
		payload: { formData },
	};
};

export const userUpdate = (user) => {
	return {
		type: UPDATE_GO,
		payload: user,
	};
};

export const updateFailed = (error) => {
	return {
		type: UPDATE_FAILED,
		payload: error,
	};
};

export const addUserTry = (formData) => {
	return {
		type: ADDUSER_TRY,
		payload: { formData },
	};
};

export const addUserGo = (data) => {
	return {
		type: ADDUSER_GO,
		payload: data,
	};
};

export const addUserFailed = (error) => {
	return {
		type: ADDUSER_FAILED,
		payload: error,
	};
};

export const callUserTry = (id) => {
	return {
		type: CALLUSER_TRY,
		payload: id,
	};
};

export const callUserGo = (data) => {
	return {
		type: CALLUSER_GO,
		payload: data,
	};
};

export const callUserFailed = (error) => {
	return {
		type: CALLUSER_FAILED,
		payload: error,
	};
};

export const editUserTry = (formData, id) => {
	return {
		type: EDITUSER_TRY,
		payload: { formData, id },
	};
};

export const editUserGo = (data) => {
	return {
		type: EDITUSER_GO,
		payload: data,
	};
};

export const editUserFailed = (error) => {
	return {
		type: EDITUSER_FAILED,
		payload: error,
	};
};

export const deleteUserTry = (id) => {
	return {
		type: REMOVE_USER_TRY,
		payload: id,
	};
};

export const deleteUserGo = (data) => {
	return {
		type: REMOVE_USER_GO,
		payload: data,
	};
};

export const deleteUserFailed = (error) => {
	return {
		type: REMOVE_USER_FAILED,
		payload: error,
	};
};

export const selfRemoveTry = (id) => {
	return {
		type: SELF_REMOVE_TRY,
		payload: id,
	};
};

export const selfRemoveGo = (data) => {
	return {
		type: SELF_REMOVE_GO,
		payload: data,
	};
};

export const selfRemoveFailed = (error) => {
	return {
		type: SELF_REMOVE_FAILED,
		payload: error,
	};
};
