import React, { Fragment, useEffect, useState } from 'react';
// import SettingMenu from '../Shared/SettingMenu';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import axios from 'axios';

// Charts
import Salesdonut from '../AllCharts/apex/salesdonut';
import {
  getBlogposts,
  getPages,
  getSliders,
  getTestimonials,
  gettingActivity,
  requestUser,
} from '@thedavid/plitzredux/actions';

// Parts
import Loader from '../../components/Loader';
import PromosCar from '../../components/Extras/PromosCar';

const Dashboard = () => {
  const dispatch = useDispatch();

  const [firstDay, setFirstDay] = useState(new Date());
  const [lastDay, setLastDay] = useState(new Date());
  const [monthPages, setMonthPages] = useState([]);
  const [monthSliders, setMonthSliders] = useState([]);
  const [monthTestimonials, setMonthTestimonials] = useState([]);
  const [monthBlogposts, setMonthBlogposts] = useState([]);
  const [theTotal, setTheTotal] = useState(0);
  const [monthPagesPer, setMonthPagesPer] = useState(0);
  const [monthSlidersPer, setMonthSlidersPer] = useState(0);
  const [monthTestimonialsPer, setMonthTestimonialsPer] = useState(0);
  const [monthBlogpostsPer, setMonthBlogpostsPer] = useState(0);
  const [plitzPromos, setPlitzPromos] = useState([]);
  const [promosLoader, setPromosLoader] = useState(true);
  const [showPromosError, setPromosError] = useState('');

  const Login = useSelector((state) => state.Login);
  const { user, isAuthenticated } = Login;
  const Activity = useSelector((state) => state.Activity);
  const { activity } = Activity;
  const Pages = useSelector((state) => state.Pages);
  const { pages } = Pages;
  const Sliders = useSelector((state) => state.Sliders);
  const { sliders } = Sliders;
  const Testimonials = useSelector((state) => state.Testimonials);
  const { testimonials } = Testimonials;
  const Blogposts = useSelector((state) => state.Blogposts);
  const { blogposts } = Blogposts;

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(requestUser());
      dispatch(gettingActivity());
      dispatch(getPages());
      dispatch(getSliders());
      dispatch(getTestimonials());
      dispatch(getBlogposts());
    }
  }, [dispatch, isAuthenticated]);

  const getAnnouncements = async () => {
    const storedId =
      typeof window !== 'undefined' && localStorage.getItem('tenant');

    const config = {
      headers: {
        storedId,
      },
    };

    await axios
      .get(`${process.env.REACT_APP_PUBLIC_URL}api/promos`, config)
      .then((res) => {
        setPlitzPromos(res.data);
        setPromosLoader(false);
      })
      .catch((err) => {
        setPromosError(err);
        setPromosLoader(false);
      });
  };

  useEffect(() => {
    const TodayDate = new Date();

    setFirstDay(new Date(TodayDate.getFullYear(), TodayDate.getMonth(), 1));
    setLastDay(new Date(TodayDate.getFullYear(), TodayDate.getMonth() + 1, 0));

    getAnnouncements();
  }, []);

  useEffect(() => {
    setMonthPages([]);

    var filteredPages = [];
    var filteredSliders = [];
    var filteredTestimonials = [];
    var filteredBlogposts = [];

    pages.forEach((p) => {
      let dataEl = new Date(p.updatedAt);
      if (dataEl.getMonth() === firstDay.getMonth()) {
        filteredPages.push(p);
      }
    });

    blogposts.forEach((f) => {
      let dataEl = new Date(f.updatedAt);
      if (dataEl.getMonth() === firstDay.getMonth()) {
        filteredBlogposts.push(f);
      }
    });

    sliders.forEach((s) => {
      let dataEl = new Date(s.updatedAt);
      if (dataEl.getMonth() === firstDay.getMonth()) {
        filteredSliders.push(s);
      }
    });

    testimonials.forEach((t) => {
      let dataEl = new Date(t.updatedAt);
      if (dataEl.getMonth() === firstDay.getMonth()) {
        filteredTestimonials.push(t);
      }
    });

    setTheTotal(
      filteredPages.length +
        filteredBlogposts.length +
        filteredSliders.length +
        filteredTestimonials.length
    );

    setMonthPages(filteredPages);
    setMonthSliders(filteredSliders);
    setMonthTestimonials(filteredTestimonials);
    setMonthBlogposts(filteredBlogposts);
  }, [firstDay, lastDay, pages, blogposts, sliders, testimonials]);

  useEffect(() => {
    const FinalResults = () => {
      monthPages.length > 0 &&
        setMonthPagesPer((monthPages.length * 100) / theTotal);
      monthSliders.length > 0 &&
        setMonthSlidersPer((monthSliders.length * 100) / theTotal);
      monthBlogposts.length > 0 &&
        setMonthBlogpostsPer((monthBlogposts.length * 100) / theTotal);
      monthTestimonials.length > 0 &&
        setMonthTestimonialsPer((monthTestimonials.length * 100) / theTotal);
    };

    return FinalResults();
  }, [monthPages, monthSliders, monthBlogposts, monthTestimonials, theTotal]);

  return (
    <React.Fragment>
      <div className='container-fluid'>
        <Row className='align-items-center'>
          <Col sm={6}>
            <div className='page-title-box'>
              <h4 className='font-size-18'>Dashboard</h4>
              <div className='breadcrumb mb-0'>
                <h1 className='welcome-text'>
                  Welcome, <strong>{user && user.fullname}</strong>
                </h1>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xl={3} md={6}>
            <Card className='mini-stat bg-primary text-white'>
              <CardBody>
                <div className='mb-4'>
                  <div className='float-left mini-stat-img mr-4'>
                    <i
                      className='fa-light fa-file'
                      style={{ fontSize: 30 }}></i>
                  </div>
                  <h5 className='font-size-16 text-uppercase mt-0 text-white-50'>
                    Pages
                  </h5>
                  <h4 className='font-weight-medium font-size-24'>
                    {pages && pages.length}
                  </h4>
                </div>
                <div className='pt-2'>
                  <Link
                    to='/all-pages'
                    className='text-white-50 plitz-flex-align'>
                    <p className='text-white-50 mb-0 mt-1'>Go To Pages</p>
                    <div className='float-right'>
                      <i className='mdi mdi-arrow-right h5'></i>
                    </div>
                  </Link>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={3} md={6}>
            <Card className='mini-stat bg-primary text-white'>
              <CardBody>
                <div className='mb-4'>
                  <div className='float-left mini-stat-img mr-4'>
                    <i
                      className='fa-light fa-repeat'
                      style={{ fontSize: 30 }}></i>
                  </div>
                  <h5 className='font-size-16 text-uppercase mt-0 text-white-50'>
                    Sliders
                  </h5>
                  <h4 className='font-weight-medium font-size-24'>
                    {sliders && sliders.length}
                  </h4>
                </div>
                <div className='pt-2'>
                  <Link
                    to='/all-cta'
                    className='text-white-50 plitz-flex-align'>
                    <p className='text-white-50 mb-0 mt-1'>Go To Sliders</p>
                    <div className='float-right'>
                      <i className='mdi mdi-arrow-right h5'></i>
                    </div>
                  </Link>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={3} md={6}>
            <Card className='mini-stat bg-primary text-white'>
              <CardBody>
                <div className='mb-4'>
                  <div className='float-left mini-stat-img mr-4'>
                    <i
                      className='fa-light fa-message-quote'
                      style={{ fontSize: 30 }}></i>
                  </div>
                  <h5 className='font-size-16 text-uppercase mt-0 text-white-50'>
                    Testimonials
                  </h5>
                  <h4 className='font-weight-medium font-size-24'>
                    {testimonials && testimonials.length}
                  </h4>
                </div>
                <div className='pt-2'>
                  <Link
                    to='/all-testimonials'
                    className='text-white-50 plitz-flex-align'>
                    <p className='text-white-50 mb-0 mt-1'>Go To Tetimonials</p>
                    <div className='float-right'>
                      <i className='mdi mdi-arrow-right h5'></i>
                    </div>
                  </Link>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={3} md={6}>
            <Card className='mini-stat bg-primary text-white'>
              <CardBody>
                <div className='mb-4'>
                  <div className='float-left mini-stat-img mr-4'>
                    <i
                      className='fa-light fa-typewriter'
                      style={{ fontSize: 30 }}></i>
                  </div>
                  <h5 className='font-size-16 text-uppercase mt-0 text-white-50'>
                    Blogposts
                  </h5>
                  <h4 className='font-weight-medium font-size-24'>
                    {blogposts && blogposts.length}
                  </h4>
                </div>
                <div className='pt-2'>
                  <Link
                    to='/all-blogposts'
                    className='text-white-50 plitz-flex-align'>
                    <p className='text-white-50 mb-0 mt-1'>Go To Blogposts</p>
                    <div className='float-right'>
                      <i className='mdi mdi-arrow-right h5'></i>
                    </div>
                  </Link>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col xl={3}>
            {Pages.loading ||
            Blogposts.loading ||
            Sliders.loading ||
            Testimonials.loading ? (
              <Loader />
            ) : (
              <Card>
                <CardBody>
                  <h4 className='card-title mb-4'>Content Work Report</h4>
                  <div className='cleafix donut-title'>
                    <p className='float-left'>
                      <i className='mdi mdi-calendar mr-1 text-primary'></i>
                      <Moment format='MMM DD'>{firstDay}</Moment> -{' '}
                      <Moment format='MMM DD'>{lastDay}</Moment>
                    </p>
                    <h6 className='text-right'>Current Month</h6>
                  </div>
                  {monthPagesPer === 0 &&
                  monthBlogpostsPer === 0 &&
                  monthSlidersPer === 0 &&
                  monthTestimonialsPer === 0 ? (
                    <Fragment>
                      <div id='ct-donut' className='ct-chart wid pt-4'>
                        <h5 className='mb-2'>
                          There are no new content changes or additions this
                          month.
                        </h5>
                        <small>
                          Add at least new blog posts or review your pages and
                          sliders to keep your content fresh, relevant, and
                          engaging.
                        </small>
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div id='ct-donut' className='ct-chart wid pt-4'>
                        <Salesdonut
                          pages={monthPagesPer}
                          blogposts={monthBlogpostsPer}
                          sliders={monthSlidersPer}
                          testimonials={monthTestimonialsPer}
                        />
                      </div>
                      <div className='mt-4'>
                        <table className='table mb-0'>
                          <tbody>
                            <tr>
                              <td>
                                <span className='badge badge-primary pl-2 pr-2 pt-1'>
                                  {monthPages.length}
                                </span>
                              </td>
                              <td>Pages</td>
                              <td className='text-right'>
                                {parseFloat(monthPagesPer).toFixed(2)}%
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span className='badge badge-primary pl-2 pr-2 pt-1'>
                                  {monthSliders.length}
                                </span>
                              </td>
                              <td>Sliders</td>
                              <td className='text-right'>
                                {parseFloat(monthSlidersPer).toFixed(2)}%
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span className='badge badge-primary pl-2 pr-2 pt-1'>
                                  {monthTestimonials.length}
                                </span>
                              </td>
                              <td>Testimonials</td>
                              <td className='text-right'>
                                {parseFloat(monthTestimonialsPer).toFixed(2)}%
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span className='badge badge-primary pl-2 pr-2 pt-1'>
                                  {monthBlogposts.length}
                                </span>
                              </td>
                              <td>Blog Posts</td>
                              <td className='text-right'>
                                {parseFloat(monthBlogpostsPer).toFixed(2)}%
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Fragment>
                  )}
                </CardBody>
              </Card>
            )}
          </Col>
          <Col xl={4}>
            <Card>
              {Activity.loading ? (
                <Loader />
              ) : (
                <CardBody>
                  <h4 className='card-title mb-4'>Activity</h4>
                  <ol className='activity-feed'>
                    {activity &&
                      activity.length > 0 &&
                      activity
                        .sort(
                          (a, b) =>
                            new Date(b.createdAt) - new Date(a.createdAt)
                        )
                        .slice(0, 5)
                        .map((act) => (
                          <li key={act._id} className='feed-item'>
                            <div className='feed-item-list'>
                              <span className='date'>
                                <Moment format='MMMM DD, YY'>
                                  {act.createdAt}
                                </Moment>{' '}
                                {act.logtype === 'positive' ? (
                                  <i className='typcn typcn-tick text-success'></i>
                                ) : act.logtype === 'negative' ? (
                                  <i className='typcn typcn-times text-danger'></i>
                                ) : (
                                  <i className='typcn typcn-warning text-warning'></i>
                                )}
                              </span>
                              <span
                                className='activity-text'
                                dangerouslySetInnerHTML={{
                                  __html: act.logcontent,
                                }}></span>
                              <br />
                              <small className='activity-text'>
                                User: <strong>{act.fullname}</strong>
                              </small>
                            </div>
                          </li>
                        ))}
                  </ol>
                  <div className='text-center'>
                    <Link to='/activity' className='btn btn-primary'>
                      All Activity
                    </Link>
                  </div>
                </CardBody>
              )}
            </Card>
          </Col>

          <Col xl={5}>
            <Row>
              <Col md={6}>
                {Login.loading ? (
                  <Loader />
                ) : (
                  <Card className='text-center'>
                    <CardBody>
                      <div className='py-4'>
                        <div className='rounded-circle avatar-xl mb-2'>
                          <img
                            src={
                              user &&
                              `${process.env.REACT_APP_PUBLIC_URL}${user.photo}`
                            }
                            alt={user && user.fullname}
                            className='img-fluid actual-image'
                          />
                        </div>
                        <small className='mt-4'>You are logged-in as</small>
                        <h5 className='text-primary'>
                          {user && user.fullname}
                        </h5>
                        <p className='text-muted'>{user && user.email}</p>
                      </div>
                    </CardBody>
                  </Card>
                )}
              </Col>
              <Col md={6}>
                <Card className='bg-primary'>
                  <CardBody>
                    <div className='text-center py-4'>
                      <h5 className='mt-0 mb-2 text-white-50 font-size-16'>
                        CMS Overview
                      </h5>
                      <p className='font-size-14 pt-1 text-white'>
                        <strong>
                          <span style={{ color: '#707070' }}>&#123;</span>
                          <span style={{ color: '#cecece' }}>the</span>David
                          <span style={{ color: '#707070' }}>&#125;</span>{' '}
                          v2.1.1
                        </strong>
                      </p>
                      <ul className='font-size-14 pt-1 text-white list-unstyled'>
                        <li>Pages: {pages && pages.length}</li>
                        <li>Sliders: {sliders && sliders.length}</li>
                        <li>Blog Posts: {blogposts && blogposts.length}</li>
                        <li>
                          Testimonials: {testimonials && testimonials.length}
                        </li>
                      </ul>
                      <p className='mb-2 text-white'>By Plitz Corporation</p>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Card style={{ overflow: 'hidden' }}>
                  <CardBody style={{ padding: 0 }}>
                    {promosLoader ? (
                      <Loader />
                    ) : (
                      <PromosCar
                        plitzPromos={plitzPromos}
                        error={showPromosError}
                      />
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
