import React, { Fragment, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Spinner } from 'reactstrap';

const Salesdonut = ({ pages, blogposts, sliders, testimonials }) => {
	const [sets, setSeries] = useState([]);
	const [options, setOptions] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		var pg = pages ? Number(pages).toFixed(2) : 0;
		var sl = sliders ? Number(sliders).toFixed(2) : 0;
		var ts = testimonials ? Number(testimonials).toFixed(2) : 0;
		var bp = blogposts ? Number(blogposts).toFixed(2) : 0;
		setSeries([parseFloat(pg), parseFloat(sl), parseFloat(bp), parseFloat(ts)]);

		// eslint-disable-next-line
	}, [pages, blogposts, sliders, testimonials]);

	useEffect(() => {
		setOptions({
			dataLabels: {
				enabled: false,
			},
			legend: {
				show: false,
			},
			plotOptions: {
				pie: {
					donut: {
						size: '70%',
					},
				},
			},
			colors: [
				'#b80d2a',
				'#f8b425',
				'#38a4f8',
				'#e83e8c',
				// '#17861d',
			],
			series: [sets],
			labels: ['Pages', 'Sliders', 'Testimonials', 'Blog Posts'],
		});

		setLoading(false);
	}, [sets]);

	return loading && options === null ? (
		<Spinner />
	) : (
		<Fragment>
			<ReactApexChart
				options={options}
				series={sets}
				type='donut'
				height='220'
			/>
		</Fragment>
	);
};

export default Salesdonut;
