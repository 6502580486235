import {
  ADD_LOCATION_FAILED,
  ADD_LOCATION_GO,
  ADD_LOCATION_TRY,
  CLEAR_CURRENT,
  EDIT_LOCATION_FAILED,
  EDIT_LOCATION_GO,
  EDIT_LOCATION_TRY,
  GET_ALOCATION_FAILED,
  GET_ALOCATION_GO,
  GET_ALOCATION_TRY,
  GET_LOCATIONS_FAILED,
  GET_LOCATIONS_GO,
  GET_LOCATIONS_TRY,
  REMOVE_LOCATION_FAILED,
  REMOVE_LOCATION_GO,
  REMOVE_LOCATION_TRY,
} from './actionTypes';

export const getLocations = () => {
  return {
    type: GET_LOCATIONS_TRY,
  };
};

export const gettingLocations = (data) => {
  return {
    type: GET_LOCATIONS_GO,
    payload: data,
  };
};

export const getLocationsFailed = (error) => {
  return {
    type: GET_LOCATIONS_FAILED,
    payload: error,
  };
};

export const getLocation = (id) => {
  return {
    type: GET_ALOCATION_TRY,
    payload: id,
  };
};

export const gettingLocation = (data) => {
  return {
    type: GET_ALOCATION_GO,
    payload: data,
  };
};

export const getLocationFailed = (error) => {
  return {
    type: GET_ALOCATION_FAILED,
    payload: error,
  };
};

export const clearCurrentLoc = () => {
  return {
    type: CLEAR_CURRENT,
  };
};

export const addLocation = (formData) => {
  return {
    type: ADD_LOCATION_TRY,
    payload: { formData },
  };
};

export const locationAdded = (data) => {
  return {
    type: ADD_LOCATION_GO,
    payload: data,
  };
};

export const addLocationFailed = (error) => {
  return {
    type: ADD_LOCATION_FAILED,
    payload: error,
  };
};

export const editLocation = (formData) => {
  return {
    type: EDIT_LOCATION_TRY,
    payload: { formData },
  };
};

export const locationEdited = (data) => {
  return {
    type: EDIT_LOCATION_GO,
    payload: data,
  };
};

export const editLocationFailed = (error) => {
  return {
    type: EDIT_LOCATION_FAILED,
    payload: error,
  };
};

export const removeLocation = (id) => {
  return {
    type: REMOVE_LOCATION_TRY,
    payload: id,
  };
};

export const removingLocation = (data) => {
  return {
    type: REMOVE_LOCATION_GO,
    payload: data,
  };
};

export const removeLocationFailed = (error) => {
  return {
    type: REMOVE_LOCATION_FAILED,
    payload: error,
  };
};
