import React, { Fragment, useEffect, useState } from 'react';
// import SettingMenu from '../../Shared/SettingMenu';
import { Link } from 'react-router-dom';
import { Row, Col, Card } from 'reactstrap';
import axios from 'axios';
import Moment from 'react-moment';

// Component
import Loader from '../../../components/Loader';

const EmailInbox = () => {
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [toDelete, setToDelete] = useState([]);

  const storedId =
    typeof window !== 'undefined' && localStorage.getItem('tenant');

  const config = {
    headers: {
      storedId,
    },
  };

  const getEmails = async () => {
    await axios
      .get(`${process.env.REACT_APP_PUBLIC_URL}api/emails`, config)
      .then((res) => {
        setEmails(res.data);
        setLoading(false);
      });
  };

  useEffect(() => {
    getEmails();

    // eslint-disable-next-line
  }, []);

  const onChangeCb = (e) => {
    if (e.target.checked) {
      setToDelete((toDelete) => [...toDelete, e.target.value]);
    } else {
      const removeThis = toDelete.filter((el) => el !== e.target.value);
      setToDelete(removeThis);
    }
  };

  const deleteItems = (e) => {
    e.preventDefault();
    const setToken =
      typeof window !== 'undefined' && localStorage.getItem('token');

    const config = {
      headers: {
        Authorization: `Bearer ${setToken}`,
      },
    };
    toDelete.forEach(async (el) => {
      await axios
        .delete(`${process.env.REACT_APP_PUBLIC_URL}api/emails/${el}`, config)
        .then((res) => getEmails());
    });
    setToDelete([]);
  };

  return loading ? (
    <Loader />
  ) : (
    <Fragment>
      <div className='container-fluid'>
        <Row className='align-items-center'>
          <Col sm={12}>
            <div className='page-title-box'>
              <h4 className='font-size-18'>Inbox</h4>
              <ol className='breadcrumb mb-0'>
                <li className='breadcrumb-item'>
                  <Link to='/'>Dashboard</Link>
                </li>
                <li className='breadcrumb-item active'>Inbox</li>
              </ol>
            </div>
          </Col>
        </Row>

        <Row>
          <div className='col-12'>
            <div className='mb-3'>
              <Card>
                <div className='btn-toolbar p-3' role='toolbar'>
                  <div className='btn-group mo-mb-2'>
                    <button
                      type='button'
                      className='btn btn-primary waves-light waves-effect'
                      onClick={(e) => deleteItems(e)}>
                      <i className='far fa-trash-alt'></i>
                    </button>
                  </div>
                </div>

                <ul className='message-list'>
                  {emails
                    .sort(
                      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                    )
                    .map((ml) => (
                      <li key={ml._id}>
                        <div className='col-mail col-mail-1'>
                          <div className='checkbox-wrapper-mail'>
                            <input
                              type='checkbox'
                              id={`box-${ml._id}`}
                              value={ml._id}
                              onChange={(e) => onChangeCb(e)}
                            />{' '}
                            <label htmlFor={`box-${ml._id}`}></label>
                          </div>
                          <Link
                            to='/email-read/'
                            state={{
                              id: ml._id,
                              quantity: emails.length,
                            }}
                            className='title'>
                            {ml.fullname}
                          </Link>
                        </div>
                        <div className='col-mail col-mail-2'>
                          <Link
                            to='/email-read/'
                            state={{
                              id: ml._id,
                              quantity: emails.length,
                            }}
                            className='subject'>
                            {ml.clientemail} –
                            <span className='teaser'>{ml.message}</span>
                          </Link>
                          <div className='date'>
                            <Moment format='MMM DD, YY'>{ml.createdAt}</Moment>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </Card>
              {/* <Row className='mt-4'>
								<div className='col-7'>Showing 1 - 20 of {emails.length}</div>
								<div className='col-5'>
									<div className='btn-group float-right'>
										<button
											type='button'
											className='btn btn-sm btn-success waves-effect'
										>
											<i className='fa fa-chevron-left'></i>
										</button>
										<button
											type='button'
											className='btn btn-sm btn-success waves-effect'
										>
											<i className='fa fa-chevron-right'></i>
										</button>
									</div>
								</div>
							</Row> */}
            </div>
          </div>
        </Row>
      </div>
    </Fragment>
  );
};

export default EmailInbox;
