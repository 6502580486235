export const GET_BLOGPOSTS_TRY = 'GET_BLOGPOSTS_TRY';
export const GET_BLOGPOSTS_GO = 'GET_BLOGPOSTS_GO';
export const GET_BLOGPOSTS_FAILED = 'GET_BLOGPOSTS_FAILED';

export const GET_ABLOGPOST_TRY = 'GET_ABLOGPOST_TRY';
export const GET_ABLOGPOST_GO = 'GET_ABLOGPOST_GO';
export const GET_ABLOGPOST_FAILED = 'GET_ABLOGPOST_FAILED';

export const ADD_BLOGPOST_TRY = 'ADD_BLOGPOST_TRY';
export const ADD_BLOGPOST_GO = 'ADD_BLOGPOST_GO';
export const ADD_BLOGPOST_FAILED = 'ADD_BLOGPOST_FAILED';

export const EDIT_BLOGPOST_TRY = 'EDIT_BLOGPOST_TRY';
export const EDIT_BLOGPOST_GO = 'EDIT_BLOGPOST_GO';
export const EDIT_BLOGPOST_FAILED = 'EDIT_BLOGPOST_FAILED';

export const REMOVE_BLOGPOST_TRY = 'REMOVE_BLOGPOST_TRY';
export const REMOVE_BLOGPOST_GO = 'REMOVE_BLOGPOST_GO';
export const REMOVE_BLOGPOST_FAILED = 'REMOVE_BLOGPOST_FAILED';

export const CLEAR_CURRENT = 'CLEAR_CURRENT';
