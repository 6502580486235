import React from 'react';
import { Modal } from 'reactstrap';

const EditorModal = ({ isOpen, toggle, children }) => {
  return (
    <Modal
      className='modal-lg'
      style={{ minWidth: '90%' }}
      isOpen={isOpen}
      toggle={toggle}>
      <div className='modal-header'>
        <h5 className='modal-title mt-0' id='myModalLabel'>
          Edition Box
        </h5>
        <button
          type='button'
          onClick={toggle}
          className='close'
          data-dismiss='modal'
          aria-label='Close'>
          <span aria-hidden='true'>&times;</span>
        </button>
      </div>
      <div className='modal-body'>{children}</div>
    </Modal>
  );
};

export default EditorModal;
