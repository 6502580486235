import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
	ADD_SLIDER_FAILED,
	ADD_SLIDER_GO,
	ADD_SLIDER_TRY,
	EDIT_SLIDER_FAILED,
	EDIT_SLIDER_GO,
	EDIT_SLIDER_TRY,
	GET_ASLIDER_FAILED,
	GET_ASLIDER_GO,
	GET_ASLIDER_TRY,
	GET_SLIDERS_FAILED,
	GET_SLIDERS_GO,
	GET_SLIDERS_TRY,
	REMOVE_SLIDER_FAILED,
	REMOVE_SLIDER_GO,
	REMOVE_SLIDER_TRY,
} from './actionTypes';

import {
	addSlider,
	editSlider,
	getAllSlidersApi,
	getSliderApi,
	removeSlider,
} from '../helpers/sliderApi';

function* theSlidersPull() {
	try {
		const response = yield call(getAllSlidersApi);

		yield put({ type: GET_SLIDERS_GO, payload: response.data });
	} catch (error) {
		yield put({
			type: GET_SLIDERS_FAILED,
			payload: error.response.data.message,
		});
	}
}

function* oneSliderPull({ payload }) {
	try {
		const response = yield call(getSliderApi, payload);

		yield put({ type: GET_ASLIDER_GO, payload: response.data });
	} catch (error) {
		yield put({
			type: GET_ASLIDER_FAILED,
			payload: error.response.data.message,
		});
	}
}

function* addingSlider({ payload: { formData } }) {
	try {
		const response = yield call(addSlider, formData);

		yield put({ type: ADD_SLIDER_GO, payload: response.data.slider });
	} catch (error) {
		yield put({
			type: ADD_SLIDER_FAILED,
			payload: error.response.data.message,
		});
	}
}

function* editingSlider({ payload: { formData } }) {
	try {
		const response = yield call(editSlider, formData);

		yield put({ type: EDIT_SLIDER_GO, payload: response.data });
	} catch (error) {
		yield put({
			type: EDIT_SLIDER_FAILED,
			payload: error.response.data.message,
		});
	}
}

function* removingSlider({ payload: id }) {
	try {
		const response = yield call(removeSlider, id);

		yield put({ type: REMOVE_SLIDER_GO, payload: response.data });
	} catch (error) {
		yield put({
			type: REMOVE_SLIDER_FAILED,
			payload: error.response.data.message,
		});
	}
}

function* pullingSliders() {
	yield takeLatest(GET_SLIDERS_TRY, theSlidersPull);
}

function* pullingSlider() {
	yield takeLatest(GET_ASLIDER_TRY, oneSliderPull);
}

function* addSliderTry() {
	yield takeLatest(ADD_SLIDER_TRY, addingSlider);
}

function* editingSliderTry() {
	yield takeLatest(EDIT_SLIDER_TRY, editingSlider);
}

function* removingSliderTry() {
	yield takeLatest(REMOVE_SLIDER_TRY, removingSlider);
}

function* SlidersSaga() {
	yield all([
		pullingSliders(),
		addSliderTry(),
		editingSliderTry(),
		removingSliderTry(),
		pullingSlider(),
	]);
}

export default SlidersSaga;
