export const GET_MENUITEMS_TRY = 'GET_MENUITEMS_TRY';
export const GET_MENUITEMS_GO = 'GET_MENUITEMS_GO';
export const GET_MENUITEMS_FAILED = 'GET_MENUITEMS_FAILED';

export const GET_AMENUITEM_TRY = 'GET_AMENUITEM_TRY';
export const GET_AMENUITEM_GO = 'GET_AMENUITEM_GO';
export const GET_AMENUITEM_FAILED = 'GET_AMENUITEM_FAILED';

export const ADD_MENUITEM_TRY = 'ADD_MENUITEM_TRY';
export const ADD_MENUITEM_GO = 'ADD_MENUITEM_GO';
export const ADD_MENUITEM_FAILED = 'ADD_MENUITEM_FAILED';

export const EDIT_MENUITEM_TRY = 'EDIT_MENUITEM_TRY';
export const EDIT_MENUITEM_GO = 'EDIT_MENUITEM_GO';
export const EDIT_MENUITEM_FAILED = 'EDIT_MENUITEM_FAILED';

export const REMOVE_MENUITEM_TRY = 'REMOVE_MENUITEM_TRY';
export const REMOVE_MENUITEM_GO = 'REMOVE_MENUITEM_GO';
export const REMOVE_MENUITEM_FAILED = 'REMOVE_MENUITEM_FAILED';

export const CLEAR_CURRENT = 'CLEAR_CURRENT';
