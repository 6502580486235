import {
	ADD_TESTIMONIAL_FAILED,
	ADD_TESTIMONIAL_GO,
	ADD_TESTIMONIAL_TRY,
	CLEAR_CURRENT,
	EDIT_TESTIMONIAL_FAILED,
	EDIT_TESTIMONIAL_GO,
	EDIT_TESTIMONIAL_TRY,
	GET_TESTIMONIALS_FAILED,
	GET_TESTIMONIALS_GO,
	GET_TESTIMONIALS_TRY,
	GET_ATESTIMONIAL_TRY,
	GET_ATESTIMONIAL_GO,
	GET_ATESTIMONIAL_FAILED,
	REMOVE_TESTIMONIAL_FAILED,
	REMOVE_TESTIMONIAL_GO,
	REMOVE_TESTIMONIAL_TRY,
} from './actionTypes';

export const getTestimonials = () => {
	return {
		type: GET_TESTIMONIALS_TRY,
	};
};

export const gettingTestimonials = (data) => {
	return {
		type: GET_TESTIMONIALS_GO,
		payload: data,
	};
};

export const getTestimonialsFailed = (error) => {
	return {
		type: GET_TESTIMONIALS_FAILED,
		payload: error,
	};
};

export const getTestimonial = (id) => {
	return {
		type: GET_ATESTIMONIAL_TRY,
		payload: id,
	};
};

export const gettingTestimonial = (data) => {
	return {
		type: GET_ATESTIMONIAL_GO,
		payload: data,
	};
};

export const getTestimonialFailed = (error) => {
	return {
		type: GET_ATESTIMONIAL_FAILED,
		payload: error,
	};
};

export const clearCurrentTs = () => {
	return {
		type: CLEAR_CURRENT,
	};
};

export const addTestimonial = (formData) => {
	return {
		type: ADD_TESTIMONIAL_TRY,
		payload: { formData },
	};
};

export const testimonialAdded = (data) => {
	return {
		type: ADD_TESTIMONIAL_GO,
		payload: data,
	};
};

export const addTestimonialFailed = (error) => {
	return {
		type: ADD_TESTIMONIAL_FAILED,
		payload: error,
	};
};

export const editTestimonial = (formData) => {
	return {
		type: EDIT_TESTIMONIAL_TRY,
		payload: { formData },
	};
};

export const testimonialEdited = (data) => {
	return {
		type: EDIT_TESTIMONIAL_GO,
		payload: data,
	};
};

export const editTestimonialFailed = (error) => {
	return {
		type: EDIT_TESTIMONIAL_FAILED,
		payload: error,
	};
};

export const removeTestimonial = (id) => {
	return {
		type: REMOVE_TESTIMONIAL_TRY,
		payload: id,
	};
};

export const removingTestimonial = (data) => {
	return {
		type: REMOVE_TESTIMONIAL_GO,
		payload: data,
	};
};

export const removeTestimonialFailed = (error) => {
	return {
		type: REMOVE_TESTIMONIAL_FAILED,
		payload: error,
	};
};
