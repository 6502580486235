import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
	ADD_TESTIMONIAL_FAILED,
	ADD_TESTIMONIAL_GO,
	ADD_TESTIMONIAL_TRY,
	EDIT_TESTIMONIAL_FAILED,
	EDIT_TESTIMONIAL_GO,
	EDIT_TESTIMONIAL_TRY,
	GET_ATESTIMONIAL_FAILED,
	GET_ATESTIMONIAL_GO,
	GET_ATESTIMONIAL_TRY,
	GET_TESTIMONIALS_FAILED,
	GET_TESTIMONIALS_GO,
	GET_TESTIMONIALS_TRY,
	REMOVE_TESTIMONIAL_FAILED,
	REMOVE_TESTIMONIAL_GO,
	REMOVE_TESTIMONIAL_TRY,
} from './actionTypes';

import {
	addTestimonial,
	editTestimonial,
	getAllTestimonialsApi,
	getTestimonialApi,
	removeTestimonial,
} from '../helpers/testimonialApi';

function* theTestimonialsPull() {
	try {
		const response = yield call(getAllTestimonialsApi);

		yield put({ type: GET_TESTIMONIALS_GO, payload: response.data });
	} catch (error) {
		yield put({
			type: GET_TESTIMONIALS_FAILED,
			payload: error.response.data.message,
		});
	}
}

function* oneTestimonialPull({ payload }) {
	try {
		const response = yield call(getTestimonialApi, payload);

		yield put({ type: GET_ATESTIMONIAL_GO, payload: response.data });
	} catch (error) {
		yield put({
			type: GET_ATESTIMONIAL_FAILED,
			payload: error.response.data.message,
		});
	}
}

function* addingTestimonial({ payload: { formData } }) {
	try {
		const response = yield call(addTestimonial, formData);

		yield put({ type: ADD_TESTIMONIAL_GO, payload: response.data.testimonial });
	} catch (error) {
		yield put({
			type: ADD_TESTIMONIAL_FAILED,
			payload: error.response.data.message,
		});
	}
}

function* editingTestimonial({ payload: { formData } }) {
	try {
		const response = yield call(editTestimonial, formData);

		yield put({ type: EDIT_TESTIMONIAL_GO, payload: response.data });
	} catch (error) {
		yield put({
			type: EDIT_TESTIMONIAL_FAILED,
			payload: error.response.data.message,
		});
	}
}

function* removingTestimonial({ payload: id }) {
	try {
		const response = yield call(removeTestimonial, id);

		yield put({ type: REMOVE_TESTIMONIAL_GO, payload: response.data });
	} catch (error) {
		yield put({
			type: REMOVE_TESTIMONIAL_FAILED,
			payload: error.response.data.message,
		});
	}
}

function* pullingTestimonials() {
	yield takeLatest(GET_TESTIMONIALS_TRY, theTestimonialsPull);
}

function* pullingTestimonial() {
	yield takeLatest(GET_ATESTIMONIAL_TRY, oneTestimonialPull);
}

function* addTestimonialTry() {
	yield takeLatest(ADD_TESTIMONIAL_TRY, addingTestimonial);
}

function* editingTestimonialTry() {
	yield takeLatest(EDIT_TESTIMONIAL_TRY, editingTestimonial);
}

function* removingTestimonialTry() {
	yield takeLatest(REMOVE_TESTIMONIAL_TRY, removingTestimonial);
}

function* TestimonialsSaga() {
	yield all([
		pullingTestimonials(),
		addTestimonialTry(),
		editingTestimonialTry(),
		removingTestimonialTry(),
		pullingTestimonial(),
	]);
}

export default TestimonialsSaga;
