import {
  ADD_MENUCATEGORY_FAILED,
  ADD_MENUCATEGORY_GO,
  ADD_MENUCATEGORY_TRY,
  CLEAR_CURRENT,
  EDIT_MENUCATEGORY_FAILED,
  EDIT_MENUCATEGORY_GO,
  EDIT_MENUCATEGORY_TRY,
  GET_AMENUCATEGORY_FAILED,
  GET_AMENUCATEGORY_GO,
  GET_AMENUCATEGORY_TRY,
  GET_MENUCATEGORIES_FAILED,
  GET_MENUCATEGORIES_GO,
  GET_MENUCATEGORIES_TRY,
  REMOVE_MENUCATEGORY_FAILED,
  REMOVE_MENUCATEGORY_GO,
  REMOVE_MENUCATEGORY_TRY,
} from './actionTypes';

export const getMenuCategories = () => {
  return {
    type: GET_MENUCATEGORIES_TRY,
  };
};

export const gettingMenuCategories = (data) => {
  return {
    type: GET_MENUCATEGORIES_GO,
    payload: data,
  };
};

export const getMenuCategoriesFailed = (error) => {
  return {
    type: GET_MENUCATEGORIES_FAILED,
    payload: error,
  };
};

export const getMenuCategory = (id) => {
  return {
    type: GET_AMENUCATEGORY_TRY,
    payload: id,
  };
};

export const gettingMenuCategory = (data) => {
  return {
    type: GET_AMENUCATEGORY_GO,
    payload: data,
  };
};

export const getMenuCategoryFailed = (error) => {
  return {
    type: GET_AMENUCATEGORY_FAILED,
    payload: error,
  };
};

export const clearCurrentMenuCat = () => {
  return {
    type: CLEAR_CURRENT,
  };
};

export const addMenuCategory = (formData) => {
  return {
    type: ADD_MENUCATEGORY_TRY,
    payload: { formData },
  };
};

export const menuCategoryAdded = (data) => {
  return {
    type: ADD_MENUCATEGORY_GO,
    payload: data,
  };
};

export const addMenuCategoryFailed = (error) => {
  return {
    type: ADD_MENUCATEGORY_FAILED,
    payload: error,
  };
};

export const editMenuCategory = (formData) => {
  return {
    type: EDIT_MENUCATEGORY_TRY,
    payload: { formData },
  };
};

export const menuCategoryEdited = (data) => {
  return {
    type: EDIT_MENUCATEGORY_GO,
    payload: data,
  };
};

export const editMenuCategoryFailed = (error) => {
  return {
    type: EDIT_MENUCATEGORY_FAILED,
    payload: error,
  };
};

export const removeMenuCategory = (id) => {
  return {
    type: REMOVE_MENUCATEGORY_TRY,
    payload: id,
  };
};

export const removingMenuCategory = (data) => {
  return {
    type: REMOVE_MENUCATEGORY_GO,
    payload: data,
  };
};

export const removeMenuCategoryFailed = (error) => {
  return {
    type: REMOVE_MENUCATEGORY_FAILED,
    payload: error,
  };
};
