import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Card, CardBody, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Parts
import Loader from '../../components/Loader';
import MyProfile from '../../components/Extras/MyProfile';
import { selfRemoveTry } from '@thedavid/plitzredux/actions';
import MyPassword from '../../components/Extras/MyPassword';

const UserSettings = () => {
	const dispatch = useDispatch();

	const Login = useSelector((state) => state.Login);
	const { user, error, loading, msg, token } = Login;

	const [showError, setShowError] = useState(false);
	const [showMsg, setShowMsg] = useState(false);

	useEffect(() => {
		if (showError) {
			setTimeout(() => {
				setShowError(false);
			}, 18000);
		}
	}, [showError]);

	useEffect(() => {
		if (msg && msg !== '') {
			setShowMsg(true);
			setTimeout(() => {
				setShowMsg(false);
			}, 18000);
		}
	}, [msg]);

	const deleteAccount = (e) => {
		e.preventDefault();
		dispatch(selfRemoveTry(user._id));
	};

	return loading ? (
		<Loader />
	) : (
		<div className='container-fluid plitz-forms'>
			<Row className='align-items-center'>
				<Col sm={12}>
					<div className='page-title-box'>
						<h1 className=''>User Settings</h1>

						<ol className='breadcrumb mb-3'>
							<li className='breadcrumb-item'>
								<Link to='/dashboard'>Dashboard</Link>
							</li>
							<li className='breadcrumb-item'>
								<Link to='/all-users'>All Users</Link>
							</li>
							<li className='breadcrumb-item'>My Settings</li>
						</ol>
						{showError && error && <Alert color='danger'>{error}</Alert>}
						{msg && showMsg && <Alert color='success'>{msg}</Alert>}
					</div>
				</Col>
			</Row>

			<Row className='justify-content-center mb-2'>
				<Col md={4}>
					<Card className='text-center'>
						<CardBody>
							<div className='py-4'>
								<div className='rounded-circle avatar-xl mb-2'>
									<img
										src={
											user &&
											user.photo &&
											`${process.env.REACT_APP_PUBLIC_URL}${user.photo}`
										}
										alt={user && user.fullname}
										className='img-fluid actual-image'
									/>
								</div>
								<small className='mt-4'>Your Info</small>
								<h5 className='text-primary'>{user && user.fullname}</h5>
								<p className='text-muted'>
									{user && user.isAdmin ? 'Administrator' : 'Editor'} |{' '}
									{user && user.verified ? 'Verified' : 'Not Active'}
								</p>

								<p className='mb-2'>{user && user.email}</p>
								<p>
									<strong>{user && user.position}</strong>
								</p>
								<p>{user && user.bio}</p>
								<ul
									className='list-unstyled social-links'
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'center',
										margin: 'auto',
										flexWrap: 'wrap',
									}}
								>
									{user && user.social && user.social.linkedin && (
										<li style={{ margin: '0 0.3rem' }}>
											<a
												href={
													user.social.linkedin.startsWith('https://')
														? user.social.linkedin
														: `https://${user && user.social.linkedin}`
												}
												target='_blank'
												className='btn-primary'
												rel='noopener noreferrer'
											>
												<i className='mdi mdi-linkedin'></i>
											</a>
										</li>
									)}
									{user && user.social && user.social.facebook && (
										<li style={{ margin: '0 0.3rem' }}>
											<a
												href={
													user.social.facebook.startsWith('https://')
														? user.social.facebook
														: `https://${user && user.social.facebook}`
												}
												target='_blank'
												className='btn-primary'
												rel='noopener noreferrer'
											>
												<i className='mdi mdi-facebook'></i>
											</a>
										</li>
									)}

									{user && user.social && user.social.twitter && (
										<li style={{ margin: '0 0.3rem' }}>
											<a
												href={
													user.social.twitter.startsWith('https://')
														? user.social.twitter
														: `https://${user && user.social.twitter}`
												}
												target='_blank'
												className='btn-primary'
												rel='noopener noreferrer'
											>
												<i className='mdi mdi-twitter'></i>
											</a>
										</li>
									)}
									{user && user.social && user.social.instagram && (
										<li style={{ margin: '0 0.3rem' }}>
											<a
												href={
													user.social.instagram.startsWith('https://')
														? user.social.instagram
														: `https://${user && user.social.instagram}`
												}
												target='_blank'
												className='btn-primary'
												rel='noopener noreferrer'
											>
												<i className='mdi mdi-instagram'></i>
											</a>
										</li>
									)}
								</ul>
								<button
									onClick={(e) => deleteAccount(e)}
									className='btn btn-secondary waves-effect mt-5'
								>
									Delete Account
								</button>
							</div>
						</CardBody>
					</Card>
				</Col>
				<Col md={8}>
					<h3>Update Profile</h3>
					<p>
						Hi {user && user.fullname}, use the form below to update your
						profile
					</p>

					{user && (
						<Fragment>
							<MyProfile
								id={user._id}
								user={user}
								loading={loading}
								error={error}
								showError={showError}
								setShowError={setShowError}
							/>
							<hr />
							<h3>Change Your Password</h3>
							<p>Use this section to change your password.</p>
							<MyPassword user={user} error={error} token={token} />
						</Fragment>
					)}
				</Col>
			</Row>
		</div>
	);
};

UserSettings.propTypes = {
	user: PropTypes.object,
};

export default UserSettings;
