import { combineReducers } from 'redux';

// Front
import Layout from './layout/reducer';
import Login from './authentication/reducer';
import Activity from './activity/reducer';
import Pages from './pages/reducer';
import Blogposts from './blogposts/reducer';
import Sliders from './sliders/reducer';
import Testimonials from './testimonials/reducer';
import Promos from './promos/reducer';
import Categories from './categories/reducer';
import Clients from './clients/reducer';
// - Restaurants
import Locations from './restaurants/locations/reducer';
import MenuItems from './restaurants/menuItem/reducer';
import MenuCategories from './restaurants/categories/reducer';
import Specials from './restaurants/specials/reducer';

const rootReducer = combineReducers({
  Login,
  Layout,
  Activity,
  Pages,
  Blogposts,
  Sliders,
  Testimonials,
  Promos,
  Categories,
  Clients,
  Locations,
  MenuItems,
  MenuCategories,
  Specials,
});

export default rootReducer;
