import { all, fork } from 'redux-saga/effects';

// Parts
import LoginSaga from './authentication/saga';
import ActivitySaga from './activity/saga';
import LayoutSaga from './layout/saga';
import PagesSaga from './pages/saga';
import BlogpostsSaga from './blogposts/saga';
import SlidersSaga from './sliders/saga';
import TestimonialsSaga from './testimonials/saga';
import PromosSaga from './promos/saga';
import CategoriesSaga from './categories/saga';
import ClientsSaga from './clients/saga';
// - Restaurants
import LocationsSaga from './restaurants/locations/saga';
import MenuItemsSaga from './restaurants/menuItem/saga';
import MenuCategoriesSaga from './restaurants/categories/saga';
import SpecialsSaga from './restaurants/specials/saga';

export default function* rootSaga() {
  yield all([
    fork(LoginSaga),
    fork(LayoutSaga),
    fork(ActivitySaga),
    fork(PagesSaga),
    fork(BlogpostsSaga),
    fork(SlidersSaga),
    fork(TestimonialsSaga),
    fork(PromosSaga),
    fork(CategoriesSaga),
    fork(ClientsSaga),
    fork(LocationsSaga),
    fork(MenuItemsSaga),
    fork(MenuCategoriesSaga),
    fork(SpecialsSaga),
  ]);
}
