import {
  ADD_MENUITEM_FAILED,
  ADD_MENUITEM_GO,
  ADD_MENUITEM_TRY,
  CLEAR_CURRENT,
  EDIT_MENUITEM_FAILED,
  EDIT_MENUITEM_GO,
  EDIT_MENUITEM_TRY,
  GET_AMENUITEM_FAILED,
  GET_AMENUITEM_GO,
  GET_AMENUITEM_TRY,
  GET_MENUITEMS_FAILED,
  GET_MENUITEMS_GO,
  GET_MENUITEMS_TRY,
  REMOVE_MENUITEM_FAILED,
  REMOVE_MENUITEM_GO,
  REMOVE_MENUITEM_TRY,
} from './actionTypes';

export const getMenuItems = () => {
  return {
    type: GET_MENUITEMS_TRY,
  };
};

export const gettingMenuItems = (data) => {
  return {
    type: GET_MENUITEMS_GO,
    payload: data,
  };
};

export const getMenuItemsFailed = (error) => {
  return {
    type: GET_MENUITEMS_FAILED,
    payload: error,
  };
};

export const getMenuItem = (id) => {
  return {
    type: GET_AMENUITEM_TRY,
    payload: id,
  };
};

export const gettingMenuItem = (data) => {
  return {
    type: GET_AMENUITEM_GO,
    payload: data,
  };
};

export const getMenuItemFailed = (error) => {
  return {
    type: GET_AMENUITEM_FAILED,
    payload: error,
  };
};

export const clearCurrentItem = () => {
  return {
    type: CLEAR_CURRENT,
  };
};

export const addMenuItem = (formData) => {
  return {
    type: ADD_MENUITEM_TRY,
    payload: { formData },
  };
};

export const menuItemAdded = (data) => {
  return {
    type: ADD_MENUITEM_GO,
    payload: data,
  };
};

export const addMenuItemFailed = (error) => {
  return {
    type: ADD_MENUITEM_FAILED,
    payload: error,
  };
};

export const editMenuItem = (formData) => {
  return {
    type: EDIT_MENUITEM_TRY,
    payload: { formData },
  };
};

export const menuItemEdited = (data) => {
  return {
    type: EDIT_MENUITEM_GO,
    payload: data,
  };
};

export const editMenuItemFailed = (error) => {
  return {
    type: EDIT_MENUITEM_FAILED,
    payload: error,
  };
};

export const removeMenuItem = (id) => {
  return {
    type: REMOVE_MENUITEM_TRY,
    payload: id,
  };
};

export const removingMenuItem = (data) => {
  return {
    type: REMOVE_MENUITEM_GO,
    payload: data,
  };
};

export const removeMenuItemFailed = (error) => {
  return {
    type: REMOVE_MENUITEM_FAILED,
    payload: error,
  };
};
