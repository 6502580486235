import {
  ADD_MENUITEM_FAILED,
  ADD_MENUITEM_GO,
  ADD_MENUITEM_TRY,
  CLEAR_CURRENT,
  EDIT_MENUITEM_FAILED,
  EDIT_MENUITEM_GO,
  EDIT_MENUITEM_TRY,
  GET_AMENUITEM_FAILED,
  GET_AMENUITEM_GO,
  GET_AMENUITEM_TRY,
  GET_MENUITEMS_FAILED,
  GET_MENUITEMS_GO,
  GET_MENUITEMS_TRY,
  REMOVE_MENUITEM_FAILED,
  REMOVE_MENUITEM_GO,
  REMOVE_MENUITEM_TRY,
} from './actionTypes';

const initialState = {
  menuItems: [],
  menuItem: null,
  loading: false,
  error: null,
  msg: null,
};

const MenuItems = (state = initialState, action) => {
  switch (action.type) {
    case GET_MENUITEMS_TRY:
    case GET_AMENUITEM_TRY:
    case ADD_MENUITEM_TRY:
    case EDIT_MENUITEM_TRY:
    case REMOVE_MENUITEM_TRY:
      return {
        ...state,
        loading: true,
      };

    case GET_MENUITEMS_GO:
      return {
        ...state,
        menuItems: action.payload,
        loading: false,
      };

    case ADD_MENUITEM_GO:
      return {
        ...state,
        menuItems: [action.payload, ...state.menuItems],
        loading: false,
      };

    case EDIT_MENUITEM_GO:
      return {
        ...state,
        menuItems: state.menuItems.map((menuItem) =>
          menuItem._id === action.payload._id ? action.payload : menuItem
        ),
        loading: false,
      };

    case GET_AMENUITEM_GO:
      return {
        ...state,
        menuItem: action.payload,
        loading: false,
      };

    case REMOVE_MENUITEM_GO:
      return {
        ...state,
        menuItems: state.menuItems.filter(
          (fld) => fld._id !== action.payload.id
        ),
        msg: action.payload.msg,
        loading: false,
      };

    case GET_MENUITEMS_FAILED:
    case GET_AMENUITEM_FAILED:
    case ADD_MENUITEM_FAILED:
    case EDIT_MENUITEM_FAILED:
    case REMOVE_MENUITEM_FAILED:
      return {
        ...state,
        menuItem: null,
        error: action.payload,
        loading: false,
      };

    case CLEAR_CURRENT:
      return {
        ...state,
        menuItem: null,
        loading: false,
      };

    default:
      return state;
  }
};

export default MenuItems;
