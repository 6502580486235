import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Card,
  CardBody,
  Col,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// Actions
import {
  deleteUserTry,
  postActivity,
  tryGetusers,
} from '@thedavid/plitzredux/actions';

// Parts
import UsersEditor from '../../components/Editors/UsersEditor';
import Loader from '../../components/Loader';

const AllUsers = () => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [chosenItem, setChosenitem] = useState(null);

  const Login = useSelector((state) => state.Login);
  const { users, user, loading, error } = Login;

  useEffect(() => {
    dispatch(tryGetusers());
  }, [dispatch]);

  const setToggle = (id) => {
    if (isOpen) {
      setChosenitem(null);
      setIsOpen(false);
    } else {
      setChosenitem(id);
      setIsOpen(true);
    }
  };

  const deleteUser = (usr) => {
    dispatch(
      postActivity({
        logtype: 'warning',
        logcontent: `The user <strong>${usr.fullname}</strong> has been removed`,
        email: user ? user.email : '',
      })
    );
    dispatch(deleteUserTry(usr._id));
  };

  return (
    <Fragment>
      {isOpen && (
        <UsersEditor
          isOpen={isOpen}
          toggle={setToggle}
          chosenItem={chosenItem}
        />
      )}

      <div className='container-fluid plitz-cards'>
        <Row className='align-items-center'>
          <Col sm={6}>
            <div className='page-title-box'>
              <h1 className=''>User Profiles</h1>
              <ol className='breadcrumb mb-0'>
                <li className='breadcrumb-item'>
                  <Link to='/dashboard'>Dashboard</Link>
                </li>
                <li className='breadcrumb-item'>All Users</li>
              </ol>
            </div>
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            {error ? <Alert color='danger'>{error}</Alert> : null}
          </Col>
        </Row>

        <Row className='justify-content-center'>
          <div
            style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
            <i className='mdi mdi-clipboard-outline text-danger ml-2'></i>{' '}
            <span className='font-size-10 ml-2'>
              * <strong>Editors</strong> can add, edit and remove blog posts
              only
            </span>
          </div>
          {loading ? (
            <Loader />
          ) : (
            users &&
            users.length > 0 &&
            users
              .sort((a, b) =>
                new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1
              )
              .map((usr) => (
                <Col key={usr._id} sm={12} md={8} lg={8} xl={5}>
                  <Card className='directory-card'>
                    <CardBody>
                      <div className='d-flex'>
                        <div className='rounded-circle avatar-lg'>
                          <img
                            src={`${process.env.REACT_APP_PUBLIC_URL}${usr.photo}`}
                            alt={usr.fullname}
                            className='img-fluid actual-image'
                          />
                        </div>
                        <div className='flex-1 ms-3'>
                          <h5 className='text-primary font-size-18 mt-0 mb-1'>
                            {usr.fullname}{' '}
                            {user && usr._id === user._id && (
                              <i className='mdi mdi-account-check text-success'></i>
                            )}
                          </h5>
                          {usr.position && (
                            <p className='font-size-12 mb-2'>{usr.position}</p>
                          )}
                          <p className='mb-0 small'>{usr.email}</p>
                        </div>
                        <ul className='list-unstyled social-links ms-auto'>
                          {usr.social && usr.social.linkedin && (
                            <li>
                              <a
                                href={
                                  usr.social.linkedin.startsWith('https://')
                                    ? usr.social.linkedin
                                    : `https://${usr.social.linkedin}`
                                }
                                target='_blank'
                                className='btn-primary'
                                rel='noopener noreferrer'>
                                <i className='mdi mdi-linkedin'></i>
                              </a>
                            </li>
                          )}
                          {usr.social && usr.social.facebook && (
                            <li>
                              <a
                                href={
                                  usr.social.facebook.startsWith('https://')
                                    ? usr.social.facebook
                                    : `https://${usr.social.facebook}`
                                }
                                target='_blank'
                                className='btn-primary'
                                rel='noopener noreferrer'>
                                <i className='mdi mdi-facebook'></i>
                              </a>
                            </li>
                          )}
                        </ul>
                        <ul className='list-unstyled social-links ml-1'>
                          {usr.social && usr.social.twitter && (
                            <li>
                              <a
                                href={
                                  usr.social.twitter.startsWith('https://')
                                    ? usr.social.twitter
                                    : `https://${usr.social.twitter}`
                                }
                                target='_blank'
                                className='btn-primary'
                                rel='noopener noreferrer'>
                                <i className='mdi mdi-twitter'></i>
                              </a>
                            </li>
                          )}
                          {usr.social && usr.social.instagram && (
                            <li>
                              <a
                                href={
                                  usr.social.instagram.startsWith('https://')
                                    ? usr.social.instagram
                                    : `https://${usr.social.instagram}`
                                }
                                target='_blank'
                                className='btn-primary'
                                rel='noopener noreferrer'>
                                <i className='mdi mdi-instagram'></i>
                              </a>
                            </li>
                          )}
                        </ul>
                      </div>
                      <hr />
                      <div
                        className='mb-1 mt-0'
                        style={{ display: 'flex', alignItems: 'center' }}>
                        <Fragment>
                          <UncontrolledTooltip
                            placement='top'
                            target={`EditTt-${usr._id}`}>
                            Edit
                          </UncontrolledTooltip>
                          {user && usr._id === user._id ? (
                            <Link
                              to='/update-profile'
                              id={`EditTt-${usr._id}`}
                              style={{ fontSize: 20, padding: 0 }}
                              className='btn waves-effect text-primary waves-light'>
                              <i className='fa-light fa-edit'></i>
                            </Link>
                          ) : (
                            <button
                              id={`EditTt-${usr._id}`}
                              type='button'
                              onClick={() => setToggle(usr._id)}
                              className='btn waves-effect text-primary waves-light'
                              data-toggle='modal'
                              data-target='#myModal'
                              style={{ fontSize: 20, padding: 0 }}>
                              <i className='fa-light fa-edit'></i>
                            </button>
                          )}
                        </Fragment>
                        {user && usr._id !== user._id ? (
                          <Fragment>
                            <UncontrolledTooltip
                              placement='top'
                              target={`RemoveFt-${usr._id}`}>
                              Remove
                            </UncontrolledTooltip>
                            <button
                              id={`RemoveFt-${usr._id}`}
                              type='button'
                              onClick={() => deleteUser(usr)}
                              className='btn waves-effect ml-2 text-danger waves-light'
                              style={{ fontSize: 20, padding: 0 }}>
                              <i className='fa-light fa-trash'></i>
                            </button>{' '}
                          </Fragment>
                        ) : null}
                        {usr.verified ? (
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}>
                            <i className='mdi mdi-check text-success ml-2'></i>{' '}
                            <span className='font-size-10'>Verified</span>
                          </div>
                        ) : (
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}>
                            <i className='mdi mdi-close text-danger ml-2'></i>{' '}
                            <span className='font-size-10'>Not Verified</span>
                          </div>
                        )}
                        {usr.isAdmin ? (
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}>
                            <i className='mdi mdi-clipboard-multiple-outline text-success ml-2'></i>{' '}
                            <span className='font-size-10'>Admin</span>
                          </div>
                        ) : (
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}>
                            <i className='mdi mdi-clipboard-outline text-danger ml-2'></i>{' '}
                            <span className='font-size-10'>Editor</span>
                          </div>
                        )}
                      </div>
                      <div className='mb-0'>
                        {usr.bio && <Fragment>{usr.bio}</Fragment>}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))
          )}
        </Row>
      </div>
    </Fragment>
  );
};

AllUsers.propTypes = {
  users: PropTypes.array,
};

export default AllUsers;
