import {
  CLIENT_LOADED,
  AUTH_ERROR_CLIENT,
  REQUEST_CLIENT,
  REGISTER_TRY_CLIENT,
  REGISTERING_CLIENT,
  REGISTER_FAILED_CLIENT,
  UPDATE_TRY_CLIENT,
  UPDATE_GO_CLIENT,
  UPDATE_FAILED_CLIENT,
  GET_CLIENTS,
  GETTING_CLIENTS,
  GETTING_FAIL_CLIENT,
  ADDCLIENT_TRY,
  ADDCLIENT_GO,
  ADDCLIENT_FAILED,
  CALLCLIENT_TRY,
  CALLCLIENT_GO,
  CALLCLIENT_FAILED,
  EDITCLIENT_TRY,
  EDITCLIENT_GO,
  EDITCLIENT_FAILED,
  REMOVE_CLIENT_TRY,
  REMOVE_CLIENT_GO,
  REMOVE_CLIENT_FAILED,
  SELF_REMOVE_TRY_CLIENT,
  SELF_REMOVE_GO_CLIENT,
  SELF_REMOVE_FAILED_CLIENT,
} from './actionTypes';

export const tryGetclients = () => {
  return {
    type: GET_CLIENTS,
  };
};

export const goGetclient = (data) => {
  return {
    type: GETTING_CLIENTS,
    payload: data,
  };
};

export const gettingClientFailed = (error) => {
  return {
    type: GETTING_FAIL_CLIENT,
    payload: error,
  };
};

export const registerClient = (formData) => {
  return {
    type: REGISTER_TRY_CLIENT,
    payload: { formData },
  };
};

export const registeringClient = (data) => {
  return {
    type: REGISTERING_CLIENT,
    payload: data,
  };
};

export const requestClient = () => {
  return {
    type: REQUEST_CLIENT,
  };
};

export const loadClient = (client) => {
  return {
    type: CLIENT_LOADED,
    payload: client,
  };
};

export const registeringClientFail = (error) => {
  return {
    type: REGISTER_FAILED_CLIENT,
    payload: error,
  };
};

export const clientFail = (error) => {
  return {
    type: AUTH_ERROR_CLIENT,
    payload: error,
  };
};

export const tryClientUpdate = (formData) => {
  return {
    type: UPDATE_TRY_CLIENT,
    payload: { formData },
  };
};

export const clientUpdate = (client) => {
  return {
    type: UPDATE_GO_CLIENT,
    payload: client,
  };
};

export const updateClientFailed = (error) => {
  return {
    type: UPDATE_FAILED_CLIENT,
    payload: error,
  };
};

export const addClientTry = (formData) => {
  return {
    type: ADDCLIENT_TRY,
    payload: { formData },
  };
};

export const addClientGo = (data) => {
  return {
    type: ADDCLIENT_GO,
    payload: data,
  };
};

export const addClientFailed = (error) => {
  return {
    type: ADDCLIENT_FAILED,
    payload: error,
  };
};

export const callClientTry = (id) => {
  return {
    type: CALLCLIENT_TRY,
    payload: id,
  };
};

export const callClientGo = (data) => {
  return {
    type: CALLCLIENT_GO,
    payload: data,
  };
};

export const callClientFailed = (error) => {
  return {
    type: CALLCLIENT_FAILED,
    payload: error,
  };
};

export const editClientTry = (formData, id) => {
  return {
    type: EDITCLIENT_TRY,
    payload: { formData, id },
  };
};

export const editClientGo = (data) => {
  return {
    type: EDITCLIENT_GO,
    payload: data,
  };
};

export const editClientFailed = (error) => {
  return {
    type: EDITCLIENT_FAILED,
    payload: error,
  };
};

export const deleteClientTry = (id) => {
  return {
    type: REMOVE_CLIENT_TRY,
    payload: id,
  };
};

export const deleteClientGo = (data) => {
  return {
    type: REMOVE_CLIENT_GO,
    payload: data,
  };
};

export const deleteClientFailed = (error) => {
  return {
    type: REMOVE_CLIENT_FAILED,
    payload: error,
  };
};

export const selfRemoveClientTry = (id) => {
  return {
    type: SELF_REMOVE_TRY_CLIENT,
    payload: id,
  };
};

export const selfRemoveClientGo = (data) => {
  return {
    type: SELF_REMOVE_GO_CLIENT,
    payload: data,
  };
};

export const selfRemoveClientFailed = (error) => {
  return {
    type: SELF_REMOVE_FAILED_CLIENT,
    payload: error,
  };
};
