import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Row, Col, Card, CardBody } from 'reactstrap';
import axios from 'axios';

// Component
import Loader from '../../../components/Loader';

const EmailRead = () => {
  const locationInfo = useLocation();
  const history = useNavigate();

  const [email, setEmail] = useState({});
  const [loading, setLoading] = useState(true);

  const storedId =
    typeof window !== 'undefined' && localStorage.getItem('tenant');

  const config = {
    headers: {
      storedId,
    },
  };

  useEffect(() => {
    const getEmails = async () => {
      await axios
        .get(
          `${process.env.REACT_APP_PUBLIC_URL}api/emails/${locationInfo.state.id}`,
          config
        )
        .then((res) => {
          setEmail(res.data);
          setLoading(false);
        });
    };

    getEmails();

    // eslint-disable-next-line
  }, []);

  const deleteItems = async (e, theId) => {
    e.preventDefault();
    const setToken =
      typeof window !== 'undefined' && localStorage.getItem('token');

    const config = {
      headers: {
        Authorization: `Bearer ${setToken}`,
      },
    };

    await axios
      .delete(`${process.env.REACT_APP_PUBLIC_URL}api/emails/${theId}`, config)
      .then((res) => history('/email-inbox'));
  };

  return (
    <Fragment>
      <div className='container-fluid email'>
        <Row className='align-items-center'>
          <Col sm={12}>
            <div className='page-title-box'>
              <h4 className='font-size-18'>Email Read</h4>
              <ol className='breadcrumb mb-0'>
                <li className='breadcrumb-item'>
                  <Link to='/'>Dashboard</Link>
                </li>
                <li className='breadcrumb-item'>
                  <Link to='/email-inbox'>Inbox</Link>
                </li>
                <li className='breadcrumb-item active'>Email Read</li>
              </ol>
            </div>
          </Col>
        </Row>

        <Row>
          <div className='col-12'>
            <div className='mb-3'>
              <Card>
                <div className='btn-toolbar p-3' role='toolbar'>
                  <Link to='/email-inbox' className='active ml-2'>
                    Inbox{' '}
                    <span className='ml-1'>
                      ({locationInfo.state && locationInfo.state.quantity})
                    </span>
                  </Link>
                  <div className='btn-group mo-mb-2'>
                    <button
                      type='button'
                      className='btn btn-primary waves-light waves-effect'
                      onClick={(e) => deleteItems(e, locationInfo.state.id)}>
                      <i className='far fa-trash-alt'></i>
                    </button>
                  </div>
                </div>
                <hr />
                {loading ? (
                  <Loader />
                ) : (
                  <CardBody>
                    <div className='media mb-4'>
                      <div className='media-body'>
                        <h4 className='font-size-15 m-0'>
                          {email && email.fullname}
                        </h4>
                        <a href={`mailto:${email && email.clientemail}`}>
                          {email.clientemail} |{' '}
                        </a>
                        <a
                          href={`tel:${
                            email && email.phone.replace(/[^0-9,.]+/g, '')
                          }`}>
                          {email.phone}
                        </a>
                      </div>
                    </div>

                    <p>{email.message}</p>

                    <hr />

                    <a
                      href={`mailto:${email.clientemail}`}
                      className='btn btn-secondary waves-effect mt-4'>
                      <i className='mdi mdi-reply'></i> Reply
                    </a>
                  </CardBody>
                )}
              </Card>
            </div>
          </div>
        </Row>
      </div>
    </Fragment>
  );
};

export default EmailRead;
