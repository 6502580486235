import React, { useEffect, useState, Fragment } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  UncontrolledTooltip,
  Alert,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import Paginator from 'react-hooks-paginator';

// Parts
import AddMenuItem from './AddMenuItem';
import EditorModal from '../../../components/Editors/EditorModal';
import Loader from '../../../components/Loader';

// Actions
import {
  clearCurrentItem,
  getMenuItems,
  postActivity,
  removeMenuItem,
} from '@thedavid/plitzredux/actions';

const AllMenuItems = () => {
  const dispatch = useDispatch();

  const menuItemLimit = 10;

  const [offset, setOffset] = useState(0);
  const [currentMenuItem, setCurrentMenuItem] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState('');
  const [showMess, setShowMess] = useState(false);

  const MenuItems = useSelector((state) => state.MenuItems);
  const { menuItems, loading, error, msg } = MenuItems;
  const Login = useSelector((state) => state.Login);
  const { user } = Login;

  useEffect(() => {
    setCurrentData(
      menuItems &&
        menuItems
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .slice(offset, offset + menuItemLimit)
    );
  }, [offset, menuItems]);

  const setToggle = (_id) => {
    if (isOpen) {
      setIsOpen(false);
      setCurrentItem('');
      dispatch(clearCurrentItem());
    } else {
      setIsOpen(true);
      setCurrentItem(_id);
    }
  };

  const deleteMenuItem = () => {
    setShowMess(true);
    dispatch(
      postActivity({
        logtype: 'warning',
        logcontent: `The Menu Item <strong>${item.title}</strong> has been removed`,
        email: user ? user.email : '',
      })
    );
    dispatch(removeMenuItem(item._id));
    setTimeout(() => {
      setShowMess(false);
    }, 7200);
  };

  useEffect(() => {
    dispatch(getMenuItems());
  }, [dispatch]);

  return (
    <Fragment>
      {isOpen && (
        <EditorModal isOpen={isOpen} toggle={setToggle}>
          <AddMenuItem toggle={setToggle} currentItem={currentItem} />
        </EditorModal>
      )}
      <div className='container-fluid plitz-cards'>
        <Row className='align-items-center'>
          <Col sm={6}>
            <div className='page-title-box'>
              <h1 className=''>Menu</h1>
              <ol className='breadcrumb mb-0'>
                <li className='breadcrumb-item'>
                  <Link to='/dashboard'>Dashboard</Link>
                </li>
                <li className='breadcrumb-item'>All Menu Items</li>
              </ol>
            </div>
          </Col>
        </Row>

        {showMess && (
          <Row>
            <Col sm={12}>
              {error ? <Alert color='danger'>{error}</Alert> : null}
              {msg ? <Alert color='warning'>{msg}</Alert> : null}
            </Col>
          </Row>
        )}

        <Row>
          {loading ? (
            <Loader />
          ) : currentData && currentData.length > 0 ? (
            currentData
              .sort((a, b) => b.position - a.position)
              .map((item) => (
                <Col key={item._id} md={12}>
                  <Card>
                    <Row>
                      <Col sm={12} md={3}>
                        <div
                          className='card-img-top'
                          style={{ maxHeight: 250 }}>
                          <img
                            className='img-fluid actual-image'
                            src={
                              item.featuredImg
                                ? `${process.env.REACT_APP_PUBLIC_URL}${item.featuredImg.fullSize}`
                                : `${process.env.REACT_APP_PUBLIC_URL}uploads/big-placeholder.jpg`
                            }
                            alt={item.title}
                          />
                        </div>
                      </Col>
                      <Col sm={12} md={9}>
                        <CardBody>
                          <div className='card-container'>
                            <div className='card-status-area'>
                              {item.isLive ? (
                                <span className='badge badge-success'>
                                  Published
                                </span>
                              ) : (
                                <span className='badge badge-dark'>Draft</span>
                              )}
                            </div>
                            <div className='card-content-area'>
                              <h3 className='mt-0'>{item.title}</h3>
                              <div className='card-extra-info'>
                                {item.subtitle && (
                                  <h5 className='card-title  mt-0'>
                                    Alternative Title: {item.subtitle}
                                  </h5>
                                )}
                                <div className='author-section'>
                                  <small>Last Modified by</small>
                                  <div className='flex-horizontal'>
                                    <div className='avatar-sm rounded-circle mr-2'>
                                      <img
                                        src={`${process.env.REACT_APP_PUBLIC_URL}${item.user.photo}`}
                                        alt={item.user.fullname}
                                        className='actual-image'
                                      />{' '}
                                    </div>
                                    <span>{item.user.fullname}</span>
                                  </div>
                                </div>
                                <div className='categories'>
                                  {item.categories &&
                                    item.categories.map((cat, index) => (
                                      <span
                                        key={index}
                                        className='badge badge-cat badge-pill badge-info'>
                                        {cat.categoryTitle}
                                      </span>
                                    ))}
                                </div>
                              </div>
                            </div>
                            <div className='card-actions-area'>
                              <div className='buttons-only'>
                                <UncontrolledTooltip
                                  placement='top'
                                  target={`EditTt-${item._id}`}>
                                  Edit
                                </UncontrolledTooltip>
                                <button
                                  id={`EditTt-${item._id}`}
                                  type='button'
                                  onClick={() => setToggle(item._id)}
                                  className='btn waves-effect text-primary waves-light'
                                  data-toggle='modal'
                                  data-target='#myModal'
                                  style={{
                                    fontSize: 20,
                                    padding: '0.1rem',
                                    margin: '0.5rem',
                                  }}>
                                  <i className='fa-light fa-edit'></i>
                                </button>{' '}
                                <UncontrolledTooltip
                                  placement='top'
                                  target={`RemoveFt-${item._id}`}>
                                  Remove
                                </UncontrolledTooltip>
                                <button
                                  id={`RemoveFt-${item._id}`}
                                  type='button'
                                  onClick={() => deleteMenuItem(bp)}
                                  className='btn waves-effect ml-2 text-danger waves-light'
                                  style={{
                                    fontSize: 20,
                                    padding: '0.1rem',
                                    margin: '0.5rem',
                                  }}>
                                  <i className='fa-light fa-trash'></i>
                                </button>{' '}
                              </div>
                              <span>
                                Last Modification:{' '}
                                <Moment format='MMMM DD, YYYY'>
                                  {item.updatedAt}
                                </Moment>
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))
          ) : (
            <h4>There is no menu items yet</h4>
          )}
        </Row>

        <Row>
          <Col sm={12}>
            {menuItems && (
              <Paginator
                totalRecords={menuItems.length}
                pageLimit={menuItemLimit}
                pageNeighbours={2}
                setOffset={setOffset}
                currentPage={currentMenuItem}
                setCurrentPage={setCurrentMenuItem}
                pageContainerClass='pagination'
                pageActiveClass='current-page'
                pageNextText={<i className='typcn typcn-chevron-right'></i>}
                pageNextClass='pagination-arrow'
                pagePrevText={<i className='typcn typcn-chevron-left'></i>}
                pagePrevClass='pagination-arrow'
              />
            )}
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default AllMenuItems;
