import { takeLatest, put, call, all, takeEvery } from 'redux-saga/effects';

import {
  ADDCLIENT_FAILED,
  ADDCLIENT_GO,
  ADDCLIENT_TRY,
  AUTH_ERROR_CLIENT,
  CALLCLIENT_FAILED,
  CALLCLIENT_GO,
  CALLCLIENT_TRY,
  CLIENT_LOADED,
  EDITCLIENT_FAILED,
  EDITCLIENT_GO,
  EDITCLIENT_TRY,
  GETTING_CLIENTS,
  GETTING_FAIL_CLIENT,
  GET_CLIENTS,
  REGISTERING_CLIENT,
  REGISTER_FAILED_CLIENT,
  REGISTER_TRY_CLIENT,
  REMOVE_CLIENT_FAILED,
  REMOVE_CLIENT_GO,
  REMOVE_CLIENT_TRY,
  REQUEST_CLIENT,
  SELF_REMOVE_FAILED_CLIENT,
  SELF_REMOVE_GO_CLIENT,
  SELF_REMOVE_TRY_CLIENT,
  UPDATE_GO_CLIENT,
  UPDATE_TRY_CLIENT,
} from './actionTypes';

import {
  getClientApi,
  getClientsApi,
  registerClientApi,
  updateClientApi,
  createClientApi,
  callClientApi,
  editClientApi,
  deleteClientApi,
} from '../helpers/clientApi';

function* theGettingClients() {
  try {
    const response = yield call(getClientsApi);

    yield put({ type: GETTING_CLIENTS, payload: response.data });
  } catch (error) {
    yield put({
      type: GETTING_FAIL_CLIENT,
      payload: error.response.data.message,
    });
  }
}

function* theLoadingClient() {
  try {
    const response = yield call(getClientApi);
    yield put({ type: CLIENT_LOADED, payload: response.data });
  } catch (error) {
    yield put({
      type: AUTH_ERROR_CLIENT,
      payload: error.response.data.message,
    });
  }
}

function* theRegisteringClient({ payload: { formData } }) {
  try {
    const response = yield call(registerClientApi, formData);

    yield put({ type: REGISTERING_CLIENT, payload: response });
  } catch (error) {
    yield put({
      type: REGISTER_FAILED_CLIENT,
      payload: error.response.data.message,
    });
  }
}

function* theUpdatingClient({ payload: { formData } }) {
  try {
    const response = yield call(updateClientApi, formData);

    yield put({ type: UPDATE_GO_CLIENT, payload: response.data });
  } catch (error) {
    yield put({
      type: UPDATE_FAILED_UPDATE_GO_CLIENT,
      payload: error.response.data.message,
    });
  }
}

function* theAddingClient({ payload: { formData } }) {
  try {
    const response = yield call(createClientApi, formData);

    yield put({ type: ADDCLIENT_GO, payload: response.data });
  } catch (error) {
    yield put({ type: ADDCLIENT_FAILED, payload: error.response.data.message });
  }
}

function* theCallingClient({ payload: id }) {
  try {
    const response = yield call(callClientApi, id);

    yield put({ type: CALLCLIENT_GO, payload: response.data });
  } catch (error) {
    yield put({
      type: CALLCLIENT_FAILED,
      payload: error.response.data.message,
    });
  }
}

function* theEditingClient({ payload }) {
  try {
    const response = yield call(editClientApi, payload);

    yield put({ type: EDITCLIENT_GO, payload: response.data });
  } catch (error) {
    yield put({
      type: EDITCLIENT_FAILED,
      payload: error.response.data.message,
    });
  }
}

function* theDeletingClient({ payload: id }) {
  try {
    const response = yield call(deleteClientApi, id);

    yield put({ type: REMOVE_CLIENT_GO, payload: response.data });
  } catch (error) {
    yield put({
      type: REMOVE_CLIENT_FAILED,
      payload: error.response.data.message,
    });
  }
}

function* theSelfDelete({ payload: id }) {
  try {
    if (
      window.confirm(
        'Are you sure you want to delete your account? This cannot be undone.'
      )
    ) {
      const response = yield call(deleteClientApi, id);

      yield put({ type: SELF_REMOVE_GO_CLIENT, payload: response.data });
    } else {
      yield put({
        type: SELF_REMOVE_FAILED_CLIENT,
        payload: 'Action cancelled by client',
      });
    }
  } catch (error) {
    yield put({
      type: SELF_REMOVE_FAILED_CLIENT,
      payload: error.response.data.message,
    });
  }
}

function* theClientsGet() {
  yield takeLatest(GET_CLIENTS, theGettingClients);
}

function* theLoadedClient() {
  yield takeEvery(REQUEST_CLIENT, theLoadingClient);
}

function* theRegisteredClient() {
  yield takeLatest(REGISTER_TRY_CLIENT, theRegisteringClient);
}

function* theUpdate() {
  yield takeLatest(UPDATE_TRY_CLIENT, theUpdatingClient);
}

function* theAddingTry() {
  yield takeLatest(ADDCLIENT_TRY, theAddingClient);
}

function* theCallingTry() {
  yield takeLatest(CALLCLIENT_TRY, theCallingClient);
}

function* theEditingTry() {
  yield takeLatest(EDITCLIENT_TRY, theEditingClient);
}

function* theDeletingTry() {
  yield takeLatest(REMOVE_CLIENT_TRY, theDeletingClient);
}

function* theSelfDeleting() {
  yield takeLatest(SELF_REMOVE_TRY_CLIENT, theSelfDelete);
}

function* ClientsSaga() {
  yield all([
    theClientsGet(),
    theLoadedClient(),
    theRegisteredClient(),
    theUpdate(),
    theAddingTry(),
    theCallingTry(),
    theEditingTry(),
    theDeletingTry(),
    theSelfDeleting(),
  ]);
}

export default ClientsSaga;
