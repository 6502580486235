import axios from 'axios';

const rooturl = `${
  process.env.REACT_APP_PUBLIC_URL || process.env.NEXT_PUBLIC_URL
}api/promos`;

export const getAllPromosApi = async () => {
  const storedId =
    typeof window !== 'undefined' && localStorage.getItem('tenant');

  const config = {
    headers: {
      storedId,
    },
  };

  const response = await axios.get(rooturl, config);

  return response;
};

export const getPromoApi = async (id) => {
  const storedId =
    typeof window !== 'undefined' && localStorage.getItem('tenant');

  const config = {
    headers: {
      storedId,
    },
  };
  const response = await axios.get(`${rooturl}/${id}`, config);

  return response;
};

export const addPromo = async (formData) => {
  const setToken =
    typeof window !== 'undefined' && localStorage.getItem('token');
  const storedId =
    typeof window !== 'undefined' && localStorage.getItem('tenant');

  const confuno = {
    headers: {
      Authorization: `Bearer ${setToken}`,
      'Content-Type': 'application/json',
      storedId,
    },
  };

  const response = await axios.post(rooturl, formData, confuno);

  return response;
};

export const editPromo = async (formData) => {
  const setToken =
    typeof window !== 'undefined' && localStorage.getItem('token');
  const storedId =
    typeof window !== 'undefined' && localStorage.getItem('tenant');

  const confdos = {
    headers: {
      Authorization: `Bearer ${setToken}`,
      'Content-Type': 'application/json',
      storedId,
    },
  };

  const response = await axios.put(rooturl, formData, confdos);

  return response;
};

export const removePromo = async (id) => {
  const setToken =
    typeof window !== 'undefined' && localStorage.getItem('token');

  const storedId =
    typeof window !== 'undefined' && localStorage.getItem('tenant');

  const conftres = {
    headers: {
      Authorization: `Bearer ${setToken}`,
      storedId,
    },
  };

  const response = await axios.delete(`${rooturl}/${id}`, conftres);

  return response;
};
