import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Row, Col, Button, Input, Card, CardBody, Alert } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Editor } from 'react-draft-wysiwyg';
import Editable from 'react-bootstrap-editable';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import Switch from 'react-switch';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import {
  addLocation,
  clearCurrentLoc,
  editLocation,
  getLocation,
  postActivity,
} from '@thedavid/plitzredux/actions';

// Parts
import Loader from '../../../components/Loader';

const AddLocation = ({ currentItem, toggle }) => {
  const currentClient = localStorage.getItem('tenant');

  const dispatch = useDispatch();
  const history = useNavigate();
  const [editorState, setEditorState] = useState(null);
  const [formData, setFormData] = useState({
    isLive: false,
    position: 0,
    locationName: '',
    seoTitle: '',
    link: 'placeholder-format-updated-while-typing-title',
    address: '',
    phones: [],
    schedule: '',
    featuredImg: {
      fullSize: 'uploads/big-placeholder.jpg',
      othersizes: {
        tablet: 'uploads/big-placeholder.jpg',
        mobile: 'uploads/big-placeholder.jpg',
      },
    },
    gallery: [],
  });
  const [phoneNumber, setPhoneNumber] = useState({
    phone: '',
    primary: false,
  });
  const [selectedFeatured, setSelectedFeatured] = useState([]);
  const [showError, setShowError] = useState(false);
  const [filesErrorFi, setFilesErrorFi] = useState('');
  const [showErrorFi, setShowErrorFi] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [switchHtml, setSwitchHtml] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  // Gallery states
  const [filesErrorGal, setFilesErrorGal] = useState('');
  const [showErrorGal, setShowErrorGal] = useState(false);
  const [selectedFilesGal, setSelectedFilesGal] = useState([]);

  const {
    isLive,
    position,
    locationName,
    seoTitle,
    link,
    address,
    phones,
    schedule,
    featuredImg,
    gallery,
  } = formData;
  const { primary, phone } = phoneNumber;

  useEffect(() => {
    if (currentItem && currentItem !== undefined) {
      dispatch(getLocation(currentItem));
    }

    // eslint-disable-next-line
  }, [currentItem, dispatch]);

  const Locations = useSelector((state) => state.Locations);
  const { location, loading, error } = Locations;
  const Login = useSelector((state) => state.Login);
  const { user } = Login;

  useEffect(() => {
    if (location && location !== null) {
      if (location.schedule) {
        const contentBlock = htmlToDraft(location.schedule);
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        setEditorState(EditorState.createWithContent(contentState));
      }

      setFormData({
        _id: location._id ? location._id : null,
        isLive: location.isLive ? location.isLive : false,
        position: location.position ? location.position : 0,
        locationName: location.locationName ? location.locationName : '',
        seoTitle: location.seoTitle ? location.seoTitle : '',
        link: location.link ? location.link : '',
        address: location.address ? location.address : '',
        phones: location.phones.length > 0 ? location.phones : [],
        schedule: location.schedule ? location.schedule : '',
        featuredImg: location.featuredImg
          ? location.featuredImg
          : {
              fullSize: 'uploads/big-placeholder.jpg',
              othersizes: {
                tablet: 'uploads/big-placeholder.jpg',
                mobile: 'uploads/big-placeholder.jpg',
              },
            },
        gallery: location.gallery.length > 0 ? location.gallery : [],
      });
    }
  }, [location]);

  const switchForLive = () => {
    setFormData({
      ...formData,
      isLive: !isLive,
    });
  };

  const switchForPrimaryPhone = () => {
    setPhoneNumber({ ...phoneNumber, primary: !primary });
  };

  const switchHtmlButton = () => {
    if (switchHtml) {
      setSwitchHtml(false);
    } else {
      setSwitchHtml(true);
    }
  };

  const onChange = (e) => {
    e.preventDefault();

    if (e.target.name === 'locationName') {
      setFormData({
        ...formData,
        locationName: e.target.value,
        link: e.target.value
          .replace(/[^a-zA-Z0-9]/g, '-')
          .replace(/--/g, '')
          .replace(/-$/, '')
          .toLowerCase(),
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    if (submitted) {
      setSubmitted(false);
    }
  };

  const onChangeSlug = (value) => {
    setFormData({ ...formData, link: value });
  };

  const contentChange = (editorState) => {
    setEditorState(editorState);
    setFormData({
      ...formData,
      schedule: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
  };

  const onRawChange = (e) => {
    e.preventDefault();
    setFormData({
      ...formData,
      schedule: e.target.value,
    });
  };

  const handleAcceptedFilesFi = (file) => {
    if (file.length > 1) {
      setFilesErrorFi('Only one file is allowed');
      setShowErrorFi(true);
    } else {
      file.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      );
      setShowErrorFi(false);
      setSelectedFeatured(file);
    }
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  const handlePhoneChange = (e) => {
    e.preventDefault();
    setPhoneNumber({ ...phoneNumber, phone: e.target.value });
  };

  useEffect(() => {
    if (schedule === '' && !switchHtml) {
      setEditorState(EditorState.createEmpty());
    } else {
      const contentBlock = htmlToDraft(schedule);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      setEditorState(EditorState.createWithContent(contentState));
    }

    // eslint-disable-next-line
  }, [switchHtml]);

  useEffect(() => {
    if (selectedFeatured.length > 0) {
      const fd = new FormData();
      fd.append('newimg', selectedFeatured[0]);

      try {
        axios
          .post(`${process.env.REACT_APP_PUBLIC_URL}api/uploads`, fd, {
            headers: {
              'Content-Type': 'multipart/form-data',
              storedid: currentClient,
            },
          })
          .then((res) => {
            setFormData({
              ...formData,
              featuredImg: {
                fullSize: res.data.filePath,
                othersizes: {
                  tablet: res.data.tablet,
                  mobile: res.data.mobile,
                },
              },
            });
          })
          .catch((error) => {
            console.log(error);
            setFilesErrorFi('Error after uploading file');
            setShowErrorFi(true);
          });
      } catch (err) {
        if (err.response.status === 500) {
          setFilesErrorFi('There was a problem with the server');
          setShowErrorFi(true);
        } else {
          setFilesErrorFi(err.response.data.msg);
        }
      }
    }

    // eslint-disable-next-line
  }, [selectedFeatured]);

  const savePhoneNumber = (e) => {
    e.preventDefault();
    const phoneExists = formData.phones.forEach((phoneItem) => {
      if (phoneItem.phone.slice(-4) === phoneNumber.phone.slice(-4)) {
        return true;
      }
    });

    if (phone === '') {
      setShowError(true);
      setErrorMsg(`Phone Number can't be empty`);
    } else if (phoneExists) {
      setShowError(true);
      setErrorMsg(`It looks like this phone has been already added`);
    } else {
      setShowError(false);
      setErrorMsg('');
      setFormData({
        ...formData,
        phones: [...formData.phones, phoneNumber],
      });
      setPhoneNumber({
        primary: false,
        phone: '',
      });
    }
  };

  const editPhoneNumber = (el) => {
    setPhoneNumber(el);
    removeFromFormData(el);
  };

  const removeFromFormData = (el) => {
    const filtered = formData.phones.filter(
      (thisPhone) => thisPhone.phone !== el.phone
    );
    setFormData({ ...formData, phones: filtered });
  };

  const resetButton = (e) => {
    e.preventDefault();
    setPhoneNumber({
      primary: false,
      phone: '',
    });
  };

  // Gallery stuff
  const handleAcceptedFilesGal = (file) => {
    file.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setShowErrorGal(false);
    setSelectedFilesGal(file);
  };

  useEffect(() => {
    if (selectedFilesGal.length > 0) {
      const fd = new FormData();

      selectedFilesGal.forEach((el) => {
        fd.append('gallery', el);
      });

      try {
        axios
          .post(`${process.env.REACT_APP_PUBLIC_URL}api/uploads/multiple`, fd, {
            headers: {
              'Content-Type': 'multipart/form-data',
              storedid: currentClient,
            },
          })
          .then((res) => {
            setFormData({
              ...formData,
              gallery: [...formData.gallery, ...res.data],
            });
          })
          .catch((error) => {
            setFilesErrorGal(error.response.data.msg);
            setShowErrorGal(true);
          });
      } catch (err) {
        if (err.response.status === 500) {
          setFilesErrorGal('There was a problem with the server');
          setShowErrorGal(true);
        } else {
          setFilesErrorGal(err.response.data.msg);
        }
      }
    }

    // eslint-disable-next-line
  }, [selectedFilesGal]);

  const removeGalitem = (e, glritem) => {
    e.preventDefault();
    const filtered = gallery.filter((gl) => gl.fullSize !== glritem.fullSize);

    setFormData({
      ...formData,
      gallery: filtered,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (phone !== '') {
      setShowError(true);
      setErrorMsg(`Save phone number box before submitting new location`);
      return;
    }

    setSubmitted(true);

    if (currentItem && currentItem !== '') {
      try {
        dispatch(editLocation(formData));
        setTimeout(() => {
          afterSubmit();
        }, 2000);
      } catch (error) {
        dispatch(
          postActivity({
            logtype: 'negative',
            logcontent: `An error occurred <em>editing</em> location: <strong>${error}</strong>`,
            email: user ? user.email : '',
          })
        );
        setErrorMsg(error);
      }
    } else {
      try {
        dispatch(addLocation(formData));
        setTimeout(() => {
          afterSubmit();
        }, 2000);
      } catch (error) {
        dispatch(
          postActivity({
            logtype: 'negative',
            logcontent: `An error occurred adding location: <strong>${error}</strong>`,
            email: user ? user.email : '',
          })
        );
      }
    }
  };

  const afterSubmit = () => {
    dispatch(
      postActivity({
        logtype: 'positive',
        logcontent: currentItem
          ? `The location <strong>${formData.locationName}</strong> was edited successfully`
          : `The location <strong>${formData.locationName}</strong> was created`,
        email: user ? user.email : '',
      })
    );
    setFormData({
      isLive: false,
      position: 0,
      locationName: '',
      seoTitle: '',
      link: '',
      address: '',
      phones: [],
      schedule: '',
      featuredImg: {
        fullSize: 'uploads/big-placeholder.jpg',
        othersizes: {
          tablet: 'uploads/big-placeholder.jpg',
          mobile: 'uploads/big-placeholder.jpg',
        },
      },
      gallery: [],
    });

    setShowError(false);
    setShowErrorFi(false);
    setSelectedFeatured([]);
  };

  useEffect(() => {
    if (location !== null && submitted) {
      if (currentItem) {
        toggle(false);
        dispatch(clearCurrentLoc());
      } else {
        history('/all-locations');
      }

      setSubmitted(false);
    }
  }, [submitted, location]);

  return loading ? (
    <Loader />
  ) : (
    <Fragment>
      <div className='container-fluid plitz-forms'>
        <Row className='align-items-center'>
          <Col sm={6}>
            <div className='page-title-box'>
              <h1 className=''>
                {currentItem && currentItem !== '' ? 'Edit Item' : 'New Item'}
              </h1>
              <ol className='breadcrumb mb-0'>
                <li className='breadcrumb-item'>
                  <Link to='/dashboard'>Dashboard</Link>
                </li>
                <li className='breadcrumb-item'>
                  <Link to='/all-locations'>All Locations</Link>
                </li>
                <li className='breadcrumb-item'>Add Location</li>
              </ol>
            </div>
          </Col>
        </Row>

        <Row>
          {showError && (
            <div className='container-fluid'>
              <Row className='align-items-center'>
                <Col sm={12}>
                  <Alert color='warning'>{errorMsg}</Alert>
                </Col>
              </Row>
            </div>
          )}
          {error && (
            <div className='container-fluid'>
              <Row className='align-items-center'>
                <Col sm={12}>
                  <Alert color='danger'>
                    <p dangerouslySetInnerHTML={{ __html: error }}></p>
                  </Alert>
                </Col>
              </Row>
            </div>
          )}
        </Row>

        <Row>
          <Col sm={12} md={9}>
            <Editable
              alwaysEditing={false}
              disabled={false}
              initialValue={
                link
                  ? link
                  : locationName
                      .replace(/[^a-zA-Z0-9]/g, '-')
                      .replace(/--/g, '')
                      .replace(/-$/, '')
                      .toLowerCase()
              }
              name='link'
              value={link}
              id='link'
              isValueClickable={true}
              label={'Location Link (Click to edit)'}
              mode='inline'
              validate={(value) => {
                if (value === '') return "this value can't be empty";
              }}
              onSubmit={(value) => onChangeSlug(value)}
              onValidated={(value) => {
                return value;
              }}
              placement='top'
              showText
              type='textfield'
            />
          </Col>
        </Row>

        <form onSubmit={(e) => onSubmit(e)}>
          <Row>
            <Col sm={12} md={9}>
              <Row className='form-group'>
                <Col sm={12}>
                  <label htmlFor='locationName' className='col-form-label'>
                    Title
                  </label>
                  <input
                    className='form-control'
                    type='text'
                    placeholder='Location Name'
                    id='locationName'
                    name='locationName'
                    value={locationName}
                    onChange={(e) => onChange(e)}
                  />
                </Col>
              </Row>
              <Row className='form-group'>
                <Col sm={12} md={6}>
                  <label htmlFor='address' className='col-form-label'>
                    Location Full Address
                  </label>
                  <input
                    className='form-control'
                    type='text'
                    placeholder='Address'
                    id='address'
                    name='address'
                    value={address}
                    onChange={(e) => onChange(e)}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <label htmlFor='seoTitle' className='col-form-label'>
                    SEO Title
                  </label>
                  <input
                    className='form-control'
                    type='text'
                    placeholder='SEO title tag'
                    id='seoTitle'
                    name='seoTitle'
                    value={seoTitle}
                    onChange={(e) => onChange(e)}
                  />
                </Col>
              </Row>
              <Row className='form-group'>
                <Col sm='12' className='mt-3 mb-3'>
                  <span className='mt-1 mr-2'>HTML Mode</span>
                  <Switch
                    uncheckedIcon={<Offsymbol />}
                    checkedIcon={<OnSymbol />}
                    onColor='#0b093a'
                    onChange={switchHtmlButton}
                    checked={switchHtml}
                  />{' '}
                </Col>
                <Col sm='12'>
                  {switchHtml ? (
                    <Input
                      placeholder='HTML Mode has been enabled'
                      type='textarea'
                      id='schedule'
                      name='schedule'
                      value={schedule}
                      onChange={(e) => onRawChange(e)}
                      style={{ height: '100%' }}
                    />
                  ) : (
                    <Editor
                      toolbar={{
                        options: [
                          'inline',
                          'list',
                          'emoji',
                          'remove',
                          'history',
                        ],
                        inline: {
                          options: ['bold', 'italic'],
                          bold: { className: 'bordered-option-classname' },
                          italic: { className: 'bordered-option-classname' },
                        },

                        history: {
                          inDropdown: false,
                          options: ['undo', 'redo'],
                        },
                      }}
                      editorState={editorState}
                      toolbarClassName='toolbarClassName'
                      wrapperClassName='wrapperClassName'
                      onEditorStateChange={contentChange}
                    />
                  )}
                </Col>
              </Row>

              {/* Phone Numbers */}
              <Row className='form-group mt-5'>
                <Col sm='12'>
                  <div className='phones-secion'>
                    <h5>Phone Numbers</h5>
                    <p>You can add more than one</p>
                  </div>
                </Col>
              </Row>
              <Row className='form-group'>
                <Col sm='12'>
                  <Card>
                    <CardBody>
                      {showError ? (
                        <Alert color='warning'>{errorMsg}</Alert>
                      ) : null}
                      <Row data-repeater-item>
                        <Col lg='5' className='form-group'>
                          <small>Add phone number for this location:</small>
                          <Input
                            placeholder='(123) 456-7890'
                            type='text'
                            id='phone'
                            name='phone'
                            value={phone}
                            onChange={(e) => handlePhoneChange(e)}
                          />
                        </Col>

                        <Col lg='4' className='form-group align-self-center'>
                          <div className='mb-4 pb-4'>
                            <Switch
                              uncheckedIcon={<Offsymbol />}
                              checkedIcon={<OnSymbol />}
                              onColor='#0b093a'
                              onChange={switchForPrimaryPhone}
                              checked={primary}
                            />

                            <span className='ml-3'>
                              {primary ? 'Main Number' : 'Secondary'}
                            </span>
                          </div>
                        </Col>

                        <Col lg='3' className='form-group align-self-center'>
                          <Button
                            onClick={(e) => savePhoneNumber(e)}
                            color='success'
                            className='mt-3'
                            style={{
                              width: '100%',
                            }}>
                            {' '}
                            Save Item{' '}
                          </Button>
                          <Button
                            onClick={(e) => resetButton(e)}
                            color='primary'
                            className='mt-3'
                            style={{
                              width: '100%',
                            }}>
                            {' '}
                            Reset{' '}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  {phones.length > 0 &&
                    phones.map((el, index) => (
                      <Card key={index}>
                        <CardBody>
                          <Row>
                            <Col sm={12} md={6}>
                              <div className='flex-horizontal'>
                                <h5>{el.phone}</h5>
                                <span>
                                  | Style:{' '}
                                  {el.primary
                                    ? 'Primary Number'
                                    : 'Secondary Number'}
                                </span>
                              </div>
                            </Col>

                            <Col
                              md={3}
                              className='form-group align-self-center'>
                              <Button
                                onClick={() => editPhoneNumber(el)}
                                color='success'
                                className='mt-3'
                                style={{
                                  width: '100%',
                                }}>
                                {' '}
                                Edit
                              </Button>
                            </Col>
                            <Col
                              md={3}
                              className='form-group align-self-center'>
                              <Button
                                onClick={() => removeFromFormData(el)}
                                color='primary'
                                className='mt-3'
                                style={{
                                  width: '100%',
                                }}>
                                Remove Phone
                              </Button>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    ))}
                </Col>
              </Row>
            </Col>
            <Col sm={12} md={3} className='pl-5'>
              <Card style={{ position: 'relative', zIndex: 1 }}>
                <CardBody>
                  <h4 className='card-title mb-4'>Location Settings</h4>

                  <div className='mb-4 pb-4 setting-bottom-divider'>
                    <p className='card-title-desc'>Status</p>
                    <Switch
                      uncheckedIcon={<Offsymbol />}
                      checkedIcon={<OnSymbol />}
                      onColor='#0b093a'
                      onChange={switchForLive}
                      checked={isLive}
                    />

                    <span className='ml-3'>
                      {isLive ? 'Published' : 'Draft'}
                    </span>
                  </div>
                  <div className='mb-4 pb-4'>
                    <p>Location Order</p>
                    <small>
                      This will be used for the order on how the locations will
                      show in the front-end
                    </small>
                    <p></p>
                    <Input
                      placeholder={0}
                      type='number'
                      id='position'
                      name='position'
                      value={position}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <h4 className='card-title mb-4'>Featured Image</h4>

                  {showErrorFi ? (
                    <Alert color='warning'>{filesErrorFi}</Alert>
                  ) : null}
                  <Dropzone
                    onDrop={(acceptedFiles) =>
                      handleAcceptedFilesFi(acceptedFiles)
                    }>
                    {({ getRootProps, getInputProps }) => (
                      <div className='dropzone'>
                        <div
                          className='dz-message needsclick'
                          {...getRootProps()}>
                          <input
                            {...getInputProps()}
                            accept='image/png, image/gif, image/jpeg, image/jpg, image/webp'
                          />
                          <h5>
                            Drop file here or
                            <br />
                            click to upload.
                          </h5>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <div className='dropzone-previews mt-3' id='file-previews'>
                    {selectedFeatured.map((f, i) => {
                      return (
                        <Card
                          className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
                          key={i + '-file'}>
                          <div className='p-2'>
                            <Row className='align-items-center'>
                              <Col className='col-auto'>
                                <img
                                  data-dz-thumbnail=''
                                  height={80}
                                  width={'auto'}
                                  className='bg-light'
                                  alt={f.name}
                                  src={f.preview}
                                />
                              </Col>
                              <Col>
                                <Link
                                  to='#'
                                  className='text-muted font-weight-bold'>
                                  {f.name}
                                </Link>
                                <p className='mb-0'>
                                  <strong>{f.formattedSize}</strong>
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      );
                    })}
                    {selectedFeatured.length === 0 && featuredImg && (
                      <Row className='align-items-center'>
                        <Col className='col-auto'>
                          <img
                            height={80}
                            width={'auto'}
                            className='bg-light'
                            alt={locationName ? locationName : 'Placeholder'}
                            src={`${process.env.REACT_APP_PUBLIC_URL}${featuredImg.fullSize}`}
                          />
                        </Col>
                      </Row>
                    )}
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <br />
                  <button
                    type='submit'
                    className='btn btn-primary waves-effect waves-light'>
                    Save
                  </button>
                  <button
                    type='button'
                    onClick={(e) => {
                      e.preventDefault();
                      setEditorState(EditorState.createEmpty());
                      dispatch(clearCurrentLoc());
                      setFormData({
                        isLive: false,
                        position: 0,
                        locationName: '',
                        seoTitle: '',
                        link: '',
                        address: '',
                        phones: [],
                        schedule: '',
                        featuredImg: {
                          fullSize: 'uploads/big-placeholder.jpg',
                          othersizes: {
                            tablet: 'uploads/big-placeholder.jpg',
                            mobile: 'uploads/big-placeholder.jpg',
                          },
                        },
                        gallery: [],
                      });
                    }}
                    className='btn btn-danger ml-2 waves-effect waves-light'>
                    Reset
                  </button>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* Gallery Section */}
          <Row className='form-group mt-5'>
            <Col sm='12'>
              <div className='extraboxes-secion'>
                <h4>Image Gallery</h4>
              </div>
            </Col>
          </Row>
          <Row className='form-group'>
            <Col sm='12'>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs='12' sm='12' md='6' className='form-group'>
                      {filesErrorGal && showErrorGal !== '' ? (
                        <Alert color='warning'>{filesErrorGal}</Alert>
                      ) : null}
                      <Dropzone
                        onDrop={(acceptedFilesGal) =>
                          handleAcceptedFilesGal(acceptedFilesGal)
                        }>
                        {({ getRootProps, getInputProps }) => (
                          <div className='dropzone'>
                            <div
                              className='dz-message needsclick'
                              {...getRootProps()}>
                              <input
                                {...getInputProps()}
                                accept='image/png, image/gif, image/jpeg, image/jpg, image/webp'
                              />
                              <h5>
                                Drop file here or
                                <br />
                                click to upload.
                              </h5>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </Col>

                    <Col xs='12' sm='12' md='6' className='form-group'>
                      <div
                        className='dropzone-previews mt-3'
                        id='file-previews'>
                        <small>Uploading Box Results</small>
                        {selectedFilesGal.map((glr, i) => {
                          return (
                            <Card
                              className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
                              key={i + '-file'}>
                              <div className='p-2'>
                                <Row className='align-items-center'>
                                  <Col className='col-auto'>
                                    <img
                                      data-dz-thumbnail=''
                                      height='80'
                                      className='avatar-sm rounded bg-light'
                                      alt={glr.name}
                                      src={glr.preview}
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to='#'
                                      className='text-muted font-weight-bold'>
                                      {glr.name}
                                    </Link>
                                    <p className='mb-0'>
                                      <strong>{glr.formattedSize}</strong>
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          );
                        })}
                        <div className='setting-bottom-divider'></div>
                        <Row className='align-items-center'>
                          <Col xs='12' sm='6' md='4'>
                            <small>Uploaded Files</small>
                          </Col>
                        </Row>

                        <Row className='align-items-center'>
                          {gallery.length > 0 &&
                            gallery.map((glritem, index) => (
                              <Col key={index} xs='6' sm='6' md='4'>
                                <button
                                  id={`${glritem._id}`}
                                  onClick={(e) => removeGalitem(e, glritem)}
                                  className='btn waves-effect text-primary waves-light remove-gal'>
                                  <div className='remove-image'>
                                    <i className='ti-trash'></i>
                                  </div>
                                  <div className='remove-overlay'>
                                    Click To Remove
                                  </div>
                                  <img
                                    width='100%'
                                    height='auto'
                                    className='bg-light'
                                    alt={`preview gal`}
                                    src={`${process.env.REACT_APP_PUBLIC_URL}${glritem.fullSize}`}
                                  />
                                </button>
                              </Col>
                            ))}
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
      </div>
    </Fragment>
  );
};

const Offsymbol = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: 12,
        color: '#fff',
        paddingRight: 2,
        paddingTop: 3,
      }}>
      {' '}
      <small>OFF</small>
    </div>
  );
};

const OnSymbol = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: 12,
        color: '#fff',
        paddingRight: 2,
        paddingTop: 3,
      }}>
      {' '}
      <small>ON</small>
    </div>
  );
};

export default AddLocation;
