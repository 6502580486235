import {
	ADD_PROMO_FAILED,
	ADD_PROMO_GO,
	ADD_PROMO_TRY,
	CLEAR_CURRENT,
	EDIT_PROMO_FAILED,
	EDIT_PROMO_GO,
	EDIT_PROMO_TRY,
	GET_APROMO_FAILED,
	GET_APROMO_GO,
	GET_APROMO_TRY,
	GET_PROMOS_FAILED,
	GET_PROMOS_GO,
	GET_PROMOS_TRY,
	REMOVE_PROMO_FAILED,
	REMOVE_PROMO_GO,
	REMOVE_PROMO_TRY,
} from './actionTypes';

const initialState = {
	promos: [],
	promo: null,
	loading: false,
	error: null,
	msg: null,
};

const Promos = (state = initialState, action) => {
	switch (action.type) {
		case GET_PROMOS_TRY:
		case GET_APROMO_TRY:
		case ADD_PROMO_TRY:
		case EDIT_PROMO_TRY:
		case REMOVE_PROMO_TRY:
			return {
				...state,
				error: null,
				loading: true,
			};

		case GET_PROMOS_GO:
			return {
				...state,
				promos: action.payload,
				loading: false,
			};

		case ADD_PROMO_GO:
			return {
				...state,
				error: null,
				promos:
					state.promos.length === 0
						? [action.payload]
						: [action.payload, ...state.promos],
				loading: false,
			};

		case EDIT_PROMO_GO:
			return {
				...state,
				promos: state.promos.map((promo) =>
					promo._id === action.payload._id ? action.payload : promo
				),
				loading: false,
			};

		case GET_APROMO_GO:
			return {
				...state,
				promo: action.payload,
				loading: false,
			};

		case REMOVE_PROMO_GO:
			return {
				...state,
				promos: state.promos.filter((fld) => fld._id !== action.payload.id),
				error: null,
				msg: action.payload.msg,
				loading: false,
			};

		case GET_PROMOS_FAILED:
		case GET_APROMO_FAILED:
		case ADD_PROMO_FAILED:
		case EDIT_PROMO_FAILED:
		case REMOVE_PROMO_FAILED:
			return {
				...state,
				promo: null,
				error: action.payload,
				loading: false,
			};

		case CLEAR_CURRENT:
			return {
				...state,
				promo: null,
				error: null,
				loading: false,
			};

		default:
			return state;
	}
};

export default Promos;
