export const GET_SLIDERS_TRY = 'GET_SLIDERS_TRY';
export const GET_SLIDERS_GO = 'GET_SLIDERS_GO';
export const GET_SLIDERS_FAILED = 'GET_SLIDERS_FAILED';

export const GET_ASLIDER_TRY = 'GET_ASLIDER_TRY';
export const GET_ASLIDER_GO = 'GET_ASLIDER_GO';
export const GET_ASLIDER_FAILED = 'GET_ASLIDER_FAILED';

export const ADD_SLIDER_TRY = 'ADD_SLIDER_TRY';
export const ADD_SLIDER_GO = 'ADD_SLIDER_GO';
export const ADD_SLIDER_FAILED = 'ADD_SLIDER_FAILED';

export const EDIT_SLIDER_TRY = 'EDIT_SLIDER_TRY';
export const EDIT_SLIDER_GO = 'EDIT_SLIDER_GO';
export const EDIT_SLIDER_FAILED = 'EDIT_SLIDER_FAILED';

export const REMOVE_SLIDER_TRY = 'REMOVE_SLIDER_TRY';
export const REMOVE_SLIDER_GO = 'REMOVE_SLIDER_GO';
export const REMOVE_SLIDER_FAILED = 'REMOVE_SLIDER_FAILED';

export const CLEAR_CURRENT = 'CLEAR_CURRENT';
