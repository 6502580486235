import React from 'react';
import { Modal } from 'reactstrap';

// Parts
import AddClient from '../../pages/Clients/AddClient';

const UsersEditor = ({ isOpen, toggle, chosenItem }) => {
  return (
    <Modal className='modal-md' isOpen={isOpen} toggle={toggle}>
      <div className='modal-header'>
        <h5 className='modal-title mt-0' id='myModalLabel'>
          Edition Box
        </h5>
        <button
          type='button'
          onClick={toggle}
          className='close'
          data-dismiss='modal'
          aria-label='Close'>
          <span aria-hidden='true'>&times;</span>
        </button>
      </div>
      <div className='modal-body'>
        <AddClient id={chosenItem} toggle={toggle} />
      </div>
    </Modal>
  );
};

export default UsersEditor;
