import axios from 'axios';

const url = `${
  process.env.REACT_APP_PUBLIC_URL || process.env.NEXT_PUBLIC_URL
}api/users`;

export const getUsersApi = async () => {
  const setToken =
    typeof window !== 'undefined' && localStorage.getItem('token');
  const storedId =
    typeof window !== 'undefined' && localStorage.getItem('tenant');

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${setToken}`,
      storedId,
    },
  };

  const response = await axios.get(`${url}`, config);

  return response;
};

export const loginUserApi = async (formData) => {
  const storedId =
    typeof window !== 'undefined' && localStorage.getItem('tenant');

  const config = {
    headers: {
      'Content-Type': 'application/json',
      storedId: storedId,
    },
  };

  const response = await axios.post(`${url}/login`, formData, config);

  return response;
};

export const registerUserApi = async (formData) => {
  const storedId =
    typeof window !== 'undefined' && localStorage.getItem('tenant');

  const config = {
    headers: {
      'Content-Type': 'application/json',
      storedId,
    },
  };
  const response = await axios.post(`${url}`, formData, config);

  return response;
};

export const getUserApi = async () => {
  const setToken =
    typeof window !== 'undefined' && localStorage.getItem('token');
  const storedId =
    typeof window !== 'undefined' && localStorage.getItem('tenant');

  const thisconf = {
    headers: {
      Authorization: `Bearer ${setToken}`,
      storedId,
    },
  };

  const response = await axios.get(`${url}/profile`, thisconf);

  return response;
};

export const changePassApi = async (email) => {
  const setToken =
    typeof window !== 'undefined' && localStorage.getItem('token');
  const storedId =
    typeof window !== 'undefined' && localStorage.getItem('tenant');

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${setToken}`,
      storedId,
    },
  };

  const response = await axios.post(`${url}/forgot`, email, config);

  return response;
};

export const updateUserApi = async (formData) => {
  const setToken =
    typeof window !== 'undefined' && localStorage.getItem('token');
  const storedId =
    typeof window !== 'undefined' && localStorage.getItem('tenant');

  const changeconf = {
    headers: {
      Authorization: `Bearer ${setToken}`,
      'Content-Type': 'application/json',
      storedId,
    },
  };

  const response = await axios.put(`${url}/update`, formData, changeconf);

  return response;
};

export const newPassApi = async (formData) => {
  const storedId =
    typeof window !== 'undefined' && localStorage.getItem('tenant');

  const thisconf = {
    headers: {
      Authorization: `Bearer ${formData.token}`,
      'Content-Type': 'application/json',
      storedId,
    },
  };

  const response = await axios.put(`${url}/change`, formData, thisconf);

  return response;
};

export const createUserApi = async (formData) => {
  const setToken =
    typeof window !== 'undefined' && localStorage.getItem('token');
  const storedId =
    typeof window !== 'undefined' && localStorage.getItem('tenant');

  const editionconf = {
    headers: {
      Authorization: `Bearer ${setToken}`,
      'Content-Type': 'application/json',
      storedId,
    },
  };

  const response = await axios.post(`${url}/create`, formData, editionconf);

  return response;
};

export const callUserApi = async (id) => {
  const setToken =
    typeof window !== 'undefined' && localStorage.getItem('token');
  const storedId =
    typeof window !== 'undefined' && localStorage.getItem('tenant');

  const editionconf = {
    headers: {
      Authorization: `Bearer ${setToken}`,
      storedId,
    },
  };

  const response = await axios.get(`${url}/${id}`, editionconf);

  return response;
};

export const editUserApi = async (payload) => {
  const setToken =
    typeof window !== 'undefined' && localStorage.getItem('token');
  const { formData, id } = payload;
  const storedId =
    typeof window !== 'undefined' && localStorage.getItem('tenant');

  const editionconf = {
    headers: {
      Authorization: `Bearer ${setToken}`,
      'Content-Type': 'application/json',
      storedId,
    },
  };

  const response = await axios.put(
    `${url}/create/${id}`,
    formData,
    editionconf
  );

  return response;
};

export const deleteUserApi = async (id) => {
  const storedId =
    typeof window !== 'undefined' && localStorage.getItem('tenant');
  const setToken =
    typeof window !== 'undefined' && localStorage.getItem('token');

  const editionconf = {
    headers: {
      Authorization: `Bearer ${setToken}`,
      storedId,
    },
  };

  const response = await axios.delete(`${url}/${id}`, editionconf);

  return response;
};
